import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { ValidatorForm } from "react-material-ui-form-validator";
import { Button, Dialog, DialogTitle, MobileStepper } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

import StepCaseForm from "./CreateCaseDialogComponents/StepCaseForm";
import StepFileSelection from "./CreateProjectdialogComponents/StepFileSelection";
import Backend from "../../common/utils/Backend";

const nextStepState = Object.freeze({
  NEXT: "Next",
  CREATE: "Create",
  EDIT: "Edit",
});

const activeStepState = Object.freeze({
  FORM: 0,
  FILE_SELECTION: 1,
});

const CreateCaseDialog = (props) => {
  const { open, setOpen, editCase } = props;
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [activeStep, setActiveStep] = useState(activeStepState.FORM);
  const [formData, setFormData] = useState({ files: [] });
  const [caseColumns, setCaseColumns] = useState([]);
  const [nextButtonState, setNextButtonState] = useState(nextStepState.NEXT);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, []);

  // handle open dialog and set edit case if edit mode
  useEffect(() => {
    if (open) {
      setActiveStep(activeStepState.FORM);
      setFormData({ files: [] });
    }
    if (editCase === null) {
      return;
    }
    setCaseColumns((prevCaseColumns) => {
      let newColumns = prevCaseColumns.map((column) => {
        let newColumn = { ...column };
        if (editCase[column.name]) {
          newColumn.value = editCase[column.name];
        }
        return newColumn;
      });
      return newColumns;
    });
    if (editCase.Files.length > 0) {
      setFormData((prevFormData) => {
        return { ...prevFormData, files: editCase.Files };
      });
    }
  }, [open]);

  useEffect(() => {
    if (activeStep === activeStepState.FORM) {
      setNextButtonState(nextStepState.NEXT);
    } else if (activeStep === activeStepState.FILE_SELECTION) {
      if (editCase) {
        setNextButtonState(nextStepState.EDIT);
      } else {
        setNextButtonState(nextStepState.CREATE);
      }
    }
  }, [activeStep]);

  const handleKeyPress = ({ key }) => {
    if (!open) return;
    if (key === "Enter") handleNext();
  };

  const handleSave = () => {
    const data = {
      files: formData.files,
      caseColumns: caseColumns.filter((column) => column.id !== "FileNum"),
    };
    if (editCase) {
      Backend.editCase(editCase.Id, data).then((response) => {
        if (response.ok) {
          window.showSuccessSnackbar("Case Created");
          props.updateCases();
        }
      });
    } else {
      Backend.createCase(data).then(() => {
        window.showSuccessSnackbar("Case Created");
        props.updateCases();
      });
    }
  };

  const handleNext = () => {
    if (activeStep === activeStepState.FILE_SELECTION) {
      handleSave();
      setOpen(false);
      return;
    }
    setUpdateTrigger((prevUpdateTrigger) => !prevUpdateTrigger);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => setActiveStep(activeStep - 1);

  const onChangeFiles = (e) => setFormData({ ...formData, files: e });

  return (
    <>
      <Dialog onClose={() => setOpen(false)} open={open} maxWidth="lg">
        <DialogTitle>Create New Case</DialogTitle>
        <ValidatorForm onSubmit={handleNext} style={{ width: 800 }}>
          {activeStep === activeStepState.FORM && (
            <StepCaseForm
              updateTrigger={updateTrigger}
              editCase={editCase}
              caseColumns={caseColumns}
              setCaseColumns={setCaseColumns}
            />
          )}
          {activeStep === activeStepState.FILE_SELECTION && (
            <StepFileSelection
              selectionTarget="Case"
              key="2"
              formData={formData}
              onChangeFiles={onChangeFiles}
            />
          )}
          <MobileStepper
            variant="dots"
            steps={2}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button size="small" type="submit">
                {nextButtonState}
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                <KeyboardArrowLeft />
                {"Back"}
              </Button>
            }
          />
        </ValidatorForm>
      </Dialog>
    </>
  );
};

CreateCaseDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  editCase: PropTypes.object,
  updateCases: PropTypes.func.isRequired,
};

export default CreateCaseDialog;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Backend from "../../common/utils/Backend";
import {
  IconButton,
  TableRow,
  TableCell,
  TableHead,
  Table,
  TableBody,
  Button,
  Card,
  CardContent,
  Typography,
  CardActions,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

// Icons
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import GroupDetailsDialog from "./GroupDetailsDialog";

const useStyles = makeStyles({
  paper: {
    padding: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

function GroupsContainer(props) {
  const [groupDetailsOpen, setGroupDetailsOpen] = useState(false);
  const [group, setGroup] = useState(null);

  const classes = useStyles();
  const { user, userGroupIds } = props;

  useEffect(() => {
    props.updateGroups();
  }, []);

  const newGroupClick = () => {
    setGroup(null);
    setGroupDetailsOpen(true);
  };

  const deleteGroup = (id) => {
    window.openResponseDialog("Delete Group forever?", (response) => {
      if (response) {
        Backend.deleteGroup(id, (res) => {
          if (res.success === true) {
            props.updateGroups();
            window.showSuccessSnackbar("Group deleted!");
          }
        });
      }
    });
  };

  const editGroup = (group) => {
    setGroup(group);
    setGroupDetailsOpen(true);
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">Groups</Typography>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Group Name</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.groups &&
              props.groups.map((group, idx) => {
                const groupCanBeEdited =
                  props.isSuperAdmin ||
                  (user.group.canEditGroups === true &&
                    !userGroupIds.includes(group.id));
                return (
                  <TableRow key={group.id}>
                    <TableCell component="th" scope="row">
                      {group.name}
                    </TableCell>
                    <TableCell align="right">
                      {idx > 0 && [
                        <Tooltip
                          key="grpEditBtn"
                          disableInteractive
                          title={
                            groupCanBeEdited
                              ? "Edit Group details"
                              : "No Permission"
                          }
                        >
                          <span>
                            <IconButton
                              disabled={!groupCanBeEdited}
                              onClick={() => editGroup(group)}
                              size="large"
                            >
                              <EditIcon />
                            </IconButton>
                          </span>
                        </Tooltip>,
                        <Tooltip
                          key="grpDeleteBtn"
                          disableInteractive
                          title={
                            groupCanBeEdited
                              ? "Delete group forever"
                              : "No Permission"
                          }
                        >
                          <span>
                            <IconButton
                              disabled={!groupCanBeEdited}
                              onClick={() => deleteGroup(group.id)}
                              size="large"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </span>
                        </Tooltip>,
                      ]}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </CardContent>
      <CardActions>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={newGroupClick}
        >
          Add new Group
        </Button>
      </CardActions>
      <GroupDetailsDialog
        open={groupDetailsOpen}
        user={user}
        group={group}
        groups={props.groups}
        onClose={() => {
          setGroupDetailsOpen(false);
          setGroup(null);
        }}
        onApply={() => {
          setGroupDetailsOpen(false);
          setGroup(null);
          props.updateGroups();
        }}
      />
    </Card>
  );
}

GroupsContainer.propTypes = {
  groups: PropTypes.array,
  updateGroups: PropTypes.func,
  user: PropTypes.object.isRequired,
  isSuperAdmin: PropTypes.bool,
  userGroupIds: PropTypes.array.isRequired,
};

export default GroupsContainer;

//Framework imports
import React, { useContext } from "react";
import PropTypes from "prop-types";
//External packages
import { IconButton, Tooltip } from "@mui/material";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// HSA KIT Components
import { FileViewerContext } from "../FileViewer";

/*
Function to toggle the toolbar.
The const "toggle" sets the Imagepreview and Foldertree width to 0, when these two have a value >0
*/

function ToggleButtonFV(props) {
  const fileViewer = useContext(FileViewerContext);
  // const [newPreviewWidth, setNewPreviewWidth] = useState(0);
  // const [newTreeWidth, setNewTreeWidth] = useState(0);

  const styles = {
    toolbarButton: {
      display: "inline-block",
      width: 40,
      height: 40,
      padding: 0,
      margin: 0,
      fontSize: 22,
    },
  };

  /*
  Errorfunction to toggle the Toolbar
  */
  const toggle = () => {
    //maybe add notification that sidebar wont toggle if no file selected
    if (fileViewer.selectedFile === null) return;
    if (fileViewer.oldPreviewWidth && fileViewer.oldTreeWidth > 0) {
      fileViewer.setPreviewWidth(fileViewer.oldPreviewWidth);
      fileViewer.setTreeWidth(fileViewer.oldTreeWidth);
      fileViewer.setOldPreviewWidth(0);
      fileViewer.setOldTreeWidth(0);
    } else {
      fileViewer.setOldPreviewWidth(fileViewer.previewWidth);
      fileViewer.setOldTreeWidth(fileViewer.treeWidth);
      fileViewer.setPreviewWidth(0);
      fileViewer.setTreeWidth(0);
    }
    props.updateDimensions();
  };

  return (
    <Tooltip disableinteractive="true" placement="left" title="Toggle Sidebar">
      <IconButton
        className={`${{ ...styles.toolbarButton }}`}
        style={{ ...styles.toolbarButton }}
        onClick={toggle}
      >
        <FontAwesomeIcon icon={faExchangeAlt} />
      </IconButton>
    </Tooltip>
  );
}

ToggleButtonFV.propTypes = {
  updateDimensions: PropTypes.func,
};

export default ToggleButtonFV;

// Framework imports
import React, { useState, useEffect, useContext, useRef } from "react";
import PropTypes from "prop-types";
import { withStyles, createStyles } from "@mui/styles";

// External packages
import WaveSurfer from "wavesurfer.js";
import SpectrogramPlugin from "wavesurfer.js/dist/plugins/spectrogram";
// import TimelinePlugin from "wavesurfer.js/dist/plugins/timeline";

// HSA Imports
import { AudioFileContext } from "./AudioFileContainer";

// mui imports
import { Button, Tooltip } from "@mui/material";

// icon imports
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/**
 * Collected (reusable) styles for page elements.
 */
const styles = createStyles({
  root: {
    width: "100%",
    borderBottom: "1px solid  #EBEBEB",
  },
  toggleButton: {
    width: "100%",
  },
  waveVisible: {
    "& spectrogram": {
      height: "36px !important",
    },
  },
  spectrogramVisible: {
    background: "green",
    "& .wrapper:first-child": {
      height: "36px !important",
    },
  },
});

/**
 * Displays the magitude spectrogram of an audio file / stream.
 * @returns React node for MagnitudeSpectrogram
 */
function MagnitudeSpectrogram(props) {
  const { classes, leftWidth, setWaveSurfer, setVisibleTimeRange } = props;
  const AudioFile = useContext(AudioFileContext);
  const wavesurfer = useRef();
  const waveformRef = useRef();
  const [waveVisible, setWaveVisible] = useState(true);
  const [spectrogramVisible, setspectrogramVisible] = useState(true);
  const zoomRef = useRef(1);
  const [minZoom, setMinZoom] = useState(null);

  useEffect(() => {
    initWS();
    // add event listener for mouse wheel movement
    window.addEventListener("wheel", zoom);
    return () => {
      // remove event listener for mouse wheel movement
      window.removeEventListener("wheel", zoom);
    };
  }, []);

  useEffect(() => {
    if (wavesurfer.current) {
      wavesurfer.current.setOptions({
        height: waveVisible ? 128 : 36,
      });
    }
  }, [waveVisible]);

  useEffect(() => {
    if (wavesurfer.current) {
      const plugins = wavesurfer.current.plugins;
      let spectrogramIdx = -1;
      for (let i = 0; i < plugins.length; i++) {
        if (plugins[i] instanceof SpectrogramPlugin) {
          spectrogramIdx = i;
          break;
        }
      }

      if (spectrogramVisible && spectrogramIdx === -1) {
        addSpectrogram();
        return;
      }
      if (!spectrogramVisible && spectrogramIdx > -1) {
        plugins[spectrogramIdx].destroy();
        plugins.splice(spectrogramIdx, 1);
        return;
      }
    }
  }, [spectrogramVisible]);

  useEffect(() => {
    if (waveVisible === false) {
      wavesurfer.current.height = spectrogramVisible ? 256 : 36;
    }
  }, [spectrogramVisible, waveVisible]);

  const zoom = (e) => {
    if (!wavesurfer.current) return;
    let zoomDelta = 0.1 * zoomRef.current;
    if (e.deltaY > 0) {
      zoomDelta = -zoomDelta;
    }
    const newZoom = zoomRef.current + zoomDelta;
    zoomRef.current = Math.max(getMinZoom(), newZoom);
    wavesurfer.current.zoom(newZoom);
  };

  const getMinZoom = () => {
    if (minZoom) return minZoom;
    if (!wavesurfer.current) return 1;
    const audioDuration = wavesurfer.current.getDuration();
    const waveformWidth = wavesurfer.current.renderer.container.clientWidth;
    const zoomToFit = waveformWidth / audioDuration;
    setMinZoom(zoomToFit);
    return zoomToFit;
  };

  const addSpectrogram = () => {
    if (wavesurfer.current && wavesurfer.current.plugins.length === 0) {
      wavesurfer.current.registerPlugin(
        SpectrogramPlugin.create({
          showTime: true,
          labels: true,
        })
      );
      return;
    }
  };

  const initWS = () => {
    const ws = WaveSurfer.create({
      container: waveformRef.current,
      autoCenter: false,
      autoScroll: true,
      labels: true,
      cursorWidth: 2,
    });
    ws.setVolume(0.1);

    ws.load(AudioFile.audioFileSrc);
    ws.on("timeupdate", (currentTime) => {
      AudioFile.updateTimeStampPosition(currentTime);
    });
    ws.on("scroll", (visibleStartTime, visibleEndTime) => {
      AudioFile.audio.visibleTimeRange = [visibleStartTime, visibleEndTime];

      setVisibleTimeRange([visibleStartTime, visibleEndTime]);
    });
    ws.on("zoom", () => {
      const wrapper = ws.getWrapper();
      const parent = wrapper.parentElement;

      const parentWidth = parent.clientWidth;
      const contentWidth = wrapper.clientWidth;
      const duration = AudioFile.audio.duration();
      const currentTime = AudioFile.audio.seek();
      const visibleDuration = duration * (parentWidth / contentWidth);
      const widthToDurationFactor = parentWidth / visibleDuration;
      const visibleLeft = currentTime - visibleDuration / 2;
      const scrollLeft = Math.max(0, visibleLeft * widthToDurationFactor);
      parent.scrollTo(scrollLeft, 0);

      if (scrollLeft === 0) {
        AudioFile.audio.visibleTimeRange = [0, visibleDuration];
        setVisibleTimeRange([0, visibleDuration]);
      }
    });
    wavesurfer.current = ws;
    addSpectrogram();
    setWaveSurfer(ws);
  };

  const onToggleWave = () => {
    setWaveVisible(!waveVisible);
  };

  const onToggleSpectogram = () => {
    setspectrogramVisible(!spectrogramVisible);
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          width: leftWidth,
          display: "grid",
          gridTemplateRows: "auto 1fr",
        }}
      >
        <Tooltip
          title={(waveVisible ? "hide" : "show") + " Wave"}
          placement="right"
          disableInteractive
        >
          <Button
            style={{ height: waveVisible ? "128px" : "36px" }}
            className={classes.toggleButton}
            onClick={onToggleWave}
          >
            {waveVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
        </Tooltip>
        <Tooltip
          title={(spectrogramVisible ? "hide" : "show") + " Spectogram"}
          placement="right"
          disableInteractive
        >
          <Button className={classes.toggleButton} onClick={onToggleSpectogram}>
            {spectrogramVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
        </Tooltip>
      </div>
      <div
        id="MagnitudeSpectrogram"
        className={spectrogramVisible ? "" : "spectogramMinimized"}
        style={{ width: "100%", overflow: "auto" }}
      >
        {/* Visualisation */}
        <div ref={waveformRef}></div>
      </div>
    </div>
  );
}

MagnitudeSpectrogram.propTypes = {
  classes: PropTypes.object.isRequired,
  timestamp: PropTypes.number,
  leftWidth: PropTypes.number,
  setWaveSurfer: PropTypes.func,
  setVisibleTimeRange: PropTypes.func,
};

export default withStyles(styles)(MagnitudeSpectrogram);

//Framework imports
import React, { useState } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";

//Imports from Project
import ToggleButtonFV from "./components/ToggleButtonFV";
import ToggleButton from "../viewer/components/ToggleButton";
import { Fade, Popper, Paper, IconButton, Tooltip } from "@mui/material";

import { ZoomIn, ZoomOut, ViewQuilt } from "@mui/icons-material";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const styles = {
  toolbar: {
    position: "relative",
    margin: 0,
    padding: 0,
    width: "40px",
    background: "#fff",
    height: "100%",
    display: "grid",
    gridTemplateRows: "1fr auto",
    overflowX: "hidden",
    overflowY: "auto",
  },
  buttonGroup: {
    display: "inline-block",
    height: 40,
    padding: 0,
    margin: 0,
    fontSize: 22,
  },
  buttonStyle: {
    width: "40px",
    height: "40px",
    margin: 0,
    display: "inline-block",
    padding: 0,
    fontSize: "22px",
  },
  textButtonStyle: {
    fontSize: 18,
    fontWeight: "bold",
  },
  toolbarButtonIcon: {
    verticalAlign: "-4px",
  },
};

const style = (theme) => ({
  toolBar: {
    position: "relative",
    margin: 0,
    padding: 0,
    marginRight: 5,
    background: "#fff",
    height: "100%",
    display: "grid",
    gridTemplateRows: "1fr auto",
    overflowX: "hidden",
    overflowY: "auto",
  },
  toolbarButton: {
    display: "inline-block",
    width: 40,
    height: 40,
    padding: 0,
    margin: 0,
    fontSize: 22,
  },
  toolbarButtonIcon: {
    verticalAlign: "-4px",
  },
  textIcon: {
    fontSize: 18,
    fontWeight: "bold",
  },
  toolbarButtonChecked: {
    width: 40,
    color: theme.palette.primary.main,
  },
  divider: {
    marginLeft: 5,
    marginRight: 5,
  },
});

/*
Function to place and to style the buttons 
*/
function Toolbar(props) {
  const [openAdjust, setOpenAdjust] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <div
      style={{
        ...styles.toolbar,
      }}
    >
      <div>
        <Popper
          open={openAdjust}
          anchorEl={anchorEl}
          placement="left"
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                style={{
                  height: 40,
                }}
              >
                {/* <ToggleButton
                    classes={classes}
                    title={"Toggle time bar"}
                    name={"showTimeBar"}
                    value={rendererRef.getLayoutElement("showTimeBar")}
                    icon={"faStopwatch"}
                    //onToggle={rendererRef.onToggle}
                    onToggle={onToggleTimeBar}
                    disabled={Boolean(!displayTimeBar)}
                  />

                  <ToggleButton
                    classes={classes}
                    title={"Toggle Z-stack"}
                    name={"showZStackBar"}
                    value={rendererRef.getLayoutElement("showZStackBar")}
                    icon={"faLayerGroup"}
                    onToggle={rendererRef.onToggle}
                    disabled={Boolean(!displayZStackBar)}
                  />

                  <ToggleButton
                    classes={classes}
                    title={"Toggle scalebar"}
                    name={"showScaleBar"}
                    value={rendererRef.getLayoutElement("showScaleBar")}
                    icon={"faRuler"}
                    onToggle={rendererRef.onToggle}
                    disabled={Boolean(ome && ome.physicalSizeX)}
                  />

                  <ToggleButton
                    classes={classes}
                    title={"Toggle Object Index"}
                    name={"showObjectIdx"}
                    value={rendererRef.getLayoutElement("showObjectIdx")}
                    icon={"faInfoCircle"}
                    onToggle={rendererRef.onToggle}
                  /> */}

                <ToggleButton
                  classes={props.classes}
                  title={"Toggle Navigator"}
                  name={"showMiniMap"}
                  value={props.rendererRef.getLayoutElement("showMiniMap")}
                  icon={"MapIcon"}
                  onToggle={props.rendererRef.onToggle}
                />

                <ToggleButton
                  classes={props.classes}
                  title={"Toggle image info"}
                  name={"showImageInfo"}
                  value={props.rendererRef.getLayoutElement("showImageInfo")}
                  icon={"faInfo"}
                  onToggle={props.rendererRef.onToggle}
                />

                <ToggleButton
                  classes={props.classes}
                  title={"Toggle zoom info bar"}
                  name={"showZoomBar"}
                  value={props.rendererRef.getLayoutElement("showZoomBar")}
                  icon={"LinearScaleIcon"}
                  onToggle={props.rendererRef.onToggle}
                />

                {/* <ToggleButton
                    classes={classes}
                    title={"Toggle File navigation buttons"}
                    name={"showFileNavButtons"}
                    value={rendererRef.getLayoutElement("showFileNavButtons")}
                    icon={"UnfoldMore"}
                    onToggle={rendererRef.onToggle}
                  /> */}
              </Paper>
            </Fade>
          )}
        </Popper>
        {props.splitscreenCount === 1 && (
          <Tooltip placement="left" title="Adjust layout">
            <IconButton
              className={props.classes.toolbarButton}
              size="small"
              onClick={(e) => {
                setOpenAdjust(!openAdjust);
                setAnchorEl(e.target);
              }}
            >
              <ViewQuilt style={{ ...styles.toolbarButtonIcon }} />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div>
        <hr></hr>
        <div>
          <Tooltip
            disableinteractive="true"
            placement="left"
            title="Download current view"
          >
            <IconButton
              onClick={() => {
                props.onSaveScreenshot();
              }}
              style={{ ...styles.buttonStyle }}
            >
              <FontAwesomeIcon icon={faCamera} />
            </IconButton>
          </Tooltip>
        </div>
        <div>
          <Tooltip disableinteractive="true" placement="left" title="Zoom 100%">
            <IconButton
              onClick={() => {
                props.triggerZoomAction("oneToN", 1);
              }}
              style={{ ...styles.buttonStyle, ...styles.textButtonStyle }}
            >
              <span>{"1:1"}</span>
            </IconButton>
          </Tooltip>
        </div>
        <div>
          <Tooltip
            disabledisableinteractive="true"
            placement="left"
            title="Zoom 75%"
          >
            <IconButton
              onClick={() => {
                props.triggerZoomAction("oneToN", 4);
              }}
              style={{ ...styles.buttonStyle, ...styles.textButtonStyle }}
            >
              <span>{"1:4"}</span>
            </IconButton>
          </Tooltip>
        </div>
        <div>
          <Tooltip disableinteractive="true" placement="left" title="Zoom 10%">
            <IconButton
              onClick={() => {
                props.triggerZoomAction("oneToN", 10);
              }}
              style={{ ...styles.buttonStyle, ...styles.textButtonStyle }}
            >
              <span>{"1:10"}</span>
            </IconButton>
          </Tooltip>
        </div>
        <div>
          <Tooltip
            disableinteractive="true"
            placement="left"
            title="Zoom to fit"
          >
            <IconButton
              style={{ ...styles.buttonStyle }}
              onClick={() => {
                props.triggerZoomAction("zoomFit", 0);
              }}
            >
              <ZoomOutMapIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div>
          <div>
            <Tooltip disableinteractive="true" placement="left" title="Zoom In">
              <IconButton
                onClick={() => {
                  props.triggerZoomAction("increase", 0);
                }}
                style={{ ...styles.buttonStyle }}
              >
                <ZoomIn />
              </IconButton>
            </Tooltip>
            <Tooltip
              disableinteractive="true"
              placement="left"
              title="Zoom Out"
            >
              <IconButton
                onClick={() => {
                  props.triggerZoomAction("decrease", 0);
                }}
                style={{ ...styles.buttonStyle }}
              >
                <ZoomOut />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        {props.updateDimensions && (
          <ToggleButtonFV updateDimensions={props.updateDimensions} />
        )}
      </div>
    </div>
  );
}

Toolbar.propTypes = {
  triggerZoomAction: PropTypes.func,
  onSaveScreenshot: PropTypes.func,
  updateDimensions: PropTypes.func,
  splitscreenCount: PropTypes.number,
  rendererRef: PropTypes.object,
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Toolbar);

// Framework imports
import React, { useContext } from "react";
import PropTypes from "prop-types";

// Additional pacakges
import { Grid } from "@mui/material";

// HSA imports
import { AudioViewerContext } from "./AudioViewer";
import { AnnotationAction } from "../common/components/RoiTypes";
import Structure from "../common/components/Structure";
// import StructureTree from "./sidebar/StructureTree";
import AudioSideBarDock from "./sidebar/AudioSideBarDock";

/**
 * Collected (reusable) styles for page elements.
 */
const styles = {
  root: {
    padding: "5px",
    height: "100%",
    background: "#fff",
    borderLeft: "5px solid  #EBEBEB",
    display: "grid",
    gridTemplateRows: "auto",
    overflowY: "auto",
  },
};

/**
 * Sidebar with all detailed functions for the audio viewer.
 * Equivalent to the sidebar in the image viewer, only for audio options.
 * @param {bool} hide Is the sidebar hidden? Defaults to false.
 * @param {number} width The overall width of the sidebar.
 * @returns React node for Audio Sidebar
 */
export default function AudioSideBar(props) {
  const { hide = false, width = 455, ...other } = props;
  const AudioViewer = useContext(AudioViewerContext);

  /**
   * Trigger a save event for all annotations of a structure.
   * @param {Structure} structure The structure to save annotations for.
   */
  const triggerAction = (structure, action) => {
    if (!(structure instanceof Structure))
      throw TypeError(
        `structure must be of type Structure, recieved ${typeof structure}: ${structure}`
      );
    if (!Object.values(AnnotationAction).includes(action)) {
      throw TypeError(`Invalid action, received ${typeof action}: ${action}`);
    }

    // Do not trigger event twice.
    if (
      AudioViewer.childActions.some(
        (a) =>
          a.fileId === AudioViewer.selectedFile.id &&
          a.structureId === structure.id &&
          a.task === action
      )
    )
      return;

    AudioViewer.setChildActions([
      ...AudioViewer.childActions,
      {
        fileId: AudioViewer.selectedFile.id,
        structureId: structure.id,
        task: action,
      },
    ]);
  };

  return hide ? (
    <></>
  ) : (
    <Grid sx={{ ...other.style, ...other.sx, ...styles.root, width: width }}>
      <AudioSideBarDock
        user={AudioViewer.user}
        project={AudioViewer.project}
        structures={AudioViewer.structures}
        setStructures={AudioViewer.setStructures}
        selectedStructure={AudioViewer.selectedStructure}
        setSelectedStructure={(s) => AudioViewer.setSelectedStructure(s)}
        triggerAction={triggerAction}
      />
    </Grid>
  );
}

AudioSideBar.propTypes = {
  hide: PropTypes.bool,
  width: PropTypes.number,
};

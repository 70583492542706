// Framework imports
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";

// HSA imports
import TrainAIModel from "../../home/dialogs/TrainAIModel";

/**
 *
 * @param {object} project The current project.
 * @param {array} structures The structures of the current project.
 * @param {function} setStructures The function to set/update the structures of the current project.
 * @returns {JSX.Element} The AI training tab.
 */
export default function AiTrainingTab(props) {
  const { project, structures, setStructures, ome } = props;
  const [openTrainingDialog, setOpenTrainingDialog] = useState(false);

  return (
    <div style={{ margin: "10px" }}>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => {
          setOpenTrainingDialog(true);
        }}
      >
        Train AI
      </Button>
      <TrainAIModel
        open={openTrainingDialog}
        setOpen={setOpenTrainingDialog}
        project={project}
        structures={structures}
        setStructures={setStructures}
        ome={ome}
      />
    </div>
  );
}

AiTrainingTab.propTypes = {
  project: PropTypes.object.isRequired,
  structures: PropTypes.array,
  setStructures: PropTypes.func,
  ome: PropTypes.object,
};

AiTrainingTab.defaultProps = {
  structures: [],
  setStructures: () => {},
};

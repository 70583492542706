// Framework imports
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

// External packages
// import { Grid } from "@mui/material";

// HSA Imports
import { FileViewerContext } from "./FileViewer";
import Backend from "../common/utils/Backend";
import Renderer from "./Renderer";

/**
 * Collected (reusable) styles for page elements.
 */
// const styles = {
//   root: {
//     height: "100%",
//     display: "grid",
//     borderRight: "5px solid  #EBEBEB",
//     justifyContent: "center",
//     textAlign: "center",
//     background: "#fff",
//   },
//   img: {
//     with: "800px",
//     height: "600px",
//   },
// };

/**
 * Element containing all visualisations of all audio files / streams in a project.
 */
export default function FileRenderer(props) {
  const fileViewer = useContext(FileViewerContext);
  const [histogramConfigs, setHistogramConfigs] = useState({}); //
  const [omeDict, setOmeDict] = useState(null);

  let isChained = false;
  let showZStackBar = false;
  let displayZStackBar = false;
  let displayTimeBar = false;
  let showFullscreen = false;
  let zoomROI = false;
  let changingFile = false;
  let chainListFileIds = {};
  let rendererDict = {};

  //triggers on new Selected File
  useEffect(() => {
    if (!props.currentFileId) return;
    Backend.loadImage(
      { id: props.currentFileId },
      (ome) => {
        if (!ome.fileName) return;
        showZStackBar = ome.sizeZ > 1;
        displayTimeBar = ome.sizeT > 1;
        displayZStackBar = ome.sizeZ > 1;

        setOmeDict({
          ...omeDict,
          [props.currentFileId]: ome,
        });
      },
      (err) => window.openErrorDialog(err),
      () => {}
    );

    setHistogramConfigs({
      ...histogramConfigs,
      [props.currentFileId]: {
        channels: [
          {
            color: "#ffffff",
            enabled: true,
            gamma: 1,
            max: 250,
            min: 0,
            name: "TL Brightfield",
            type: "brightfield",
          },
        ],
        nChannels: 1,
      },
    });

    return () => {
      setOmeDict(null);
    };
  }, [props.currentFileId]);

  return (
    <div style={{ height: "100%" }}>
      {histogramConfigs[props.currentFileId] &&
        omeDict &&
        omeDict[props.currentFileId] &&
        fileViewer.activeFileId && (
          <Renderer
            activeFileId={fileViewer.activeFileId}
            canvasId={"caseViewerCanvas" + props.index} // +index
            chainListFileIds={chainListFileIds}
            // changeHiConfig={} setsViewerState
            changingFile={changingFile}
            componentRef={
              (c) =>
                fileViewer.setRendererDict({
                  ...fileViewer.rendererDict,
                  [props.currentFileId]: c,
                }) //currentFileId
            }
            // dimensionsUpdated={fileViewer.dimensionsUpdated}
            displayTimeBar={displayTimeBar}
            displayZStackBar={displayZStackBar}
            fileId={props.currentFileId}
            // fsChain={} setsViewerState
            histogramConfig={histogramConfigs[props.currentFileId]}
            // id={} not used
            isActive={fileViewer.activeFileId === props.currentFileId}
            isChained={isChained}
            ome={omeDict[props.currentFileId]}
            onChangeChain={() => {
              console.log("change chain");
            }}
            onSelectFile={() => {
              console.log("selectFile");
            }}
            // openNextFile={}
            // openPrevFile={}
            // project={}
            // projectId={}
            rendererDict={rendererDict}
            rightSpace={545}
            // setChangingFile={}, maybe need later
            showFullscreen={showFullscreen}
            showZStackBar={showZStackBar}
            splitscreenCount={fileViewer.splitscreenFileIds.length}
            splitscreenFileIds={fileViewer.splitscreenFileIds}
            splitscreenIdx={props.index}
            tZoomROI1={() => {
              console.log("TZOom");
            }}
            updateGlobalT={() => {
              console.log("gloablT");
            }}
            updateGlobalZ={() => {
              console.log("globalZ");
            }}
            // viewerConfig={}
            zoomBottom={0}
            zoomLeft={0}
            zoomROI={zoomROI}
            zoomRight={0}
            zoomTop={0}
            getMousePosition={props.getMousePosition}
          />
        )}
    </div>
  );
}

FileRenderer.propTypes = {
  currentFileId: PropTypes.string,
  index: PropTypes.number,
  getMousePosition: PropTypes.func,
};

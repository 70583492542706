import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import withStyles from "@mui/styles/withStyles";
import { withAllViewerContexts } from "../contexts/AllViewerContexts";
import {
  Grid,
  Typography,
  Tooltip,
  Divider,
  List,
  IconButton,
} from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Backend from "../../common/utils/Backend";

// define the component's styling
const styles = () => ({
  fileTreeView: {
    width: "100%",
    margin: 0,
    background: "#fff",
    position: "relative",
  },
  sidebarHandle: {
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    width: 5,
    background: "#EBEBEB",
    cursor: "pointer",
    "&:hover": {
      background: "#0673C1",
    },
  },
  closeButton: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  expensionSummary: {
    marginTop: 0,
    marginBottom: 0,
    background: "#ff0000",
  },
  labelWrap: {
    paddingRight: 15,
    width: "100%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "inline-block",
  },
  previewImageContainer: {
    position: "relative",
  },
  importText: {
    padding: 5,
    fontWeight: "bold",
    lineHeight: "45px",
    display: "inline-block",
  },
  lineThrough1: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    pointerEvents: "none",
    background:
      "linear-gradient(to bottom right, transparent calc(50% - 1px), red, transparent calc(50% + 1px) )",
  },
  lineThrough2: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    pointerEvents: "none",
    background:
      "linear-gradient(to top right, transparent calc(50% - 1px), red, transparent calc(50% + 1px) )",
  },
  previewImageNotSelected: {
    border: "1px solid #ccc",
  },
  previewImageSelected: {
    border: "3px solid #0673C1!important",
  },
  previewImageInSplitscreen: {
    border: "3px dashed #0673C1!important",
  },
  fileItem: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  sceneItem: {
    position: "relative",
    display: "inline-block",
    width: "fit-content",
    paddingLeft: "5px",
    objectFit: "contain",
    // border: "1px solid #ccc!important",
    cursor: "pointer",
    "& button": {
      padding: 0,
      visibility: "hidden",
      zIndex: 999999,
      width: 0,
      height: 0,
    },
    "&:hover": {
      boxShadow: "1px 1px 2px #333",
      "& button": {
        visibility: "visible",
        zIndex: 999999999999,
      },
    },
  },
  divider: {
    paddingLeft: 5,
    paddingRight: 15,
  },
  filesList: {
    height: "calc(100vh - 480px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
  excludeAllContainer: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  excludeButton: {
    position: "absolute",
    top: 0,
    right: 0,
  },
});

class FileTreeView extends Component {
  constructor(props) {
    super(props);
    this.overflowListRef = React.createRef(); // Create a ref object
    this.state = {
      missingThumbnails: {},
      currentSceneFilterIdx: 0,
    };
    this.imageListItemRefs = [];
  }

  handleScroll = (e) => {
    this.props.persistentStorage.save("files_scroll", e.target.scrollTop);
  };

  setMountedState = (stateObject, callback) => {
    if (this._isMounted) {
      this.setState(stateObject, callback);
    }
  };

  componentDidMount() {
    this._isMounted = true;
    this.overflowListRef.current.addEventListener("scroll", this.handleScroll);

    let scrollPosition = this.props.persistentStorage.load("files_scroll"); //localStorage.getItem(scrollKey);
    this.overflowListRef.current.scrollTo(0, scrollPosition);
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.overflowListRef.current.removeEventListener(
      "scroll",
      this.handleScroll
    );
  }

  hasNotExcludedScenes = (files) => {
    const result = files.some((file) => !file.excludeScene);
    return result;
  };

  isInView = (el) => {
    if (
      !this.imageList ||
      this.imageList.getBoundingClientRect().height === 0
    ) {
      this.imageList = document.getElementById("SceneImageList");
      if (
        this.imageList &&
        this.imageList.getAttribute("listener") !== "true"
      ) {
        this.imageList.addEventListener("scroll", this.handleImageListScroll);
      }
    }
    let result = false;
    if (this.imageList && el) {
      let parentTop = this.imageList.getBoundingClientRect().top;
      let parentHeight = this.imageList.getBoundingClientRect().height;
      let elTop = el.getBoundingClientRect().top;
      let elHeight = el.getBoundingClientRect().height;

      result = elTop + elHeight > parentTop && elTop < parentTop + parentHeight;
    }
    return result;
  };

  handleImageListScroll = () => {
    this.forceUpdate();
  };

  render() {
    const { currentSceneFilterIdx } = this.state;
    const {
      classes,
      project,
      onSelectFile,
      fileId,
      onExcludeFilesToggle,
      splitscreenFileIds,
      projectContext,
    } = this.props;
    const { fileGroups } = projectContext;

    const fileNames = Object.keys(fileGroups).sort();
    let selectedPath = "";
    for (let scenes of Object.values(fileGroups)) {
      for (let file of scenes) {
        if (fileId === file.id) {
          selectedPath = file.sourcePath;
        }
      }
    }

    let maxSceneIdx = 0;
    for (let file of project.files) {
      if (file.scene > maxSceneIdx) {
        maxSceneIdx = file.scene;
      }
    }

    return (
      <Grid item className={classes.fileTreeView}>
        {project && (
          <Grid item xs={12}>
            <Typography variant="h6" style={{ fontSize: "18px" }}>
              {"Files (" + fileNames.length + "):"}
            </Typography>
            <div className={classes.excludeAllContainer}>
              {maxSceneIdx > 0 && (
                <Tooltip
                  title={
                    "Include only scene " +
                    (((currentSceneFilterIdx + 1) % (maxSceneIdx + 1)) + 1) +
                    " of each file!"
                  }
                >
                  <IconButton
                    style={{ color: "#757575" }}
                    size="small"
                    onClick={() => {
                      const projectsToExclude = project.files.filter((file) => {
                        return (
                          file.scene === currentSceneFilterIdx &&
                          file.excludeScene !== true
                        );
                      });
                      const projectsToInclude = project.files.filter((file) => {
                        return (
                          file.scene !== currentSceneFilterIdx &&
                          file.excludeScene === true
                        );
                      });
                      onExcludeFilesToggle(projectsToExclude, true);
                      onExcludeFilesToggle(projectsToInclude, false);
                      if (maxSceneIdx > currentSceneFilterIdx) {
                        this.setState({
                          currentSceneFilterIdx: currentSceneFilterIdx + 1,
                        });
                      } else {
                        this.setState({ currentSceneFilterIdx: 0 });
                      }
                    }}
                  >
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        border: "2px solid rgb(102, 102, 102)",
                        borderRadius: "50%",
                        textAlign: "center",
                        lineHeight: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      {currentSceneFilterIdx + 1}
                    </div>
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip
                disableInteractive
                title={
                  this.hasNotExcludedScenes(project.files)
                    ? "Exclude all from analysis"
                    : "Include all to analysis"
                }
              >
                <IconButton
                  style={{ color: "#757575" }}
                  size="small"
                  onClick={() => {
                    const toExclude = this.hasNotExcludedScenes(project.files);
                    console.log("project.files:", project.files);
                    onExcludeFilesToggle(
                      project.files.filter(
                        (file) => file.excludeScene !== toExclude
                      ),
                      toExclude
                    );
                  }}
                >
                  {this.hasNotExcludedScenes(project.files) ? (
                    <RemoveCircleIcon />
                  ) : (
                    <AddCircleIcon />
                  )}
                </IconButton>
              </Tooltip>
            </div>

            <List
              id={"SceneImageList"}
              ref={this.overflowListRef}
              className={classes.filesList}
            >
              {fileNames.map((fn, fn_index) => (
                <React.Fragment key={fn_index}>
                  <div
                    ref={(el) => {
                      if (!this.imageListItemRefs[fn_index]) {
                        this.imageListItemRefs[fn_index] = el;
                        this.forceUpdate();
                      }
                    }}
                    className={classes.fileItem}
                    style={{
                      marginTop: fn_index === 0 && 0,
                      background:
                        fn === selectedPath ? "rgba(0, 0, 0, 0.08)" : "none",
                    }}
                  >
                    {this.isInView(this.imageListItemRefs[fn_index]) ? (
                      <React.Fragment>
                        <Typography noWrap={true} className={classes.labelWrap}>
                          {fileGroups[fn][0].fileName}
                        </Typography>
                        <div>
                          {fileGroups[fn]
                            .sort((a, b) => a.scene - b.scene)
                            .map((sceneFile, f_id) => (
                              <div
                                key={f_id}
                                className={classes.sceneItem}
                                style={{
                                  display: sceneFile.sceneName
                                    ? "block"
                                    : "inline-block",
                                  margin: 10,
                                  width: sceneFile.sceneName
                                    ? "inherit"
                                    : "fit-content",
                                }}
                              >
                                <Tooltip
                                  disableInteractive
                                  placement="top"
                                  title={
                                    !sceneFile.excludeScene
                                      ? "Exclude from analysis"
                                      : "Include in analysis"
                                  }
                                >
                                  <IconButton
                                    className={classes.excludeButton}
                                    style={{ color: "#757575" }}
                                    size="small"
                                    onClick={() =>
                                      onExcludeFilesToggle(
                                        [sceneFile],
                                        !sceneFile.excludeScene
                                      )
                                    }
                                  >
                                    {!sceneFile.excludeScene ? (
                                      <RemoveCircleIcon />
                                    ) : (
                                      <AddCircleIcon />
                                    )}
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  disableInteractive
                                  key={f_id}
                                  title={"Scene " + (sceneFile.scene + 1)}
                                >
                                  <div
                                    className={classes.previewImageContainer}
                                    onClick={() => onSelectFile(sceneFile.id)}
                                  >
                                    <div
                                      className={
                                        classNames(
                                          (fileId === sceneFile.id &&
                                            classes.previewImageSelected) ||
                                            (splitscreenFileIds.includes(
                                              sceneFile.id
                                            ) &&
                                              classes.previewImageInSplitscreen)
                                        ) ||
                                        (fileId !== sceneFile.id &&
                                          classes.previewImageNotSelected)
                                      }
                                    >
                                      {this.state.missingThumbnails[
                                        sceneFile.id
                                      ] && (
                                        <div className={classes.importText}>
                                          import
                                        </div>
                                      )}
                                      {sceneFile.sceneName ? (
                                        <span>{sceneFile.sceneName}</span>
                                      ) : (
                                        <img
                                          draggable="true"
                                          onDragStart={(e) => {
                                            e.dataTransfer.setData(
                                              "text/plain",
                                              sceneFile.id
                                            );
                                          }}
                                          className={classes.previewImage}
                                          height="45"
                                          src={Backend.renderThumbnail(
                                            sceneFile.id
                                          )}
                                          onError={() => {
                                            let missingThumbnails =
                                              this.state.missingThumbnails;
                                            missingThumbnails[
                                              sceneFile.id
                                            ] = true;
                                            this.setMountedState({
                                              missingThumbnails:
                                                missingThumbnails,
                                            });
                                          }}
                                          alt=""
                                        />
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        display: !sceneFile.excludeScene
                                          ? "none"
                                          : "block",
                                      }}
                                      className={classes.lineThrough1}
                                    ></div>
                                    <div
                                      style={{
                                        display: !sceneFile.excludeScene
                                          ? "none"
                                          : "block",
                                      }}
                                      className={classes.lineThrough2}
                                    ></div>
                                  </div>
                                </Tooltip>
                              </div>
                            ))}
                        </div>
                      </React.Fragment>
                    ) : (
                      <div style={{ height: 78 }}></div>
                    )}
                  </div>
                  {fn_index < fileNames.length - 1 && (
                    <Divider className={classes.divider} />
                  )}
                </React.Fragment>
              ))}
            </List>
          </Grid>
        )}
      </Grid>
    );
  }
}

// define the component's interface
FileTreeView.propTypes = {
  classes: PropTypes.object.isRequired,
  project: PropTypes.object,
  onSelectFile: PropTypes.func,
  onExcludeFilesToggle: PropTypes.func,
  fileId: PropTypes.string,
  splitscreenFileIds: PropTypes.array,
  persistentStorage: PropTypes.object,
  projectContext: PropTypes.object,
};

export default withAllViewerContexts(withStyles(styles)(FileTreeView));

// Framework imports
import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";

import { Save } from "@mui/icons-material";

// External packages
import { Grid, IconButton, Tooltip, Divider } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import { AudioViewerContext } from "./AudioViewer";
import ExclusionBoxIcon from "./components/ExclusionBoxIcon";

/**
 * Collected (reusable) styles for page elements.
 */
const styles = {
  root: {
    height: "100%",
    background: "#fff",
    borderLeft: "5px solid  #EBEBEB",
    display: "grid",
    gridTemplateRows: "1fr auto",
  },
  saveButton: {
    color: "primary.main",
  },
  divider: {
    marginLeft: "5px",
    marginRight: "5px",
  },
};

/**
 * Vertical toolbar for quick tool selection.
 * @param {function} save A function to execute to save the project.
 * @returns React node for AudioToolBar.
 */
export default function AudioToolBar(props) {
  const { save, ...other } = props;
  const AudioViewer = useContext(AudioViewerContext);
  const { excludeToolActive, setExcludeToolActive } = AudioViewer;

  /**
   * Handle keyboard strokes.
   * @param {event} e KeyboardEvent.
   */
  const handleKeyDownEvent = (e) => {
    if (e.ctrlKey) {
      if (e.code === "KeyS") {
        save();
        e.preventDefault();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDownEvent);
    return () => window.removeEventListener("keydown", handleKeyDownEvent);
  }, []);

  return (
    <Grid sx={{ ...other.style, ...other.sx, ...styles.root }}>
      <div>
        <Tooltip
          title={
            "Exclude overlaps" + (excludeToolActive ? " active" : " inactive")
          }
        >
          <IconButton
            size="large"
            onClick={() => setExcludeToolActive(!excludeToolActive)}
            sx={{
              color: excludeToolActive ? "primary.main" : "primary.inactive",
            }}
          >
            <ExclusionBoxIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div>
        <Tooltip disableInteractive placement="left" title="Toggle sidebar">
          <IconButton size="large" onClick={() => AudioViewer.toggleSideBar()}>
            <FontAwesomeIcon icon={faExchangeAlt} />
          </IconButton>
        </Tooltip>
        <Divider sx={styles.divider} />
        <Tooltip disableInteractive placement="left" title="Save [Ctrl]+[S]">
          <IconButton sx={styles.saveButton} onClick={save} size="large">
            <Save />
          </IconButton>
        </Tooltip>
      </div>
    </Grid>
  );
}

AudioToolBar.propTypes = {
  save: PropTypes.func.isRequired,
};

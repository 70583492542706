// Copyright: HS Analysis GmbH, 2023
// Author: Valentin Haas

// Framework imports
import React from "react";
import PropTypes from "prop-types";

// External packages
import DialogContentText from "@mui/material/DialogContentText";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ImageList from "@mui/material/ImageList";

// HSA packages
import { DatasetType } from "../../../common/components/AITrainingSettings";

const styles = {
  images: {
    width: "100%",
    maxHeight: 350,
    objectFit: "cover",
  },
  projectTile: {
    cursor: "pointer",
    padding: 1,
    "&:hover": {
      background: "#AAA",
    },
  },
  projectTileSelected: {
    background: "#1BA1E2!important",
  },
  tileHeadline: {
    textAlign: "center",
    padding: 0,
    marginBottom: 4,
    lineHeight: "32px",
  },
};

const datasetOptions = Object.freeze({
  default: {
    classification: {
      title: "Classification",
      description: "Assign objects to different classes.",
      src: "img/classification_default.jpg",
      alt: "Classification Img",
      datasetType: DatasetType.ImageClassification,
    },
    objectDetection: {
      title: "Object Detection",
      description: "Detect objects and draw bounding box around it.",
      src: "img/object_detection_default.jpg",
      alt: "Object Detection Img",
      datasetType: DatasetType.ImageObjectDetection,
    },
    segmentation: {
      title: "Segmentation",
      description: "Detect objects and draw exact border around object.",
      src: "img/segmentation_default.jpg",
      alt: "Segmentation Img",
      datasetType: DatasetType.ImageSegmentation,
    },
    instanceSegmentation: {
      title: "Instance Segmentation",
      description: "Segmentation + differentiate between touching objects.",
      src: "img/instance_segmentation_default.jpg",
      alt: "Instance Segmentation Img",
      datasetType: DatasetType.ImageInstanceSegmentation,
    },
  },
  med: {
    classification: {
      title: "Classification",
      description: "Assign objects to different classes.",
      src: "img/classification_med.jpg",
      alt: "Classification Img",
      datasetType: DatasetType.ImageClassification,
    },
    objectDetection: {
      title: "Object Detection",
      description: "Detect objects and draw bounding box around it.",
      src: "img/object_detection_med.jpg",
      alt: "Object Detection Img",
      datasetType: DatasetType.ImageObjectDetection,
    },
    segmentation: {
      title: "Segmentation",
      description: "Detect objects and draw exact border around object.",
      src: "img/segmentation_med.jpg",
      alt: "Segmentation Img",
      datasetType: DatasetType.ImageSegmentation,
    },
    instanceSegmentation: {
      title: "Instance Segmentation",
      description: "Segmentation + differentiate between touching objects.",
      src: "img/instance_segmentation_med.jpg",
      alt: "Instance Segmentation Img",
      datasetType: DatasetType.ImageInstanceSegmentation,
    },
  },
  audio: {
    classification: {
      title: "Audio Classification",
      description: "Assigns audio clips to different classes.",
      src: "img/classification_default.jpg",
      alt: "Audio Classification Image",
      datasetType: DatasetType.AudioClassification,
    },
    objectDetection: {
      title: "Sequence Detection",
      description:
        "Detect sequences within audio and marks them in audio stream.",
      src: "img/object_detection_default.jpg",
      alt: "Sequence Detection Image",
      datasetType: DatasetType.AudioSequenceDetection,
    },
    segmentation: {
      title: "Sequence Segmentation",
      description:
        "Detect sequences and draw exact border around time and frequency.",
      src: "img/segmentation_default.jpg",
      alt: "Sequence Segmentation Image",
      datasetType: DatasetType.AudioSequenceSegmentation,
    },
    instanceSegmentation: {
      title: "Sequence Instance Segmentation",
      description:
        "Sequence Segmentation + differentiate between touching tracks.",
      src: "img/instance_segmentation_default.jpg",
      alt: "Instance Segmentation Image",
      datasetType: DatasetType.AudioSequenceInstanceSegmentation,
    },
  },
});

const mapModuleToModelOptions = Object.freeze({
  AudioAnnotator: datasetOptions.audio,
});

/**
 * Component to select the model type for the training.
 * @param {AITrainingSettings} trainingSettings The collected training settings.
 * @param {function} setTrainingSettings The function to set the training settings.
 * @param {string} projectType The project type. Used to determine the selectable model options.
 * @returns {JSX.Element} The component to select the model type for the training.
 */
export default function ModelTypeSelection(props) {
  const { trainingSettings, setTrainingSettings } = props;
  const model_options =
    mapModuleToModelOptions[props.projectType] ?? datasetOptions.default;

  return (
    <div>
      <>
        <DialogContentText
          component={"span"}
          style={{ paddingTop: "0px", marginBottom: "20px" }}
        >
          <div>
            Choose the model type for your training:
            <ul>
              {Object.keys(model_options).map((option) => {
                return (
                  <li key={model_options[option].datasetType}>
                    <strong>{model_options[option].title}:</strong>{" "}
                    {model_options[option].description}
                  </li>
                );
              })}
            </ul>
          </div>
        </DialogContentText>
        <ImageList
          style={{ margin: "0px" }}
          //rowHeight={360}
          spacing={8}
          cols={Object.keys(model_options).length}
        >
          {Object.keys(model_options).map((option) => (
            <ImageListItem
              key={model_options[option].title}
              sx={{
                ...styles.projectTile,
                ...(trainingSettings.datasetParameters.datasetType ===
                  model_options[option].datasetType &&
                  styles.projectTileSelected),
              }}
              onClick={() => {
                trainingSettings.datasetParameters.datasetType =
                  model_options[option].datasetType;
                setTrainingSettings(trainingSettings);
              }}
            >
              <img
                src={model_options[option].src}
                alt={model_options[option].alt}
                style={styles.images}
              />
              <ImageListItemBar
                title={model_options[option].title}
                position="bottom"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </>
    </div>
  );
}

ModelTypeSelection.propTypes = {
  trainingSettings: PropTypes.object.isRequired,
  setTrainingSettings: PropTypes.func.isRequired,
  projectType: PropTypes.string,
};

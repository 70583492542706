import React, { Component } from "react";
import PropTypes from "prop-types";
import * as echarts from "echarts";
import ecStat from "echarts-stat";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";

import withStyles from "@mui/styles/withStyles";
import { withSpectraViewer } from "../contexts/SpectraViewerContext";

const styles = () => ({
  root: {
    position: "relative",
    height: "100%",
    width: "100%",
    display: "grid",
    gridTemplateRows: "auto 1fr",
  },
  chartObject: {
    width: "100%",
    height: "100%",
  },
  margin: {
    textAlign: "center",
    margin: "10px 30px 0",
  },
});

class ResultChart extends Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.state = {
      chartType: props.initChartType,
    };
  }

  updateDimensions = () => {
    if (this.resultChart) this.resultChart.resize();
  };

  componentDidUpdate = () => {
    this.updateDimensions();
  };

  componentDidMount = () => {
    this.initChart();
  };

  initChart = () => {
    let chartDom = this.chartRef.current;
    echarts.registerTransform(ecStat.transform.regression);

    this.resultChart = echarts.init(chartDom);

    this.setOption(this.state.chartType);
  };

  setOption = (chartType) => {
    if (this.resultChart) {
      this.resultChart.clear();
    }
    // ToDo
    // // Group PCAs with same areas and preprocessing
    // let pcas = this.props.spectrumViewer.pcas;
    // let pcaGroups = [];

    // pcas.forEach((pca) => {
    //   // Find all similar pcas
    //   let pcaGroup = pcas.filter((element) => {
    //     if (
    //       element.areas === pca.areas &&
    //       element.preprocessing === pca.preprocessing
    //     ) {
    //       return element;
    //     }
    //   });
    //   pcaGroups.push(pcaGroup);
    //   // Remove from overall array
    //   pcaGroup.forEach((pcaGroupElement) =>
    //     pcas.splice(
    //       pcas.findIndex((pcaElement) => pcaElement.id === pcaGroupElement.id),
    //       1
    //     )
    //   );
    // });

    // console.log("PcaGroups", pcaGroups);

    let data = [];
    let xAxisLabel = "Actual";
    let yAxisLabel = "Prediction";

    switch (chartType) {
      case "rmsecvScore":
        xAxisLabel = "Score";
        yAxisLabel = "RMSECV";
        data = this.props.spectraViewer.pcas.map((item) => {
          return {
            value: [item.used_scores, item.result.rmse],
          };
        });
        break;

      case "r2Score":
        xAxisLabel = "Score";
        yAxisLabel = "R²";
        data = this.props.spectraViewer.pcas.map((item) => {
          return {
            value: [item.used_scores, item.result.r2],
          };
        });
        break;

      case "predTrue":
        data = this.props.spectraViewer.rawSpectra.map((serie, idx) => {
          return {
            itemStyle: {
              color: this.props.datasets[idx].color,
            },
            value: [
              serie.meta.substances[0].concentration,
              serie.meta.substances[0].predictedConcentration,
            ],
          };
        });
        break;

      case "diffTrue":
        data = this.props.spectraViewer.rawSpectra.map((series, idx) => {
          return {
            name: this.props.datasets[idx].name,
            itemStyle: {
              color: this.props.datasets[idx].color,
            },
            value: [
              series.meta.substances[0].concentration,
              series.meta.substances[0].concentration -
                series.meta.substances[0].predictedConcentration,
            ],
          };
        });
        break;

      default:
        // Empty Chart
        xAxisLabel = "";
        yAxisLabel = "";
        data = [];
    }

    let series = [
      {
        name: "Markers",
        type: "line",
        data: data,
      },
    ];
    let labels = ["Markers"];

    // Reference lines in certain charts
    if (chartType === "predTrue" || chartType === "diffTrue") {
      labels.push("Line");
      let lineData = this.props.spectraViewer.rawSpectra.map((serie) => {
        if (chartType === "diffTrue") {
          return [serie.meta.substances[0].concentration, 0];
        } else {
          return [
            serie.meta.substances[0].concentration,
            serie.meta.substances[0].concentration,
          ];
        }
      });
      lineData.unshift([0, 0]);
      series.push({
        name: "Line",
        type: "line",
        data: lineData,
      });
    }

    let option = {
      legend: {
        data: labels,
        left: "center",
        bottom: 10,
      },
      grid: {
        top: 40,
        left: 60,
        right: 80,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
        },
      },
      xAxis: {
        name: xAxisLabel,
      },
      yAxis: {
        type: "value",
        name: yAxisLabel,
        min: "dataMin",
        max: "dataMax",
        axisLabel: {
          show: true,
          interval: 1e-3,
          showMinLabel: false,
          showMaxLabel: false,
        },
      },
      series: series,
    };

    option && this.resultChart.setOption(option);
  };

  handleChange = (e) => {
    this.setState({ chartType: e.target.value }, () => {
      this.setOption(this.state.chartType);
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <FormControl className={classes.margin}>
          <InputLabel>Chart Type</InputLabel>
          <Select
            name="SelectChartType"
            label="Chart Type"
            // id="demo-customized-select"
            value={this.state.chartType}
            onChange={(e) => this.handleChange(e)}
            //input={<BootstrapInput />}
          >
            {this.props.chartTypes.map((chartType, idx) => {
              return (
                <MenuItem key={idx} value={chartType.value}>
                  {chartType.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <div className={classes.chartObject} ref={this.chartRef} />
      </div>
    );
  }
}

ResultChart.propTypes = {
  classes: PropTypes.object.isRequired,
  spectraViewer: PropTypes.object.isRequired,
  initChartType: PropTypes.string,
  chartTypes: PropTypes.array,
  datasets: PropTypes.array,
};

export default withSpectraViewer(withStyles(styles)(ResultChart));

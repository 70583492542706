import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";

import {
  Grid,
  FormControl,
  TextField,
  FormHelperText,
  Slider,
} from "@mui/material";

const styles = {
  sliderContainer: {
    margin: 0,
    width: "100%",
  },
  input: {
    width: 55,
  },
};

class CustomRangeSlider extends React.Component {
  transformExponential(v, exponent) {
    let result = v ** (1 / exponent);
    return result;
  }
  render() {
    const { params, classes } = this.props;
    return (
      <FormControl key={params.key} component="fieldset" fullWidth>
        <FormHelperText>{params.label}</FormHelperText>
        <Grid
          className={classes.sliderContainer}
          container
          spacing={2}
          alignItems="center"
        >
          <Grid item>
            <TextField
              className={classes.input}
              name="Textfield"
              variant="standard"
              size="small"
              value={params.valueMin}
              margin="dense"
              onChange={(e) => {
                const value = parseInt(e.target.value, 10);
                params.onSliderChange([value, params.valueMax]);
                if (params.onChangeMin) params.onChangeMin(value);
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter" && params.onKeyEnter) {
                  params.onKeyEnter();
                }
              }}
              inputProps={{
                step: params.step,
                min: params.min,
                max: params.max,
                type: "number",
                "aria-labelledby": "input-slider",
                role: "numberInput1",
              }}
            />
          </Grid>
          <Grid item xs>
            <Slider
              min={this.transformExponential(params.min, 2)}
              max={this.transformExponential(params.max, 2)}
              step={this.transformExponential(params.step, 2)}
              value={[
                this.transformExponential(params.valueMin, 2),
                this.transformExponential(params.valueMax, 2),
              ]}
              onChange={(e) => {
                const v = e.target.value;
                let value = [parseInt(v[0] ** 2, 10), parseInt(v[1] ** 2, 10)];
                params.onSliderChange(value);
              }}
              onChangeCommitted={params.onSliderchangeComitted}
            />
          </Grid>
          <Grid item>
            <TextField
              className={classes.input}
              name="Textfield"
              variant="standard"
              size="small"
              value={params.valueMax}
              margin="dense"
              onChange={(e) => {
                const value = parseInt(e.target.value, 10);
                params.onSliderChange([params.valueMin, value]);
                if (params.onChangeMax) params.onChangeMax(value);
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter" && params.onKeyEnter) {
                  params.onKeyEnter();
                }
              }}
              inputProps={{
                step: params.step,
                min: params.min,
                max: params.max,
                type: "number",
                "aria-labelledby": "input-slider",
                role: "numberInput2",
              }}
            />
          </Grid>
        </Grid>
      </FormControl>
    );
  }
}

CustomRangeSlider.propTypes = {
  classes: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomRangeSlider);

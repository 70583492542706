import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import DownloadIcon from "@mui/icons-material/Download";
import { downloadZippedChartDataAndImage, setOptions } from "./Utils";
import * as echarts from "echarts";
import IconButton from "@mui/material/IconButton";
import { LinearProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import BarChartIcon from "@mui/icons-material/BarChart";

// Styles
const useStyles = makeStyles((theme) => ({
  app: {
    width: "100%",
    height: "280px",
    display: "flex",
    alignItems: "center",
  },
  boxStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "32px",
  },
  card: {
    height: "calc(100vh - 160px)",
    width: "100%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
    borderRadius: "5px",
    backgroundColor: "#f5f5f5",
    paddingTop: "16px",
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingBottom: "14px",
  },
  cardContent: {
    overflowY: "auto",
  },
  cardWrapper: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chartButtons: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  chartDimension: {
    width: "32vw",
    maxWidth: "1000px",
    height: "250px",
  },
  divider: {
    "&.MuiDivider-root": {
      backgroundColor: "rgb(66, 132, 243)",
      opacity: "1",
    },
  },
  iconAdjust: {
    position: "relative",
    top: "-2px",
  },
  loading: {
    position: "relative",
    width: "90%",
    top: "80%",
    left: "5%",
    bottom: "20%",
    right: "5%",
  },
  scrollable: {
    height: "calc(100vh - 110px)",
    width: "100%",
    overflowY: "auto",
  },
}));

export default function ApacheEChartsTimeSeries(props) {
  const { chartData, chartDataKeys, error, loading, m, n, selectedModel } =
    props;
  const classes = useStyles();
  const top = "top";

  useEffect(() => {
    if (!loading && n > 0) {
      const charts = [];

      for (let i = 0; i < n; i++) {
        const element = document.getElementById(`chart_${i + 1}`);
        charts.push(echarts.init(element));
      }

      for (let i = 0; i < n; i++) {
        const key = chartDataKeys[i];
        const data = chartData[key];
        charts[i].setOption(setOptions(key, data));
      }

      return () => {
        for (let i = 0; i < n; i++) {
          charts[i].dispose();
        }
      };
    }
  }, [loading, chartData]);

  const makeTopRow = () => {
    return (
      <Box className={classes.boxStyle}>
        <Typography variant="h6">
          <BarChartIcon className={classes.iconAdjust} /> Training Statistics
        </Typography>
      </Box>
    );
  };

  const setChartAndDownloadButton = (i) => {
    const key = chartDataKeys[i];
    const data = chartData[key];
    const chartId = `chart_${i + 1}`;
    const exportTooltip = "Export data and image as .zip";
    if (key && data) {
      return (
        <>
          <div
            id={chartId}
            className={classes.chartDimension}
            key={`chart_${i}`}
          ></div>
          <div className={classes.chartButtons}>
            <Tooltip title={exportTooltip} placement={top} disableInteractive>
              <IconButton
                onClick={() =>
                  downloadZippedChartDataAndImage(chartId, key, data)
                }
              >
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          </div>
        </>
      );
    }

    return (
      <div
        id={chartId}
        className={classes.chartDimension}
        key={`chart_${i}`}
      ></div>
    );
  };

  if (error) {
    return <div>An error occurred: {error.message}</div>;
  }

  if (loading) {
    const check = "...Preparing Graphs...";
    return (
      <div className={classes.scrollable}>
        <div className={classes.cardWrapper}>
          <Card className={classes.card}>
            {makeTopRow()}
            <Divider className={classes.divider} />
            <CardContent className={classes.cardContent}>
              <br />
              <div>
                <b>
                  ML Model: &quot;{selectedModel.replace("Custom - ", "")}&quot;{" "}
                </b>
                <br />
                <br />
                <b>{check}</b>
                <br />
                <br />
                <br />
                <br />
                <LinearProgress className={classes.loading} />
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }

  if (!loading && n == 0) {
    const noData =
      'There are no chart data associated with ML model "' +
      selectedModel.replace("Custom - ", "") +
      '".';
    return (
      <div className={classes.scrollable}>
        <div className={classes.cardWrapper}>
          <Card className={classes.card}>
            {makeTopRow()}
            <Divider className={classes.divider} />
            <CardContent className={classes.cardContent}>
              <br />
              <div>
                <b>{noData}</b>
                <br />
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }

  if (!loading && n > 0) {
    return (
      <div className={classes.scrollable}>
        <div className={classes.cardWrapper}>
          <Card className={classes.card}>
            {makeTopRow()}
            <Divider className={classes.divider} />
            <CardContent className={classes.cardContent}>
              {Array.from({ length: Math.ceil(m / 2) }, (_, i) => (
                <div key={`row_${i}`} className={classes.app}>
                  {setChartAndDownloadButton(2 * i)}
                  {setChartAndDownloadButton(2 * i + 1)}
                </div>
              ))}
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
}

ApacheEChartsTimeSeries.propTypes = {
  chartData: PropTypes.object,
  chartDataKeys: PropTypes.array,
  error: PropTypes.object,
  loading: PropTypes.bool,
  m: PropTypes.number,
  n: PropTypes.number,
  selectedModel: PropTypes.string,
};

import React from "react";
import PropTypes from "prop-types";

class CustomSvgIcon extends React.Component {
  render() {
    const { name, width, height, stroke, vertAlign } = this.props;
    switch (name) {
      case "GrabCutIcon":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="71.337 278 32.666 34"
            style={{ isolation: "isolate", verticalAlign: vertAlign }}
            width={width}
            height={height}
          >
            <mask id="_mask_MjjndE998VThuTWR6sXZOZaDpVlW90eA">
              <path
                d=" M 98.857 280.738 C 92.762 277.793 81.511 284.526 79.848 285.855 C 77.596 287.652 75.733 289.156 74.337 290.805 C 72.944 292.465 72.04 294.378 72.001 296.628 C 71.971 298.624 72.587 300.798 73.808 303.397 C 75.826 307.664 80.163 310.67 84.798 311.878 C 89.568 312.316 93.483 311.692 96.857 308.065 C 100.638 303.997 103.96 298.299 103.999 292.129 C 104.006 291.119 104.274 283.591 98.857 280.738 Z "
                style={{
                  fillRule: "evenodd",
                  fill: "white",
                  stroke: "none",
                }}
              />
            </mask>
            <path
              d=" M 98.857 280.738 C 92.762 277.793 81.511 284.526 79.848 285.855 C 77.596 287.652 75.733 289.156 74.337 290.805 C 72.944 292.465 72.04 294.378 72.001 296.628 C 71.971 298.624 72.587 300.798 73.808 303.397 C 75.826 307.664 80.163 310.67 84.798 311.878 C 89.568 312.316 93.483 311.692 96.857 308.065 C 100.638 303.997 103.96 298.299 103.999 292.129 C 104.006 291.119 104.274 283.591 98.857 280.738 Z "
              style={{
                fillRule: "evenodd",
                fill: "none",
                mask: "url(#_mask_MjjndE998VThuTWR6sXZOZaDpVlW90eA)",
                vectorEffect: "non-scaling-stroke",
                strokeWidth: "3.316", //"4.182",
                stroke: "currentColor",
                strokeLinejoin: "miter",
                strokeLinecap: "square",
                strokeMiterlimit: "3",
              }}
              stroke={stroke}
            />
            <path
              d=" M 80.857 291.096 C 82.471 289.217 83.676 288.765 85.857 287.675 C 88.27 286.469 89.97 286 91.857 285.356"
              style={{
                fill: "none",
                vectorEffect: "non-scaling-stroke",
                strokeWidth: "1.5",
                stroke: "currentColor",
                strokeLinejoin: "miter",
                strokeLinecap: "round",
                strokeMiterlimit: "3",
              }}
              stroke={stroke}
            />
            <path
              d=" M 74.337 286 C 76.153 284.316 76.176 284.083 78.357 282.994 C 80.77 281.788 81.113 281.644 83 281"
              style={{
                fill: "none",
                vectorEffect: "non-scaling-stroke",
                strokeWidth: "1.5",
                stroke: "currentColor",
                strokeLinejoin: "miter",
                strokeLinecap: "round",
                strokeMiterlimit: "3",
              }}
              stroke={stroke}
            />
          </svg>
        );
      case "SAMIcon":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="71.337 278 32.666 34"
            style={{ isolation: "isolate", verticalAlign: vertAlign }}
            width={width}
            height={height}
          >
            <mask id="_mask_MjjndE998VThuTWR6sXZOZaDpVlW90eA">
              <path
                d=" M 98.857 280.738 C 92.762 277.793 81.511 284.526 79.848 285.855 C 77.596 287.652 75.733 289.156 74.337 290.805 C 72.944 292.465 72.04 294.378 72.001 296.628 C 71.971 298.624 72.587 300.798 73.808 303.397 C 75.826 307.664 80.163 310.67 84.798 311.878 C 89.568 312.316 93.483 311.692 96.857 308.065 C 100.638 303.997 103.96 298.299 103.999 292.129 C 104.006 291.119 104.274 283.591 98.857 280.738 Z "
                style={{
                  fillRule: "evenodd",
                  fill: "white",
                  stroke: "none",
                }}
              />
            </mask>
            <path
              d=" M 98.857 280.738 C 92.762 277.793 81.511 284.526 79.848 285.855 C 77.596 287.652 75.733 289.156 74.337 290.805 C 72.944 292.465 72.04 294.378 72.001 296.628 C 71.971 298.624 72.587 300.798 73.808 303.397 C 75.826 307.664 80.163 310.67 84.798 311.878 C 89.568 312.316 93.483 311.692 96.857 308.065 C 100.638 303.997 103.96 298.299 103.999 292.129 C 104.006 291.119 104.274 283.591 98.857 280.738 Z "
              style={{
                fillRule: "evenodd",
                fill: "none",
                mask: "url(#_mask_MjjndE998VThuTWR6sXZOZaDpVlW90eA)",
                vectorEffect: "non-scaling-stroke",
                strokeWidth: "3.316", //"4.182",
                stroke: "currentColor",
                strokeLinejoin: "miter",
                strokeLinecap: "square",
                strokeMiterlimit: "3",
              }}
              stroke={stroke}
            />
            <circle
              cx="77.5"
              cy="282.5"
              r="2"
              style={{
                fill: "currentColor",
                vectorEffect: "non-scaling-stroke",
                strokeWidth: "1.5",
                stroke: "currentColor",
                strokeLinejoin: "miter",
                strokeLinecap: "round",
                strokeMiterlimit: "3",
              }}
              stroke={stroke}
            />
            <circle
              cx="84"
              cy="293"
              r="2"
              style={{
                fill: "currentColor",
                vectorEffect: "non-scaling-stroke",
                strokeWidth: "1.5",
                stroke: "currentColor",
                strokeLinejoin: "miter",
                strokeLinecap: "round",
                strokeMiterlimit: "3",
              }}
              stroke={stroke}
            />
          </svg>
        );
      case "ResultsIcon":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            style={{ verticalAlign: vertAlign }}
            width={width}
            height={height}
          >
            <path
              fill="currentColor"
              d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64v-96h160v96zm0-160H64v-96h160v96zm224 160H288v-96h160v96zm0-160H288v-96h160v96z"
            ></path>
          </svg>
        );
      case "HeatmapIcon":
        return (
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1000 1000"
            style={{ verticalAlign: vertAlign }}
            width={width}
            height={height}
          >
            <g>
              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                <path
                  fill="currentColor"
                  d="M224.5,5006.8c-21.1-9.6-55.5-36.4-76.6-59.4c-38.3-42.1-38.3-72.8-44-4886L100-4782.4l4843.8,3.8l4843.8,5.7l49.8,46c65.1,61.3,78.5,137.9,38.3,222.2c-17.2,36.4-42.1,74.7-53.7,82.4c-11.5,7.7-2114.5,17.2-4675.3,23l-4654.2,9.6l-9.6,4644.6c-9.6,5092.8,0,4707.8-122.6,4750C295.4,5027.9,276.2,5027.9,224.5,5006.8z"
                />
                <path
                  fill="currentColor"
                  d="M3915.3,4393.9c-316-86.2-599.5-319.9-745.1-614.8c-93.8-191.5-120.7-310.3-120.7-522.9c1.9-450.1,245.2-844.7,645.5-1045.8c174.3-86.2,316-118.8,522.9-118.8c208.8,0,360.1,34.5,526.7,120.7c400.3,204.9,641.6,595.7,641.6,1036.2c0,218.3-26.8,335.2-116.8,524.8c-128.3,268.1-388.8,498-687.6,603.3C4420.9,4434.1,4097.3,4441.8,3915.3,4393.9z M4396,4018.5c160.9-42.1,256.7-95.8,371.6-212.6c423.3-423.3,237.5-1128.1-344.8-1300.5c-536.3-159-1086,348.6-971.1,900.2c61.3,296.9,283.5,532.5,574.6,611C4164.3,4053,4262,4053,4396,4018.5z"
                />
                <path
                  fill="currentColor"
                  d="M1724.2,4028.1c-145.6-24.9-295-105.3-409.9-218.3c-363.9-365.8-279.6-978.7,172.4-1235.4c421.4-239.4,978.7-13.4,1122.4,453.9c139.8,450.1-141.7,909.8-612.9,999.8C1883.2,4049.1,1854.4,4049.1,1724.2,4028.1z M2024.9,3608.6c218.3-101.5,291.1-360.1,160.9-570.8c-78.5-126.4-260.5-191.5-423.3-149.4c-340.9,88.1-385,572.7-63.2,720.2C1797,3654.6,1927.2,3654.6,2024.9,3608.6z"
                />
                <path
                  fill="currentColor"
                  d="M6420.5,4026.1c-331.3-67-597.6-365.8-626.3-701c-30.6-363.9,197.3-702.9,544-810.2c293-90,582.2-15.3,792.9,204.9c114.9,120.7,164.7,212.6,201.1,367.7c86.2,369.7-124.5,766.1-478.8,900.2C6732.7,4031.9,6535.4,4051,6420.5,4026.1z M6723.1,3616.3c145.6-61.3,231.8-195.4,233.7-360.1c1.9-379.2-494.2-524.8-710.6-208.8c-38.3,57.5-46,91.9-46,216.4c0,136,3.8,153.2,59.4,222.2C6372.6,3627.8,6564.2,3681.4,6723.1,3616.3z"
                />
                <path
                  fill="currentColor"
                  d="M8780.2,4028.1c-210.7-44-425.2-195.4-528.6-373.5c-203-344.8-101.5-808.3,226-1034.3c480.7-329.4,1126.2-51.7,1220,524.8c55.6,352.4-153.2,716.3-486.5,840.8C9090.5,4031.9,8891.3,4051,8780.2,4028.1z M9079,3616.3c266.2-113,314.1-471.2,90-656.9c-289.2-237.5-708.7,36.4-612.9,402.2C8611.6,3581.8,8866.4,3704.4,9079,3616.3z"
                />
                <path
                  fill="currentColor"
                  d="M6409,1480.7c-469.2-72.8-856.1-419.5-974.9-871.5c-42.1-159-38.3-446.3,5.7-605.2c109.2-390.7,448.2-722.1,838.9-821.7c411.8-105.3,823.6,9.6,1126.2,316c226,229.8,337.1,498,337.1,814c-1.9,444.4-226,825.5-618.7,1038.1C6920.4,1461.5,6627.4,1515.2,6409,1480.7z M6780.6,1068.9C7089,977,7318.8,703.1,7351.3,389c40.2-396.5-254.7-773.8-658.8-844.7c-235.6-42.1-488.4,44.1-670.4,226c-369.7,367.7-283.5,967.2,176.2,1231.5c55.5,30.6,134.1,65.1,176.2,74.7C6493.3,1103.4,6673.3,1099.6,6780.6,1068.9z"
                />
                <path
                  fill="currentColor"
                  d="M1632.2,1061.2c-365.8-116.8-597.6-484.6-538.2-858.1c111.1-716.3,1040-909.8,1432.7-298.8c193.4,304.5,149.4,702.9-109.2,963.4C2208.8,1074.7,1908,1149.4,1632.2,1061.2z M2067,645.6c195.4-113,243.2-394.6,97.7-572.7c-182-220.3-530.5-180-649.3,76.6C1354.5,496.2,1733.8,837.2,2067,645.6z"
                />
                <path
                  fill="currentColor"
                  d="M8707.4,1068.9c-134.1-42.1-231.8-101.5-333.3-201.1c-314.1-316-306.5-814,21.1-1120.4c306.4-287.3,781.5-279.6,1080.2,19.2c419.5,421.4,237.5,1131.9-333.3,1300.5C9019.6,1103.4,8818.5,1103.4,8707.4,1068.9z M9038.7,685.8c245.2-72.8,352.4-379.2,204.9-588C9056-170.3,8636.5-99.4,8556.1,212.8c-57.5,218.4,68.9,432.9,285.4,482.7C8929.6,716.5,8933.4,716.5,9038.7,685.8z"
                />
                <path
                  fill="currentColor"
                  d="M3955.5-1478.4c-306.4-67-634-321.8-775.7-605.2c-95.8-187.7-130.2-335.2-130.2-540.1c0-519.1,316-950,823.6-1126.2c172.4-59.4,507.6-61.3,685.7-1.9c352.4,114.9,649.3,402.2,773.8,750.8c49.8,134.1,67,453.9,36.4,616.7c-13.4,67-59.4,193.4-103.4,283.5C5028.1-1622.1,4484.1-1363.5,3955.5-1478.4z M4430.5-1873c111.1-26.8,249-113,348.6-214.5c101.5-105.3,160.9-206.9,197.3-348.6c93.8-350.5-91.9-733.6-432.9-898.3c-114.9-55.5-139.8-59.4-325.6-59.4c-189.6,0-210.7,3.8-327.5,61.3c-490.3,241.3-605.2,863.8-229.8,1248.8C3861.7-1876.8,4143.2-1800.2,4430.5-1873z"
                />
                <path
                  fill="currentColor"
                  d="M8795.5-1654.7c-197.3-23-409.9-132.2-566.9-289.2c-295-295-362-756.6-164.7-1143.4c59.4-118.8,272-333.3,386.9-392.6c247.1-126.4,522.9-155.1,766.1-78.5c589.9,185.8,860,871.5,559.3,1415.4c-109.2,197.3-346.7,386.9-574.6,455.9C9103.9-1658.5,8897-1641.2,8795.5-1654.7z M9169-2099c214.5-101.5,321.8-264.3,331.3-503.7c7.7-124.5,0-162.8-38.3-249c-65.1-143.6-151.3-233.7-279.6-296.9c-145.6-70.9-348.6-74.7-492.2-9.6c-379.2,174.3-463.5,668.4-160.9,957.7C8697.8-2037.7,8956.4-1997.5,9169-2099z"
                />
                <path
                  fill="currentColor"
                  d="M1795-1846.2c-555.4-65.1-873.4-639.7-626.3-1131.9c185.8-371.6,647.4-530.5,1024.7-352.4c302.6,139.8,475,442.5,448.2,777.6c-26.8,312.2-266.2,605.3-559.3,678C1980.8-1850,1865.9-1838.5,1795-1846.2z M2011.5-2263.7c346.7-145.6,308.4-639.7-59.4-739.3c-304.5-82.4-574.6,258.6-434.8,547.8C1607.3-2271.4,1823.8-2187.1,2011.5-2263.7z"
                />
                <path
                  fill="currentColor"
                  d="M6439.7-1853.9c-360.1-59.4-649.3-400.3-651.2-764.2c0-105.3,44.1-281.6,95.8-383.1c59.4-116.8,222.2-266.2,362-333.3c116.8-55.5,141.7-59.4,327.5-59.4c185.8,0,210.7,3.8,327.5,59.4c226,107.3,394.6,327.5,440.5,572.7C7437.5-2236.9,6970.2-1765.7,6439.7-1853.9z M6790.2-2304c116.8-80.4,170.5-197.3,162.8-344.7c-13.4-214.5-166.6-360.1-381.1-362c-415.6,0-532.5,574.6-151.3,747C6525.9-2217.8,6690.6-2235,6790.2-2304z"
                />
              </g>
            </g>
          </svg>
        );
      case "ResetZoomIcon":
        return (
          <svg
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="ZoomInIcon"
          >
            <rect
              x="8"
              y="8"
              width="3"
              height="3"
              style={{
                fill: "none",
                strokeWidth: 1,
                stroke: "currentColor",
              }}
            />
            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
          </svg>
        );
    }
  }
}

CustomSvgIcon.propTypes = {
  name: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  stroke: PropTypes.string,
  vertAlign: PropTypes.string,
};

export default CustomSvgIcon;

import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import PropTypes from "prop-types";
import {
  Typography,
  TextField,
  Tooltip,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Button,
} from "@mui/material";
import { withScanViewerContext } from "../contexts/ScanViewerContext";
import SketchColorPicker from "../../common/components/SketchColorPicker";
import { Add, Delete } from "@mui/icons-material";
import { getRandomColor } from "../../common/utils/RandomColorGenerator";

const styles = (theme) => ({
  root: {
    padding: 20,
    height: "100%",
    overflowY: "auto",
  },
  ChannelTextField: {
    paddingBottom: 0,
    paddingTop: 0,
    paddingLeft: 0,
    width: 345,
  },
  buttonSelected: {
    width: "100%",
    color: "black",
    background: theme.palette.selected.main,
    borderRadius: 0,
  },
  buttonUnselected: {
    width: "100%",
    color: "black",
    borderRadius: 0,
    background: "white",
  },
  listItemSelected: {
    background: theme.palette.selected.main,
  },
  listItemUnselected: {
    background: "white",
  },
});

/**
 * class to render fluorescence card and handle interaction
 */
class ScanFluorescence extends Component {
  constructor(props) {
    super(props);

    this.state = {
      channelName: "",
      selectedIndex: -1,
    };
  }
  addChannel = () => {
    const newItem = {
      label: this.state.channelName,
      color: getRandomColor(),
    };
    this.props.scanViewerContext.channels.push(newItem);
    this.emitParams("add_fl_channel");
    this.setState({ channelName: "" });
    this.setState({
      selectedIndex: this.props.scanViewerContext.channels.length - 1,
    });
  };

  selectChannel = (index) => {
    this.setState({ selectedIndex: index }, () => {
      this.emitParams("change_fl_channel");
    });
  };

  deleteChannel = (channel, index) => {
    window.openResponseDialog(
      "Do you really want to delete " + channel.label + "?",
      (response) => {
        if (response) {
          this.props.scanViewerContext.channels.splice(index, 1);
          this.emitParams("delete_fl_channel");
          this.setState({
            selectedIndex: this.props.scanViewerContext.channels.length - 1,
          });
        }
      }
    );
  };

  emitParams = (state) => {
    let paramJson = {
      state: state,
      selectedChannel: this.state.selectedIndex,
      channels: this.props.scanViewerContext.channels,
    };
    this.props.scanViewerContext.updateFluorescenceParams(
      "scan_message",
      paramJson
    );
  };

  render() {
    const { selectedIndex, channelName } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <h2>Scans</h2>
        <Button
          className={
            selectedIndex < 0
              ? classes.buttonSelected
              : classes.buttonUnselected
          }
          size="large"
          onClick={() => this.selectChannel(-1)}
        >
          Brightfield
        </Button>
        <Typography variant="h6">
          {"Channels (" + this.props.scanViewerContext.channels.length + "):"}
        </Typography>
        <List style={{ overflowX: "hidden" }}>
          {this.props.scanViewerContext.channels.map((channel, index) => (
            <ListItemButton
              className={
                selectedIndex === index
                  ? classes.listItemSelected
                  : classes.listItemUnselected
              }
              key={index}
              onClick={() => this.selectChannel(index)}
            >
              <TextField
                name="Textfield"
                className={classes.ChannelTextField}
                label="channel name"
                variant="standard"
                value={channel.label}
                error={channel.label === ""}
                onChange={(e) => {
                  channel.label = e.target.value;
                  this.forceUpdate();
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && e.target.value !== "") {
                    channel.label = e.target.value;
                    this.forceUpdate();
                  }
                }}
              />
              <SketchColorPicker
                color={channel.color}
                handleChange={(color) => {
                  channel.color = color;
                  this.forceUpdate();
                }}
              />
              <Tooltip disableInteractive title="Delete channel">
                <IconButton
                  onClick={() => {
                    this.deleteChannel(channel, index);
                  }}
                  size="large"
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </ListItemButton>
          ))}
          <ListItem>
            <TextField
              name="Textfield"
              className={classes.ChannelTextField}
              label="Channel Name"
              variant="standard"
              value={channelName}
              onChange={(e) => {
                this.setState({ channelName: e.target.value });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && channelName !== "") {
                  this.addChannel();
                }
              }}
            />
            <Tooltip disableInteractive title="Add new channel">
              <span>
                <IconButton
                  disabled={channelName === ""}
                  onClick={() => {
                    this.addChannel();
                  }}
                  size="large"
                >
                  <Add />
                </IconButton>
              </span>
            </Tooltip>
          </ListItem>
        </List>
      </div>
    );
  }
}

ScanFluorescence.propTypes = {
  classes: PropTypes.object.isRequired,
  scanViewerContext: PropTypes.object.isRequired,
};

export default withScanViewerContext(withStyles(styles)(ScanFluorescence));

// Framework Imports
import React, { useRef, useCallback, useEffect, useState } from "react";
import { PropTypes } from "prop-types";

// External components
import Slider from "@mui/material/Slider";

// HSA Imports
import { roundToDecimal } from "../utils/Utils";

/**
 * A slider controllable by mousewheel when hovering over it.
 * @param {number} defaultValue Optional. The starting position of the slider. Defaults to 0.
 * @param {number} min Optional. The minimal accepted value of the slider. Defaults to 0.
 * @param {number} max Optional. The maximum accepted value of the slider. Defaults to 100.
 * @param {number} step Optional. The step size of a single step. Defaults to 1.
 * @param {function} onChange The function to be executed on value change. Receives {number} newValue.
 * @param {function} onChangeCommitted The function to be executed on final value change. Receives {number} newValue.
 * @returns React node.
 */
export default function WheelControllableSlider(props) {
  const {
    defaultValue = 0,
    min = 0,
    max = 100,
    step = 1,
    onChange = () => {},
    onChangeCommitted = () => {},
    ...other
  } = props;
  const sliderRef = useRef();
  const [sliderPosition, setSliderPosition] = useState(defaultValue);
  const sliderPostionRef = useRef(defaultValue);

  useEffect(() => {
    onChange(sliderPosition);
  }, [sliderPosition]);

  /**
   * Activate silder change on scroll
   */
  const sliderScrollOn = () => {
    sliderRef.current.addEventListener("wheel", adjustSliderByScrolling);
  };

  /**
   * Deactivate slider change on scroll
   */
  const sliderScrollOff = () => {
    sliderRef.current.removeEventListener("wheel", adjustSliderByScrolling);
  };

  /**
   * Allow slider adjustment via mousewheel.
   * @param {event} e Scrollevent.
   */
  const adjustSliderByScrolling = useCallback((e) => {
    e.preventDefault();
    // Round to value between 0 and 100, with a precision of 0.01
    // Use increments as passed along
    const newVal = roundToDecimal(
      Math.min(
        max,
        Math.max(
          min,
          -(
            (Math.sign(e.deltaX) + Math.sign(e.deltaY) + Math.sign(e.deltaZ)) *
            step
          ) + sliderPostionRef.current
        )
      ),
      2
    );
    sliderPostionRef.current = newVal;
    setSliderPosition(newVal);
  }, []);

  return (
    <Slider
      {...other}
      min={min}
      max={max}
      step={step}
      onMouseEnter={sliderScrollOn}
      onMouseLeave={sliderScrollOff}     
      onChange={(_, value) => {
        sliderPostionRef.current = value;
        setSliderPosition(value);
        onChange(value);
      }}
      onChangeCommitted={(_, value) => {
        sliderPostionRef.current = value;
        setSliderPosition(value);
        onChangeCommitted(value);
      }}
      value={sliderPosition}
      ref={sliderRef}
    />
  );
}

WheelControllableSlider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  defaultValue: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func,
  onChangeCommitted: PropTypes.func,
};

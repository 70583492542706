// Copyright: HS Analysis GmbH, 2023
// Author: Valentin Haas

// Framework imports
import React, { useEffect } from "react";
import PropTypes from "prop-types";

// External packages
import DialogContentText from "@mui/material/DialogContentText";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";

// HSA packages
import Structure, {
  parentStructures,
  childStructures,
} from "../../../common/components/Structure";
import StructureTree from "../../../audioViewer/sidebar/StructureTree";
import AITrainingSettings from "../../../common/components/AITrainingSettings";

const styles = {
  root: {
    overflow: "hidden",
    display: "grid",
    gridTemplateRows: "auto 1fr",
  },
};

/**
 * View to choose which structures should be used for training.
 * @param {AITrainingSettings} trainingSettings The training settings.
 * @param {function} setTrainingSettings The function to set the training settings.
 * @param {object} project The project the strucuture tree referrs to.
 * @param {object} user The current user.
 * @param {array} structures The structures to display.
 * @param {function} setStructures The function to set the structures.
 * @param {boolean} allStructuresEnabled True if all structures are enabled, false otherwise.
 * @param {function} setAllStructuresEnabled The function to set the allStructuresEnabled flag.
 * @returns {JSX.Element} The selection view.
 */
export default function StructureSelection(props) {
  const {
    trainingSettings,
    setTrainingSettings,
    project,
    structures = [],
    setStructures = () => {},
    allStructuresEnabled = false,
    setAllStructuresEnabled = () => {},
  } = props;

  useEffect(() => {
    setTrainingSettings(() => {
      trainingSettings.structures = structures.filter((s) => s.isChosen);
      return trainingSettings;
    });
  }, [structures]);

  /**
   * Checks if a structure can be selected.
   * @param {Structure} structure The structure to check.
   * @returns {boolean} True if the structure can be selected, false otherwise.
   */
  const structureCanBeSelected = (structure) => {
    // Override if all structures are enabled.
    if (allStructuresEnabled) return true;

    // Structure is not selectable if it has no objects.
    if (structure.totalObjectCount <= 0) return false;

    // Check if any parent is selected
    const anyParentSelected = parentStructures(structure, structures)
      .slice(0, -1) // Remove the structure itself
      .some((parent) => parent.isChosen);

    // Check if any child is selected
    const anyChildSelected = childStructures(structure, structures)
      .slice(1) // Remove the structure itself
      .some((child) => child.isChosen);

    return !(anyParentSelected || anyChildSelected);
  };

  return (
    <>
      <Grid container sx={styles.root}>
        <Grid container item xs={12}>
          <Grid item xs={10}>
            <DialogContentText>
              Select all structures that should be detected/classified by your
              model:
            </DialogContentText>
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Checkbox
                  name="CheckboxAllStructuresEnabled"
                  checked={allStructuresEnabled}
                  onChange={(e) => setAllStructuresEnabled(e.target.checked)}
                />
              }
              label={
                <Typography variant="caption">Enable all structures</Typography>
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <StructureTree
        project={project}
        structures={structures}
        setStructures={setStructures}
        isLocked={true}
        allowChoices={true}
        allStructuresEnabled={allStructuresEnabled}
        structureSelectability={structureCanBeSelected}
        trainingSettings={trainingSettings}
        setTrainingSettings={setTrainingSettings}
      />
    </>
  );
}

StructureSelection.propTypes = {
  trainingSettings: PropTypes.instanceOf(AITrainingSettings).isRequired,
  setTrainingSettings: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  structures: PropTypes.arrayOf(PropTypes.instanceOf(Structure)),
  setStructures: PropTypes.func,
  allStructuresEnabled: PropTypes.bool,
  setAllStructuresEnabled: PropTypes.func,
};

// Copyright: HS Analysis GmbH, 2023
// Author: Valentin Haas

// Framework imports
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// External packages

// HSA packages
import { parentStructures } from "../../common/components/Structure";
import Backend from "../../common/utils/Backend";
import AITrainingSettings, {
  DefaultDatasetType,
  DefaultDatasetApproach,
  getModelParameters,
  DatasetApproach,
} from "../../common/components/AITrainingSettings";
import StepperDialog from "../../common/components/StepperDialog";
import ModelMetaData from "./TrainAIModelComponents/ModelMetaData";
import ModelTypeSelection from "./TrainAIModelComponents/ModelTypeSelection";
import StructureSelection from "./TrainAIModelComponents/StructureSelection";
import ModelAndDatasetSettings from "./TrainAIModelComponents/ModelAndDatasetSettings";

import { withSpinloader } from "../../common/components/Spinloader";

// export default function TrainAIModel(props) {
const TrainAIModel = (props) => {
  const {
    open,
    setOpen,
    project,
    structures = [],
    setStructures = () => {},
    ome,
  } = props;
  const history = useHistory();
  const forceUpdate = React.useReducer(() => ({}))[1];

  const [trainingSettings, setTrainingSettings] = useState(
    new AITrainingSettings(project.id, project.type)
  );

  const [aiModels, setAiModels] = useState([]);
  const [nameInUse, setNameInUse] = useState(false);
  const [containsInvalidChar, setContainsInvalidChar] = useState(false);
  const [allStructuresEnabled, setAllStructuresEnabled] = useState(false);

  useEffect(() => {
    Backend.getModelMetadata("hsa_models", false, (models) => {
      setAiModels(models);

      // Set default dataset and model parameter types depending on project type.
      setTrainingSettings((prev) => {
        prev.datasetParameters.datasetType = DefaultDatasetType(project.type);
        prev.datasetParameters.datasetApproach = DefaultDatasetApproach(
          project.type
        );
        prev.modelParameters = getModelParameters(
          prev.datasetParameters.datasetType
        );
        return prev;
      });
    });
  }, []);

  useEffect(() => {
    validateMetaData(false);
  }, [trainingSettings.metaData.name, trainingSettings.metaData.version]);

  useEffect(() => {
    if (
      trainingSettings.datasetParameters.datasetApproach ===
      DatasetApproach.AudioSlidingWindow
    ) {
      setTrainingSettings((prev) => {
        prev.modelParameters.sequenceLengthSeconds = 5.0;
        prev.modelParameters.sequenceOverlapSeconds = 0.0;
        return prev;
      });
    } else if (
      trainingSettings.datasetParameters.datasetApproach ===
      DatasetApproach.AudioFileBased
    ) {
      setTrainingSettings((prev) => {
        prev.modelParameters.sequenceLengthSeconds = 0.0;
        prev.modelParameters.sequenceOverlapSeconds = 0.0;
        return prev;
      });
    }
  }, [trainingSettings.datasetParameters.datasetApproach]);

  /**
   * The steps of the dialog.
   */
  const steps = [
    {
      title: "Select Model Type",
      content: (
        <ModelTypeSelection
          trainingSettings={trainingSettings}
          setTrainingSettings={(params) => {
            setTrainingSettings(params);
            forceUpdate();
          }}
          projectType={project.type}
        />
      ),
      validate: () => {
        return validateModelType();
      },
    },
    {
      title: "Select Structures",
      content: (
        <StructureSelection
          trainingSettings={trainingSettings}
          setTrainingSettings={(params) => {
            setTrainingSettings(params);
            forceUpdate();
          }}
          project={project}
          structures={structures}
          setStructures={setStructures}
          allStructuresEnabled={allStructuresEnabled}
          setAllStructuresEnabled={setAllStructuresEnabled}
        />
      ),
      validate: () => {
        return validateStructureSelection();
      },
    },
    {
      title: "Training Settings",
      content: (
        <ModelAndDatasetSettings
          trainingSettings={trainingSettings}
          setTrainingSettings={(params) => {
            setTrainingSettings(params);
            forceUpdate();
          }}
          projectType={project.type}
          ome={ome}
        />
      ),
    },
    {
      title: "Model Selection",
      content: (
        <ModelMetaData
          trainingSettings={trainingSettings}
          setTrainingSettings={(params) => {
            setTrainingSettings(params);
            forceUpdate();
          }}
          existingAiModels={aiModels.models}
          nameInUse={nameInUse}
          containsInvalidChar={containsInvalidChar}
          validateEntries
        />
      ),
      validate: () => {
        return validateMetaData();
      },
    },
  ];

  //#region Validation

  /**
   * Checks if a given modelname already exists.
   * @param {string} valueToCheck The text to be checked.
   * @returns {bool} Whether or not the name already exists.
   */
  const checkNameAvailibility = (valueToCheck) => {
    if (aiModels.length === 0) return false;
    return (
      aiModels.models.findIndex((aiModel) => {
        return aiModel.meta_data.name === valueToCheck;
      }) >= 0
    );
  };

  /**
   * Checks if input string has forbidden characters.
   * @param {string} input The text to be checked.
   * @returns {bool} Whether or not a forbidden char is present.
   */
  const isInvalidInput = (input) => {
    const invalidChars = /[&#\\/?:*"|<>]/g;
    return invalidChars.test(input);
  };

  /**
   * Validates the metadata of the model. Relevant for the first step of the dialog.
   * @param {bool} verbose Whether or not to show a warning snackbar in case of invalid metadata. Default: true.
   * @returns {Promise} Whether or not the metadata is valid.
   */
  const validateMetaData = async (verbose = true) => {
    return new Promise((resolve) => {
      const localNameInUse = checkNameAvailibility(
        trainingSettings.metaData.name
      );
      const localContainsInvalidChar = isInvalidInput(
        trainingSettings.metaData.name
      );
      setNameInUse(localNameInUse);
      setContainsInvalidChar(localContainsInvalidChar);

      if (trainingSettings.metaData.isNewModel) {
        // Check settings for a new model
        const isValid =
          trainingSettings.metaData.name !== "" &&
          !localNameInUse &&
          !localContainsInvalidChar;

        if (verbose && !isValid)
          window.showWarningSnackbar(
            "Incomplete or invalid metadata, please check your inputs."
          );

        resolve(isValid);
      } else {
        // Check settings for retraining an existing model
        const isValid =
          trainingSettings.metaData.name !== "" &&
          trainingSettings.metaData.version !== "";

        if (verbose && !isValid)
          window.showWarningSnackbar(
            "Incomplete or invalid metadata, please check your inputs."
          );

        resolve(isValid);
      }
    });
  };

  const validateModelType = async () => {
    return new Promise((resolve) => {
      const isValid =
        Boolean(trainingSettings.datasetParameters.datasetType) ||
        trainingSettings.datasetParameters.datasetType === 0;

      if (!isValid)
        window.showWarningSnackbar(
          "Incomplete or invalid model type, please check your inputs."
        );

      resolve(isValid);
    });
  };

  const validateStructureSelection = async () => {
    return new Promise((resolve) => {
      if (allStructuresEnabled) {
        // Anything goes.
        resolve(true);
        return;
      }
      const parentsNotChosen = !structures
        .filter((s) => s.isChosen)
        // Check that a chosen Structure does not have a chosen parent.
        .some((s) => {
          // Remove last item from array, as it is the structure itself.
          return parentStructures(s, structures)
            .slice(0, -1)
            .some((p) => p.isChosen);
        });

      if (!parentsNotChosen)
        window.showWarningSnackbar(
          "Structures cannot be chosen if one of their parents is also chosen."
        );

      // Check that at least one structure is chosen.
      const anyStructureChosen = structures.some((s) => s.isChosen);

      if (!anyStructureChosen)
        window.showWarningSnackbar("Please choose at least one structure.");

      resolve(parentsNotChosen && anyStructureChosen);
    });
  };

  /**
   * Validates the settings of all steps using the validate() function of each step, if it exists.
   * @returns {int} The index of the first step that is invalid. -1 if all steps are valid.
   */
  const validateAllSettings = async () => {
    Promise.resolve(
      steps.findIndex((step) => {
        if (!("validate" in Object.keys(step))) return false;
        return !step.validate();
      })
    );
  };
  //#endregion
  //#region Actions between steps

  const extractSettings = () => {
    // Only relevant if an existing model is used.
    if (trainingSettings.metaData.isNewModel) {
      return;
    }

    const modelUsed = aiModels.find(
      (aiModel) => aiModel.name === trainingSettings.metaData.name
    );

    const versionUsed = modelUsed.versions.find(
      (version) => version.label === trainingSettings.metaData.version
    );

    console.log(versionUsed);

    setTrainingSettings(trainingSettings);
  };

  const actionsBetweenSteps = (stepNo) => {
    switch (stepNo) {
      case 0:
        extractSettings();
        break;
      default:
        break;
    }
  };

  //#endregion

  const submit = async () => {
    // Start training.
    Backend.trainModel(trainingSettings);

    setOpen(false);
    // Check if all steps are valid.
    const firstInvalidStep = await validateAllSettings();
    if (firstInvalidStep >= 0) {
      return firstInvalidStep;
    }

    window.showSuccessSnackbar("Sent training parameters.");
    props.spinloader.show();

    // Create a promise that resolves when the Socket.IO message is received.
    const socketMessagePromise = new Promise((resolve) => {
      const socket = Backend.getSocket();
      socket.on("training_queued", () => {
        props.spinloader.hide();
        resolve();
      });
    });

    // Wait for the Socket.IO message to be received.
    await socketMessagePromise;

    window.showActionSnackbar(
      "Queued training.",
      () => history.push("/ai_view"),
      "Go to AI View",
      "Stay here"
    );
  };

  return (
    <StepperDialog
      isOpen={open}
      setOpen={setOpen}
      steps={steps}
      onNext={actionsBetweenSteps}
      onSubmit={submit}
      submitMessage={
        trainingSettings.datasetParameters.datasetOnly ? "Dataset" : "Train"
      }
    />
  );
};

TrainAIModel.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  structures: PropTypes.array,
  setStructures: PropTypes.func,
  ome: PropTypes.object,
  spinloader: PropTypes.object,
};

export default withSpinloader(TrainAIModel);

import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import Backend from "../../common/utils/Backend";
import CustomListSelector from "../../globalComponents/CustomListSelector";
const styles = () => ({
  container: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    marginTop: 5,
  },
  column: {
    border: "2px solid rgb(218, 218, 218)",
    padding: 10,
  },
  columnHeadline: { fontSize: 16, marginBottom: 10 },
});
const defaultCheckboxAdminData = {
  canImportProjects: {
    label: "Import Projects",
    checked: false,
  },
  canAccessLogFiles: {
    label: "Access Log Files",
    checked: false,
  },
  canExportModels: {
    label: "Export AI Models",
    checked: false,
  },
  canImportModels: {
    label: "Import AI Models",
    checked: false,
  },
  canEditGroups: {
    label: "Edit Group",
    checked: false,
  },
  canAccessAdminPage: {
    label: "Access Admin Page",
    checked: false,
  },
  canUseSystemActions: {
    label: "Use System Actions",
    checked: false,
  },
  canSetJobRunTimes: {
    label: "Set Job Run Times",
    checked: false,
  },
  canAccessLicensingPage: {
    label: "Access Licensing Page",
    checked: false,
  },
  canEditUsers: {
    label: "Edit Users",
    checked: false,
  },
  canEditAutoImportSettings: {
    label: "Edit auto import settings",
    checked: false,
  },
  canEditUserGroups: {
    label: "Edit User Groups",
    checked: false,
  },
  canAccessAllUserActions: {
    label: "Access All User Actions",
    checked: false,
  },
  canCreateModules: {
    label: "Create Project Modules",
    checked: false,
  },
};

const defaultCheckboxProjectData = {
  isProjectVisible: {
    label: "Project visible for group members",
    checked: false,
  },
  canOpenProject: {
    label: "Open project",
    checked: false,
  },
  canAccessResults: {
    label: "Access Results",
    checked: false,
  },
  canComment: {
    label: "Comment",
    checked: false,
  },
  canAnnotate: {
    label: "Annotate",
    checked: false,
  },
  canEditStructures: {
    label: "Edit structures",
    checked: false,
  },
  canRunJob: {
    label: "Run job",
    checked: false,
  },
  canAccessAITab: {
    label: "Access AI Tab",
    checked: false,
  },
  canEditAITabSettings: {
    label: "Edit AI Tab Settings",
    checked: false,
  },
  canTrainModel: {
    label: "Train models",
    checked: false,
  },
  canEditProjectProperties: {
    label: "Edit project properties",
    checked: false,
  },
};

class GroupDetailsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupName: props.group === null ? "" : props.group.name,
      nameError: false,
      presetAdminIdx: 0,
      presetProjectIdx: 0,
    };
    this.checkboxAdminData = JSON.parse(
      JSON.stringify(defaultCheckboxAdminData)
    );
    this.checkboxProjectData = JSON.parse(
      JSON.stringify(defaultCheckboxProjectData)
    );

    this.presetAdminOptionsList = [
      "Not Selected",
      "Minimal",
      "User",
      "User AI",
      "Admin",
      "Super Admin",
    ];
    this.presetProjectOptionsList = [
      "Not Selected",
      "No Presets",
      "Viewing",
      "Annotating",
      "Annotating and Training",
      "Admin",
    ];
  }
  componentDidMount = () => {
    this.updateCheckboxes();
  };
  resetDetails = () => {
    this.checkboxAdminData = JSON.parse(
      JSON.stringify(defaultCheckboxAdminData)
    );
    this.checkboxProjectData = JSON.parse(
      JSON.stringify(defaultCheckboxProjectData)
    );
    this.setState({ groupName: "" });
  };
  updateCheckboxes = () => {
    if (this.props.group !== null) {
      for (const [key, value] of Object.entries(this.props.group)) {
        if (typeof value === "boolean") {
          if (this.checkboxAdminData[key]) {
            this.checkboxAdminData[key].checked = value;
          } else if (this.checkboxProjectData[key]) {
            this.checkboxProjectData[key].checked = value;
          }
        } else if (key === "name") {
          this.setState({
            groupName: value,
          });
        }
      }
    }
    this.setState({
      presetAdminIdx: 0,
      presetProjectIdx: 0,
    });
  };
  componentDidUpdate = (prevProps) => {
    if (prevProps.group === null && this.props.group !== null) {
      this.updateCheckboxes();
    }
  };

  onApply = () => {
    let group = {
      name: this.state.groupName,
    };
    for (const [key, value] of Object.entries(this.checkboxAdminData)) {
      group[key] = value.checked;
    }
    for (const [key, value] of Object.entries(this.checkboxProjectData)) {
      group[key] = value.checked;
    }
    if (this.props.group) {
      group.id = this.props.group.id;
      Backend.updateGroup(group, (res) => {
        if (res === true || res.success === true) {
          this.resetDetails();
          this.props.onApply();
          window.showSuccessSnackbar("saved!");
        }
      });
    } else {
      Backend.createGroup(group, (res) => {
        if (res.success === true) {
          this.resetDetails();
          this.props.onApply();
          window.showSuccessSnackbar("Group created!");
        }
      });
    }
  };

  onClose = () => {
    this.resetDetails();
    this.props.onClose();
  };

  // set checkbox, if user has that permission
  setcheckboxAdminDataValue = (key, value) => {
    if (this.props.user.group[key]) {
      this.checkboxAdminData[key].checked = value;
    }
  };

  onAdminPresetChange = (value) => {
    if (value === 0) return; // no changes
    let updateObject = {
      presetAdminIdx: value,
    };
    for (let key of Object.keys(this.checkboxAdminData)) {
      this.setcheckboxAdminDataValue(key, false);
    }

    switch (value) {
      case 5: // Super Admin
        this.setcheckboxAdminDataValue("canCreateModules", true);
      // falls through
      case 4: //Admin
        this.setcheckboxAdminDataValue("canEditGroups", true);
        this.setcheckboxAdminDataValue("canAccessAdminPage", true);
        this.setcheckboxAdminDataValue("canUseSystemActions", true);
        this.setcheckboxAdminDataValue("canSetJobRunTimes", true);
        this.setcheckboxAdminDataValue("canAccessLicensingPage", true);
        this.setcheckboxAdminDataValue("canEditUsers", true);
        this.setcheckboxAdminDataValue("canEditAutoImportSettings", true);
        this.setcheckboxAdminDataValue("canEditUserGroups", true);
        this.setcheckboxAdminDataValue("canAccessAllUserActions", true);
      // falls through
      case 3: // User AI
        this.setcheckboxAdminDataValue("canExportModels", true);
        this.setcheckboxAdminDataValue("canImportModels", true);
      // falls through
      case 2: // User
        this.setcheckboxAdminDataValue("canAccessLogFiles", true);
        this.setcheckboxAdminDataValue("canImportProjects", true);
    }
    if (
      this.state.groupName === "" ||
      this.presetAdminOptionsList.includes(this.state.groupName)
    ) {
      this.updateGroupName(this.presetAdminOptionsList[value]);
    }
    this.setState(updateObject);
  };

  // if validation to check needed, set here
  setCheckboxProjectData = (key, value) => {
    this.checkboxProjectData[key].checked = value;
  };

  onProjectPresetChange = (value) => {
    if (value === 0) return; // no changes
    let updateObject = {
      presetProjectIdx: value,
    };

    for (let key of Object.keys(this.checkboxProjectData)) {
      this.setCheckboxProjectData(key, false);
    }

    switch (value) {
      case 5: // Admin
        this.setCheckboxProjectData("canEditProjectProperties", true);
      // falls through
      case 4: // Annotating and Training
        this.setCheckboxProjectData("canTrainModel", true);
        this.setCheckboxProjectData("canEditAITabSettings", true);
        this.setCheckboxProjectData("canAccessAITab", true);
      // falls through
      case 3: // Annotating
        this.setCheckboxProjectData("canEditStructures", true);
        this.setCheckboxProjectData("canAnnotate", true);
        this.setCheckboxProjectData("canComment", true);
        this.setCheckboxProjectData("canRunJob", true);
      // falls through
      case 2: // Viewing
        this.setCheckboxProjectData("canAccessResults", true);
        this.setCheckboxProjectData("canOpenProject", true);
    }
    this.setState(updateObject);
  };

  onAdminPermissionCheckboxChange = (e, item) => {
    item.checked = e.target.checked;
    this.forceUpdate();
  };

  onProjectPermissionCheckboxChange = (e, key, item) => {
    item.checked = e.target.checked;
    if (key === "canOpenProject" && !item.checked) {
      this.checkboxProjectData.canEditProjectProperties.checked = false;
      this.checkboxProjectData.canTrainModel.checked = false;
      this.checkboxProjectData.canEditAITabSettings.checked = false;
      this.checkboxProjectData.canAccessAITab.checked = false;
      this.checkboxProjectData.canEditStructures.checked = false;
      this.checkboxProjectData.canAnnotate.checked = false;
      this.checkboxProjectData.canComment.checked = false;
      this.checkboxProjectData.canAccessResults.checked = false;
      this.checkboxProjectData.canRunJob.checked = false;
    } else if (key === "canAccessAITab" && !item.checked) {
      this.checkboxProjectData.canEditAITabSettings.checked = false;
      this.checkboxProjectData.canTrainModel.checked = false;
    }
    this.forceUpdate();
  };

  updateGroupName = (newGroupName) => {
    let nameError = newGroupName === "";
    if (!nameError) {
      let groupWithSameName = this.props.groups.find(
        (group) => group.name === newGroupName
      );
      nameError = !(typeof groupWithSameName === "undefined");
      if (this.props.group) {
        if (groupWithSameName && this.props.group.id === groupWithSameName.id) {
          nameError = false;
        }
      }
    }
    this.setState({
      groupName: newGroupName,
      nameError: nameError,
    });
  };

  render() {
    const { groupName, nameError, presetAdminIdx, presetProjectIdx } =
      this.state;
    const { classes, open } = this.props;

    return (
      <Dialog
        open={open}
        onClose={this.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Group Details</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            error={nameError}
            margin="dense"
            name="name"
            label="Name"
            fullWidth
            value={groupName}
            onChange={(e) => {
              this.updateGroupName(e.target.value);
            }}
          />
          <div className={classes.container}>
            <div className={classes.column}>
              <Typography variant="h6" className={classes.columnHeadline}>
                Admin Permissions:
              </Typography>
              <CustomListSelector
                label="Presets"
                optionsList={this.presetAdminOptionsList}
                selectedIdx={presetAdminIdx}
                onChange={this.onAdminPresetChange}
              />

              <FormGroup>
                {Object.keys(this.checkboxAdminData).map((key, idx) => {
                  const item = this.checkboxAdminData[key];
                  return (
                    <FormControlLabel
                      disabled={this.props.user.group[key] === false}
                      key={idx}
                      control={
                        <Checkbox
                          name="CheckboxAdminPermission"
                          checked={item.checked}
                          onChange={(e) => {
                            this.onAdminPermissionCheckboxChange(e, item);
                          }}
                        />
                      }
                      label={item.label}
                    />
                  );
                })}
              </FormGroup>
            </div>
            <div className={classes.column}>
              <Typography variant="h6" className={classes.columnHeadline}>
                Default Project Permissions:
              </Typography>
              <CustomListSelector
                label="Presets"
                optionsList={this.presetProjectOptionsList}
                selectedIdx={presetProjectIdx}
                onChange={this.onProjectPresetChange}
              />
              <FormGroup>
                {Object.keys(this.checkboxProjectData).map((key, idx) => {
                  const item = this.checkboxProjectData[key];
                  return (
                    <FormControlLabel
                      key={idx}
                      control={
                        <Checkbox
                          name="CheckboxProjectPermission"
                          checked={item.checked}
                          onChange={(e) => {
                            this.onProjectPermissionCheckboxChange(
                              e,
                              key,
                              item
                            );
                          }}
                        />
                      }
                      label={item.label}
                    />
                  );
                })}
              </FormGroup>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onClose} color="primary">
            Cancel
          </Button>
          <Button disabled={nameError} onClick={this.onApply} color="primary">
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

GroupDetailsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  user: PropTypes.object,
  group: PropTypes.object,
  groups: PropTypes.array,
  onClose: PropTypes.func,
  onApply: PropTypes.func,
};

export default withStyles(styles)(GroupDetailsDialog);

import React, { useState } from "react";
import PropTypes from "prop-types";
import { HexAlphaColorPicker } from "react-colorful";
import { makeStyles } from "@mui/styles";
import "../../css/colorpicker.css";
import { presetColors } from "../utils/RandomColorGenerator";

const useStyles = makeStyles({
  popover: {
    position: "relative",
  },
  pickerContainer: {
    position: "fixed",
    zIndex: "2",
    marginRight: "10px",
    padding: "16px",
    borderRadius: "5px",
    background: "#33333a",
    boxShadow: "0 6px 12px #999",
  },
  color: {
    width: "36px",
    height: "14px",
    borderRadius: "2px",
  },
  mainSwatch: {
    padding: "5px",
    background: "#fff",
    borderRadius: "1px",
    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    display: "inline-block",
    cursor: "pointer",
    marginTop: 6,
  },
  cover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
    zIndex: "1",
  },
  pickerSwatches: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: "12px",
    justifyContent: "center",
  },
  pickerSwatch: {
    width: "20px",
    aspectRatio: "1",
    margin: "4px",
    border: "none",
    padding: 0,
    borderRadius: "4px",
    cursor: "pointer",
    outline: "none",
  },
});

/**
 * Color picker that uses the SketchPicker from react-colorful.
 * @param {string} color The color to display as a hex string.
 * @param {function} handleChange Callback function that receives the new color as a hex string.
 * @param {function} onClose Callback function that is called when the color picker is closed.
 * @param {bool} includeWhite Flag if the color white should be included in the color swatches.
 * @returns
 */
const SketchColorPicker = (props) => {
  const { color, handleChange, onClose, includeWhite } = props;
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [xPosition, setXPosition] = useState(0);
  const [yPosition, setYPosition] = useState(0);

  let colorsForSwatches = presetColors;
  if (includeWhite) {
    colorsForSwatches = ["#ffffff", ...presetColors];
  }

  const handleClick = (e) => {
    let x = e.clientX;
    let y = e.clientY;
    if (window.innerHeight - e.clientY < 330) {
      y -= 250;
    }
    if (window.innerWidth - e.clientX < 330) {
      x -= 150;
    }
    setXPosition(x);
    setYPosition(y);
    setDisplayColorPicker(true);
    e.stopPropagation();
  };

  const handleClose = (e) => {
    setDisplayColorPicker(false);
    e.stopPropagation();
    onClose();
  };

  const classes = useStyles();

  return (
    <div className={classes.popover}>
      <div className={classes.mainSwatch} onClick={handleClick}>
        <div className={classes.color} style={{ background: color }} />
      </div>
      {displayColorPicker && [
        <div key={"close"} className={classes.cover} onClick={handleClose} />,
        <div
          key={"picker"}
          className={classes.pickerContainer}
          style={{ top: yPosition + 10, left: xPosition }}
        >
          <HexAlphaColorPicker
            className="color-picker"
            color={color}
            onChange={handleChange}
          />
          <div className={classes.pickerSwatches}>
            {colorsForSwatches.map((presetColor) => (
              <button
                key={presetColor}
                className={classes.pickerSwatch}
                style={{ background: presetColor }}
                onClick={(e) => {
                  handleChange(presetColor);
                  e.stopPropagation();
                }}
              />
            ))}
          </div>
        </div>,
      ]}
    </div>
  );
};

SketchColorPicker.propTypes = {
  color: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  onClose: PropTypes.func,
  includeWhite: PropTypes.bool,
};

SketchColorPicker.defaultProps = {
  handleChange: () => {},
  onClose: () => {},
  includeWhite: false,
};

export default SketchColorPicker;

import React, { useRef, useEffect } from "react";
import {
  Typography,
  Tooltip,
  Card,
  CardContent,
  CardActions,
  Button,
  Fab,
} from "@mui/material";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Backend from "../../common/utils/Backend";

// Icons
import AddIcon from "@mui/icons-material/Add";

import { makeStyles } from "@mui/styles";

import CaseSettingsListItem from "./CaseSettingsListItem";

const useStyles = makeStyles({
  root: {
    marginBottom: 20,
  },
  selectFieldDatatype: {
    width: 300,
    marginRight: 5,
  },
  selectFieldDefault: {
    lineHeight: "40px",
    "& .MuiIconButton-root": {
      display: "none",
    },
    "& .MuiSelect-select": {
      height: "22px",
    },
  },
  defaultSelectInput: {
    lineHeight: "40px",
    "& .MuiSelect-select": {
      height: "22px",
    },
  },
  fab: {
    marginLeft: "auto !important",
  },
});

const toColumnName = (inputString) => {
  return inputString
    .replace(/[^0-9a-zA-Z ]/g, "")
    .toLowerCase()
    .replace(/\s+/g, "_");
};

const CaseSettingsContainer = () => {
  const classes = useStyles();
  const cardContentRef = useRef(null); // create a ref
  const [caseCols, setCaseCols] = React.useState([]);
  const [listHeight, setListHeight] = React.useState(window.innerHeight - 350);
  const [savedAppsettings, setSavedAppsettings] = React.useState({});
  const [appsettings, setAppsettings] = React.useState({});

  React.useEffect(() => {
    Backend.readAppSettings().then((newAppsettings) => {
      const caseColumns = newAppsettings.caseColumns.map((caseCol) => {
        if (caseCol.items === null) {
          caseCol.items = [];
        }
        caseCol.visible =
          typeof caseCol.visible === "undefined" || caseCol.visible === "true";

        return caseCol;
      });
      setCaseCols(caseColumns);
      setAppsettings(newAppsettings);
      setSavedAppsettings(newAppsettings);
    });
    const handleResize = () => {
      setListHeight(window.innerHeight - 350);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when the component unmounts or the effect runs again
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Create a ref for each CaseSettingsListItem
  const caseColRefs = useRef([]);
  useEffect(() => {
    caseColRefs.current = caseColRefs.current.slice(0, caseCols.length);
  }, [caseCols]);

  const isAppsettingsValid = () => {
    return Object.keys(appsettings).length > 4;
  };

  const onDeleteRow = (idx) => {
    setCaseCols((prevValues) => {
      let newCaseCols = [...prevValues];
      newCaseCols.splice(idx, 1);
      return newCaseCols;
    });
  };

  const handleConfirmation = async (deleteColumnData, newAppsettings) => {
    const continueSave = await window.openConfirmationDialog(
      deleteColumnData.length > 1
        ? "Possible Data Loss detected"
        : "No Data loss detected!",
      deleteColumnData.length > 1
        ? deleteColumnData.map((item, idx) => <div key={idx}>{item}</div>)
        : "The changes seem to not have any effect on already existing Cases."
    );
    if (continueSave) {
      Backend.writeAppSettings(newAppsettings, (response) => {
        if (response.success) {
          window.showSuccessSnackbar("Case Settings saved!");
          setSavedAppsettings(newAppsettings);
        } else {
          window.showErrorSnackbar(
            "Something went wrong! Case Settings no saved!"
          );
        }
      });
    }
  };

  const getValidatedCaseCols = () => {
    const result = caseCols.map((_, idx) => {
      const caseCol = caseColRefs.current[idx].getCaseData();
      const caseColIdx = savedAppsettings.caseColumns.findIndex(
        (caseColumn) => caseColumn.name === caseCol.name
      );
      if (caseColIdx === -1) {
        caseCol.name = toColumnName(caseCol.label);
      }
      return caseCol;
    });
    return result;
  };

  const onSave = () => {
    if (isAppsettingsValid()) {
      let newAppsettings = appsettings;
      newAppsettings.caseColumns = getValidatedCaseCols();

      // Same with writeAppSettings, to write table
      Backend.getPossibleCaseSettingsChanges(newAppsettings, (response) => {
        if (response.criticalColumns) {
          let deleteColumnData = [
            "Do you really want to accept this change. You will lose the following Data of existing Cases?",
          ];
          for (const [key, value] of Object.entries(response.criticalColumns)) {
            const column = savedAppsettings.caseColumns.find(
              (item) => item.name === key
            );
            deleteColumnData.push(
              `${column ? column.label : key} with ${value} non empty ${
                value === 1 ? "entry" : "entries"
              }!`
            );
          }
          handleConfirmation(deleteColumnData, newAppsettings);
        }
      });
    } else {
      window.showErrorSnackbar("Error: Something went wrong!");
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    setCaseCols((prevValues) => {
      let newCaseCols = [...prevValues];
      const [reorderedItem] = newCaseCols.splice(result.source.index, 1);
      newCaseCols.splice(result.destination.index, 0, reorderedItem);
      return newCaseCols;
    });
  };

  const addRow = () => {
    setCaseCols((prevCaseCols) => [
      ...prevCaseCols,
      {
        name: "case_setting_" + prevCaseCols.length,
        label: "Case Setting " + prevCaseCols.length,
        type: "Text",
        defaultValue: "",
        items: [],
      },
    ]);
    const cardContentEl = cardContentRef.current; // get the DOM element
    if (cardContentEl) {
      cardContentEl.scrollTo({
        top: cardContentEl.scrollHeight,
        behavior: "smooth",
      });
      setTimeout(
        () =>
          cardContentEl.scrollTo({
            top: cardContentEl.scrollHeight,
            behavior: "smooth",
          }),
        100
      );
    }
  };

  const caseAccCol = caseCols.find((caseCol) => caseCol.label === "Case Acc#");

  const renderCol = (idx, data) => {
    return (
      <CaseSettingsListItem
        ref={(el) => (caseColRefs.current[idx] = el)}
        data={data}
        idx={idx}
        onDeleteRow={onDeleteRow}
      />
    );
  };

  return (
    <Card>
      <CardContent
        ref={cardContentRef}
        style={{ height: listHeight, overflow: "auto" }}
      >
        <Typography variant="h6">Case Settings</Typography>
        {caseAccCol && renderCol(0, caseAccCol)}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {caseCols.map((data, idx) => {
                  if (data.label === "Case Acc#") {
                    return null;
                  }
                  return (
                    <Draggable
                      key={data.name}
                      draggableId={data.name}
                      index={idx}
                    >
                      {(provided) => {
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              userSelect: "none",
                              ...provided.draggableProps.style,
                            }}
                          >
                            {renderCol(idx, data)}
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                })}
                <div style={{ height: 0 }}>{provided.placeholder}</div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          color="primary"
          variant="outlined"
          onClick={onSave}
        >
          Save
        </Button>
        <Tooltip title="Add new Case Column!" disableInteractive>
          <Fab
            color="primary"
            aria-label="add"
            className={classes.fab}
            onClick={addRow}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </CardActions>
    </Card>
  );
};
export default CaseSettingsContainer;

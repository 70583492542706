import * as echarts from "echarts";
import JSZip from "jszip";
import { saveAs } from "file-saver";

export const downloadZippedChartDataAndImage = async (chartId, key, data) => {
  console.log(chartId);
  console.log(key);
  console.log(data);
  const zip = new JSZip();

  // Convert data to CSV format
  const csvData = [`x-values,y-values`];
  for (let i = 0; i < data.x_values.length; i++) {
    csvData.push(`${data.x_values[i]},${data.y_values[i]}`);
  }
  const csvContent = csvData.join("\n");

  // Add CSV to zip
  zip.file(`${key}.csv`, csvContent);

  // Add chart image to zip
  const chartElement = document.getElementById(chartId);
  const chartBase64 = echarts.getInstanceByDom(chartElement).getDataURL({
    type: "png",
    pixelRatio: 2,
    backgroundColor: "rgb(245, 245, 247)",
  });
  const base64Data = chartBase64.replace(/^data:image\/png;base64,/, "");
  zip.file(`${key}.png`, base64Data, { base64: true });

  // Generate zip and prompt download
  zip.generateAsync({ type: "blob" }).then((content) => {
    saveAs(content, `${key}_chart-data.zip`);
  });
};

const setColors = (label) => {
  const redWords = ["loss", "error"];
  const greyWords = ["time", "memory", "epoch", "iter"];
  const redColors = ["rgb(235, 100, 53)", "rgb(235, 100, 53, 0.4)"];
  const greyColors = ["rgb(107, 107, 107)", "rgba(107, 107, 107, 0.4)"];
  const defaultBlue = ["", ""];

  switch (true) {
    case redWords.some((sub) => label.toLowerCase().includes(sub)):
      return redColors;
    case greyWords.some((sub) => label.toLowerCase().includes(sub)):
      return greyColors;
    default:
      return defaultBlue;
  }
};

export const setOptions = (key, data) => {
  const label = key;
  const xValues = data.x_values;
  const yValues = data.y_values;
  const xAxisName = data.x_axis;
  const yAxisName = data.y_axis;
  const [solidColor, transparentColor] = setColors(label);

  const options_output = {
    title: {
      text: label,
      x: "center",
    },
    xAxis: {
      type: "category",
      data: xValues,
      name: xAxisName,
    },
    yAxis: {
      type: "value",
      data: yValues,
      name: yAxisName,
    },
    dataZoom: [
      {
        type: "slider",
        start: 0,
        end: 100,
        fillerColor: transparentColor,
        handleColor: solidColor,
        handleStyle: {
          color: transparentColor,
        },
      },
    ],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    series: [
      {
        showSymbol: false,
        type: "line",
        data: yValues,
        itemStyle: {
          color: solidColor,
        },
      },
    ],
    animationDuration: 0,
  };

  return options_output;
};

export const downloadNZip = (n, chartDataKeys, chartData) => {
  for (let i = 0; i < n; i++) {
    const key = chartDataKeys[i];
    const data = chartData[key];
    downloadZippedChartDataAndImage(`chart_${i + 1}`, key, data);
  }
};

// Copyright: HS Analysis GmbH, 2023
// Author: Valentin Haas

// Framework imports
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// External packages
import DialogContentText from "@mui/material/DialogContentText";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Typography } from "@mui/material";

// HSA packages
import {
  DatasetTypeShort,
  DatasetApproachShort,
} from "../../../common/components/AITrainingSettings";

const styles = {
  modelSelButtonContainer: {
    marginBottom: "10px",
  },
  modelSelButton: {
    width: "20%",
    marginRight: "5px",
  },
  existingModel: {
    minWidth: 200,
    marginRight: "5px",
  },
  existingVersion: {
    minWidth: 100,
  },
};

/**
 * Component to display the model metadata selection in the AI training dialog.
 * @param {AITrainingSettings} trainingSettings All settings for the AI training, as defined in the AITrainingSettings class.
 * @param {function} setTrainingSettings Function to set the training settings.
 * @param {array} existingAiModels Array of existing AI models.
 * @param {boolean} nameInUse Whether the model name is already used in the existing AI models.
 * @param {boolean} containsInvalidChar Whether the model name contains invalid characters.
 * @returns {JSX.Element} The component to display.
 */
export default function ModelMetaData(props) {
  const {
    trainingSettings,
    setTrainingSettings,
    existingAiModels,
    nameInUse,
    containsInvalidChar,
  } = props;

  const [useHSANamingFormat, setUseHSANamingFormat] = useState(true);
  const [modelName, setModelName] = useState("");

  const modelVersion = trainingSettings.metaData.version;
  const isNewModel = trainingSettings.metaData.isNewModel;

  useEffect(() => {
    let initialModelName = trainingSettings.structures[0].label;
    if (useHSANamingFormat) {
      trainingSettings.metaData.name = getHSAModelName(initialModelName);
    } else {
      trainingSettings.metaData.name = modelName;
    }
    setTrainingSettings(trainingSettings);
    setModelName(initialModelName);
  }, []);

  /**
   * Generates the HSA model name based on the user-defined model name and the training settings.
   * @param {string} name The user-defined model name.
   * @returns {string} The HSA model name, based on the user-defined model name and the training settings.
   */
  const getHSAModelName = (name) => {
    const nameParts = [name];

    if (trainingSettings.datasetParameters.datasetType) {
      nameParts.push(
        DatasetTypeShort[trainingSettings.datasetParameters.datasetType]
      );
    }

    // Structure indices that are used for training
    if (trainingSettings.structures.length > 0) {
      nameParts.push(
        trainingSettings.structures[0].label +
          (trainingSettings.structures.length > 1
            ? "(" + trainingSettings.structures.length.toString() + ")"
            : "")
      );
    }

    // Dataset approach that is used for training
    if (trainingSettings.datasetParameters.datasetApproach) {
      nameParts.push(
        DatasetApproachShort[trainingSettings.datasetParameters.datasetApproach]
      );
    }

    // Image height that is used for training
    if (trainingSettings.modelParameters.imageHeight) {
      nameParts.push(`${trainingSettings.modelParameters.imageHeight}px`);
    }

    // Pyramid level that is used for training
    if (trainingSettings.modelParameters.pyramidLevel) {
      nameParts.push(trainingSettings.modelParameters.pyramidLevel.toString());
    }

    // Input channels that are used for training
    if (trainingSettings.modelParameters.fluorescenceChannels) {
      if (trainingSettings.modelParameters.fluorescenceChannels.length === 0) {
        // Brightfield
        nameParts.push(
          trainingSettings.modelParameters.inputChannels === 3 ? "rgb" : "grey"
        );
      } else {
        // Fluorescence
        nameParts.push(
          JSON.stringify(
            trainingSettings.modelParameters.fluorescenceChannels
          ).replace(/"/g, "")
        );
      }
    }

    return nameParts.join("_");
  };

  return (
    <>
      <DialogContentText
        component={"span"}
        style={{ paddingTop: "0px", marginBottom: "20px" }}
      >
        <div>
          You can train a new model from scratch or start training based on an
          existing model:
          <ul>
            <li>
              <strong>Train from scratch:</strong> type in name of your model
            </li>
            <li>
              <strong>Use existing model:</strong> select existing model name
              and version
            </li>
          </ul>
        </div>
      </DialogContentText>
      {/* Two buttons next to each other, choosing between new or existing */}
      <Grid sx={styles.modelSelButtonContainer} container>
        <Button
          sx={styles.modelSelButton}
          variant="contained"
          color={isNewModel && isNewModel !== null ? "primary" : "secondary"}
          onClick={() => {
            if (trainingSettings.metaData.isNewModel) return;
            trainingSettings.metaData.name = "";
            trainingSettings.metaData.version = "";
            trainingSettings.metaData.isNewModel = true;
            setTrainingSettings(trainingSettings);
          }}
        >
          New Model
        </Button>
        <Button
          sx={styles.modelSelButton}
          variant="contained"
          color={isNewModel || isNewModel === null ? "secondary" : "primary"}
          onClick={() => {
            if (!trainingSettings.metaData.isNewModel) return;
            trainingSettings.metaData.name = "";
            trainingSettings.metaData.version = "";
            trainingSettings.metaData.isNewModel = false;
            setTrainingSettings(trainingSettings);
          }}
        >
          Existing Model
        </Button>
      </Grid>
      {/* If new model is selected, show text field to enter model name */}
      {isNewModel && isNewModel !== null && (
        <div>
          <div>
            <TextField
              name="Textfield"
              label="Model Name"
              value={modelName}
              error={modelName === "" || nameInUse || containsInvalidChar}
              helperText={
                modelName === ""
                  ? "Model name needed!"
                  : nameInUse
                  ? "Name already in use!"
                  : containsInvalidChar
                  ? 'Must not use the following: & + # \\ / | ? : * " < >'
                  : ""
              }
              onChange={(e) => {
                setModelName(e.target.value);
                if (useHSANamingFormat) {
                  trainingSettings.metaData.name = getHSAModelName(
                    e.target.value
                  );
                } else {
                  trainingSettings.metaData.name = e.target.value;
                }
                setTrainingSettings(trainingSettings);
              }}
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={useHSANamingFormat}
                  onChange={(e) => {
                    setUseHSANamingFormat(e.target.checked);
                    if (e.target.checked) {
                      trainingSettings.metaData.name =
                        getHSAModelName(modelName);
                    } else {
                      trainingSettings.metaData.name = modelName;
                    }
                  }}
                  color="primary"
                />
              }
              label="Using HSA naming convention"
            />
          </div>
          {useHSANamingFormat && (
            <div>
              <Typography>
                {"Model Name: "}
                <span style={{ fontWeight: "bold" }}>
                  {trainingSettings.metaData.name.slice(0, modelName.length)}
                </span>
                {trainingSettings.metaData.name.slice(modelName.length)}
              </Typography>
            </div>
          )}
        </div>
      )}
      {/* If existing model is selected, show dropdown to select model name and version */}
      {!trainingSettings.metaData.isNewModel &&
        trainingSettings.metaData.isNewModel !== null && (
          <>
            <TextField
              name="Textfield"
              select
              label="Select Model"
              value={
                existingAiModels.find((aiModel) => aiModel.name === modelName)
                  ?.label ?? ""
              }
              error={modelName === ""}
              helperText={modelName === "" ? "Please select a model" : ""}
              style={styles.existingModel}
              onChange={(e) => {
                trainingSettings.metaData.name = e.target.value;
                trainingSettings.metaData.version = "";
                setTrainingSettings(trainingSettings);
              }}
            >
              {existingAiModels.map((aiModel) => (
                <MenuItem key={aiModel.name} value={aiModel.name}>
                  {aiModel.name}
                </MenuItem>
              )) ?? []}
            </TextField>
            <TextField
              name="Textfield"
              select
              label="Select Version"
              value={modelVersion}
              error={modelVersion === ""}
              helperText={modelVersion === "" ? "Please select a version" : ""}
              style={styles.existingVersion}
              onChange={(e) => {
                trainingSettings.metaData.version = e.target.value;
                setTrainingSettings(trainingSettings);
              }}
            >
              {existingAiModels
                .find((aiModel) => aiModel.name === modelName)
                ?.versions.map((version) => (
                  <MenuItem key={version.label} value={version.label}>
                    {version.label}
                  </MenuItem>
                )) ?? [""]}
            </TextField>
          </>
        )}
    </>
  );
}

ModelMetaData.propTypes = {
  trainingSettings: PropTypes.object.isRequired,
  setTrainingSettings: PropTypes.func.isRequired,
  existingAiModels: PropTypes.array,
  nameInUse: PropTypes.bool,
  containsInvalidChar: PropTypes.bool,
};

ModelMetaData.defaultProps = {
  existingAiModels: [],
  nameInUse: false,
  containsInvalidChar: false,
};

// Framework components
import { React, useState } from "react";
import { PropTypes } from "prop-types";
import IconButton from "@mui/material/IconButton";
// import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import ResetIcon from "@mui/icons-material/Replay";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import {
  TextField,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  Chip,
} from "@mui/material";

// Material UI components

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    height: "70px",
  },
  iconButton: {
    padding: 10,
  },
  dialog: {
    width: "500px",
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  chip: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

/**
 * Filter list of models in AI Viewer.
 */
export default function AIModelsFiltering({
  loading,
  modelFilterValue,
  onFilterChange,
}) {
  const classes = useStyles();
  const modelTypes = [
    "All",
    "Classification",
    "Segmentation",
    "Object Detection",
    "Instance Segmentation",
  ];
  const trainingStatus = ["All", "Pending", "Running", "Finished", "Failed"];

  const [structureFilter, setStructureFilter] = useState("");
  const [structureFilters, setStructureFilters] = useState([]); // TODO
  const [modelTypeFilter, setModelTypeFilter] = useState("All"); // TODO
  const [trainingStatusFilter, setTrainingStatusFilter] = useState("All"); // TODO
  const [open, setOpen] = useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStructureInputChange = (event) => {
    setStructureFilter(event.target.value);
  };

  const handleStructureInputKeyPress = (event) => {
    if (event.key === "Enter") {
      setStructureFilters((prevFilters) => [...prevFilters, structureFilter]);
      setStructureFilter("");
      event.preventDefault();
    }
  };

  const handleDeleteStructureFilter = (chipToDelete) => () => {
    setStructureFilters((chips) =>
      chips.filter((chip) => chip !== chipToDelete)
    );
  };

  const resetFilters = () => {
    setStructureFilter("");
    setStructureFilters([]);
    setModelTypeFilter("All");
    setTrainingStatusFilter("All");
  };

  return (
    <Paper className={classes.root}>
      {/* <IconButton
        className={classes.iconButton}
        aria-label="filter"
        onClick={handleClickOpen}
        style={{
          color:
            structureFilters.length == 0 ||
            modelTypeFilter !== "All" ||
            trainingStatusFilter !== "All"
              ? "grey"
              : "blue",
        }}
      >
        <FilterListIcon />
      </IconButton> */}
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle>Filter Models</DialogTitle>
        <DialogContent>
          <IconButton
            className={classes.iconButton}
            aria-label="reset"
            onClick={resetFilters}
          >
            <ResetIcon />
          </IconButton>
          <TextField
            label="Structure"
            name="Textfield"
            value={structureFilter}
            onChange={handleStructureInputChange}
            onKeyPress={handleStructureInputKeyPress}
            className={classes.textField}
            fullWidth
          />
          {structureFilters.map((filter) => (
            <Chip
              key={filter}
              label={filter}
              onDelete={handleDeleteStructureFilter(filter)}
              className={classes.chip}
            />
          ))}
          <TextField
            select
            label="Model Type"
            value={modelTypeFilter}
            onChange={(event) => setModelTypeFilter(event.target.value)}
            className={classes.textField}
            fullWidth
            name="Textfield"
          >
            {modelTypes.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Training Status"
            value={trainingStatusFilter}
            onChange={(event) => setTrainingStatusFilter(event.target.value)}
            className={classes.textField}
            fullWidth
            name="Textfield"
          >
            {trainingStatus.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
      </Dialog>
      <InputBase
        className={classes.input}
        placeholder="Search Models"
        inputProps={{ "aria-label": "search models" }}
        value={modelFilterValue}
        onChange={onFilterChange}
        disabled={loading}
      />
      <IconButton
        className={classes.iconButton}
        aria-label="search"
        disabled={loading}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}

AIModelsFiltering.propTypes = {
  loading: PropTypes.bool,
  modelFilterValue: PropTypes.string,
  onFilterChange: PropTypes.func,
};

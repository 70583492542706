import React, { Component } from "react";
import PropTypes from "prop-types";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import FileCopyIcon from "@mui/icons-material/FileCopy";

import {
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
} from "@mui/material";
import { GetApp, Publish } from "@mui/icons-material";

import { intersects } from "../utils/PolygonUtil";
import { withSpinloader } from "../../common/components/Spinloader";
import { RegionROI } from "../utils/ROI";
import { getContainedRegionRois } from "../utils/StructuresUtils";
import { downloadArrayAsFile } from "../../common/utils/Utils";

class ListItemOptionsMenu extends Component {
  state = {
    optionsAnchorEl: null,
  };

  handleOptionsOpen = (e) => {
    this.setState({ optionsAnchorEl: e.target });
  };

  handleOptionsClose = (e) => {
    this.setState({ optionsAnchorEl: null });
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  addSubType = (e) => {
    const { structure, projectContext } = this.props;

    if (structure.subtypeLevel < 9) {
      projectContext.addSubType(structure);
      structure.showSubtypes = true;
      structure.hasChild = true;
      this.handleOptionsClose(e);
    }
  };

  addSubStructure = (e) => {
    const { structure, projectContext } = this.props;

    if (structure.subtypeLevel < 9) {
      projectContext.addSubStructure(structure);
      structure.showSubtypes = true;
      structure.hasChild = true;
      this.handleOptionsClose(e);
    }
  };

  // start export of annotations of selected structure
  exportAnnotations = (e) => {
    const { projectContext } = this.props;
    const { selectedLayer, structures } = projectContext;
    const strData = projectContext.getProjectStringInfos();
    const roiLayers = projectContext.roiLayers[projectContext.fileId];
    let rois = getContainedRegionRois(
      structures[selectedLayer],
      structures,
      roiLayers
    );
    let roiRegions = rois.map((regionRoi) => {
      return {
        regions: regionRoi.regions,
        isObject: regionRoi.isObject,
      };
    });

    console.log("roiRegions:", roiRegions);

    downloadArrayAsFile(
      roiRegions,
      strData.name +
        "_" +
        this.props.structure.label +
        "_" +
        strData.date +
        "_" +
        roiRegions.length +
        "_" +
        projectContext.user +
        ".annhsa"
    );
    this.handleOptionsClose(e);
  };

  // open file dialog and import structures from selcted file
  importAnnotations = (e) => {
    const { projectContext } = this.props;
    const { structures, selectedLayer } = projectContext;
    const roiLayers = projectContext.roiLayers[projectContext.fileId];
    let inputElem = document.createElement("INPUT");
    inputElem.setAttribute("type", "file");
    inputElem.setAttribute("onChange", "file");
    inputElem.setAttribute("accept", ".annhsa");
    inputElem.onchange = (e) => {
      let files = e.target.files;
      if (files.length <= 0) return false;

      let fr = new FileReader();
      fr.onload = (e) => {
        this.props.spinloader.show();
        //timeout, so spinloader can start
        setTimeout(() => {
          try {
            let historyItem = [];
            const histId = structures[selectedLayer].id;
            let drawRegions = JSON.parse(e.target.result);
            let nonOverlapping = [];
            const tree = roiLayers[selectedLayer].tree;
            for (let i = 0; i < drawRegions.length; i++) {
              let drawRegion = drawRegions[i];
              const roi = new RegionROI({
                regions: drawRegion.regions,
                isObject: drawRegion.isObject,
                structureId: histId,
              });
              const overlappingRois = tree.search(roi.treeItem);
              let intersecting = false;
              for (let r of overlappingRois) {
                try {
                  if (intersects(roi, r.roi)) {
                    intersecting = true;
                    break;
                  }
                } catch (e) {
                  intersecting = true;
                  break;
                }
              }
              if (!intersecting) {
                nonOverlapping.push(roi);
              }
            }
            nonOverlapping.forEach((r) => {
              historyItem.push({ add: true, id: histId, roi: r });
              roiLayers[selectedLayer].layer.regionRois.push(r);
              tree.insert(r.treeItem);
            });
            roiLayers[selectedLayer].layer.regionRois = tree
              .all()
              .map((treeItem) => treeItem.roi);
            window.projectHistory.add(historyItem);
          } catch (e) {
            console.log("not supported error:", e);
            window.showErrorSnackbar("File not supported!");
          }
          this.props.spinloader.hide();
        });
      };
      fr.readAsText(files.item(0));
    };
    inputElem.click();
    inputElem.remove();
    this.handleOptionsClose(e);
  };

  /**
   * Checks if a structure can be moved in a direction.
   *
   * @param {int} direction - 1 is up -1 is down
   * @returns {bool} Move in this direction possible
   */
  canMoveStructure = (direction) => {
    const { structure, projectContext } = this.props;
    return projectContext.canMoveStructure(structure, direction);
  };

  /**
   * Moves a structure up or down in structure list
   *
   * @param {event} e
   * @param {int} direction - 1 is up -1 is down
   */
  moveStructure = (e, direction) => {
    const { structure, projectContext } = this.props;
    const newSelectedLayer = projectContext.moveStructure(structure, direction);
    if (newSelectedLayer) this.props.onSelectLayer(newSelectedLayer);
    this.handleOptionsClose(e);
  };

  deleteStructure = (e) => {
    const { structure, projectContext } = this.props;
    let parentId = structure.parentId;
    const newSelectedLayer = projectContext.deleteStructure(structure);
    if (newSelectedLayer) this.props.onSelectLayer(newSelectedLayer);
    this.handleOptionsClose(e);

    // check if parent still has childs
    let numberChilds = projectContext.structures.filter(
      (element) => element.parentId === parentId
    ).length;

    if (numberChilds === 0 && parentId !== 0) {
      let parentIdx = projectContext.structures.findIndex(
        (element) => element.id === parentId
      );
      projectContext.structures[parentIdx].hasChild = false;
    }
  };

  duplicateStructure = (e) => {
    const { structure, projectContext } = this.props;
    const newSelectedLayer = projectContext.duplicateStructure(structure);
    if (newSelectedLayer) this.props.onSelectLayer(newSelectedLayer);
    this.handleOptionsClose(e);
  };

  render() {
    const { structure } = this.props;

    const { optionsAnchorEl } = this.state;

    return (
      <React.Fragment>
        <Tooltip disableInteractive title="Actions">
          <IconButton onClick={this.handleOptionsOpen} size="large">
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        {optionsAnchorEl && (
          <Menu
            anchorEl={optionsAnchorEl}
            keepMounted
            open={Boolean(optionsAnchorEl)}
            onClose={this.handleOptionsClose}
          >
            {structure.id !== 1 && [
              <MenuItem
                key={10000}
                onClick={this.addSubType}
                disabled={!structure.dynamic}
              >
                <ListItemIcon>
                  <PlaylistAddIcon fontSize="small" />
                </ListItemIcon>
                Add subtype
              </MenuItem>,
              <MenuItem
                key={10001}
                onClick={this.addSubStructure}
                disabled={!structure.dynamic || structure.classificationSubtype}
              >
                <ListItemIcon>
                  <PlaylistAddIcon fontSize="small" />
                </ListItemIcon>
                Add substructure
              </MenuItem>,
              <MenuItem
                key={10002}
                onClick={this.duplicateStructure}
                disabled={!structure.dynamic}
              >
                <ListItemIcon>
                  <FileCopyIcon fontSize="small" />
                </ListItemIcon>
                Duplicate structure
              </MenuItem>,
            ]}

            <MenuItem key={10003} onClick={this.exportAnnotations}>
              <ListItemIcon>
                <GetApp fontSize="small" />
              </ListItemIcon>
              Export annotations
            </MenuItem>
            <MenuItem key={10004} onClick={this.importAnnotations}>
              <ListItemIcon>
                <Publish fontSize="small" />
              </ListItemIcon>
              Import annotations
            </MenuItem>
            {structure.id !== 1 && [
              <Divider key={10005} />,
              <MenuItem
                key={10006}
                onClick={(e) => this.moveStructure(e, 1)}
                disabled={!this.canMoveStructure(1)}
              >
                <ListItemIcon>
                  <ArrowUpwardIcon fontSize="small" />
                </ListItemIcon>
                Move structure up
              </MenuItem>,
              <MenuItem
                key={10007}
                onClick={(e) => this.moveStructure(e, -1)}
                disabled={!this.canMoveStructure(-1)}
              >
                <ListItemIcon>
                  <ArrowDownwardIcon fontSize="small" />
                </ListItemIcon>
                Move structure down
              </MenuItem>,
              <Divider key={10008} />,
              <MenuItem
                key={10009}
                style={{ color: "red" }}
                onClick={this.deleteStructure}
                disabled={!structure.dynamic}
              >
                <ListItemIcon>
                  <DeleteIcon fontSize="small" style={{ color: "red" }} />
                </ListItemIcon>
                Delete structure
              </MenuItem>,
            ]}
          </Menu>
        )}
      </React.Fragment>
    );
  }
}

// define the component's interface
ListItemOptionsMenu.propTypes = {
  projectContext: PropTypes.object,
  structure: PropTypes.object,
  onSelectLayer: PropTypes.func,
  spinloader: PropTypes.object,
};

export default withSpinloader(ListItemOptionsMenu);

//#region Type checks

import { isArray } from "mathjs";

/**
 * Validate that a string follows the uuid format per RFC4122.
 * @param {string} inputString String to validate for uuid formatting.
 * @param {boolean} acceptNil Whether to accept NIL type UUID or not. Defaults to false
 * @returns {boolean} Whether the given string is a valid UUID.
 */
export function isUuid(inputString, acceptNil = false) {
  if (typeof inputString !== "string") return false;
  if (typeof acceptNil !== "boolean")
    throw TypeError(
      `acceptNil must be of type boolean, received ${typeof acceptNil}: ${acceptNil}`
    );

  const uuid =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  const uuid_w_nil =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  // Cheap length check
  if (inputString.length !== 36) return false;

  if (acceptNil) {
    return uuid_w_nil.test(inputString);
  } else {
    return uuid.test(inputString);
  }
}

/**
 * Check if a given string is a valid 3, 6, or 8 character hex color representation.
 * @param {string} inputString A string to test for valid color format.
 * @returns {bool} The given string is a valid hex color representation.
 */
export function isHexColor(inputString) {
  if (typeof inputString !== "string") return false;
  const hexColor = /^(#[\da-f]{3}|#[\da-f]{6}|#[\da-f]{8})$/i;
  return hexColor.test(inputString);
}

/**
 * Checks if a value is a plain object.
 * @param {number} input Object to test.
 * @returns {boolean} Whether the given value resembles an object.
 */

export function isObject(obj) {
  return obj && typeof obj === "object" && !Array.isArray(obj);
}

/**
 * Checks if a value is an integer value.
 * @param {number} input Number to test.
 * @returns {boolean} Whether the given value resembles an integer.
 */
export function isInt(input) {
  if (typeof input === "bigint") return true; // BigInts are always ints
  if (typeof input !== "number") return false;

  return (input | 0) === input;
}
//#endregion

//#region Conversions
/**
 * Rounds a floating point number to specified decimals after the comma.
 * @param {number} num Number to round.
 * @param {int} decimals Decimals after the comma to round to. Defaults to 0.
 * @returns {number} Input rounded to specified decimal count.
 */
export function roundToDecimal(num, decimals = 0) {
  if (typeof num != "number") {
    throw TypeError(
      `num must be of type number, received ${typeof num}: ${num}`
    );
  }

  if (!isInt(decimals) || decimals < 0) {
    throw TypeError(
      `decimals must be an integer >= 0, received ${typeof decimals}: ${decimals}`
    );
  }

  return (
    Math.round((num + Number.EPSILON) * Math.pow(10, decimals)) /
    Math.pow(10, decimals)
  );
}

/**
 * Convert a byte to its two character, hexadecimal uppercase representation.
 * @param {byte} input Byte (between 0 and 255) to convert to 2-letter hex value.
 * @returns {string} The input converted to a two-character hex string.
 */
export function byteToHex(input) {
  if (!isInt(input) || input < 0 || input > 255) {
    throw TypeError(
      `input must be an integer >= 0, <= 255, received ${typeof input}: ${input}`
    );
  }
  return ("0" + Number(input).toString(16)).slice(-2).toUpperCase();
}
//#endregion

/**
 * Get the viewer defining url path from a project type in the format "/path/".
 * @param {string} projectType Name of the project type.
 * @returns {string} Viewer defining path for URL.
 */
export function viewerType(projectType) {
  if (typeof projectType !== "string")
    throw TypeError(
      `projectType must be of type string, received ${typeof projectType}: ${projectType}`
    );
  projectType = projectType.toLowerCase();
  // Spectra Viewer
  if (
    projectType.includes("esrtraining") ||
    projectType.includes("esrevaluation")
  ) {
    return "/esr_view/";
  }
  // Proteome Viewer
  else if (projectType.includes("proteomeanalysis")) {
    return "/proteome_view/";
  }
  // Audio Viewer
  else if (projectType.includes("audioannotator")) {
    return "/audio_view/";
  }
  // Image Viewer
  else {
    return "/view/";
  }
}

/**
 * Download a json object as a file with a given name.
 * @param {JSON} jsonObject The JSON object to save.
 * @param {string} downloadName Optional. How the file will be called once downloaded.
 *                              Needs not end in .json. Defaults to export.json.
 */
export function downloadJsonObjectAsFile(
  jsonObject,
  downloadName = "export.json"
) {
  if (!isObject(jsonObject))
    throw TypeError(
      `jsonObject must be of type object, received ${typeof jsonObject}: ${jsonObject}`
    );
  if (typeof downloadName !== "string")
    throw TypeError(
      `downloadName must be of type string, received ${typeof downloadName}: ${downloadName}`
    );
  if (downloadName.length <= 0)
    throw Error(`downloadName must not be empty, received: ${downloadName}`);

  let dataStr =
    "data:text/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify(jsonObject));

  let dlAnchorElem = document.createElement("a");
  dlAnchorElem.setAttribute("href", dataStr);
  dlAnchorElem.setAttribute("download", downloadName);
  dlAnchorElem.click();
  dlAnchorElem.remove();
}

/**
 * Download an array as a file with a given name.
 * @param {array} arrayIn The array to save.
 * @param {string} downloadName Optional. How the file will be called once downloaded.
 *                              Needs not end in .json. Defaults to export.json.
 */
export function downloadArrayAsFile(arrayIn, downloadName = "export.json") {
  if (!isArray(arrayIn))
    throw TypeError(
      `arrayIn must be of type array, received ${typeof arrayIn}: ${arrayIn}`
    );
  if (typeof downloadName !== "string")
    throw TypeError(
      `downloadName must be of type string, received ${typeof downloadName}: ${downloadName}`
    );
  if (downloadName.length <= 0)
    throw Error(`downloadName must not be empty, received: ${downloadName}`);

  let dataStr =
    "data:text/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify(arrayIn));

  let dlAnchorElem = document.createElement("a");
  dlAnchorElem.setAttribute("href", dataStr);
  dlAnchorElem.setAttribute("download", downloadName);
  dlAnchorElem.click();
  dlAnchorElem.remove();
}

/**
 * Creates pop-up window to import a file via http input field.
 * @param {Array} acceptedFileTypes Strings of file endings allowed to import (including the dot, e.g. .json).
 * @returns {Promise} The files to import.
 */
export async function requestFileInput(acceptedFileTypes) {
  if (!Array.isArray(acceptedFileTypes))
    throw TypeError(
      `acceptedFileTypes must be an Array, received ${typeof acceptedFileTypes}: ${acceptedFileTypes}`
    );

  return new Promise((resolve) => {
    // Import window
    const inputElem = document.createElement("INPUT");
    inputElem.setAttribute("type", "file");
    inputElem.setAttribute("onChange", "file");
    inputElem.setAttribute("accept", acceptedFileTypes);
    inputElem.onchange = (e) => resolve(e.target.files);
    inputElem.click();
    inputElem.remove();
  });
}

/**
 * Shuffles array in place using Fisher-Yates algorithm.
 * @param {Array} a An array containing items to be shuffled.
 * @returns {Array} The shuffled array
 */
export function shuffle(a) {
  if (!Array.isArray(a))
    throw TypeError(`a must be of type Array, received ${typeof a}: ${a}`);

  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

/**
 * if there were no new calls of this function for the delay time, the newest func will be triggered
 * @param {function} func callback function
 * @param {int} delay in ms
 * @returns
 */
export function debounce(fn, delay) {
  let timeoutId;
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

/**
 * Given an index and the total number of entries, return the log-scaled value.
 * @param {int} idx Index of all numbers to take logarithm of.
 * @param {int} total Total number of indicies.
 * @param {number} base Optional. Base of logarithm. Defaults to 10.
 * @returns
 */
export function logScale(idx, total, base = 10) {
  const logmax = logBase(total + 1, base);
  const exp = (logmax * idx) / total;
  return Math.round(Math.pow(base, exp) - 1);
}

/**
 * Takes the logarithm of a number to a given base.
 * @param {number} val Value to calculate logarithm for.
 * @param {number} base Base to take logarithem to.
 * @returns {number} log_base(val)
 */
export function logBase(val, base) {
  return Math.log(val) / Math.log(base);
}

function hexToRgba(hex) {
  const regex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i;
  const result = regex.exec(hex);

  if (!result) {
    return null;
  }

  const [, r, g, b, a] = result;

  return {
    r: parseInt(r, 16),
    g: parseInt(g, 16),
    b: parseInt(b, 16),
    a: a ? parseInt(a, 16) / 255 : 1,
  };
}

/**
 * Determines if a given color is light or dark.
 * @param {string} color - The hex code of the color to check.
 * @returns {boolean} True if the color is light, false if it is dark.
 */

export function isColorLight(color) {
  // Convert the color to RGB
  const rgba = hexToRgba(color);

  // If the color is light because of transparence, return true imeediately
  if (rgba.a < 0.5) return true;

  // Calculate the luminance using the formula for relative luminance defined in WCAG 2.0
  const luminance = 0.2126 * rgba.r + 0.7152 * rgba.g + 0.0722 * rgba.b;

  // Set the threshold for light colors
  const threshold = 186;

  // Return true if the luminance is greater than the threshold for light colors
  return luminance > threshold;
}

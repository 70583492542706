import React from "react";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

const StyledIconButton = styled(IconButton)(({ theme, checked }) => ({
  color: "#757575",
  padding: 12,
  "& svg": {
    fontSize: "1.5rem",
    ...(checked && {
      color: theme.palette.primary.main,
    }),
  },
}));
const EyeIconButton = (props) => {
  const { checked, onClick, ...rest } = props;

  return (
    <StyledIconButton {...rest} checked={checked} onClick={onClick}>
      {checked ? <VisibilityIcon /> : <VisibilityOffIcon />}
    </StyledIconButton>
  );
};

EyeIconButton.propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default EyeIconButton;

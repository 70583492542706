// Copyright HS Analysis GmbH, 2023
// Author: Valentin Haas

// Framework imports
import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";

// External imports
import { floor } from "mathjs";

// Material UI imports
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

// HSA Imports
import AITrainingSettings, {
  DatasetApproach,
  DatasetApproachNames,
  TrainingDataTypes,
  Optimizer,
  OptimizerNames,
  LossFunction,
  LossFunctionNames,
  Metrics,
  MetricsNames,
} from "../../../common/components/AITrainingSettings";

// #region Commonly used settings
/**
 * A text field to select the dataset approach.
 * @param {AITrainingSettings} trainingSettings The training settings object.
 * @param {function} setTrainingSettings The function to set/update the training settings object.
 * @param {trainingDataTypes} trainingDataType The type of the training data.
 * @returns {JSX.Element} The component JSX.
 */
export function DatasetApproachSelection(props) {
  const { trainingSettings, setTrainingSettings, trainingDataType } = props;

  return (
    <TextField
      select
      name="dataset_approach"
      margin="normal"
      size="small"
      label="Dataset approach"
      variant="outlined"
      value={trainingSettings.datasetParameters.datasetApproach}
      onChange={(e) => {
        trainingSettings.datasetParameters.datasetApproach = e.target.value;
        setTrainingSettings(trainingSettings);
      }}
    >
      {Object.values(DatasetApproach)
        .filter((value) => floor(value / 10) === trainingDataType)
        .map((value) => {
          return (
            <MenuItem key={value} value={value}>
              {DatasetApproachNames[value]}
            </MenuItem>
          );
        })}
    </TextField>
  );
}

DatasetApproachSelection.propTypes = {
  trainingSettings: PropTypes.instanceOf(AITrainingSettings).isRequired,
  setTrainingSettings: PropTypes.func.isRequired,
  trainingDataType: PropTypes.oneOf(Object.values(TrainingDataTypes))
    .isRequired,
};

/**
 * A number selection field to select the number of epochs.
 * @param {AITrainingSettings} trainingSettings The training settings object.
 * @param {function} setTrainingSettings The function to set/update the training settings object.
 * @returns {JSX.Element} The component JSX.
 */
export function EpochSelection(props) {
  const { trainingSettings, setTrainingSettings } = props;

  return (
    <TextField
      name="epochs"
      margin="normal"
      type="number"
      size="small"
      label="Epochs"
      placeholder="Enter number of epochs..."
      variant="outlined"
      value={trainingSettings.trainingParameters.epochs}
      onChange={(e) => {
        trainingSettings.trainingParameters.epochs = e.target.value;
        setTrainingSettings(trainingSettings);
      }}
      InputProps={{ inputProps: { min: 1 } }}
    />
  );
}

EpochSelection.propTypes = {
  trainingSettings: PropTypes.instanceOf(AITrainingSettings).isRequired,
  setTrainingSettings: PropTypes.func.isRequired,
};

// #endregion

// #region Model Settings

/**
 * A number selection field to select the model learning rate.
 * @param {AITrainingSettings} trainingSettings The training settings object.
 * @param {function} setTrainingSettings The function to set/update the training settings object.
 * @returns {JSX.Element} The component JSX.
 * @returns
 */
export function LearningRateSelection(props) {
  const { trainingSettings, setTrainingSettings } = props;

  return (
    <TextField
      name="learning_rate"
      margin="normal"
      type="number"
      size="small"
      label="Learning rate"
      placeholder="Enter learning rate..."
      variant="outlined"
      InputProps={{
        inputProps: { step: 0.0001, min: 0.0 },
      }}
      value={trainingSettings.trainingParameters.learningRate}
      onChange={(e) => {
        trainingSettings.trainingParameters.learningRate = e.target.value;
        setTrainingSettings(trainingSettings);
      }}
    />
  );
}

LearningRateSelection.propTypes = {
  trainingSettings: PropTypes.instanceOf(AITrainingSettings).isRequired,
  setTrainingSettings: PropTypes.func.isRequired,
};

/**
 * A number selection field to select the model batch size.
 * @param {AITrainingSettings} trainingSettings The training settings object.
 * @param {function} setTrainingSettings The function to set/update the training settings object.
 * @returns {JSX.Element} The component JSX.
 */
export function BatchSizeSelection(props) {
  const { trainingSettings, setTrainingSettings } = props;

  return (
    <TextField
      name="batch_size"
      margin="normal"
      type="number"
      size="small"
      label="Batch size"
      InputProps={{
        inputProps: {
          min: 1,
        },
      }}
      value={trainingSettings.trainingParameters.batchSize}
      onChange={(e) => {
        trainingSettings.trainingParameters.batchSize = e.target.value;
        setTrainingSettings(trainingSettings);
      }}
    />
  );
}

BatchSizeSelection.propTypes = {
  trainingSettings: PropTypes.instanceOf(AITrainingSettings).isRequired,
  setTrainingSettings: PropTypes.func.isRequired,
};

/**
 * A number selection field to select the early stopping limit.
 * @param {AITrainingSettings} trainingSettings The training settings object.
 * @param {function} setTrainingSettings The function to set/update the training settings object.
 * @returns {JSX.Element} The component JSX.
 */
export function EarlyStoppingLimitSelection(props) {
  const { trainingSettings, setTrainingSettings } = props;

  return (
    <TextField
      name="earlyStopping"
      margin="normal"
      type="number"
      size="small"
      label="Early stopping"
      InputLabelProps={{
        shrink: true,
      }}
      value={trainingSettings.trainingParameters.earlyStopping}
      InputProps={{ inputProps: { min: 1, max: 10000 } }}
      onChange={(e) => {
        trainingSettings.trainingParameters.earlyStopping = e.target.value;
        setTrainingSettings(trainingSettings);
      }}
    />
  );
}

EarlyStoppingLimitSelection.propTypes = {
  trainingSettings: PropTypes.instanceOf(AITrainingSettings).isRequired,
  setTrainingSettings: PropTypes.func.isRequired,
};

/**
 * A list of optimizers to select one from.
 * @param {AITrainingSettings} trainingSettings The training settings object.
 * @param {function} setTrainingSettings The function to set/update the training settings object.
 * @returns {JSX.Element} The component JSX.
 */
export function OptimizerSelection(props) {
  const { trainingSettings, setTrainingSettings } = props;

  return (
    <TextField
      select
      name="optimizer"
      margin="normal"
      size="small"
      label="Optimizer"
      variant="outlined"
      value={trainingSettings.trainingParameters.optimizer}
      onChange={(e) => {
        trainingSettings.trainingParameters.optimizer = e.target.value;
        setTrainingSettings(trainingSettings);
      }}
    >
      {Object.values(Optimizer).map((value) => {
        return (
          <MenuItem key={value} value={value}>
            {OptimizerNames[value]}
          </MenuItem>
        );
      })}
    </TextField>
  );
}

OptimizerSelection.propTypes = {
  trainingSettings: PropTypes.instanceOf(AITrainingSettings).isRequired,
  setTrainingSettings: PropTypes.func.isRequired,
};

/**
 * A list of loss functions to select one or more from.
 * @param {AITrainingSettings} trainingSettings The training settings object.
 * @param {function} setTrainingSettings The function to set/update the training settings object.
 * @returns {JSX.Element} The component JSX.
 */
export function LossFunctionSelection(props) {
  const { trainingSettings, setTrainingSettings } = props;

  return (
    <TextField
      select
      label="Loss Functions"
      size="small"
      margin="normal"
      placeholder="Select loss functions..."
      error={trainingSettings.trainingParameters.lossFunctions.length === 0}
      SelectProps={{
        value: trainingSettings.trainingParameters.lossFunctions.map(
          (value) => LossFunctionNames[value]
        ),
        multiple: true,
        renderValue: (selected) => {
          if (selected.length === 1) return selected[0];
          return `${selected.length} selected`;
        },
      }}
    >
      {Object.values(LossFunction).map((value) => (
        <MenuItem key={value} value={value}>
          <Checkbox
            name="CheckboxLossFunction"
            checked={trainingSettings.trainingParameters.lossFunctions.includes(
              value
            )}
            onChange={() => {
              if (
                trainingSettings.trainingParameters.lossFunctions.includes(
                  value
                )
              ) {
                trainingSettings.trainingParameters.lossFunctions =
                  trainingSettings.trainingParameters.lossFunctions.filter(
                    (item) => item !== value
                  );
              } else {
                trainingSettings.trainingParameters.lossFunctions.push(value);
              }
              setTrainingSettings(trainingSettings);
            }}
          />
          <ListItemText primary={LossFunctionNames[value]} />
        </MenuItem>
      ))}
    </TextField>
  );
}

LossFunctionSelection.propTypes = {
  trainingSettings: PropTypes.instanceOf(AITrainingSettings).isRequired,
  setTrainingSettings: PropTypes.func.isRequired,
};

/**
 * A list of metrics to select one or more from.
 * @param {AITrainingSettings} trainingSettings The training settings object.
 * @param {function} setTrainingSettings The function to set/update the training settings object.
 * @returns {JSX.Element} The component JSX.
 */
export function MetricsSelection(props) {
  const { trainingSettings, setTrainingSettings } = props;

  return (
    <TextField
      select
      label="Metrics"
      size="small"
      margin="normal"
      placeholder="Select metrics..."
      error={trainingSettings.trainingParameters.metrics.length === 0}
      SelectProps={{
        value: trainingSettings.trainingParameters.metrics.map(
          (value) => MetricsNames[value]
        ),
        multiple: true,
        renderValue: (selected) => {
          if (selected.length === 1) return selected[0];
          return `${selected.length} selected`;
        },
      }}
    >
      {Object.values(Metrics).map((value) => (
        <MenuItem key={MetricsNames[value]} value={MetricsNames[value]}>
          <Checkbox
            name="CheckboxMetrics"
            checked={trainingSettings.trainingParameters.metrics.includes(
              value
            )}
            onChange={() => {
              if (trainingSettings.trainingParameters.metrics.includes(value)) {
                trainingSettings.trainingParameters.metrics =
                  trainingSettings.trainingParameters.metrics.filter(
                    (item) => item !== value
                  );
              } else {
                trainingSettings.trainingParameters.metrics.push(value);
              }
              setTrainingSettings(trainingSettings);
            }}
          />
          <ListItemText primary={MetricsNames[value]} />
        </MenuItem>
      ))}
    </TextField>
  );
}

MetricsSelection.propTypes = {
  trainingSettings: PropTypes.instanceOf(AITrainingSettings).isRequired,
  setTrainingSettings: PropTypes.func.isRequired,
};

// #endregion

// #region Dataset Settings

/**
 * A drop down menu to select whether to create a dataset, train a model or both.
 * @param {AITrainingSettings} trainingSettings The training settings object.
 * @param {function} setTrainingSettings The function to set/update the training settings object.
 * @returns {JSX.Element} The component JSX.
 */
export function DatasetCreationOptions(props) {
  const { trainingSettings, setTrainingSettings } = props;
  const creationOptions = [
    "Create dataset & train model",
    "Create dataset only",
    "Use existing dataset",
  ];

  const [selectedCreationOption, setSelectedCreationOption] = useState(
    "Create dataset & train model"
  );

  useEffect(() => {
    setSelectedCreationOption(
      trainingSettings.datasetParameters.useExistingDataset
        ? "Use existing dataset"
        : trainingSettings.datasetParameters.datasetOnly
        ? "Create dataset only"
        : "Create dataset & train model"
    );
  }, [
    trainingSettings.datasetParameters.useExistingDataset,
    trainingSettings.datasetParameters.datasetOnly,
  ]);

  return (
    <TextField
      select
      name="dataset_creation_options"
      margin="normal"
      size="small"
      label="Dataset creation options"
      variant="outlined"
      value={selectedCreationOption}
      onChange={(e) => {
        trainingSettings.datasetParameters.useExistingDataset =
          e.target.value === "Use existing dataset";
        trainingSettings.datasetParameters.datasetOnly =
          e.target.value === "Create dataset only";
        setTrainingSettings(trainingSettings);
      }}
    >
      {Object.values(creationOptions).map((value) => {
        return (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        );
      })}
    </TextField>
  );
}

DatasetCreationOptions.propTypes = {
  trainingSettings: PropTypes.instanceOf(AITrainingSettings).isRequired,
  setTrainingSettings: PropTypes.func.isRequired,
};

/**
 * A drop down menu to select the image size from a list of predefined values, each a power of 2.
 * @param {AITrainingSettings} trainingSettings The training settings object.
 * @param {function} setTrainingSettings The function to set/update the training settings object.
 * @returns {JSX.Element} The component JSX.
 */
export function ImageSizeSelection(props) {
  const { trainingSettings, setTrainingSettings } = props;

  return (
    <TextField
      select
      name="image size"
      margin="normal"
      size="small"
      label="Image Size"
      variant="outlined"
      value={trainingSettings.modelParameters.imageWidth}
      onChange={(e) => {
        trainingSettings.modelParameters.imageWidth = e.target.value;
        trainingSettings.modelParameters.imageHeight = e.target.value;
        setTrainingSettings(trainingSettings);
      }}
    >
      {[128, 256, 512, 1024, 2048].map((value) => {
        return (
          <MenuItem key={value} value={value}>
            {value + " px"}
          </MenuItem>
        );
      })}
    </TextField>
  );
}

ImageSizeSelection.propTypes = {
  trainingSettings: PropTypes.instanceOf(AITrainingSettings).isRequired,
  setTrainingSettings: PropTypes.func.isRequired,
};

/**
 * A drop down menu to select the image pyramid level from a list of predefined values, passed as props.
 * @param {AITrainingSettings} trainingSettings The training settings object.
 * @param {function} setTrainingSettings The function to set/update the training settings object.
 * @param {Array} pyramidLevels The pyramid levels that can be selected. Each level is an object with a level and description.
 * @returns {JSX.Element} The component JSX.
 */
export function ImagePyramidLevelSelection(props) {
  const { trainingSettings, setTrainingSettings, pyramidLevels } = props;

  return (
    <TextField
      select
      name="pyramid level"
      margin="normal"
      size="small"
      label="Pyramid Level"
      variant="outlined"
      value={trainingSettings.modelParameters.pyramidLevel}
      onChange={(e) => {
        trainingSettings.modelParameters.pyramidLevel = e.target.value;
        setTrainingSettings(trainingSettings);
      }}
    >
      {pyramidLevels.map((levelData) => {
        return (
          <MenuItem key={levelData.level} value={levelData.level}>
            {levelData.description}
          </MenuItem>
        );
      })}
    </TextField>
  );
}

ImagePyramidLevelSelection.propTypes = {
  trainingSettings: PropTypes.instanceOf(AITrainingSettings).isRequired,
  setTrainingSettings: PropTypes.func.isRequired,
  pyramidLevels: PropTypes.arrayOf(
    PropTypes.shape({
      level: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
};

/**
 * A drop down menu to select the image input channels from a list of predefined values, evaluated from the OME metadata.
 * @param {AITrainingSettings} trainingSettings The training settings object.
 * @param {function} setTrainingSettings The function to set/update the training settings object.
 * @param {OME} ome The OME metadata object.
 * @returns {JSX.Element} The component JSX.
 */
export function ImageInputChannelSelection(props) {
  const { trainingSettings, setTrainingSettings, ome } = props;

  // Check if the image is brightfield or fluorescence
  const isBrightfield =
    ome && ome.channels.length === 1 && ome.channels[0].type === "brightfield";

  useEffect(() => {
    if (!isBrightfield) {
      // Set the fluorescence channels (default: all channels are selected)
      trainingSettings.modelParameters.fluorescenceChannels = [];
      ome.channels.forEach((channel) => {
        trainingSettings.modelParameters.fluorescenceChannels.push(
          channel.name
        );
      });

      trainingSettings.modelParameters.inputChannels =
        trainingSettings.modelParameters.fluorescenceChannels.length;

      setTrainingSettings(trainingSettings);
    }
  }, []);

  return !isBrightfield > 0 ? (
    <TextField
      select
      label="Input Channels"
      size="small"
      margin="normal"
      placeholder="Select input channels..."
      error={trainingSettings.modelParameters.fluorescenceChannels.length === 0}
      SelectProps={{
        value: trainingSettings.modelParameters.fluorescenceChannels,
        multiple: true,
        renderValue: (selected) => {
          if (selected.length === 1) return selected[0];
          return `${selected.length} selected`;
        },
      }}
    >
      {ome.channels.map((channel) => (
        <MenuItem key={channel.name} value={channel.name}>
          <Checkbox
            checked={trainingSettings.modelParameters.fluorescenceChannels.includes(
              channel.name
            )}
            onChange={() => {
              const index =
                trainingSettings.modelParameters.fluorescenceChannels.indexOf(
                  channel.name
                );

              if (index === -1) {
                // Element is not in the array, so add it
                trainingSettings.modelParameters.fluorescenceChannels.push(
                  channel.name
                );
              } else {
                // Element is already in the array, so remove it
                trainingSettings.modelParameters.fluorescenceChannels.splice(
                  index,
                  1
                );
              }
              setTrainingSettings(trainingSettings);
            }}
          />
          <ListItemText
            primary={channel.name}
            style={{
              background: channel.color,
              paddingLeft: 10,
            }}
          />
        </MenuItem>
      ))}
    </TextField>
  ) : (
    <TextField
      label="Input Channels"
      select
      size="small"
      value={trainingSettings.modelParameters.inputChannels}
      onChange={(e) => {
        trainingSettings.modelParameters.inputChannels = e.target.value;
        setTrainingSettings(trainingSettings);
      }}
    >
      <MenuItem key={3} value={3}>
        3 (RGB)
      </MenuItem>
      <MenuItem key={1} value={1}>
        1 (grey)
      </MenuItem>
    </TextField>
  );
}

ImageInputChannelSelection.propTypes = {
  trainingSettings: PropTypes.instanceOf(AITrainingSettings).isRequired,
  setTrainingSettings: PropTypes.func.isRequired,
  ome: PropTypes.object.isRequired,
};

/**
 * A number selection field to select the sequence length in seconds for audio datasets.
 * @param {AITrainingSettings} trainingSettings The training settings object.
 * @param {function} setTrainingSettings The function to set/update the training settings object.
 * @returns {JSX.Element} A number selection field to select the sequence length in seconds for audio datasets.
 */
export function AudioSequenceLengthSelection(props) {
  const { trainingSettings, setTrainingSettings } = props;

  return (
    <TextField
      name="sequence_length_seconds"
      margin="normal"
      type="number"
      size="small"
      label="Sequence length (seconds)"
      placeholder="Enter sequence length..."
      variant="outlined"
      value={trainingSettings.modelParameters.sequenceLengthSeconds}
      onChange={(e) => {
        trainingSettings.modelParameters.sequenceLengthSeconds = e.target.value;
        setTrainingSettings(trainingSettings);
      }}
      InputProps={{
        inputProps: {
          min: 0.001,
          step: 0.001,
        },
      }}
    />
  );
}

AudioSequenceLengthSelection.propTypes = {
  trainingSettings: PropTypes.instanceOf(AITrainingSettings).isRequired,
  setTrainingSettings: PropTypes.func.isRequired,
};

/**
 * A number selection field to select the sequence overlap in seconds for audio datasets.
 * @param {AITrainingSettings} trainingSettings The training settings object.
 * @param {function} setTrainingSettings The function to set/update the training settings object.
 * @returns {JSX.Element} A number selection field to select the sequence overlap in seconds for audio datasets.
 */
export function AudioSequenceOverlapSelection(props) {
  const { trainingSettings, setTrainingSettings } = props;

  return (
    <TextField
      name="sequence_overlap_seconds"
      margin="normal"
      type="number"
      size="small"
      label="Sequence overlap (seconds)"
      variant="outlined"
      value={trainingSettings.modelParameters.sequenceOverlapSeconds}
      onChange={(e) => {
        trainingSettings.modelParameters.sequenceOverlapSeconds =
          e.target.value;
        setTrainingSettings(trainingSettings);
      }}
      InputProps={{
        inputProps: {
          step: 0.001,
        },
      }}
    />
  );
}

AudioSequenceOverlapSelection.propTypes = {
  trainingSettings: PropTypes.instanceOf(AITrainingSettings).isRequired,
  setTrainingSettings: PropTypes.func.isRequired,
};

/**
 * A number selection field to select the number of classes for audio datasets.
 * @param {AITrainingSettings} trainingSettings The training settings object.
 * @param {function} setTrainingSettings The function to set/update the training settings object.
 * @returns {JSX.Element} A number selection field to select the number of classes for audio datasets.
 */
export function AudioNumberOfClassesSelection(props) {
  const { trainingSettings, setTrainingSettings } = props;

  return (
    <TextField
      name="number_of_classes"
      margin="normal"
      type="number"
      size="small"
      label="Number of classes"
      variant="outlined"
      value={trainingSettings.modelParameters.numberOfClasses}
      onChange={(e) => {
        trainingSettings.modelParameters.numberOfClasses = e.target.value;
        setTrainingSettings(trainingSettings);
      }}
      InputProps={{
        inputProps: {
          min: 1,
          step: 1,
        },
      }}
    />
  );
}

AudioNumberOfClassesSelection.propTypes = {
  trainingSettings: PropTypes.instanceOf(AITrainingSettings).isRequired,
  setTrainingSettings: PropTypes.func.isRequired,
};

// #endregion

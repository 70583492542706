// Framework imports
import React, { useContext, useEffect, useState, useRef } from "react";
import LazyLoad from "react-lazyload";

// External packages
import { Grid, Tooltip } from "@mui/material";

// HSA Imports
import { FileViewerContext } from "./FileViewer";
import Backend from "../common/utils/Backend";
import { theme } from "../../src/index";

/**
 * Collected (reusable) styles for page elements.
 */

const styles = {
  fileBox: {
    minWidth: 0,
    width: 200,
    // borderLeft: "5px solid  #EBEBEB",
    background: "#fff",
    overflow: "auto",
    justifyContent: "center",
  },
  imgContainer: {
    margin: "0 10px 10px",
    textAlign: "center",
  },
  img: {
    display: "inline-block",
    width: "100%",
  },
  placeholder: {
    width: "170px",
    height: "40px",
    border: "1px solid gray",
    borderWidth: 2,
    fontSize: 15,
    textAlign: "center",
    marginTop: "20px",
    marginLeft: "auto",
    marginRight: "auto",
  },
};

/**
 * Preview from the loaded images of the selected Foldertree
 */
export default function ImagePreview() {
  const labelHeight = 30;
  const fileViewer = useContext(FileViewerContext);
  const [fileList, setFileList] = useState([]);
  const [enterDelay, setEnterDelay] = useState(1000);
  const imageRefs = useRef([]);

  /*
  If you click on a tree file, the selected Image in the Imagepreview scrolls into View
   */
  // useEffect(() => {
  //   if (fileViewer.selectedFile) {
  //     const fileIdx = fileList.findIndex(
  //       (file) => file.path === fileViewer.selectedFile
  //     );
  //     imageRefs.current[fileIdx].scrollIntoView({
  //       behavior: "smooth",
  //     });
  //   }
  // }, [fileViewer.selectedFile]);

  /*
  Loading the Filelist
  Trigger: Selected Folder
  */
  useEffect(() => {
    if (fileViewer.selectedFolder) {
      Backend.walkDir(fileViewer.selectedFolder.path, true, (files) => {
        imageRefs.current = imageRefs.current.slice(0, files.length);

        setFileList(
          files
            .filter((file) => file.type === "file")
            .map((file) => {
              return file;
            })
        );
      });
    }
  }, [fileViewer.selectedFolder]);

  /*
  Scaling the images after changing the Imagepreview width
  Trigger: Changing Imagepreview width
  */
  useEffect(() => {
    calculatePreviewHeight();
  }, [fileViewer.previewWidth]);

  /*
  Set Imagepreview width
  Trigger: Initialise
  */
  // useEffect(() => {
  //   fileViewer.setPreviewWidth(styles.fileBox.width);
  // }, []);

  /*
  Errorfunction to scale the images
  */
  const calculatePreviewHeight = () => {
    for (let i = 0; i < fileList.length; i++) {
      let element1 = imageRefs.current[i];
      if (element1) {
        const elemHeight = element1.clientHeight;
        if (elemHeight > 0) {
          fileList[i].height = elemHeight + labelHeight;
        }
      }
    }
  };

  return (
    <Grid
      style={{
        ...styles.fileBox,
        width: fileViewer.previewWidth,
      }}
    >
      {fileList.length > 0 ? (
        <div>
          {fileList.map((file, idx) => (
            <LazyLoad key={file.path}>
              <Tooltip
                title={
                  <div>
                    <div style={{ width: 80, display: "inline-block" }}>
                      File Size:{" "}
                    </div>
                    {file.fileSize}
                    <br />
                    <div style={{ width: 80, display: "inline-block" }}>
                      Creation Date:{" "}
                    </div>
                    {file.creationTime}
                  </div>
                }
                key={file.path}
                enterDelay={enterDelay}
                onMouseEnter={() => {
                  setEnterDelay(1000);
                }}
              >
                <div
                  key={file.path}
                  style={{ ...styles.imgContainer, height: file.height }}
                >
                  <img
                    draggable="true"
                    onDragStart={(e) => {
                      e.dataTransfer.setData("text/plain", file.path);
                    }}
                    ref={(el) => (imageRefs.current[idx] = el)}
                    style={{
                      ...styles.img,

                      border:
                        file.path === fileViewer.selectedFile
                          ? "solid 3px " + theme.palette.primary.main
                          : "none",
                    }}
                    src={Backend.imageThumbnail(encodeURIComponent(file.path))}
                    alt="Preview Image"
                    onClick={() => {
                      fileViewer.setSelFile(file.path);
                    }}
                  />

                  <div style={{ fontSize: 12 }}>
                    {" "}
                    {file.path.substring(file.path.lastIndexOf("/") + 1)}
                  </div>
                </div>
              </Tooltip>
            </LazyLoad>
          ))}
        </div>
      ) : (
        <div style={{ ...styles.placeholder }}>No selected Folder</div>
      )}
    </Grid>
  );
}

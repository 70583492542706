import React from "react";
import { Grid } from "@mui/material";
import { PropTypes } from "prop-types";

// HSA Components
import AudioFileContainer from "./components/AudioFileContainer";

/**
 * Collected (reusable) styles for page elements.
 */
const styles = {
  root: {
    height: "100%",
    overflowY: "auto",
    padding: "10px",
  },
};

/**
 * The distance from the right in px before annotations, playbar, and visualizations begin.
 */
const LABEL_WIDTH = 100;

/**
 * Element containing all visualisations of all audio files / streams in a project.
 * @param {array} files All files to be rendered in the AudioViewer.
 * @returns React node for AudioRenderer
 */
export default function AudioRenderer(props) {
  const { files = [], ...other } = props;
  return (
    <Grid sx={{ ...other.style, ...other.sx, ...styles.root }}>
      {files.map((file) => (
        <AudioFileContainer
          key={file.id}
          file={file}
          labelWidth={LABEL_WIDTH}
        />
      ))}
    </Grid>
  );
}

AudioRenderer.propTypes = {
  files: PropTypes.array,
};

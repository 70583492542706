import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import MuiTextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@mui/styles";
import SketchColorPicker from "./../../common/components/SketchColorPicker";

const useStyles = makeStyles({
  root: {
    marginBottom: 20,
  },
  selectFieldDatatype: {
    width: 300,
    marginRight: 5,
  },
  selectFieldDefault: {
    lineHeight: "40px",
    "& .MuiIconButton-root": {
      display: "none",
    },
    "& .MuiSelect-select": {
      height: "22px",
      "& >div": {
        display: "none",
      },
    },
  },
  defaultSelectInput: {
    lineHeight: "40px",
    "& .MuiSelect-select": {
      height: "22px",
    },
  },
  fab: {
    marginLeft: "auto !important",
  },

  newItem: {
    "& >form": {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "1fr auto",
    },
  },
});

/* eslint-disable react/prop-types */
const CaseSettingsEditableOptionsMenu = (
  { data: { defaultValue, items }, idx },
  forwardedRef
) => {
  const [newSelectionItemValue, setNewSelectionItemValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [selectionItems, setSelectionItems] = useState(items);
  const newItemInputRef = useRef(null);

  const classes = useStyles();

  const getOptionsData = () => {
    return {
      defaultValue: selectedValue,
      items: selectionItems,
    };
  };

  useImperativeHandle(forwardedRef, () => ({
    getOptionsData,
  }));

  const handleOnChangeDefault = (e) => {
    setSelectedValue(e.target.value);
  };

  const handleOnUpdateSelectionItem = (e) => {
    setNewSelectionItemValue(e.target.value);
  };

  const handleOnAddSelectionItem = (e) => {
    e.preventDefault();
    if (
      !newSelectionItemValue ||
      selectionItems.map((item) => item.label).includes(newSelectionItemValue)
    ) {
      setTimeout(() => {
        newItemInputRef.current.focus();
      }, 0);
      return;
    }
    const newSelectionItems = [
      ...selectionItems,
      { label: newSelectionItemValue, color: "#ffffff" },
    ];
    setSelectedValue(newSelectionItemValue);
    setNewSelectionItemValue("");
    setSelectionItems(newSelectionItems);
    setTimeout(() => {
      newItemInputRef.current.focus();
    }, 0);
  };

  const handleOnDeleteSelectionItem = (e, itemIdx) => {
    e.preventDefault();
    e.stopPropagation();
    const updatedItems = [...selectionItems];
    updatedItems.splice(itemIdx, 1);
    if (selectedValue === selectionItems[itemIdx] && updatedItems.length > 0) {
      setSelectedValue(updatedItems[0]);
    } else if (updatedItems.length === 0) {
      setSelectedValue("");
    }
    setSelectionItems(updatedItems);
    newItemInputRef.current.focus();
  };

  return (
    <MuiTextField
      className={classes.selectFieldDefault}
      select
      fullWidth
      value={selectedValue}
      label="Default Value"
      onChange={handleOnChangeDefault}
      variant="outlined"
    >
      <MenuItem value="">None</MenuItem>
      {selectionItems.map((item, itemIdx) => (
        <MenuItem key={`${idx}_${itemIdx}`} value={item.label}>
          <span style={{ width: "100%" }}>{item.label}</span>

          <SketchColorPicker
            color={item.color}
            includeWhite={true}
            handleChange={(newColor) => {
              setSelectionItems((prevItems) => {
                const newItems = [...prevItems];
                newItems[itemIdx].color = newColor;
                return newItems;
              });
            }}
          />
          <Tooltip title="Delete Selection" style={{ float: "right" }}>
            <IconButton
              onClick={(e) => handleOnDeleteSelectionItem(e, itemIdx)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </MenuItem>
      ))}
      <MenuItem
        value="newItem"
        onKeyDown={(e) => e.stopPropagation()}
        className={classes.newItem}
      >
        <form onSubmit={handleOnAddSelectionItem}>
          <MuiTextField
            fullWidth
            label="new selection"
            value={newSelectionItemValue}
            onClick={(e) => e.stopPropagation()}
            onChange={handleOnUpdateSelectionItem}
            inputRef={newItemInputRef}
          />
          <IconButton type="submit" onClick={(e) => e.stopPropagation()}>
            <AddIcon />
          </IconButton>
        </form>
      </MenuItem>
    </MuiTextField>
  );
};

CaseSettingsEditableOptionsMenu.displayName = "CaseSettingsEditableOptionsMenu";

export default forwardRef(CaseSettingsEditableOptionsMenu);

import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";

import Slider from "@mui/material/Slider";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { IconButton } from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";

const styles = {
  root: {
    display: "flex",
    height: "400px",
    position: "absolute",
    top: "33%",
    right: "40px",
    width: "150px",
    zIndex: 100,
  },
  formControl: {
    minWidth: 100,
    maxWidth: 100,
  },
  sliderDiv: {
    right: "3.5%",
    top: "25%",
    width: "80%",
    height: "50%",
    position: "inherit",
    textAlign: "center",
  },
  upperArea: {
    top: "0%",
    width: "100%",
    height: "25%",
    position: "inherit",
  },
  upperList: {
    position: "inherit",
    bottom: "10%",
    right: "3%",
    width: "70%",
  },
  lowerArea: {
    top: "75%",
    width: "100%",
    height: "25%",
    position: "inherit",
  },
  lowerList: {
    position: "inherit",
    top: "10%",
    right: "3%",
    width: "70%",
  },
  upFormcontrol: {
    position: "absolute",
    top: "-10px",
    left: "-27px",
  },
  lowFormcontrol: {
    position: "absolute",
    top: "168px",
    left: "-27px",
  },
  upButton: {
    position: "absolute",
    top: "-4px",
    left: "50px",
    color: "#0673C1",
  },
  lowButton: {
    position: "absolute",
    top: "174px",
    left: "50px",
    color: "#0673C1",
  },
  slider: {
    float: "right",
    color: "#0673C1",
  },
  chainButton: {
    position: "absolute",
    top: "244px",
    left: "14px",
  },
};

class OverlaySlider extends Component {
  constructor(props) {
    super(props);
    if (props.componentRef) props.componentRef(this);
    this.state = {
      value: 100,
      upperListValue: "",
      lowerListValue: "",
      checkedUp: false,
      checkedLow: false,
    };
  }

  componentDidMount() {
    const {
      activeFileId,
      splitscreenFileIds,
      fullscreenFileIds,
      addFile,
      setOpacity,
    } = this.props;
    let lowValue;

    activeFileId === splitscreenFileIds[0]
      ? (lowValue = splitscreenFileIds[1])
      : (lowValue = splitscreenFileIds[0]);

    if (fullscreenFileIds.length === 1) {
      addFile(lowValue, 1);
    } else {
      addFile(activeFileId, 0);
      addFile(lowValue, 1);
    }

    setOpacity(0, this.state.value);
    setOpacity(1, 100 - this.state.value);

    this.setState({
      upperListValue: activeFileId,
      lowerListValue: lowValue,
      checkedUp: true,
      init: false,
    });
  }

  componentDidUpdate() {
    const { activeFileId } = this.props;
    const { checkedUp, checkedLow, upperListValue, lowerListValue } =
      this.state;

    if (
      (checkedUp && upperListValue !== activeFileId) ||
      (checkedLow && lowerListValue !== activeFileId)
    ) {
      this.update();
    }
  }

  update = () => {
    const { activeFileId } = this.props;
    if (this.state.checkedUp) {
      this.setState({
        upperListValue: activeFileId,
      });
      this.props.addFile(activeFileId, 0);
    } else {
      this.setState({
        lowerListValue: activeFileId,
      });
      this.props.addFile(activeFileId, 1);
    }
  };

  fileCheck = (fileId, callback) => {
    if (
      fileId === this.state.lowerListValue ||
      fileId === this.state.upperListValue
    ) {
      this.setState({
        checkedUp: !this.state.checkedUp,
        checkedLow: !this.state.checkedLow,
      });
      this.state.checkedLow
        ? this.handleUpVisChange()
        : this.handleLowVisChange();
    }
    callback();
  };

  handleChange = (event, value) => {
    this.setState({ value });
    this.props.setOpacity(0, value);
    this.props.setOpacity(1, 100 - value);
  };

  handleUpMenuChange = (event) => {
    this.setState({ upperListValue: event.target.value });
    this.props.addFile(event.target.value, 0);
    if (this.state.checkedUp) this.props.setActive(event.target.value);
    this.setState({ init: false });
  };

  handleLowMenuChange = (event) => {
    this.setState({ lowerListValue: event.target.value });
    this.props.addFile(event.target.value, 1);
    if (this.state.checkedLow) this.props.setActive(event.target.value);
    this.setState({ init: false });
  };

  handleCheck = () => {
    !this.state.checkedUp
      ? this.props.setActive(this.state.upperListValue)
      : this.props.setActive(this.state.lowerListValue);

    this.setState({
      checkedUp: !this.state.checkedUp,
      checkedLow: !this.state.checkedLow,
    });
  };

  handleUpVisChange = () => {
    if (this.state.value !== 100) {
      this.setState({ value: 100 });
      this.props.setOpacity(0, 100);
      this.props.setOpacity(1, 0);
    } else {
      this.handleLowVisChange();
    }
  };

  handleLowVisChange = () => {
    if (this.state.value !== 0) {
      this.setState({ value: 0 });
      this.props.setOpacity(0, 0);
      this.props.setOpacity(1, 100);
    } else {
      this.handleUpVisChange();
    }
  };

  render() {
    const { classes, omeDict, fsChain, splitscreenFileIds } = this.props;
    const { upperListValue, lowerListValue, value, checkedUp, checkedLow } =
      this.state;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <div className={classes.upperArea}>
            <div className={classes.upperList}>
              <FormControl className={classes.formControl}>
                <Select
                  name="SelectUppderListValue"
                  variant="standard"
                  value={upperListValue}
                  onChange={this.handleUpMenuChange}
                >
                  {splitscreenFileIds.map(
                    (id, index) =>
                      id !== lowerListValue &&
                      omeDict[id] && (
                        <MenuItem key={"u" + index} value={id}>
                          {omeDict[id].fileName} {omeDict[id].scene + 1}
                        </MenuItem>
                      )
                  )}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={classes.lowerArea}>
            <div className={classes.lowerList}>
              <FormControl className={classes.formControl}>
                <Select
                  name="SelectLowerListValue"
                  variant="standard"
                  value={lowerListValue}
                  onChange={this.handleLowMenuChange}
                >
                  {splitscreenFileIds.map(
                    (id, index) =>
                      id !== upperListValue &&
                      omeDict[id] && (
                        <MenuItem key={"l" + index} value={id}>
                          {omeDict[id].fileName} {omeDict[id].scene + 1}
                        </MenuItem>
                      )
                  )}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={classes.sliderDiv}>
            <FormControl component="fieldset">
              <RadioGroup value={true} onChange={this.handleCheck}>
                <FormControlLabel
                  className={classes.upFormcontrol}
                  value={checkedUp}
                  label=""
                  control={
                    <Radio
                      style={{
                        color: checkedUp ? "#0673C1" : "#666",
                      }}
                    />
                  }
                />
                <FormControlLabel
                  className={classes.lowFormcontrol}
                  value={checkedLow}
                  label=""
                  control={
                    <Radio
                      style={{
                        color: checkedLow ? "#0673C1" : "#666",
                      }}
                    />
                  }
                />
              </RadioGroup>
            </FormControl>
            <IconButton
              className={classes.upButton}
              onClick={this.handleUpVisChange}
              size="small"
            >
              <VisibilityIcon
                style={{ color: value === 100 ? "#0673C1" : "#666" }}
              />
            </IconButton>
            <IconButton
              className={classes.lowButton}
              onClick={this.handleLowVisChange}
              size="small"
            >
              <VisibilityIcon
                style={{ color: value === 0 ? "#0673C1" : "#666" }}
              />
            </IconButton>
            <IconButton
              className={classes.chainButton}
              onClick={fsChain}
              size="small"
            >
              {this.props.isChained ? (
                <LinkIcon style={{ color: "#0673C1" }} />
              ) : (
                <LinkOffIcon />
              )}
            </IconButton>
            <Slider
              className={classes.slider}
              getAriaValueText={() => value}
              aria-labelledby="discrete-slider-custom"
              valueLabelDisplay="auto"
              orientation="vertical"
              value={value}
              onChange={this.handleChange}
            ></Slider>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

OverlaySlider.propTypes = {
  classes: PropTypes.object.isRequired,
  componentRef: PropTypes.func,
  setOpacity: PropTypes.func,
  activeFileId: PropTypes.string,
  splitscreenFileIds: PropTypes.array,
  omeDict: PropTypes.object,
  addFile: PropTypes.func,
  setActive: PropTypes.func,
  fullscreenFileIds: PropTypes.array,
  fsChain: PropTypes.func,
  isChained: PropTypes.bool,
  rendererdict: PropTypes.object,
};

export default withStyles(styles)(OverlaySlider);

import { findClickedRoi, pointInsideRegion } from "../../utils/PolygonUtil";
import { RegionROI } from "../../utils/ROI";
import Tool from "./Tool";

class FillTool extends Tool {
  name = "Fill";
  noConfig = true;
  includeBaseROI = false;

  setLayer(obj) {
    this.layer = obj.layer;
    this.roiLayers = obj.roiLayers;
    this.selectedLayer = obj.selectedLayer;
    this.structures = obj.structures;
  }

  setPreviewRect() {}

  getParentIndex = (parentId) => {
    let structures = this.structures;
    if (structures.length < 1) {
      structures = this.state.project.viewerConfig.project.structures;
    }
    // return index of parent structures in all structures
    const parentIndex = structures.findIndex(
      (element) => element.id === parentId
    );
    return parentIndex;
  };

  getParentIndexLayer = () => {
    const structures = this.structures;
    const structure = this.structures[this.selectedLayer];
    // get index of parent roilayer
    let index = structures.findIndex((element) => element.id === structure.id);
    while (
      structures[index].subtypeLevel !== 0 &&
      structures[index].classificationSubtype
    ) {
      let pIndex = this.getParentIndex(structures[index].parentId);
      if (pIndex >= 0) index = pIndex;
      else break;
    }
    return structures.findIndex((element) => element === structures[index]);
  };

  determineCorrectLayer = (parentLayer) => {
    let correctLayer;
    if (parentLayer !== this.structures[this.selectedLayer]) {
      correctLayer = parentLayer;
    } else {
      correctLayer = this.selectedLayer;
    }
    return correctLayer;
  };

  fillHole(p) {
    const parentLayer = this.getParentIndexLayer();
    const correctLayer = this.determineCorrectLayer(parentLayer);
    const histId = this.structures[correctLayer].id;
    const tree = this.roiLayers[correctLayer].tree;
    const layer = this.roiLayers[correctLayer].layer;
    const regionRois = tree
      .search({
        minX: p.x,
        minY: p.y,
        maxX: p.x,
        maxY: p.y,
      })
      .sort((a, b) => a.roi.area - b.roi.area); //sorted rois, that are intersecting with clicked point
    let historyItem = [];

    for (let idx = 0; idx < regionRois.length; idx++) {
      let mainRoi = regionRois[idx].roi;
      if (mainRoi.regions.length > 1) {
        let mainRoiCopy = new RegionROI(mainRoi);
        mainRoiCopy.regions = [...mainRoi.regions];
        historyItem.push({ add: false, id: histId, roi: mainRoi.copy() });

        // if has holes
        for (let i = 1; i < mainRoi.regions.length; i++) {
          const hole = mainRoi.regions[i];
          const tempHoleRoi = new RegionROI({ regions: [hole] });
          if (pointInsideRegion(p, hole)) {
            //roi has clicked hole
            mainRoi.regions.splice(i, 1); //delete hole of affected polygon
            mainRoi.intRegions = undefined;
            const overlappingRegionRoiTreeItems = tree
              .search(tempHoleRoi.treeItem)
              .filter((treeItem) => {
                const p = {
                  x: treeItem.roi.regions[0][0][0],
                  y: treeItem.roi.regions[0][0][1],
                };
                return pointInsideRegion(p, hole);
              });
            let possibleRoiCandidates = [];
            if (overlappingRegionRoiTreeItems.length > 0) {
              for (let overlapTreeItem of overlappingRegionRoiTreeItems) {
                const oRoi = overlapTreeItem.roi;
                if (oRoi.uuid === mainRoi.uuid || oRoi.area > tempHoleRoi.area)
                  continue;

                possibleRoiCandidates.push(oRoi);
              }
            }

            let finalPossibleRoiCandidates = [...possibleRoiCandidates];

            let roisToExclude = [];

            //todo: handle holes
            for (let possibleOverlapRoi of possibleRoiCandidates) {
              let excludedOverlapRoi = null;
              const p = {
                x: possibleOverlapRoi.regions[0][0][0],
                y: possibleOverlapRoi.regions[0][0][1],
              };
              for (let fRoi of finalPossibleRoiCandidates) {
                if (
                  fRoi.regions.length > 1 &&
                  possibleOverlapRoi.area < fRoi.area &&
                  pointInsideRegion(p, fRoi.regions[0])
                ) {
                  excludedOverlapRoi = possibleOverlapRoi;
                  break;
                }
              }
              if (excludedOverlapRoi !== null) {
                roisToExclude.push(excludedOverlapRoi);
                finalPossibleRoiCandidates.filter(
                  (item) => item.uuid !== excludedOverlapRoi.uuid
                );
              }
            }
            finalPossibleRoiCandidates = finalPossibleRoiCandidates.filter(
              (item) => {
                const index = roisToExclude.findIndex(
                  (r) => r.uuid === item.uuid
                );
                return index < 0;
              }
            );

            for (let fRoi of finalPossibleRoiCandidates) {
              if (fRoi.regions.length > 1) {
                for (let h = 1; h < fRoi.regions.length; h++) {
                  mainRoi.regions.push(fRoi.regions[h]);
                }
                mainRoi.intRegions = undefined;
              }
              tree.remove(fRoi.treeItem);
              historyItem.push({ add: false, id: histId, roi: fRoi.copy() });
            }
            break;
          }
        }
        historyItem.push({ add: true, id: histId, roi: mainRoi });
        if (layer.regionRois.length !== tree.all().length) {
          layer.regionRois = tree.all().map((treeItem) => treeItem.roi);
        }
        window.projectHistory.add(historyItem);
        return;
      }
    }
  }

  mouse(params) {
    const { event, p } = params;
    // mouse middle button
    if (event.button === 1) {
      return;
    }

    if (event.type === "mousedown" && event.button === 0) {
      const clickedOnRoi = findClickedRoi(
        p,
        this.selectedLayer,
        this.structures,
        this.roiLayers,
        this.includeBaseROI
      );
      if (!clickedOnRoi) {
        //only try finding hole, if not clicked on roi
        this.fillHole(p);
      }
    }
  }

  drawCustomCursor() {}

  exit() {}

  renderConfiguration() {
    return null;
  }
}

export default FillTool;

// Framework imports
import React, { useState } from "react";
import PropTypes from "prop-types";

// External packages
import { Grid, IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu, MenuItem, Divider } from "@mui/material";
import { GetApp, Publish } from "@mui/icons-material";
import PlaylistAdd from "@mui/icons-material/PlaylistAdd";
import FileCopy from "@mui/icons-material/FileCopy";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import Delete from "@mui/icons-material/Delete";

// HSA Components
import Structure, {
  structureCanMove,
  moveStructure,
  insertStructure,
  deleteStructure,
  duplicateStructure,
  newStructureId,
} from "../../common/components/Structure";
import { AnnotationAction } from "../../common/components/RoiTypes";
import { getUnusedColor } from "../../common/utils/RandomColorGenerator";

const styles = {
  menuIcon: { marginRight: "16px" },
};

/**
 * All options and operations associated with structures in a project.
 * @param {object} structure The structure the menu referrs to.
 * @param {array} structures All structures of a project.
 * @param {function} setStructures Update all structures of a project. Receives (array structures).
 * @param {function} triggerAction Flag a structure to have it's annotations exported. Receives (Structure structure, AnnotationAction: action).
 * @returns React node for StructureOptionMenu.
 */
export default function StructureOptionMenu(props) {
  const { structure, structures, setStructures, triggerAction, ...other } =
    props;
  if (!(structure instanceof Structure)) {
    throw TypeError(
      `structure must be of type Structure, received ${typeof structure}: ${structure}`
    );
  }
  if (!Array.isArray(structures)) {
    throw TypeError(
      `structures must be of type Array, received ${typeof structures}: ${structures}`
    );
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  /**
   * Add a child structure to an exisisting parentstructure.
   * @param {bool} isStructure Whether the new substructure is a sub-structure (true) or sub-class (false).
   */
  const addChild = (isStructure) => {
    let newStructure = new Structure(
      newStructureId(structures),
      isStructure
        ? `Substructure of ${structure.label}`
        : `Subclass of ${structure.label}`,
      isStructure,
      getUnusedColor(structures),
      true,
      structure.id,
      0,
      false,
      true,
      false,
      structure.nestingDepth + 1,
      0
    );
    setStructures(insertStructure(newStructure, structures));
    setMenuOpen(false);
  };

  const onDuplicateStructure = () => {
    setStructures(duplicateStructure(structure, structures));
    setMenuOpen(false);
  };

  const onExportAnnotations = () => {
    triggerAction(structure, AnnotationAction.Export);
    setMenuOpen(false);
  };

  const onImportAnnotations = () => {
    triggerAction(structure, AnnotationAction.Import);
    setMenuOpen(false);
  };

  return (
    <Grid sx={{ ...other.style, ...other.sx }}>
      <IconButton
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          setMenuOpen((oldState) => !oldState);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      {menuOpen && (
        <Menu
          open={menuOpen}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
            setMenuOpen(false);
          }}
        >
          <MenuItem
            key={10}
            onClick={() => addChild(true)}
            disabled={!structure.isStructure || !structure.isDynamic}
          >
            <PlaylistAdd sx={styles.menuIcon} fontSize="small" />
            Add substructure
          </MenuItem>
          <MenuItem
            key={20}
            onClick={() => addChild(false)}
            disabled={!structure.isDynamic}
          >
            <PlaylistAdd sx={styles.menuIcon} fontSize="small" />
            Add subclass
          </MenuItem>
          <MenuItem key={30} onClick={onDuplicateStructure}>
            <FileCopy sx={styles.menuIcon} fontSize="small" />
            Duplicate structure
          </MenuItem>
          <MenuItem key={40} onClick={onExportAnnotations}>
            <GetApp sx={styles.menuIcon} fontSize="small" />
            Export annotations
          </MenuItem>
          <MenuItem key={50} onClick={onImportAnnotations}>
            <Publish sx={styles.menuIcon} fontSize="small" />
            Import annotations
          </MenuItem>
          <Divider key={100} />
          <MenuItem
            key={110}
            onClick={() =>
              setStructures(moveStructure(structure, -1, structures))
            }
            disabled={
              // !structure.isDynamic ||
              !structureCanMove(structure, -1, structures)
            }
          >
            <ArrowUpward sx={styles.menuIcon} fontSize="small" />
            Move structure up
          </MenuItem>
          <MenuItem
            key={120}
            onClick={() =>
              setStructures(moveStructure(structure, +1, structures))
            }
            disabled={
              // !structure.isDynamic ||
              !structureCanMove(structure, +1, structures)
            }
          >
            <ArrowDownward sx={styles.menuIcon} fontSize="small" />
            Move structure down
          </MenuItem>
          <Divider key={200} />
          <MenuItem
            key={210}
            sx={{ color: "red" }}
            onClick={() =>
              setStructures(
                deleteStructure(structure, structures, (struct) =>
                  triggerAction(struct, AnnotationAction.DeleteAll)
                )
              )
            }
            disabled={!structure.isDynamic}
          >
            <Delete sx={styles.menuIcon} fontSize="small" />
            Delete structure
          </MenuItem>
        </Menu>
      )}
    </Grid>
  );
}

StructureOptionMenu.propTypes ==
  {
    structure: PropTypes.instanceOf(Structure).isRequired,
    structures: PropTypes.array.isRequired,
    setStructures: PropTypes.func.isRequired,
    triggerAction: PropTypes.func.isRequired,
  };

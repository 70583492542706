// Framework components
import React, { createContext, useState, useEffect, useRef } from "react";
import { PropTypes } from "prop-types";

// Additional packages
import { Grid } from "@mui/material";

// HSA KIT components
import Backend from "../common/utils/Backend";
import { sortStructures } from "../common/components/Structure";

// AudioViewer components
import AudioRenderer from "./AudioRenderer";
import AudioToolBar from "./AudioToolBar";
import AudioSideBar from "./AudioSideBar";
import LocalVerticalResizeBorder from "../common/components/LocalVerticalResizeBorder";

/**
 * Collected (reusable) styles for page elements.
 */
const styles = {
  root: {
    width: "100%",
    height: "100%",
    background: "#EBEBEB",
    overflow: "hidden",
    position: "relative",
    display: "grid",
  },
};

/**
 * Makes selected data available for all children of AudioViewer.
 * Used for information that must be synced over all components per project,
 * such as structures and project id.
 */
export const AudioViewerContext = createContext();

/**
 * The minimum width of the sidebar from the right side in px.
 */
const MIN_SIDEBAR_WIDTH = 455;

/**
 * Overall parent component for Audio Viewer.
 * This component contains all parts of the audio viewer and will be called with the /audio_view route.
 */
export default function AudioViewer({ projectId }) {
  const project = useRef({});
  const forceUpdate = React.useReducer(() => ({}))[1];
  const [sideBarWidth, setSideBarWidth] = useState(MIN_SIDEBAR_WIDTH);
  const [hideSideBar, setHideSideBar] = useState(false);
  const [user, setUser] = useState(null);
  const [saveNow, triggerSave] = useState(false);

  const [files, setFiles] = useState([]);
  const [savedFiles, setSavedFiles] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);

  const [structures, setStructures] = useState([]);
  const structuresRef = useRef(structures);
  const [allStructuresSaved, setAllStructuresSaved] = useState(true);
  const allStructuresSavedRef = useRef(allStructuresSaved);
  const [selectedStructure, setSelectedStructure] = useState(null);

  const [childActions, setChildActions] = useState([]);
  const [excludeToolActive, setExcludeToolActive] = useState(false);

  const [isDrawing, setIsDrawing] = useState(false);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    Backend.loadProject(
      {
        id: projectId,
        isOpenProject: true,
      },
      (incomingProject) => {
        project.current = incomingProject;
        setFiles(incomingProject.files);
        setSelectedFile(incomingProject.files[0] ?? null);
        window.setNavigationbarTitle(
          `${incomingProject.name} (${incomingProject.viewerConfig.project.label})`
        );
      },
      (err) => {
        console.error(err);
        window.openErrorDialog(err.toString());
      }
    );

    Backend.getCurrentUser((user) => setUser(user));
    Backend.loadStructures(
      projectId,
      (structures) => setStructures(sortStructures(structures)),
      (err) => console.error(err)
    );

    // Save-reminder every 10 minutes.
    const saveReminder = setInterval(
      () =>
        window.showActionSnackbar(
          "Would you like to save your annotations?",
          () => saveProject(),
          "Save now",
          "Dismiss"
        ),
      10 * 60 * 1000
    );

    // add an event listener to the window object to listen for resize events
    window.addEventListener("resize", handleResize);

    return () => {
      // remove the event listener to clean up
      window.removeEventListener("resize", handleResize);
      clearInterval(saveReminder);
      Backend.closeProject(
        projectId,
        () => {},
        (err) =>
          console.warn(`Could not perform all closing actions: \n\n ${err}`)
      );
    };
  }, []);

  /**
   * Allow functions defined on load to use latest state
   */
  useEffect(() => {
    structuresRef.current = structures;
    allStructuresSavedRef.current = allStructuresSaved;
  }, [allStructuresSaved, structures]);

  // Update savestatus array on change of files
  useEffect(() => {
    setSavedFiles((prevSaveStatus) => {
      let newSaveStatus = prevSaveStatus;
      files.forEach((f) => {
        // Files begin as saved
        newSaveStatus[f.id] =
          f.id in newSaveStatus ? newSaveStatus[f.id] : true;
      });
      return newSaveStatus;
    });
  }, [files]);

  useEffect(() => {
    handleResize();
  }, [sideBarWidth, hideSideBar]);

  // specify what should happen when the window is resized
  const handleResize = () => {
    const currentSideBarWidth = hideSideBar ? 0 : sideBarWidth;
    let newWidth = window.innerWidth - currentSideBarWidth;
    // your resize handling code here
    setContainerWidth(newWidth);
  };

  /**
   * Updates the status of files when modifying and/or saving.
   * @param {uuid} fileId File id of status to update.
   * @param {bool} fileSaveStatus New save status. true = saved, false = not saved.
   */
  const reportFileSaveStatus = (fileId, fileSaveStatus) => {
    let tmpSaveStatus = savedFiles;
    tmpSaveStatus[fileId] = fileSaveStatus;
    setSavedFiles(tmpSaveStatus);

    // Check if any file is unsaved
    const allFilesSaved = !Object.values(savedFiles).some((val) => !val);
    if (allFilesSaved) {
      window.showSuccessSnackbar("Successfully saved all annotations.");
    }
  };

  /**
   * Saves project, including structures.
   */
  const saveProject = () => {
    // Save structures
    if (!allStructuresSavedRef.current) {
      console.debug("Saving structures...");
      Backend.saveStructures(
        projectId,
        structuresRef.current,
        () => {
          window.showSuccessSnackbar("Successfully saved all structures.");
          setAllStructuresSaved(true);
        },
        (err) => {
          window.showErrorSnackbar(
            `Could not save structures of project"${projectId}": ${err}`
          );
          console.warn(err);
        }
      );
    }
    triggerSave(true);
  };

  // Reset save status
  useEffect(() => {
    if (!saveNow) return;
    triggerSave(false);
  }, [saveNow]);

  const toggleSideBar = () => {
    setHideSideBar(!hideSideBar);
    handleResize();
  };

  const updateFiles = (files) => {
    setFiles(files);
    forceUpdate();
  };

  const updateSelectedFile = (file) => {
    saveProject();
    setTimeout(() => setSelectedFile(file), 0);
  };

  /**
   * Global context for project
   */
  const context = {
    project: project.current,
    containerWidth,
    user,
    saveNow,
    saveProject,
    sideBarWidth,
    setSideBarWidth,
    toggleSideBar,
    triggerSave,
    reportFileSaveStatus,
    structures,
    setStructures: (structures) => {
      setStructures(structures);
      setAllStructuresSaved(false);
    },
    selectedStructure,
    setSelectedStructure,
    selectedFile,
    updateSelectedFile,
    childActions,
    setChildActions,
    excludeToolActive,
    setExcludeToolActive,
    isDrawing,
    setIsDrawing,
    updateFiles,
  };

  return (
    <Grid
      container
      sx={{
        ...styles.root,
        gridTemplateColumns: `1fr auto 3px auto`,
      }}
    >
      <AudioViewerContext.Provider value={context}>
        <AudioRenderer files={selectedFile ? [selectedFile] : []} />
        <AudioToolBar save={saveProject} />
        <LocalVerticalResizeBorder
          targetWidth={sideBarWidth}
          resizeWidth={setSideBarWidth}
          min={MIN_SIDEBAR_WIDTH}
          leftBorder={true}
        />
        <AudioSideBar hide={hideSideBar} width={sideBarWidth} />
      </AudioViewerContext.Provider>
    </Grid>
  );
}

AudioViewer.propTypes = {
  projectId: PropTypes.string.isRequired,
};

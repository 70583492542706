import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Backend from "../../common/utils/Backend";
import {
  IconButton,
  TableRow,
  TableCell,
  TableHead,
  Table,
  TableBody,
  Button,
  Card,
  CardContent,
  Typography,
  CardActions,
  Tooltip,
  Chip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
// Icons
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import UserDetailsDialog from "./UserDetailsDialog";

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 650,
  },
  paper: {
    padding: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  chip: {
    margin: 2,
  },
}));

function TableRowRender(props) {
  const { user, canEditUsers, deleteUser, handleUserEdit } = props;
  const classes = useStyles();

  const editTooltipTitle = canEditUsers
    ? "Edit user details"
    : "No Permission!";
  const deleteTooltipTitle = canEditUsers
    ? "Delete user forever"
    : "No Permission!";

  return (
    <TableRow key={user.id}>
      <TableCell component="th" scope="row">
        {user.fullName}
      </TableCell>
      <TableCell align="right">{user.email}</TableCell>
      <TableCell align="right">
        {user.groups.map((group, groupIdx) => {
          return (
            <Chip key={groupIdx} className={classes.chip} label={group.name} />
          );
        })}
      </TableCell>
      <TableCell align="right">
        {user.fullName !== "HSA" && [
          <Tooltip key="1" disableInteractive title={editTooltipTitle}>
            <span>
              <IconButton
                disabled={canEditUsers !== true}
                onClick={() => handleUserEdit(user)}
                size="large"
              >
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>,
          <Tooltip key="2" disableInteractive title={deleteTooltipTitle}>
            <span>
              <IconButton
                disabled={canEditUsers !== true}
                onClick={() => deleteUser(user.id)}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>,
        ]}
      </TableCell>
    </TableRow>
  );
}

TableRowRender.propTypes = {
  user: PropTypes.object.isRequired,
  canEditUsers: PropTypes.bool.isRequired,
  deleteUser: PropTypes.func.isRequired,
  handleUserEdit: PropTypes.func.isRequired,
};

function UsersContainer(props) {
  const [userDetails, setUserDetails] = useState(null);
  const [create, setCreate] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    props.updateGroups();
  }, []);

  const handleBackendResponse = (res, successCallback) => {
    if (res.success !== undefined && res.success === true) {
      successCallback();
    } else if (res.status !== undefined && res.status === 403) {
      window.showErrorSnackbar("No permission to edit users!");
    } else {
      window.showErrorSnackbar("Something went wrong!");
    }
  };

  const onUserEdit = (e, userGroups) => {
    if (create) {
      Backend.createUser(e, (res) => {
        handleBackendResponse(res, () => {
          Backend.updateUserGroups(res.user.id, userGroups, () => {
            props.updateUserList();
          });
        });
      });
    } else {
      Backend.updateUser(e, (res) => {
        handleBackendResponse(res, () => {
          Backend.updateUserGroups(e.id, userGroups, () => {
            props.updateUserList();
          });
        });
      });
    }
    setUserDetails(null);
    setCreate(false);
  };

  const deleteUser = (id) => {
    window.openResponseDialog("Delete user forever?", (response) => {
      if (response) {
        Backend.deleteUser(id, (res) => {
          handleBackendResponse(res, () => {
            props.updateUserList();
          });
        });
      }
    });
  };

  const newUserClick = () => {
    setUserDetails({
      email: "",
      fullName: "",
      role: "User",
      password: "",
    });
    setCreate(true);
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">Users</Typography>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Full Name</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Groups</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.users &&
              props.users.map((user) => (
                <TableRowRender
                  key={user.id}
                  user={user}
                  canEditUsers={props.canEditUsers}
                  deleteUser={deleteUser}
                  handleUserEdit={setUserDetails}
                />
              ))}
          </TableBody>
        </Table>
      </CardContent>
      <CardActions>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={newUserClick}
        >
          Add new User
        </Button>
      </CardActions>
      <UserDetailsDialog
        open={Boolean(userDetails)}
        create={create}
        groups={props.groups}
        user={userDetails}
        onApply={onUserEdit}
        onClose={() => {
          setUserDetails(null);
          setCreate(false);
        }}
        canEditUserGroups={props.canEditUserGroups}
      />
    </Card>
  );
}

UsersContainer.propTypes = {
  open: PropTypes.bool,
  groups: PropTypes.array,
  updateGroups: PropTypes.func,
  users: PropTypes.array,
  updateUserList: PropTypes.func,
  canEditUsers: PropTypes.bool,
  canEditUserGroups: PropTypes.bool,
};

export default UsersContainer;

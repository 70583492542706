import React, { Component } from "react";
import PropTypes from "prop-types";
import Backend from "../../common/utils/Backend";
import { configureImage, colorInImage } from "../utils/RendererUtils";
import { getParentIndexLayer } from "../utils/StructuresUtils";
import withStyles from "@mui/styles/withStyles";
import withTheme from "@mui/styles/withTheme";
import { Tooltip, IconButton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { withTiles } from "../contexts/TilesContext";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const styles = {
  root: {
    position: "absolute",
    bottom: 5,
    left: 5,
    border: "2px solid rgb(85, 85, 85)",
    opacity: 0.8,
  },
  canvas: {
    position: "relative",
  },
  zBar: {
    position: "absolute",
    top: 10,
    bottom: 16,
    left: 10,
    width: 30,
    transition: "opacity 0.15s ease-in-out",
  },
  zStepUp: {
    color: "#ffffff",
    position: "absolute",
    top: 28,
    left: 0,
    width: 30,
    height: 30,
    padding: 0,
  },
  zStepDown: {
    color: "#ffffff",
    position: "absolute",
    top: 76,
    left: 0,
    width: 30,
    height: 30,
    padding: 0,
  },
  zStackIcon: {
    color: "#ffffff",
    position: "absolute",
    left: 6,
    top: 6,
  },
  zIndex: {
    color: "#ffffff",
    position: "absolute",
    top: 54,
    left: 0,
    width: 30,
    height: 30,
    textAlign: "center",
  },
};

class CroppedImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pd: 10,
      zoomSpeed: 10,
    };

    this.drawingEnabled = false;
    this.canvasFactor1 = 1;
    this.left = 1;
    this.top = 1;
    this.mousepos = {
      x: 0,
      y: 0,
    };
    this.mouseOnCanvas = false;
    this.positionInLayer = -1;
    this.previousPageIndex = 0;
    this.loadedCount = 0;
    this.fullyLoadedCount = 0;
    this.imgs = [];
    this.imgs1 = [];
    this.visibleRegions = [];
    this.r = null;
  }

  componentDidMount = () => {
    // set index for roi
    this.props.roI.galleryIndex = this.props.index;

    // load focus Z-Stack
    if (this.props.roI.z) {
      this.props.roI.firstTimeGallery = true;
      this.validateZ();
    }

    // bind mouse wheel events
    this.canvas.addEventListener(
      "DOMMouseScroll",
      (e) => this.mousewheel(e),
      true
    );
    this.canvas.addEventListener("mousewheel", (e) => this.mousewheel(e), {
      passive: false,
    });

    // get drawing context from canvas
    this.ctx = this.canvas.getContext("2d");

    // converts the relative size css information into an absolute size which we can access
    this.width = this.canvas.width = this.canvas.offsetWidth;
    this.height = this.canvas.height = this.canvas.offsetHeight;
  };

  componentWillUnmount() {
    this.canvas.removeEventListener(
      "DOMMouseScroll",
      (e) => this.mousewheel(e),
      true
    );
    this.canvas.removeEventListener("mousewheel", (e) => this.mousewheel(e), {
      passive: false,
    });
  }

  componentDidUpdate() {
    const { roI, selectedWithKey } = this.props;
    // if roi was selected with key --> scroll that roi is on top of screen
    if (roI.selected && roI.selectedWithKey && selectedWithKey) {
      this.props.scroll(this.canvas.getBoundingClientRect().top);
    }
    // redraw image when the component updates
    this.draw();
  }

  UNSAFE_componentWillUpdate() {
    const { roI } = this.props;
    // check if new page
    if (this.previousPageIndex !== this.props.tiles.getPageIndex()) {
      this.previousPageIndex = this.props.tiles.getPageIndex();
      // calculate zoomspeed depending on width or height of roi
      let imgBoundWidth = roI.bounds.right - roI.bounds.left;
      let imgBoundHeight = roI.bounds.bottom - roI.bounds.top;
      let zoomSp;
      if (imgBoundWidth >= imgBoundHeight) {
        zoomSp = imgBoundWidth * 0.1;
        this.setState({ zoomSpeed: zoomSp }); // 10%
      } else {
        zoomSp = imgBoundHeight * 0.1;
        this.setState({ zoomSpeed: zoomSp }); // 10%
      }
      // reset variables
      this.loadedCount = 0;
      this.imgs = [];
      this.imgs1 = [];
      this.setState({ pd: 10 });
    }
    this.validateZ();
    // reset
    this.loadedCount = 0;
  }

  UNSAFE_componentWillMount() {
    const { roI } = this.props;
    // calculate zoomspeed depending on width or height of roi
    let imgBoundWidth = roI.bounds.right - roI.bounds.left;
    let imgBoundHeight = roI.bounds.bottom - roI.bounds.top;
    let zoomSp;
    if (imgBoundWidth >= imgBoundHeight) {
      zoomSp = imgBoundWidth * 0.1; // 10%
      this.setState({ zoomSpeed: zoomSp });
    } else {
      zoomSp = imgBoundHeight * 0.1; // 10%
      this.setState({ zoomSpeed: zoomSp });
    }
    this.validateZ();
  }

  // if no z level is set --> use middle z level
  validateZ = () => {
    if (this.props.roI.z === -1) {
      this.props.roI.z = this.props.globalZ;
    }
  };

  findVisibleRois = (pd) => {
    const { roI, structures, selectedLayer } = this.props;
    // only return rois that are visible with given padding / zoom --> use tree
    let parentLayerIndex = getParentIndexLayer(
      structures[selectedLayer],
      structures
    );
    return this.props.tools[this.props.activeTool].roiLayers[
      parentLayerIndex
    ].tree.search({
      minX: roI.bounds.left - pd,
      minY: roI.bounds.top - pd,
      maxX: roI.bounds.right + pd,
      maxY: roI.bounds.bottom + pd,
    });
  };

  getPageForChannel(c) {
    // calculate page index for tile
    return (
      Math.round(this.props.t) *
        this.props.ome.channels.length *
        this.props.ome.sizeZ +
      Math.round(this.props.roI.z) * this.props.ome.channels.length +
      c
    );
  }

  areAllImagesComplete = (imgs) => {
    // check if given images are completely loaded
    let allComplete = true;
    imgs.forEach((element) => {
      if (!element.complete) {
        allComplete = false;
      }
    });
    return allComplete;
  };

  drawRoi = (ctx, roi, imgLeft, imgTop, canvasFactor) => {
    const opacity = roi.isObject ? 0 : this.props.opacity;
    ctx.globalAlpha = opacity / 2;
    ctx.fillStyle = roi.color;
    ctx.beginPath();
    for (let regions of roi.regions) {
      ctx.moveTo(
        (regions[0][0] - imgLeft) * canvasFactor,
        (regions[0][1] - imgTop) * canvasFactor
      );
      let left = imgLeft;
      let top = imgTop;
      regions.forEach(function (point) {
        ctx.lineTo(
          (point[0] - left) * canvasFactor,
          (point[1] - top) * canvasFactor
        );
      });
    }
    this.ctx.closePath();
    this.ctx.fill("evenodd");
    if (opacity === 0) {
      ctx.strokeStyle = roi.color;
    }
    ctx.globalAlpha = 1;
    ctx.stroke();
  };

  draw() {
    const { roI, ome, tools, activeTool, drawLayer } = this.props;
    // draw image, contour, crosshair, ... in image

    this.canvas.height = this.props.canvasWidth;
    this.canvas.width = this.props.canvasWidth;

    // set padding (depends on zooming)
    let pd = 0;
    // check if new padding is still in whole image
    let boInsideImage =
      roI.bounds.left - this.state.pd > 0 &&
      roI.bounds.right + this.state.pd < ome.sizeX &&
      roI.bounds.top - this.state.pd > 0 &&
      roI.bounds.bottom + this.state.pd < ome.sizeY;
    if (boInsideImage) {
      pd = this.state.pd;
    } else {
      pd = this.state.pd - this.state.zoomSpeed; // if max. zoomed out --> do not change padding
    }

    // set image properties (dimensions) with padding
    let imgBottom = roI.bounds.bottom + pd;
    let imgTop = roI.bounds.top - pd;
    let imgLeft = roI.bounds.left - pd;
    let imgRight = roI.bounds.right + pd;
    let imgBoundWidth = roI.bounds.right - roI.bounds.left;
    let imgBoundHeight = roI.bounds.bottom - roI.bounds.top;

    // make bounding box to square and edge protection (that image is in whole image)
    if (imgBoundWidth > imgBoundHeight) {
      let d = (imgBoundWidth - imgBoundHeight) / 2;
      if (imgTop - d < 0) {
        // if too high
        imgBottom = imgBottom + d + (d - imgTop);
        imgTop = 0;
      } else if (imgBottom + d > ome.sizeY - 1) {
        // if too low
        imgTop = imgTop - d - (d - (ome.sizeY - imgBottom));
        imgBottom = ome.sizeY - 1;
      } else {
        imgTop = imgTop - d;
        imgBottom = imgBottom + d;
      }
    } else if (imgBoundWidth < imgBoundHeight) {
      let d = (imgBoundHeight - imgBoundWidth) / 2;
      if (imgLeft - d < 0) {
        // too far left
        imgRight = imgRight + d + (d - imgLeft);
        imgLeft = 0;
      } else if (imgRight + d > ome.sizeX - 1) {
        // too far right
        imgLeft = imgLeft - d - (d - (ome.sizeX - imgRight));
        imgRight = ome.sizeX - 1;
      } else {
        imgLeft = imgLeft - d;
        imgRight = imgRight + d;
      }
    }

    // calculate pyramid level to display roi
    let factorPyramidLevel =
      (this.props.canvasWidth * ome.sizeY) /
      ((imgBottom - imgTop) * this.props.tiles.getImgHeight());
    let level = Math.floor(this.getBaseLog(2, factorPyramidLevel));
    if (level < 0) {
      level = 0;
    } else if (level > ome.maxLevel) {
      level = ome.maxLevel;
    }

    let ctx = this.canvas.getContext("2d");
    this.imgs = [];
    this.imgs1 = [];
    // get number of tiles in calculated level
    let rows = Math.pow(2, level);

    // get factor for width and height
    let factor_w =
      (this.props.tiles.getImgWidth() * rows) / this.props.ome.sizeX;
    let factor_h =
      (this.props.tiles.getImgHeight() * rows) / this.props.ome.sizeY;
    let xBound = imgLeft * factor_w;
    let yBound = imgTop * factor_h;
    let boundWidth = (imgRight - imgLeft) * factor_w;
    let boundHeight = (imgBottom - imgTop) * factor_h;

    // x- and y-Tile upper left
    let xTile = Math.floor(xBound / this.props.tiles.getImgWidth());
    let yTile = Math.floor(yBound / this.props.tiles.getImgHeight());

    xTile = Math.max(xTile, 0);
    yTile = Math.max(yTile, 0);

    // assume 4 tiles
    let rows1 = 2;
    let cols1 = 2;
    if (
      Math.floor(xBound / this.props.tiles.getImgWidth()) ===
      Math.floor((xBound + boundWidth) / this.props.tiles.getImgWidth())
    ) {
      // if left and right corner in same tile only one column
      cols1 = 1;
    }
    if (
      Math.floor(yBound / this.props.tiles.getImgHeight()) ===
      Math.floor((yBound + boundHeight) / this.props.tiles.getImgHeight())
    ) {
      // if upper and lower corner in same tile one row
      rows1 = 1;
    }
    if (xTile === Math.pow(2, level) - 1) {
      cols1 = 1;
    }
    if (yTile === Math.pow(2, level) - 1) {
      rows1 = 1;
    }

    // calculate and load tiles for roi depending on pyramide level
    for (let x = xTile; x < xTile + cols1; x++) {
      for (let y = yTile; y < yTile + rows1; y++) {
        for (let i = 0; i < ome.channels.length; i++) {
          let page = this.getPageForChannel(i);
          if (page === -1) {
            return;
          }
          let tileId =
            page + "," + level + "," + x + "," + y + "," + this.props.fileId;
          if (
            (!this.props.tiles.getVisibleImage(tileId) ||
              !this.props.tiles.getVisibleImage(tileId).complete ||
              roI.firstTimeGallery) &&
            this.props.objectToLoad.rois.includes(this.props.roI)
          ) {
            // if is not in cache and needs to get loaded in backend
            let vImg = new Image();
            vImg.src = Backend.renderRegion({
              id: this.props.fileId,
              page: page,
              lv: level,
              x: x,
              y: y,
            });
            this.imgs.push(vImg);
            this.imgs1.push([vImg, tileId]);
            this.props.tiles.pushVisibleImage(vImg, tileId);
          } else {
            // load tile from cache
            if (this.props.tiles.getVisibleImage(tileId)) {
              this.imgs.push(this.props.tiles.getVisibleImage(tileId));
              this.imgs1.push([
                this.props.tiles.getVisibleImage(tileId),
                tileId,
              ]);
            }
          }
        }
      }
    }

    // clear canvas
    ctx.clearRect(0, 0, this.props.canvasWidth, this.props.canvasWidth);
    ctx.save();
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.clearRect(0, 0, this.props.canvasWidth, this.props.canvasWidth);
    ctx.restore();

    // put images in canvas
    for (let y = 0; y < this.imgs.length; y++) {
      if (
        this.imgs[y].complete &&
        this.props.tiles.getColoredImage(this.imgs1[y][1])
      ) {
        // if image is finished loading --> draw
        let channel;
        let nChannels = ome.channels.length;
        for (let i = 0; i < nChannels; i++) {
          if (y % nChannels === i) {
            channel = this.props.tiles.getHistogramConfig().channels[i];
          }
        }

        if (channel.enabled) {
          // composition should be screen for fluorescence
          const compositionModeFluor = "screen";
          ctx.globalCompositeOperation = compositionModeFluor;

          // draw image with different channels
          let imageForCanvas = this.props.tiles.getColoredImage(
            this.imgs1[y][1]
          );

          // variables for destionation and source image
          let sX; // source x
          let sY; // source y
          let sWidth; // source width
          let sHeight; // source height
          let x1D; // destination x
          let y1D; // destination y
          let wD; // destination width
          let hD; // destination height
          let imgWidth = this.props.tiles.getImgWidth();
          let imgHeight = this.props.tiles.getImgHeight();
          let fktWidth = (imgWidth * (xTile + 1) - xBound) / boundWidth;
          let fktHeight = (imgHeight * (yTile + 1) - yBound) / boundHeight;

          // calculate values for destination and source image (for cropping)
          if (this.imgs.length === this.props.ome.channels.length) {
            // if roi is in 1 tile
            sX = xBound - xTile * imgWidth;
            sY = yBound - yTile * imgHeight;
            sWidth = boundWidth;
            sHeight = boundHeight;
            x1D = 0;
            y1D = 0;
            wD = this.props.canvasWidth;
            hD = this.props.canvasWidth;
          } else if (rows1 === 1 && y >= this.props.ome.channels.length) {
            // if roi is in two horizontal tiles
            sX = 0;
            sY = yBound - yTile * imgHeight;
            sWidth = boundWidth + xBound - imgWidth * (xTile + 1);
            sHeight = imgHeight * (yTile + 1) - yBound;
            x1D = this.props.canvasWidth * fktWidth;
            y1D = 0;
            wD = this.props.canvasWidth * (1 - fktWidth);
            hD = this.props.canvasWidth * fktHeight;
          } else if (rows1 === 1 && y < this.props.ome.channels.length) {
            // if roi is in two horizontal tiles
            sX = xBound - xTile * imgWidth;
            sY = yBound - yTile * imgHeight;
            sWidth = imgWidth * (xTile + 1) - xBound;
            sHeight = imgHeight * (yTile + 1) - yBound;
            x1D = 0;
            y1D = 0;
            wD = this.props.canvasWidth * fktWidth;
            hD = this.props.canvasWidth * fktHeight;
          } else if (y >= 0 && y < this.props.ome.channels.length) {
            // upper left
            sX = xBound - xTile * imgWidth;
            sY = yBound - yTile * imgHeight;
            sWidth = imgWidth * (xTile + 1) - xBound;
            sHeight = imgHeight * (yTile + 1) - yBound;
            x1D = 0;
            y1D = 0;
            wD = this.props.canvasWidth * fktWidth;
            hD = this.props.canvasWidth * fktHeight;
          } else if (
            y >= this.props.ome.channels.length &&
            y < this.props.ome.channels.length * 2
          ) {
            // lower left
            sX = xBound - xTile * imgWidth;
            sY = 0;
            sWidth = imgWidth * (xTile + 1) - xBound;
            sHeight = boundHeight - (imgHeight * (yTile + 1) - yBound);
            x1D = 0;
            y1D = this.props.canvasWidth * fktHeight;
            wD = this.props.canvasWidth * fktWidth;
            hD = this.props.canvasWidth * (1 - fktHeight);
          } else if (
            y >= this.props.ome.channels.length * 2 &&
            y < this.props.ome.channels.length * 3
          ) {
            // upper right
            sX = 0;
            sY = yBound - yTile * imgHeight;
            sWidth = boundWidth + xBound - imgWidth * (xTile + 1);
            sHeight = imgHeight * (yTile + 1) - yBound;
            x1D = this.props.canvasWidth * fktWidth;
            y1D = 0;
            wD = this.props.canvasWidth * (1 - fktWidth);
            hD = this.props.canvasWidth * fktHeight;
          } else {
            // lower right
            sX = 0;
            sY = 0;
            sWidth = boundWidth + xBound - imgWidth * (xTile + 1);
            sHeight = boundHeight - (imgHeight * (yTile + 1) - yBound);
            x1D = this.props.canvasWidth * fktWidth;
            y1D = this.props.canvasWidth * fktHeight;
            wD = this.props.canvasWidth * (1 - fktWidth);
            hD = this.props.canvasWidth * (1 - fktHeight);
          }

          ctx.imageSmoothingEnabled = true;
          if (imageForCanvas) {
            // draw loaded image canvas
            ctx.drawImage(
              imageForCanvas,
              sX,
              sY,
              sWidth,
              sHeight,
              x1D,
              y1D,
              wD,
              hD
            );
          }

          this.fullyLoadedCount = this.fullyLoadedCount + 1;
          if (this.areAllImagesComplete(this.imgs)) {
            this.props.roI.fullyLoaded = true;
            this.fullyLoadedCount = 0;
            if (this.props.roI === this.props.objectToLoad.roi) {
              // set image/roi that should be loaded next
              this.props.setObjectToLoad();
            }
          }
          roI.firstTimeGallery = false;
        }
      } else if (this.props.objectToLoad.rois.includes(this.props.roI)) {
        // if image is not finished loading from backend and roi is the object to load next --> load image
        this.imgs[y].onload = () => {
          let channel;
          let nChannels = this.props.ome.channels.length;
          for (let i = 0; i < nChannels; i++) {
            if (y % nChannels === i) {
              channel = this.props.tiles.getHistogramConfig().channels[i];
            }
          }

          if (channel.enabled) {
            // composition should be screen for fluorescence
            const compositionModeFluor = "screen";
            ctx.globalCompositeOperation = compositionModeFluor;

            if (channel.color === "#ffffff" || channel.color === -1) {
              // rgb channel
              if (
                this.imgs1[y] &&
                !this.props.tiles.getColoredImage(this.imgs1[y][1])
              ) {
                let imageForCanvas = configureImage(this.imgs[y], channel);
                this.props.tiles.pushColoredImages(
                  imageForCanvas,
                  this.imgs1[y][1]
                );
              }
            } else {
              // colored images cache
              if (
                this.imgs1[y] &&
                !this.props.tiles.getColoredImage(this.imgs1[y][1])
              ) {
                let imageForCanvas = colorInImage(this.imgs[y], channel);
                this.props.tiles.pushColoredImages(
                  imageForCanvas,
                  this.imgs1[y][1]
                );
              }
            }
          }

          this.loadedCount = this.loadedCount + 1;
          if (this.areAllImagesComplete(this.imgs)) {
            // if all images loaded --> reset images
            this.imgs = [];
            this.imgs1 = [];
            this.props.roI.firstTimeGallery = false;
            this.loadedCount = 0;
            this.props.roI.fullyLoaded = true;
            this.forceUpdate();
          }
        };
      }
    }

    // draw contour of roi
    if (this.props.contour) {
      this.ctx.globalCompositeOperation = "source-over";
      this.ctx.strokeStyle = this.props.isBrightfield ? "black" : "white";
      ctx.lineWidth = 2;
      let canvasFactor = this.props.canvasWidth / (imgBottom - imgTop);
      this.canvasFactor1 = canvasFactor;
      this.left = imgLeft;
      this.top = imgTop;

      if (
        !tools[activeTool] ||
        (tools[activeTool] &&
          tools[activeTool].name !== "Pen" &&
          tools[activeTool].name !== "Region" &&
          tools[activeTool].name !== "Rectangle" &&
          tools[activeTool].name !== "Ellipse") ||
        !tools[activeTool].layer
      ) {
        // no drawing tool enabled
        // only draw main roi
        this.drawRoi(ctx, roI, imgLeft, imgTop, canvasFactor);
        ctx.beginPath();
      } else {
        // drawing tool enabled
        if (
          tools[activeTool].layer.regionRois[this.props.index] &&
          !this.mouseOnCanvas
        ) {
          // drawing tool enabled but mouse not on canvas --> only draw main roi
          let r = tools[activeTool].layer.regionRois.filter(
            (element) => element.bounds === roI.bounds
          )[0];
          if (r) {
            this.r = r;
            // only draw main roi
            this.drawRoi(ctx, r, imgLeft, imgTop, canvasFactor);
          }
        }

        if (this.mouseOnCanvas) {
          // draw other contours if mouse is on canvas and tool is activated
          // only draw contours that are visible in image (use tree)
          this.visibleRegions = [];
          this.visibleRegions = this.findVisibleRois(this.state.pd); // also icludes main roi

          // draw visible regions in canvas
          for (let i = 0; i < this.visibleRegions.length; i++) {
            this.drawRoi(
              ctx,
              this.visibleRegions[i].roi,
              imgLeft,
              imgTop,
              canvasFactor
            );
          }

          // draw drawlayer
          if (drawLayer.regionRois.length > 0) {
            this.drawRoi(
              ctx,
              drawLayer.regionRois[0],
              imgLeft,
              imgTop,
              canvasFactor
            );
          }
        }
      }
    }

    // draw custom mouse cursor for pen tool
    if (tools[activeTool] && this.mousepos.x !== 0) {
      tools[activeTool].drawCustomCursor(
        ctx,
        this.mousepos,
        this.canvasFactor1,
        true
      );
    }

    // draw crosshair
    // clear overlayCanvas
    let ctxOv = null;
    if (this.canvasOverlay !== null && this.canvasOverlay) {
      ctxOv = this.canvasOverlay.getContext("2d");
      this.canvasOverlay.height = this.props.canvasWidth;
      this.canvasOverlay.width = this.props.canvasWidth;
      ctxOv.clearRect(0, 0, this.props.canvasWidth, this.props.canvasWidth);
      ctxOv.save();
      ctxOv.setTransform(1, 0, 0, 1, 0, 0);
      ctxOv.clearRect(0, 0, this.props.canvasWidth, this.props.canvasWidth);
      ctxOv.restore();
    }
    if (this.props.drawCrosshair) {
      ctxOv.lineWidth = this.props.crosshairLineWidth;
      let lineLength = (this.props.canvasWidth / 2) * 0.9;
      let linePostion = this.props.canvasWidth / 2;
      let widthHeight = this.props.canvasWidth;

      // horizontal line left
      ctxOv.beginPath();
      ctxOv.moveTo(4, this.props.canvasWidth / 2);
      ctxOv.lineTo(lineLength, linePostion);
      ctxOv.closePath();
      ctxOv.strokeStyle = this.props.crosshairColor;
      ctxOv.globalAlpha = this.props.crosshairOpacity;
      ctxOv.stroke();

      // horizontal line right
      ctxOv.beginPath();
      ctxOv.moveTo(widthHeight - 4, linePostion);
      ctxOv.lineTo(widthHeight - lineLength, linePostion);
      ctxOv.closePath();
      ctxOv.strokeStyle = this.props.crosshairColor;
      ctxOv.globalAlpha = this.props.crosshairOpacity;
      ctxOv.stroke();

      // vertical line top
      ctxOv.beginPath();
      ctxOv.moveTo(linePostion, 4);
      ctxOv.lineTo(linePostion, lineLength);
      ctxOv.closePath();
      ctxOv.strokeStyle = this.props.crosshairColor;
      ctxOv.globalAlpha = this.props.crosshairOpacity;
      ctxOv.stroke();

      // vertical line bottom
      ctxOv.beginPath();
      ctxOv.moveTo(linePostion, widthHeight - 4);
      ctxOv.lineTo(linePostion, widthHeight - lineLength);
      ctxOv.closePath();
      ctxOv.strokeStyle = this.props.crosshairColor;
      ctxOv.globalAlpha = this.props.crosshairOpacity;
      ctxOv.stroke();
    }
  }

  getRoiStructureId = (roi) => {
    const { structures, selectedLayer } = this.props;
    // return structureId of roi
    if (roi.structureId === 0 && roi.subtypeName === "") {
      // run job parent elements
      return structures[selectedLayer].id;
    } else if (roi.structureId === 0) {
      // for old projects with no ids or after run job (structureId === 0)
      let idx = structures.findIndex(
        (element) =>
          element.color === roi.color && element.label === roi.subtypeName
      );
      return structures[idx].id;
    } else {
      // if new project --> just return structureId property
      return roi.structureId;
    }
  };

  getClassificationStructure = (id) => {
    const { structures } = this.props;
    // return structure for classification with given id
    return structures.filter((element) => element.id === id)[0];
  };

  handleCanvasClick = (e) => {
    const {
      roI,
      tools,
      structures,
      selectedLayer,
      roiLayers,
      activeTool,
      automaticTraining,
      classificationId,
      project,
    } = this.props;
    // classify and select roi

    // return if roi is not loaded --> return
    if (this.props.roI.firstTimeGallery) {
      if (!this.props.roI.aiAnnotated) {
        return;
      }
    }

    if (!project.type.includes("Histo") && selectedLayer === 0) return; //dont allow subtype marking for baseroi

    // only classify and select roi if no drawing tool is activated
    if (
      !tools[activeTool] ||
      (tools[activeTool] &&
        tools[activeTool].name !== "Pen" &&
        tools[activeTool].name !== "Region" &&
        tools[activeTool].name !== "Rectangle" &&
        tools[activeTool].name !== "Ellipse")
    ) {
      // set clicked roi to selected = true
      roiLayers[selectedLayer].layer.regionRois.forEach((element) => {
        element.selected = false;
        element.selectedWithKey = false;
      });
      this.props.roI.selected = true;
      this.props.roI.selectedWithKey = false;
      if (this.props.selectedWithKey) {
        // set false --> no automatic scrolling
        this.props.setSelectedWithKey(false);
      }

      // return here in histo-point-counting module --> only select but not classify
      if (project.type.includes("HistoPointCounting")) {
        this.props.updateGallery();
        return;
      }

      // classify roi
      let roiWasSubtype = roI.isSubtype && !roI.aiAnnotated;
      let bufferRoiId = this.getRoiStructureId(roI);

      let classificationStructure;
      if (e.nativeEvent.which === 3) {
        // right click
        classificationStructure = this.getClassificationStructure(
          classificationId[2]
        );
      }
      if (e.nativeEvent.which === 1) {
        // left click
        classificationStructure = this.getClassificationStructure(
          classificationId[1]
        );
      }

      if (typeof classificationStructure === "undefined") return;

      // set properties
      roI.color = classificationStructure.color;
      roI.isSubtype = classificationStructure.classificationSubtype
        ? true
        : false;
      roI.isAnnotated = classificationStructure.classificationSubtype
        ? true
        : false;
      roI.isLabeled = classificationStructure.classificationSubtype
        ? true
        : false;
      roI.subtypeName = classificationStructure.label;
      roI.structureId = classificationStructure.id;
      roI.isSelObj = false;
      roI.aiAnnotated = false;

      // increase annotationCount
      if (
        automaticTraining &&
        !structures[selectedLayer].classificationSubtype
      ) {
        if (roI.isSubtype && !roiWasSubtype) {
          // if classify unlabeled to labeled
          this.props.tiles.setStrAnnoCount(this.getRoiStructureId(roI), 1);
        } else if (roI.isSubtype && roiWasSubtype) {
          // if classify labeled to labeled
          this.props.tiles.setStrAnnoCount(this.getRoiStructureId(roI), 1);
          this.props.tiles.setStrAnnoCount(bufferRoiId, -1);
        } else if (roiWasSubtype) {
          // if classify labeled to unlabeled
          this.props.tiles.setStrAnnoCount(bufferRoiId, -1);
        }
      }

      // check if all images in scene are labeled
      //this.allImgsAnnotated();

      // check if start training automatically
      let childs = this.findChilds(structures[selectedLayer]);
      let structureId = structures[selectedLayer].id;
      let annotations =
        this.getNuberChildRois(childs) -
        this.props.tiles.getStrAnnoCountElement(structureId);
      if (
        annotations >= this.props.startData &&
        automaticTraining &&
        !this.props.alruns
      ) {
        let enoughAnnos = this.enoughAnnotations(childs);
        if (enoughAnnos) {
          this.props.tiles.setAnnotationCount(0);
          this.props.tiles.setParentAnnoCount(
            structures[selectedLayer].id,
            annotations
          );
          this.props.startAutomaticTraining();
        } else {
          // give warning if too less annotations
          this.props.giveWarning();
        }
      }
      // update gallery
      this.props.updateGallery();
    }
  };

  allImgsAnnotated = () => {
    const { selectedLayer, roiLayers } = this.props;
    // check if all images in scene are labeled
    let allImagesLabeled = roiLayers[selectedLayer].layer.regionRois.filter(
      (element) => !element.isLabeled
    );
    if (allImagesLabeled.length === 0) {
      // set property that scene is fully annotated
      this.props.setFUllyAnnotated();
    } else if (allImagesLabeled.length > 0) {
      // set property that scene is annotated
      this.props.setAnnotated();
    }
  };

  getNuberChildRois = (childs) => {
    // get number of rois for each child
    let n = 0;
    childs.forEach((element) => {
      n = n + this.props.tiles.getStrAnnoCountElement(element.id);
    });
    return n;
  };

  findChilds = (structure) => {
    const { structures } = this.props;
    // return all direct classificationsubtypes
    return structures.filter(
      (element) =>
        element.subtypeLevel === structure.subtypeLevel + 1 &&
        element.parentId === structure.id &&
        element.classificationSubtype
    );
  };

  enoughAnnotations = (childs) => {
    // check if enough annotations for training of dl model (at least one class with 5 annotations and one other class with 1 annotation)
    let minFive = false;
    let twoAnnotated = 0;
    childs.forEach((element) => {
      // check if minimum of five in one class
      if (this.props.tiles.getStrAnnoCountElement(element.id) >= 5) {
        minFive = true;
      }
      // check if two classes with at least one element
      if (this.props.tiles.getStrAnnoCountElement(element.id) >= 1) {
        twoAnnotated = twoAnnotated + 1;
      }
    });

    // if one class with at least five and one other class with at least one annotation --> enough annotations for training
    let enough = false;
    if (minFive && twoAnnotated >= 2) {
      enough = true;
    }
    return enough;
  };

  handleCanvasMouseDown = (e) => {
    const { roI, tools, activeTool, project } = this.props;
    // no mouse down if roi is still loading
    if (this.props.roI.firstTimeGallery) {
      return;
    }
    // set property that mouse is down
    this.props.tiles.setIsMousedown(true);

    if (this.drawingEnabled) {
      // get position, ... for drawing event of tool
      var rect = this.canvas.getBoundingClientRect();
      var mouseX = e.clientX - rect.left;
      var mouseY = e.clientY - rect.top;
      let p = {
        x: mouseX / this.canvasFactor1 + this.left,
        y: mouseY / this.canvasFactor1 + this.top,
      };
      if (tools[activeTool]) {
        tools[activeTool].mouse({
          event: e,
          p: p,
          color: roI.color,
          subtype: roI.isSubtype,
          name: roI.subtypeName,
        });
      }
      // update
      this.forceUpdate();
    }
    // mouse wheel click not possible in point counting module
    if (!project.type.includes("HistoPointCounting")) {
      // zoom to roi in viewer when mouse wheel click
      if (e.nativeEvent.which === 2) {
        this.props.gallery(
          false,
          roI.bounds.left,
          roI.bounds.right,
          roI.bounds.top,
          roI.bounds.bottom
        );
      }
    }
  };

  handleMouseMove = (e) => {
    const { roI, tools, activeTool } = this.props;
    // no mouse move if roi is still loading
    if (roI.firstTimeGallery) {
      return;
    }
    if (this.drawingEnabled) {
      // set property that mouse is on canvas
      this.mouseOnCanvas = true;
      // get position, ... for drawing event of tool
      var rect = this.canvas.getBoundingClientRect();
      var mouseX = e.clientX - rect.left;
      var mouseY = e.clientY - rect.top;
      let p = {
        x: mouseX / this.canvasFactor1 + this.left,
        y: mouseY / this.canvasFactor1 + this.top,
      };
      if (
        (tools[activeTool] && tools.region.points.length !== 0) ||
        (tools[activeTool] && tools[activeTool].name === "Pen") ||
        (tools[activeTool] && tools[activeTool].name === "Rectangle") ||
        (tools[activeTool] && tools[activeTool].name === "Ellipse")
      ) {
        tools[activeTool].mouse({
          event: e,
          p: p,
          color: roI.color,
          subtype: roI.isSubtype,
          name: roI.subtypeName,
        });
        // update
        this.forceUpdate();
      }
      // update mouse position
      this.mousepos.x = e.clientX - rect.left;
      this.mousepos.y = e.clientY - rect.top;
    }
  };

  handleMouseUp = (e) => {
    const { roI, tools, activeTool, roiLayers, selectedLayer, structures } =
      this.props;
    // no mouse up if roi is still loading
    if (roI.firstTimeGallery) {
      return;
    }
    // set property that mouse is not down anymore
    this.props.tiles.setIsMousedown(false);

    if (
      this.drawingEnabled &&
      tools[activeTool] &&
      (tools[activeTool].name === "Pen" ||
        tools[activeTool].name === "Region" ||
        tools[activeTool].name === "Rectangle" ||
        tools[activeTool].name === "Ellipse")
    ) {
      // get position, ... for drawing event of tool
      var rect = this.canvas.getBoundingClientRect();
      var mouseX = e.clientX - rect.left;
      var mouseY = e.clientY - rect.top;
      let p = {
        x: mouseX / this.canvasFactor1 + this.left,
        y: mouseY / this.canvasFactor1 + this.top,
      };

      // get position of roi --> insert if at correct position after update
      // get layer which contains roi
      let parentLayer = getParentIndexLayer(
        structures[selectedLayer],
        structures
      );
      // get position of roi in roilayer
      let posInRoiLayer = roiLayers[parentLayer].layer.regionRois.findIndex(
        (roi) => roi === roI
      );
      if (posInRoiLayer !== -1) {
        this.positionInLayer = posInRoiLayer;
      }
      if (tools[activeTool]) {
        tools[activeTool].mouse({
          event: e,
          p: p,
          color: roI.color,
          subtype: roI.isSubtype,
          name: roI.subtypeName,
          positionInRoiLayer: this.positionInLayer,
          fullyLoaded: true,
        });
      }
      // reset object to load --> reload after drawing
      this.props.setObjectToLoad(false, true);
      // update
      this.forceUpdate();
    }
  };

  handleMouseLeave = () => {
    const { roI, tools, activeTool } = this.props;
    // no mouse leave if roi is still loading
    if (roI.firstTimeGallery) {
      return;
    }
    // set property that mouse is not on image anymore
    this.props.tiles.setIsOnImage(false);

    // if mouse is not down when leaving image --> end drawing mode
    if (!this.props.tiles.getIsMousedown()) {
      this.drawingEnabled = false;
    }

    // reset mouseposition
    this.mousepos.x = 0;
    this.mousepos.y = 0;
    this.mouseOnCanvas = false;
    if (
      tools[activeTool] &&
      (tools[activeTool].name === "Pen" ||
        tools[activeTool].name === "Region" ||
        tools[activeTool].name === "Rectangle" ||
        tools[activeTool].name === "Ellipse")
    ) {
      // update gallery
      this.props.updateGallery();
    }
    // update
    this.forceUpdate();
  };

  handleMouseEnter = () => {
    const { roI, roiLayers, selectedLayer } = this.props;
    if (!this.props.tiles.getIsMousedown()) {
      // if mouse is not down when entering image --> start drawing mode
      this.drawingEnabled = true;
      // set position and props of roi in context --> to fix stop drawing outside of image
      this.props.tiles.setRoiProps(this.props.roI);
      let posInRoiLayer = roiLayers[selectedLayer].layer.regionRois.findIndex(
        (roi) => roi === roI
      );
      this.props.tiles.setPositionRoi(posInRoiLayer);
    } else {
      // if mouse is already down when entering image --> do not start drawing mode becaus drawing is enabled on other image
      this.props.tiles.setIsInOtherImage(true);
    }
    // set property that mouse is on image
    this.props.tiles.setIsOnImage(true);
  };

  mousewheel = (event) => {
    const { roI, tools, activeTool, ome } = this.props;
    // only zoom if drawing / tool is activated
    if (
      tools[activeTool] &&
      (tools[activeTool].name === "Pen" ||
        tools[activeTool].name === "Region" ||
        tools[activeTool].name === "Rectangle" ||
        tools[activeTool].name === "Ellipse")
    ) {
      // check if zooming would result in zoom outside of whole image
      let insideImage =
        roI.bounds.left - this.state.pd > 0 &&
        roI.bounds.right + this.state.pd < ome.sizeX &&
        roI.bounds.top - this.state.pd > 0 &&
        roI.bounds.bottom + this.state.pd < ome.sizeY;

      if (insideImage) {
        if (event.deltaY < 0) {
          // zoom in if possible
          if (this.state.pd >= this.state.zoomSpeed) {
            this.setState({ pd: this.state.pd - this.state.zoomSpeed });
          } else {
            this.setState({ pd: 0 });
          }
        } else {
          // zoom out
          this.setState({ pd: this.state.pd + this.state.zoomSpeed });
        }
      } else {
        if (event.deltaY < 0) {
          // zoom in if possible
          if (this.state.pd >= this.state.zoomSpeed) {
            this.setState({ pd: this.state.pd - this.state.zoomSpeed });
          } else {
            this.setState({ pd: 0 });
          }
        }
      }

      // increase zoomspeed by 10%
      let imgBoundWidth =
        this.props.roI.bounds.right - this.props.roI.bounds.left;
      this.setState({ zoomSpeed: (imgBoundWidth + this.state.pd * 2) * 0.1 }); // 10%
    } else {
      // increase / decrease z level
      if (this.props.showZStackBar) {
        this.onZStep(event.deltaY < 0 ? 1 : -1);
      }
    }

    // prevent other scroll actions if there is a zstack
    let actTool =
      tools[activeTool] &&
      (tools[activeTool].name === "Pen" ||
        tools[activeTool].name === "Region" ||
        tools[activeTool].name === "Rectangle" ||
        tools[activeTool].name === "Ellipse");
    if (this.props.showZStackBar || actTool) {
      this.props.roI.firstTimeGallery = true;
      event.preventDefault();
      this.forceUpdate();
    }
  };

  getBaseLog(x, y) {
    return Math.log(y) / Math.log(x);
  }

  onZStep(deltaZ) {
    // change z level for roi
    let newZ = this.props.roI.z + deltaZ;
    if (newZ < 0) newZ = 0;
    if (newZ > this.props.ome.sizeZ - 1) newZ = this.props.ome.sizeZ - 1;
    if (newZ !== this.props.roI.z) {
      this.props.roI.firstTimeGallery = true;
      this.props.roI.fullyLoaded = false;
      this.props.roI.z = newZ;
    }
    this.forceUpdate();
  }

  render() {
    const { classes, ome, tools, activeTool, roI, isBrightfield } = this.props;

    // do not show z-stack bar in upper left while drawing
    const drawMode =
      tools[activeTool] &&
      (tools[activeTool].name === "Pen" ||
        tools[activeTool].name === "Region" ||
        tools[activeTool].name === "Rectangle" ||
        tools[activeTool].name === "Ellipse");

    let pixelSize = ome.physicalSizeX / Math.pow(10, -6);
    let unit = ome.physicalSizeXUnit;

    return (
      <div ref={(c) => (this.root = c)}>
        <Tooltip
          disableInteractive
          title={
            "Width: " +
            Math.round((roI.bounds.right - roI.bounds.left) * pixelSize) +
            unit +
            "    Height: " +
            Math.round((roI.bounds.bottom - roI.bounds.top) * pixelSize) +
            unit +
            "    Area: " +
            Math.round(
              (roI.bounds.bottom - roI.bounds.top) *
                (roI.bounds.right - roI.bounds.left) *
                Math.pow(pixelSize, 2)
            ) +
            unit +
            "²"
          }
        >
          <div>
            <div style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 10,
                  display: this.props.roI.fullyLoaded ? "none" : "block",
                  height: this.props.canvasWidthString,
                  width: this.props.canvasWidthString,
                  background: "transparent",
                  textAlign: "center",
                  lineHeight: this.props.canvasWidthString,
                }}
              >
                <CircularProgress />
              </div>
              <canvas
                style={{
                  height: this.props.canvasWidthString,
                  width: this.props.canvasWidthString,
                  border: roI.selected
                    ? "4px dashed " + roI.color
                    : "4px solid " + roI.color,
                  backgroundColor:
                    roI.selected && isBrightfield ? "#ffffff" : "#000000",
                }}
                ref={(c) => (this.canvas = c)}
                className={classes.canvas}
                onClick={this.handleCanvasClick}
                onMouseDown={this.handleCanvasMouseDown}
                onContextMenu={(e) => {
                  this.handleCanvasClick(e);
                  e.preventDefault();
                }}
                onMouseMove={this.handleMouseMove}
                onMouseUp={this.handleMouseUp}
                onMouseLeave={this.handleMouseLeave}
                onMouseEnter={(e) => {
                  this.handleMouseEnter(e);
                }}
              />
              {this.props.drawCrosshair && (
                <canvas
                  style={{
                    height: this.props.canvasWidthString,
                    width: this.props.canvasWidthString,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 5,
                    pointerEvents: "none",
                  }}
                  ref={(c) => (this.canvasOverlay = c)}
                />
              )}
            </div>
            {ome.sizeZ > 1 && !drawMode && (
              <div className={classes.zBar} style={{ opacity: 1 }}>
                <FontAwesomeIcon
                  className={classes.zStackIcon}
                  icon={faLayerGroup}
                  color="inherit"
                />
                <Tooltip disableInteractive title="Z-Stack up">
                  <IconButton
                    className={classes.zStepUp}
                    onClick={() => this.onZStep(1)}
                    size="large"
                  >
                    <ArrowDropUp color="inherit" />
                  </IconButton>
                </Tooltip>
                <span className={classes.zIndex}>{this.props.roI.z}</span>
                <Tooltip disableInteractive title="Z-Stack down">
                  <IconButton
                    className={classes.zStepDown}
                    onClick={() => this.onZStep(-1)}
                    size="large"
                  >
                    <ArrowDropDown color="inherit" />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
        </Tooltip>
      </div>
    );
  }
}

// define the component's interface
CroppedImage.propTypes = {
  classes: PropTypes.object.isRequired,
  componentRef: PropTypes.func,
  onRefresh: PropTypes.func,
  ome: PropTypes.object,
  histogramConfig: PropTypes.object,
  visibleImage: PropTypes.array,
  coloredImages: PropTypes.array,
  roI: PropTypes.object,
  structures: PropTypes.array,
  selectedLayer: PropTypes.number,
  canvasWidth: PropTypes.number,
  canvasWidthString: PropTypes.string,
  gallery: PropTypes.func,
  contour: PropTypes.bool,
  activeTool: PropTypes.string,
  tools: PropTypes.array,
  fileId: PropTypes.string,
  index: PropTypes.number,
  indexOffset: PropTypes.number,
  page: PropTypes.number,
  roiLayers: PropTypes.array,
  fromAI: PropTypes.bool,
  drawLayer: PropTypes.object,
  updateGallery: PropTypes.func,
  startData: PropTypes.number,
  alruns: PropTypes.bool,
  setAlruns: PropTypes.func,
  globalZ: PropTypes.number,
  z: PropTypes.number,
  t: PropTypes.number,
  tiles: PropTypes.object,
  opacity: PropTypes.number,
  selectedWithKey: PropTypes.bool,
  scroll: PropTypes.func,
  objectToLoad: PropTypes.object,
  setObjectToLoad: PropTypes.func,
  isBrightfield: PropTypes.bool,
  drawCrosshair: PropTypes.bool,
  crosshairLineWidth: PropTypes.number,
  crosshairColor: PropTypes.string,
  crosshairOpacity: PropTypes.number,
  automaticTraining: PropTypes.bool,
  classificationId: PropTypes.array,
  project: PropTypes.object,
  setSelectedWithKey: PropTypes.func,
  startAutomaticTraining: PropTypes.func,
  giveWarning: PropTypes.func,
  setFUllyAnnotated: PropTypes.func,
  setAnnotated: PropTypes.func,
  showZStackBar: PropTypes.bool,
};

export default withTiles(withTheme(withStyles(styles)(CroppedImage)));

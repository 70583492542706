// Framework imports
import React, { useState, useRef, useEffect, useContext } from "react";
import PropTypes from "prop-types";

// External packages
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SpeedIcon from "@mui/icons-material/Speed";
import Stack from "@mui/material/Stack";
import StopIcon from "@mui/icons-material/Stop";
import VolumeDownIcon from "@mui/icons-material/VolumeDown";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import Tooltip from "@mui/material/Tooltip";

// HSA Imports
import WheelControllableSlider from "../../common/components/WheelControllableSlider";
import { AudioFileContext } from "./AudioFileContainer";

/**
 * Collected (reusable) styles for page elements.
 */
const styles = {
  root: {
    width: "100%",
    borderBottom: "1px solid #EBEBEB",
  },
  sliders: {
    minWidth: 250,
  },
};

/**
 * Audio Controls for a sound.
 * @param {Howl} audio Instance of audio referenced by scrollbar.
 * @returns React node for AudioControlBar.
 */
export default function AudioControlBar(props) {
  const { getWaveSurfer } = props;
  const waveS = getWaveSurfer();
  if (!waveS) return null;

  const AudioFile = useContext(AudioFileContext);
  const disableKeyboardShortcutsRef = useRef(
    AudioFile.disableKeyboardShortcuts
  );

  // Volume controls
  // const [volume, setVolume] = useState(audio.volume() * 100);
  const [savedVolume, setSavedVolume] = useState(waveS.media.volume * 100);

  useEffect(() => {
    // Listen for keyboard controls
    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, []);

  useEffect(() => {
    disableKeyboardShortcutsRef.current = AudioFile.disableKeyboardShortcuts;
  }, [AudioFile.disableKeyboardShortcuts]);

  /**
   * Decide what happens on any keypress event.
   * @param {KeyboardEvent} e Keypress event.
   */
  const handleKeyPress = (e) => {
    if (disableKeyboardShortcutsRef.current) return;
    if (!waveS) return;
    switch (e.code) {
      case "Space":
        // Play/Pause Audio
        waveS.playPause();
        e.preventDefault();
        break;
      case "ArrowLeft":
        // Jump back
        jump(-1);
        break;
      case "ArrowRight":
        // Jump ahead
        jump(1);
        break;
    }
  };

  /**
   * Jump short distances in the audiofile. The distance depends on the length of the audiofile:
   * Until threshold, jump 5% of song, then jump fixed second value.
   * @param {number} direction The direction to jump in. Will be multiplied with the calculated interval.
   */
  const jump = (direction) => {
    if (typeof direction !== "number") {
      throw TypeError(
        `direction must be of type number, received ${typeof direction}: ${direction}`
      );
    }

    const PERCENTAGE_UNTIL_CUTOFF = 0.05;
    const FIXED_DISTANCE_SEC = 5;
    const DURATION_THRESHOLD_SEC =
      (1 / PERCENTAGE_UNTIL_CUTOFF) * FIXED_DISTANCE_SEC;

    const duration = waveS.getDuration();
    // Until threshold, jump PERCENTAGE_UNTIL_CUTOFF of song, then jump fixed second value.
    const interval =
      duration <= DURATION_THRESHOLD_SEC
        ? duration * PERCENTAGE_UNTIL_CUTOFF
        : FIXED_DISTANCE_SEC;

    waveS.skip(direction * interval);
  };


  return (
    <Grid
      container
      sx={{
        ...styles.root,
      }}
    >
      {/* Buttons */}
      {waveS && !waveS.media.paused ? (
        <Tooltip title="Pause" disableInteractive>
          <IconButton onClick={() => waveS.pause()}>
            <PauseIcon sx={{ color: "secondary.main" }} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Play" disableInteractive>
          <IconButton onClick={() => waveS.play()}>
            <PlayArrowIcon sx={{ color: "secondary.main" }} />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Reset" disableInteractive>
        <IconButton
          onClick={() => {
            if (waveS) {
              waveS.setTime(0);
              waveS.pause();
            }
          }}
        >
          <StopIcon sx={{ color: "secondary.main" }} />
        </IconButton>
      </Tooltip>

      <Stack
        spacing={2}
        direction="row"
        sx={styles.sliders}
        alignItems="center"
      >
        {/* Volume Control */}
        {waveS.media.volume === 0 ? (
          <Tooltip title="Unmute" disableInteractive>
            <IconButton
              onClick={() => {
                // audio.volume(0);
                waveS.setVolume(savedVolume);
              }}
            >
              <VolumeOffIcon sx={{ color: "secondary.main" }} />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Mute" disableInteractive>
            <IconButton
              onClick={() => {
                // audio.volume(savedVolume / 100);
                setSavedVolume(waveS.media.volume);
                waveS.setVolume(0);
              }}
            >
              <VolumeDownIcon sx={{ color: "secondary.main" }} />
            </IconButton>
          </Tooltip>
        )}
        <WheelControllableSlider
          aria-label="Volume"
          defaultValue={waveS.media.volume * 100}
          min={0}
          max={100}
          size="small"
          valueLabelDisplay="auto"
          onChange={(value) => {
            waveS.setVolume(value / 100);            
          }}
        />
        <VolumeUpIcon sx={{ color: "secondary.main" }} />
      </Stack>
      <Stack
        spacing={2}
        direction="row"
        sx={styles.sliders}
        alignItems="center"
      >
        {/* Speed Control */}
        <Tooltip title="Playback Speed" disableInteractive>
          <SpeedIcon sx={{ color: "secondary.main" , marginLeft: "20px"}} />
        </Tooltip>
        <WheelControllableSlider
          aria-label="Playback Speed"
          min={0.5}
          max={4}
          step={0.1}
          defaultValue={waveS.media.playbackRate}
          size="small"
          valueLabelDisplay="auto"
          onChange={(value) => {
            waveS.setPlaybackRate(value);
          }}
        />
      </Stack>
    </Grid>
  );
}

AudioControlBar.propTypes = {
  disableKeyboardShortcuts: PropTypes.bool,
  getWaveSurfer: PropTypes.func,
};

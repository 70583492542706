// Framework imports
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// External packages
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import StopIcon from "@mui/icons-material/Stop";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// HSA packages
import ClickAwareTreeItem from "../../common/components/ClickAwareTreeItem";
import Structure from "../../common/components/Structure";
import SketchColorPicker from "../../common/components/SketchColorPicker";
import StructureOptionMenu from "./StructureOptionMenu";
import { theme } from "../../index";

const styles = {
  structure: {
    width: "100%",
    display: "grid",
    overflow: "hidden",
    alignItems: "center",
  },
  structureElement: { marginRight: "10px" },
  inputElement: {
    width: "100%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  structureIcon: {
    color: "primary.inactive",
    padding: "3px",
  },
};

/**
 * Structure items that can be used inside a mui TreeView.
 * Will render children depending on childrens presence.
 * @param {object} structure The structure to use.
 * @param {array} structures All structures of a project.
 * @param {Structure} selectedStructure Optional. The currently selected structure. Default: null.
 * @returns {JSX.Element} The structure item.
 */
export default function RecursiveStructureItem(props) {
  const {
    structure,
    structures,
    selectedStructure = null,
    trainingSettings = null,
    setTrainingSettings = () => {},
    ...other
  } = props;
  const leftSpacing =
    structure.nestingDepth > 0 ? structure.nestingDepth * 17 : 0;

  return structure.hasChildren ? (
    <ClickAwareTreeItem
      nodeId={structure.id.toString()}
      sx={{
        ...other.style,
        ...other.sx,
        marginLeft: -leftSpacing + "px",
        paddingLeft: leftSpacing + "px",
        background:
          selectedStructure?.id === structure.id
            ? theme.palette.selected.main
            : "white",
      }}
      label={
        <StructureItem
          structure={structure}
          structures={structures}
          trainingSettings={trainingSettings}
          setTrainingSettings={setTrainingSettings}
          {...other}
        />
      }
    >
      {structures
        .filter((s) => s.parentId === structure.id)
        .map((structure) => (
          <RecursiveStructureItem
            key={structure.id}
            structure={structure}
            structures={structures}
            selectedStructure={selectedStructure}
            trainingSettings={trainingSettings}
            setTrainingSettings={setTrainingSettings}
            {...other}
          />
        ))}
    </ClickAwareTreeItem>
  ) : (
    <ClickAwareTreeItem
      nodeId={structure.id.toString()}
      sx={{
        ...other.style,
        ...other.sx,
        marginLeft: -leftSpacing + "px",
        paddingLeft: leftSpacing + "px",
        background:
          selectedStructure?.id === structure.id
            ? theme.palette.selected.main
            : "white",
      }}
      label={
        <StructureItem
          structure={structure}
          structures={structures}
          trainingSettings={trainingSettings}
          setTrainingSettings={setTrainingSettings}
          {...other}
        />
      }
    />
  );
}

RecursiveStructureItem.propTypes = {
  structure: PropTypes.instanceOf(Structure).isRequired,
  structures: PropTypes.array.isRequired,
  setStructures: PropTypes.func,
  selectedStructure: PropTypes.instanceOf(Structure),
  setSelectedStructure: PropTypes.func,
  triggerAction: PropTypes.func,
  isLocked: PropTypes.bool,
  isInSelectionMode: PropTypes.bool,
  trainingSettings: PropTypes.object,
  setTrainingSettings: PropTypes.func,
};

/**
 * Structure display with options.
 * @param {object} structure The structure to use.
 * @param {array} structures All structures of a project.
 * @param {function} setStructures Optional. Update all structures of a project. Receives (array structures). Default: () => {}.
 * @param {function} setSelectedStructure Optional. Set the currrently selected structure. Receives (uint id | null). Default: () => {}.
 * @param {function} triggerAction Optional. Flag a structure to have it's annotations exported. Receives (Structure structure). Default: () => {}.
 * @param {bool} isLocked Optional. Flag if the tree is locked. Default: false.
 * @param {bool} isInSelectionMode Optional. Flag if the tree is in selection mode. Default: false.
 * @param {function} updateChoosability Optional. Triggers update of choosability of all structures. Default: () => {}.
 * @returns React node for StructureItem.
 */
export function StructureItem(props) {
  const {
    structure,
    structures,
    setStructures = () => {},
    setSelectedStructure = () => {},
    triggerAction = () => {},
    isLocked = false,
    isInSelectionMode = false,
    updateChoosability = () => {},
    trainingSettings = null,
    setTrainingSettings = () => {},
    ...other
  } = props;

  // TODO: Remove copies, work directly on structure object
  const [label, setLabel] = useState(structure.label);
  const [color, setColor] = useState(structure.color);
  const [isChosen, setIsChosen] = useState(structure.isChosen);
  const [annotationsAreVisible, setAnnotationVisibility] = useState(
    structure.annotationsAreVisible
  );

  useEffect(() => updateStructure(), [annotationsAreVisible, isChosen]);
  useEffect(() => updateChoosability(), [isChosen, structure.isChosen]);

  useEffect(() => {
    setLabel(structure.label);
    setColor(structure.color);
    setIsChosen(structure.isChosen);
    setAnnotationVisibility(structure.annotationsAreVisible);
  }, [structures]);

  /**
   * Updates the structure within the structures array.
   * @returns {void}
   */
  const updateStructure = () => {
    const tmpStructures = structures;
    const tmpStructure = tmpStructures.find((s) => s.id === structure.id);
    tmpStructure.isChosen = isChosen;

    if (isLocked) return;
    tmpStructure.label = label;
    tmpStructure.color = color;
    tmpStructure.annotationsAreVisible = annotationsAreVisible;

    // Write updates
    setStructures(tmpStructures.map((s) => s));
  };

  return (
    <Grid
      sx={{
        ...other.style,
        ...other.sx,
        ...styles.structure,
        ...(isInSelectionMode
          ? { gridTemplateColumns: "auto auto 1fr auto auto auto" }
          : { gridTemplateColumns: "auto 1fr auto auto auto" }),
      }}
      onClick={() => setSelectedStructure(structure)}
      {...other}
    >
      <Checkbox
        name="CheckboxStructureChosen"
        disabled={!(structure.canBeChosen || structure.isChosen)}
        sx={{
          display: isInSelectionMode ? "block" : "none",
        }}
        color={
          structure.isChosen && !structure.canBeChosen ? "error" : "primary"
        }
        checked={isChosen}
        onChange={() => {
          setIsChosen((old) => !old);
          updateStructure();
          // update training settings
          setTrainingSettings(() => {
            trainingSettings.structures = structures
              .map((item) => {
                if (item.id === structure.id) {
                  item.isChosen = !structure.isChosen;
                }
                return item;
              })
              .filter((s) => s.isChosen);
            return trainingSettings;
          });
        }}
      />
      <Tooltip
        disableInteractive
        title={structure.isStructure ? "Structure" : "Class"}
      >
        {structure.isStructure ? (
          <StopIcon sx={styles.structureIcon} />
        ) : (
          <AccountTreeIcon sx={styles.structureIcon} />
        )}
      </Tooltip>
      <Tooltip
        sx={styles.structureElement}
        title={`${structure.totalObjectCount} rois over all`}
      >
        <TextField
          variant="standard"
          name="Textfield"
          sx={styles.inputElement}
          disabled={isLocked}
          value={label === null ? "" : label}
          onChange={(e) => setLabel(e.target.value)}
          onBlur={updateStructure}
        />
      </Tooltip>
      {!isLocked && (
        <Tooltip
          disableInteractive
          title={
            annotationsAreVisible
              ? "Hide current structure"
              : "Show current structure"
          }
        >
          <IconButton
            sx={{ ...styles.structureElement, ...styles.structureIcon }}
            onClick={(e) => {
              setAnnotationVisibility((old) => !old);
              e.stopPropagation();
            }}
          >
            {annotationsAreVisible ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </Tooltip>
      )}
      <Grid sx={styles.structureElement}>
        <SketchColorPicker
          color={color}
          handleChange={(newColor) => {
            if (isLocked) return;
            setColor(newColor);
          }}
          onClose={updateStructure}
        />
      </Grid>
      {!isLocked && (
        <StructureOptionMenu
          sx={styles.structureElement}
          structure={structure}
          structures={structures}
          setStructures={setStructures}
          triggerAction={triggerAction}
        />
      )}
    </Grid>
  );
}

StructureItem.propTypes = {
  structure: PropTypes.instanceOf(Structure).isRequired,
  structures: PropTypes.array.isRequired,
  setStructures: PropTypes.func,
  setSelectedStructure: PropTypes.func,
  triggerAction: PropTypes.func,
  isLocked: PropTypes.bool,
  isInSelectionMode: PropTypes.bool,
  updateChoosability: PropTypes.func,
  trainingSettings: PropTypes.object,
  setTrainingSettings: PropTypes.func,
};

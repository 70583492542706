import React from "react";

import { pointInside } from "../../utils/PolygonUtil";
import Tool from "./Tool";

import { Typography, TextField, FormGroup } from "@mui/material";

import SketchColorPicker from "../../../common/components/SketchColorPicker";

class TilesHistoPointCountingTool extends Tool {
  name = "TilesHistoPointCountingTool";
  selection = null;
  crosshairColor = "#000000";
  crosshairLineWidth = 2;

  setLayer(obj) {
    this.ome = obj.ome;
    this.structures = obj.structures;
    this.layer = obj.layer;
    this.roiLayers = obj.roiLayers;
    this.selectedLayer = obj.selectedLayer;
  }

  setNextTile = (roiItem) => {
    if (roiItem) {
      this.selection = roiItem;
      window.moveToRect(this.selection.roi.bounds);
    }
  };

  findSmallestTreeItem(p) {
    let minArea = Number.MAX_SAFE_INTEGER;
    let resultItem;
    for (let i = 0; i < this.roiLayers.length; i++) {
      if (this.structures[i].visible) {
        let treeItems = this.roiLayers[i].tree.search({
          minX: p.x,
          minY: p.y,
          maxX: p.x,
          maxY: p.y,
        });
        for (let treeItem of treeItems) {
          let b = treeItem.roi.bounds;

          if (pointInside(p, treeItem.roi)) {
            let bArea = (b.right - b.left) * (b.bottom - b.top);
            if (bArea < minArea) {
              minArea = bArea;
              resultItem = treeItem;
              this.selectedLayer = i;
            }
          }
        }
      }
    }
    return resultItem;
  }

  /**
   * Draw a custom cursor
   */
  drawCustomCursor(ctx, mousePosition, fkt) {
    if (this.selection) {
      // draw outer red rect that defines tile
      ctx.beginPath();
      ctx.globalAlpha = 1.0;
      ctx.strokeStyle = "#FF0000";
      ctx.lineWidth = 2 / fkt;
      let b = this.selection.roi.bounds;
      ctx.rect(b.left, b.top, b.right - b.left, b.bottom - b.top);
      ctx.stroke();
      ctx.closePath();

      // draw crosshair
      ctx.lineWidth = this.crosshairLineWidth / fkt;
      let tileWidth = b.right - b.left;
      let tileHeight = b.bottom - b.top;
      let lineLengthWidth = (tileWidth / 2) * 0.9;
      let lineLengthHeight = (tileHeight / 2) * 0.9;

      // horizontal line left
      ctx.beginPath();
      ctx.strokeStyle = this.crosshairColor;
      ctx.moveTo(b.left, b.top + tileHeight / 2);
      ctx.lineTo(b.left + lineLengthWidth, b.top + tileHeight / 2);
      ctx.closePath();
      ctx.globalAlpha = this.crosshairOpacity;
      ctx.stroke();

      // horizontal line right
      ctx.beginPath();
      ctx.strokeStyle = this.crosshairColor;
      ctx.moveTo(b.right, b.top + tileHeight / 2);
      ctx.lineTo(b.right - lineLengthWidth, b.top + tileHeight / 2);
      ctx.closePath();
      ctx.globalAlpha = this.crosshairOpacity;
      ctx.stroke();

      // vertical line top
      ctx.beginPath();
      ctx.strokeStyle = this.crosshairColor;
      ctx.moveTo(b.left + tileWidth / 2, b.top);
      ctx.lineTo(b.left + tileWidth / 2, b.top + lineLengthHeight);
      ctx.closePath();
      ctx.globalAlpha = this.crosshairOpacity;
      ctx.stroke();

      // vertical line bottom
      ctx.beginPath();
      ctx.strokeStyle = this.crosshairColor;
      ctx.moveTo(b.left + tileWidth / 2, b.bottom);
      ctx.lineTo(b.left + tileWidth / 2, b.bottom - lineLengthHeight);
      ctx.closePath();
      ctx.globalAlpha = this.crosshairOpacity;
      ctx.stroke();
    }
  }

  exit() {}

  renderConfiguration() {
    return (
      <div>
        <Typography variant="h6">{this.name}:</Typography>
        <FormGroup row>
          <Typography style={{ marginTop: "6px", marginRight: "5px" }}>
            Crosshair color:
          </Typography>
          <SketchColorPicker
            color={this.crosshairColor}
            handleChange={(color) => {
              this.crosshairColor = color;
              window.forceSidebarUpdate();
            }}
          />
        </FormGroup>

        <br />

        <FormGroup row style={{ marginBottom: "25px" }}>
          <Typography style={{ width: "150px" }}>
            Crosshair linewidth:
          </Typography>
          <TextField
            style={{ width: "40px", marginTop: "-3px" }}
            name="Textfield"
            value={this.crosshairLineWidth}
            onChange={(e) => {
              this.crosshairLineWidth = Number(e.target.value);
              window.forceSidebarUpdate();
            }}
            inputProps={{
              step: 1,
              min: 1,
              max: 30,
              type: "number",
            }}
          />
        </FormGroup>
      </div>
    );
  }
}

export default TilesHistoPointCountingTool;

// Framework components
import React, { useEffect } from "react";
import { PropTypes } from "prop-types";
import { useState } from "react";

// Material UI components
import {
  ListItemButton,
  ListItemText,
  LinearProgress,
  Button,
  ListItemSecondaryAction,
  Tooltip,
} from "@mui/material";

// HSA components
import Backend from "../../common/utils/Backend";

/**
 * List item from list models in AI Viewer.
 */
export default function AIModelListItem({
  loading,
  name,
  progress,
  onStopTraining,
  selected,
  model,
  setSelectedModel,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const [datasetProgress, setDatasetProgress] = useState(0.0);
  const [trainingEpochProgress, setTrainingEpochProgress] = useState(0.0);
  const [trainingStatus, setTrainingStatus] = useState(
    model.information.trainingStatus
  );

  const getTrainingEpochProgress = () => {
    // get training epoch progress
    if (trainingStatus == "running") {
      Backend.getTrainingEpochProgress(name, (res) => {
        setTrainingEpochProgress(parseFloat(res));
      });
    }
  };

  useEffect(() => {
    const socket = Backend.getSocket();
    // get dataset progress
    socket.on("dataset_progress", (data) => {
      const dataObj = JSON.parse(data);
      if (dataObj.model_name != name) return;
      setDatasetProgress(dataObj.progress);
    });

    // get training status
    socket.on("training_status", (data) => {
      const dataObj = JSON.parse(data);
      if (dataObj.model_name != name) return;
      setTrainingStatus(dataObj.status);
    });

    // check for training progress every 10 seconds
    getTrainingEpochProgress();
    setInterval(getTrainingEpochProgress, 10 * 1000);

    return () => {
      // Clean up the effect
      socket.disconnect();
    };
  }, []);

  const renderState = () => {
    switch (true) {
      case trainingStatus == "pending":
        return <span>Pending</span>;
      case trainingStatus == "creating dataset":
        return (
          <>
            <span>
              <span style={{ marginRight: "8px" }}>Creating Dataset</span>
              <LinearProgress
                variant="determinate"
                value={datasetProgress}
                style={{ width: "50%" }}
              />
            </span>
          </>
        );
      case trainingStatus == "running":
        return (
          <>
            <span>
              <span style={{ marginRight: "8px" }}>Training Running</span>
              <LinearProgress
                variant="determinate"
                value={Number(
                  (trainingEpochProgress /
                    model.settings.trainingParameters.epochs) *
                    100
                )}
                style={{ width: "50%" }}
              />
            </span>
          </>
        );
      case trainingStatus == "finished":
        return <span style={{ color: "green" }}>Finished Training</span>;
      case trainingStatus == "created dataset":
        return <span style={{ color: "green" }}>Created Dataset</span>;
      case trainingStatus.includes("error"):
        return (
          <Tooltip title={model.information.errorMessage.split(" - ")[0]}>
            <span style={{ color: "red" }}>Error</span>
          </Tooltip>
        );
      default:
        return "";
    }
  };

  return (
    <ListItemButton
      style={{
        borderBottom: "1px solid #ccc",
        backgroundColor: isHovered || selected ? "#f5f5f5" : "inherit",
        cursor: isHovered ? "pointer" : "default",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => setSelectedModel(name)}
      disabled={loading}
      disableRipple
    >
      <ListItemText
        primary={
          <span
            style={{
              display: "flex",
              alignItems: "center",
              userSelect: "none",
            }}
          >
            <span style={{ marginRight: "8px" }}>
              {name.replace("Custom - ", "")}
            </span>
          </span>
        }
        secondary={renderState()}
      />
      {progress > 0 && progress < 100 && (
        <ListItemSecondaryAction>
          <Button onClick={onStopTraining} color="secondary">
            Stop Training
          </Button>
        </ListItemSecondaryAction>
      )}
    </ListItemButton>
  );
}

AIModelListItem.propTypes = {
  loading: PropTypes.bool,
  name: PropTypes.string,
  state: PropTypes.oneOf(["finished", "running", "pending"]),
  progress: PropTypes.number,
  onStopTraining: PropTypes.func,
  selected: PropTypes.bool,
  model: PropTypes.object,
  setSelectedModel: PropTypes.func,
};

import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Checkbox,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { isColorLight } from "../../common/utils/Utils";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    overflow: "auto",
  },
  caseStateContainer: {
    borderRadius: 5,
    padding: 5,
    width: "100%",
    textAlign: "center",
    border: "1px solid rgba(0, 0, 0, 0.54)",
    color: "white",
  },
  tagBox: {
    // <--- in case tags will be added in the future
    display: "inline-block",
    width: 20,
    height: 20,
    borderRadius: 5,
    color: "#fff",
    border: "1px solid rgba(0, 0, 0, 0.54)",
    margin: 2,
  },
  row: {
    cursor: "pointer",
  },
});

const DynamicCaseTable = (props) => {
  const classes = useStyles();
  const { cases, onDeleteRow, columns, history } = props;

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(columns[0].CreationDateTime);
  const [columnVisibility, setColumnVisibility] = useState(
    columns.reduce(
      (acc, column) => ({ ...acc, [column.id]: column.visible }),
      {}
    )
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleColumnVisibility = (columnId) => {
    setColumnVisibility((prevColumnVisibility) => ({
      ...prevColumnVisibility,
      [columnId]: !prevColumnVisibility[columnId],
    }));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const stableSort = (array, order, orderBy) => {
    const sortedArray = array.sort((a, b) => {
      const orderByA =
        typeof a[orderBy] === "object" ? a[orderBy].length : a[orderBy];
      const orderByB =
        typeof b[orderBy] === "object" ? b[orderBy].length : b[orderBy];
      if (order === "asc") {
        return orderByA < orderByB ? -1 : 1;
      } else {
        return orderByA < orderByB ? 1 : -1;
      }
    });
    return sortedArray;
  };

  const handleRowClick = (row) => {
    history.push("/file_view/" + row.Id);
  };
  return (
    <TableContainer className={classes.root} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(
              (column) =>
                columnVisibility[column.id] && (
                  <TableCell key={column.id + "_headCell"}>
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={() => handleRequestSort(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                )
            )}
            <TableCell key={"sortIcon_headCell"}>
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {columns.map((column) => (
                  <MenuItem
                    key={column.id + "_filterMenuItem"}
                    onClick={() => handleColumnVisibility(column.id)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        name="CheckboxColumnVisibility"
                        edge="start"
                        checked={columnVisibility[column.id]}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText primary={column.label} />
                  </MenuItem>
                ))}
              </Menu>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(cases, order, orderBy).map((row, idx) => {
            return (
              <TableRow
                key={idx}
                hover
                className={classes.row}
                onClick={() => handleRowClick(row)}
              >
                {columns.map((column) => {
                  const cellIsVisible = columnVisibility[column.id];
                  if (!cellIsVisible) return null;
                  let value = row[column.id];
                  if (column.items.length > 0) {
                    const item = column.items.find(
                      (item) => item.label === value
                    );
                    if (
                      item &&
                      item.color !== "#ffffff" &&
                      item.color !== "#fff"
                    ) {
                      value = (
                        <div
                          className={classes.caseStateContainer}
                          style={{
                            background: item.color,
                            color: isColorLight(item.color)
                              ? "inherit"
                              : "white",
                          }}
                        >
                          {row[column.id]}
                        </div>
                      );
                    }
                  }
                  if (value === null) value = "";
                  return (
                    <TableCell key={column.id + "_SortCell"}>
                      {typeof value === "object" && value.type !== "div"
                        ? value.length
                        : value}
                    </TableCell>
                  );
                })}
                <TableCell>
                  <IconButton onClick={(e) => props.onEditRow(e, row)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => onDeleteRow(row)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

DynamicCaseTable.propTypes = {
  cases: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  onDeleteRow: PropTypes.func.isRequired,
  onEditRow: PropTypes.func.isRequired,
  history: PropTypes.object,
};

export default withRouter(DynamicCaseTable);

// Framework imports
import React, { useState, useEffect, useContext, useRef } from "react";
import { PropTypes } from "prop-types";

// Package imports
import { v4 as uuidv4 } from "uuid";

// HSA imports
import Backend from "../../common/utils/Backend";
import { roundToDecimal } from "../../common/utils/Utils";

import Structure from "../../common/components/Structure";
import {
  AudioRoi,
  ModificationStatus,
  RoiType,
  AnnotationAction,
  saveAnnotations,
  exportAnnotations,
  importAnnotations,
  deleteAllAnnotations,
} from "../../common/components/RoiTypes";
import { AudioViewerContext } from "../AudioViewer";
import { AudioFileContext } from "./AudioFileContainer";
import { Tooltip } from "@mui/material";

/**
 * Collected (reusable) styles for page elements.
 */
const styles = {
  root: {
    width: "100%",
    height: "30px",
    display: "grid",
    gridTemplateColumns: "auto 1fr",
  },
  label: {
    padding: "0 5px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  canvasContainer: {
    width: "100%",
    userSelect: "none",
    overflow: "hidden",
  },
};

/**
 * Distance in px to determine if something is nearby.
 */
const DELTA = 5;

/**
 * Displays audio annotations of a given audio file / stream per structure.
 * Allows the creation, modification, and deletion of annotations per structure.
 * @param {object} file The file being annotated.
 * @param {object} structure The structure being annotated.
 * @param {bool} saveNow Request to save the annotations of this item.
 * @param {function} reportSaveStatus A way to report the current save status back. Receives (bool isSaved).
 * @param {bool} removeOverlaps Optional. Whether or not to remove overlaps between neighboring annotations. Defaults to true.
 * @param {number} mouseX Current horizontal mouse position relative to drawing canvas.
 * @param {function} setMouseX Set the current horizontal mouse position relative to drawing canvas. Receives (number mouseX)
 * @param {bool} isVisible Optional. Whether the annotation item is visible or not. Defaults to true.
 * @returns React node for AudioAnnotationItem
 */
export default function AudioAnnotationItem(props) {
  const {
    file,
    structure,
    saveNow,
    reportSaveStatus,
    removeOverlaps = true,
    mouseX,
    setMouseX,
    isVisible = true,
    visibleTimeRange,
    ...other
  } = props;

  const prevTimeRange = useRef(visibleTimeRange);

  // External project and file information
  const AudioViewer = useContext(AudioViewerContext);
  const { containerWidth } = AudioViewer;
  const AudioFile = useContext(AudioFileContext);
  const { isDrawing, setIsDrawing } = AudioViewer;

  // Canvas where annotations are drawn.
  const canvasContainer = React.useRef();
  const canvas = React.useRef();
  const [canvasWidth, setCanvasWidth] = useState(100);
  const [cursor, setCursor] = useState("inherit");
  const [isKeyboardDrawing, setKeyboardDrawing] = useState(false);

  // Annotations
  const [annotations, setAnnotations] = useState([]);
  const data = React.useRef({
    currentAnnotation: null,
    actionState: null,
  }).current;

  //#region Effects

  // Load annotations on first render
  useEffect(() => {
    // Load annotations only for this file
    Backend.loadAnnotationsFromDB(
      AudioViewer.project.id,
      RoiType.AudioRoi,
      { fileId: file.id, structure: structure.id },
      (newRois) => setAnnotations(newRois),
      (err) =>
        window.showErrorSnackbar(
          `Could not load annotations of file ${file.label}:\n${err}`
        )
    );
  }, []);

  useEffect(() => {
    if (!isDrawing && data.actionState !== null) {
      endAnnotation();
    }
  }, [isDrawing]);

  // Ensure to update canvas when annotations are updated
  useEffect(() => {
    updateCanvas();
  }, [
    annotations,
    mouseX,
    AudioFile.timestamp,
    AudioViewer.structures,
    AudioViewer.selectedStructure,
    visibleTimeRange,
  ]);

  // Resizing
  useEffect(() => {
    handleResize();
  }, [containerWidth]);

  // Save annotations on request
  useEffect(() => {
    if (!saveNow) return;
    saveAnnotations(
      AudioViewer.project.id,
      file,
      structure,
      RoiType.AudioRoi,
      annotations,
      (newAnnos) => {
        setAnnotations(newAnnos);
        reportSaveStatus(true);
      },
      () => reportSaveStatus(false)
    );
  }, [saveNow]);

  // Perform predefined actions on request
  useEffect(() => {
    // Find relevant export tasks for this structure/file combination
    const allTasks = AudioViewer.childActions;
    const ownTasks = AudioViewer.childActions.filter(
      (a) => a.fileId === file.id && a.structureId === structure.id
    );

    // None found -> Do nothing
    if (ownTasks.length === 0) return;

    ownTasks.forEach((task) => {
      switch (task.task) {
        case AnnotationAction.Save:
          // Save all annotations of this structure to database.
          saveAnnotations(
            AudioViewer.project.id,
            file,
            structure,
            RoiType.AudioRoi,
            annotations,
            (newAnnos) => {
              setAnnotations(newAnnos);
              reportSaveStatus(true);
            },
            () => reportSaveStatus(false)
          );
          break;

        case AnnotationAction.Export:
          // Export annotations to file.
          exportAnnotations(
            AudioViewer.user.fullName,
            AudioViewer.project,
            file,
            structure,
            RoiType.AudioRoi,
            annotations
          );
          break;

        case AnnotationAction.Import:
          // Import annotations from previously exported file.
          importAnnotations(
            RoiType.AudioRoi,
            annotations,
            file,
            structure,
            (newAnnos) => {
              setAnnotations(newAnnos);
              reportSaveStatus(false);
              window.showSuccessSnackbar(
                `Successfully imported annotations for ${structure.label}`
              );
            },
            (err) => {
              window.showErrorSnackbar(err);
            }
          );
          break;

        case AnnotationAction.DeleteAll:
          // Delete all annotations of this structure.
          deleteAllAnnotations(annotations, structure, (annos) => {
            setAnnotations(annos);
            reportSaveStatus(false);
          });

          break;

        default:
          console.error(
            `Unsupported action, received ${typeof task.task}: ${task.task}`
          );
      }

      // Remove task when completed
      allTasks.splice(
        allTasks.findIndex(
          (a) =>
            a.fileId === file.id &&
            a.structureId === structure.id &&
            a.task === task.task
        ),
        1
      );
    });

    // Update global tasks
    AudioViewer.setChildActions(allTasks);
  }, [AudioViewer.childActions]);

  /**
   * Update Annotation on timestamp change.
   */
  useEffect(() => {
    if (!isKeyboardDrawing) return;
    if (!AudioViewer.isDrawing) return;
    if (!data.currentAnnotation?.x1) return;

    if (
      prevTimeRange.current &&
      prevTimeRange.current[0] !== visibleTimeRange[0]
    ) {
      const timeDeltaX = visibleTimeRange[0] - prevTimeRange.current[0];
      const pixelDeltaX =
        timeDeltaX *
        (canvasWidth / (visibleTimeRange[1] - visibleTimeRange[0]));

      updateAnnotation(
        data.currentAnnotation.x1 - pixelDeltaX,
        timestampToPx(AudioFile.timestamp)
      );
      prevTimeRange.current = visibleTimeRange;
      return;
    }

    updateAnnotation(
      data.currentAnnotation.x1,
      timestampToPx(AudioFile.timestamp)
    );
  }, [AudioFile.timestamp]);

  useEffect(() => {
    /**
     * Decide what happens on any keypress event.
     * @param {KeyboardEvent} e Keypress event.
     */
    const handleKeyPress = (e) => {
      if (AudioFile.disableKeyboardShortcuts) return;
      if (structure !== AudioViewer.selectedStructure) return;

      if (e.shiftKey) {
        // Key + Shiftkey
        switch (e.code) {
          case "Enter":
            if (AudioViewer.isDrawing) {
              // End drawing
              setKeyboardDrawing(false);
              setIsDrawing(false);
              endAnnotation();
            } else {
              // Begin drawing
              setKeyboardDrawing(true);
              setIsDrawing(true);
              data.actionState = "deleting";
              initializeAnnotation(
                timestampToPx(AudioFile.timestamp),
                "deleting"
              );
            }
            e.preventDefault();
            return;
          default:
            return;
        }
      }

      // Normal Key presses
      switch (e.code) {
        case "Enter":
          if (AudioViewer.isDrawing) {
            // End drawing
            setKeyboardDrawing(false);
            setIsDrawing(false);
            endAnnotation();
          } else {
            // Begin drawing
            setKeyboardDrawing(true);
            setIsDrawing(true);
            data.actionState = "drawing";
            initializeAnnotation(timestampToPx(AudioFile.timestamp), "drawing");
          }
          e.preventDefault();
          return;
        default:
          return;
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [AudioViewer.selectedStructure, AudioFile.timestamp, canvasWidth]);

  //#endregion
  //#region Helper functions

  /**
   * Get cursor positioon in canvas-coordinates.
   * @param {event} e Mouse event.
   * @returns {array} [x, y] position of cursor relative to canvas.
   */
  const getX = (e) => {
    const drawRect = canvas.current.getBoundingClientRect();
    const x = e.pageX - drawRect.x;
    return x;
  };

  /**
   * Check if an annotaion beginning or end is close to a horizontal mouse position.
   * @param {number} value Value to check against.
   * @param {number} x Horizontal position of cursor.
   * @returns {boolean} Whether the cursor is within the delta of the value to compare to.
   */
  const isValueNearby = (value, x) => {
    return x > value - DELTA && x < value + DELTA;
  };

  /**
   * Convert a pixel value to a timestamp proportional to the canvas width and audio duration.
   * @param {int} pxValue Pixel value of an annotation relative to its drawing canvas.
   * @returns {number} Timestamp of audio file with 3 digit precision.
   */
  const pxToTimestamp = (pxValue) => {
    return roundToDecimal(
      (pxValue / canvasWidth) * AudioFile.audio.duration(),
      3
    );
  };

  /**
   * Convert a timestamp to a pixel value proportional to the canvas width and audio duration.
   * @param {number} timestampValue Timestamp of audiofile.
   * @returns {number} Pixel value of an annotation relative to its drawing canvas.
   */
  const timestampToPx = (timestampValue) => {
    const startTime = visibleTimeRange[0];
    const endTime =
      visibleTimeRange[1] > 0
        ? visibleTimeRange[1]
        : AudioFile.audio.duration();
    const visibleDuration = endTime - startTime;
    const visibleTimeStampValue = timestampValue - startTime;
    return roundToDecimal(
      (visibleTimeStampValue / visibleDuration) * canvasWidth,
      1
    );
  };

  const timeAsPx = (timeValue) => {
    const visibleTimeStampValue = timeValue;
    return roundToDecimal(
      (visibleTimeStampValue / AudioFile.audio.duration()) * canvasWidth,
      1
    );
  };

  const isOverlapping = (anno1, anno2) => {
    // return !(anno1.maxX < anno2.minX && anno1.minX > anno2.maxX);
    return !(anno1.maxX < anno2.minX || anno1.minX > anno2.maxX);
  };

  const isInside = (anno1, anno2) => {
    return (
      Math.min(anno1.minX, anno1.maxX) > Math.min(anno2.minX, anno2.maxX) &&
      Math.max(anno1.minX, anno1.maxX) < Math.max(anno2.minX, anno2.maxX)
    );
  };

  const isValueInside = (value, anno) => {
    return value > anno.minX && value < anno.maxX;
  };

  const getCanvasWidth = () => {
    return canvasContainer.current?.getBoundingClientRect().width;
  };

  const getPixelAnnotations = () => {
    // Show nothing with invisible annotations
    if (!structure.annotationsAreVisible) return [];
    return annotations
      .filter((a) => a.modificationStatus !== ModificationStatus.Deleted)
      .map((anno) => {
        return convertToPixelAnnotation(anno);
      });
  };

  /**
   * Get start and end of a saved AudioRoi, so it can be displayed inside canvas.
   * @param {AudioRoi} anno Annotation to display inside canvas.
   * @returns {object} Annotaion consisting of minX and maxX in px.
   */
  const convertToPixelAnnotation = (anno) => {
    return {
      minX: timestampToPx(anno.startTime),
      maxX: timestampToPx(anno.endTime),
    };
  };

  /**
   * Methods to execute on canvas resize
   */
  const handleResize = () => {
    setCanvasWidth(getCanvasWidth());
    setMouseX(Math.random()); //force update
    updateCanvas();
  };
  //#endregion

  //#region Annotation Operations

  /**
   * Initializes a new annotation on its first creation.
   * @param {number} x Hozizontal mouse position in px realtive to annotation canvas.
   * @param {string} newActionState Action state of canvas. Can be drawing, deleting, grab, grabbing, null.
   * @returns
   */
  const initializeAnnotation = (x, newActionState) => {
    prevTimeRange.current = visibleTimeRange;
    updateAnnotation(x, x);
    if (newActionState !== "drawing") return;

    for (let annoIdx in annotations) {
      const audioAnno = annotations[annoIdx];
      if (audioAnno.modificationStatus === ModificationStatus.Deleted) continue;
      const anno = convertToPixelAnnotation(audioAnno);

      if (isValueNearby(anno.minX, x)) {
        updateAnnotation(anno.maxX, x);
        annotations.splice(annoIdx, 1);
        break;
      } else if (isValueNearby(anno.maxX, x)) {
        updateAnnotation(anno.minX, x);
        annotations.splice(annoIdx, 1);
        break;
      } else if (isValueInside(x, anno)) {
        updateAnnotation(anno.minX, anno.maxX);
        annotations.splice(annoIdx, 1);
        if (!isKeyboardDrawing) setCursor("grabbing");
      }
    }
  };

  /**
   * Updates an annotation with updated coordinates.
   * @param {number} x1 1st horizontal coordinate in px relative to canvas.
   * @param {number} x2 2nd horizontal coordinate in px relative to canvas.
   */
  const updateAnnotation = (x1, x2) => {
    data.currentAnnotation = {
      x1: x1,
      x2: x2,
      minX: Math.min(x1, x2),
      maxX: Math.max(x1, x2),
    };
  };

  /**
   * Convert a temporary, pixel-based annotation to an AudioRoi object and save it.
   * @param {object} tmpAnn Temporary, pixel-based annotation with minX and maxX properties.
   * @param {array} modifiedAnnotations Optional. Modified existing annotations to use instead of current annotatations. Defaults to current annotations.
   * @returns {AudioRoi} Completed AudioRoi object.
   */
  const finalizeAnnotation = (tmpAnn, modifiedAnnotations = annotations) => {
    const minX = Math.max(0, pxToTimestamp(tmpAnn.minX));
    const maxX = Math.min(
      pxToTimestamp(tmpAnn.maxX),
      AudioFile.audio.duration()
    );

    let tmpAnnotations = modifiedAnnotations;

    // Do not create zero-width annotations
    if (isNaN(minX) || isNaN(maxX) || minX === maxX) return;

    const newAnnotation = new AudioRoi(
      uuidv4(),
      file.id,
      structure.id,
      false,
      AudioViewer.user.id,
      ModificationStatus.Added,
      minX,
      maxX,
      0,
      32000,
      [0]
    );

    // Add new annotation
    tmpAnnotations.push(newAnnotation);
    setAnnotations(tmpAnnotations);

    // Mark annotations as changed
    reportSaveStatus(false);
  };

  const getAnnotationTransformed = (minX, maxX) => {
    if (visibleTimeRange[1] > 0) {
      const audioDuration = AudioFile.audio.duration();
      const deltaX = maxX - minX;
      const visibleDuration = visibleTimeRange[1] - visibleTimeRange[0];
      const factor = visibleDuration / audioDuration;
      const delatXTransofrmed = deltaX * factor;
      const minTimestampPx = timeAsPx(visibleTimeRange[0]);
      minX = minX * factor + minTimestampPx;
      maxX = minX + delatXTransofrmed;
    }
    return { minX, maxX };
  };

  const mergeOverlappingAnnotations = () => {
    let existingAnnotations = annotations;
    let { minX, maxX } = getAnnotationTransformed(
      data.currentAnnotation.minX,
      data.currentAnnotation.maxX
    );

    existingAnnotations
      .filter((a) => a.modificationStatus !== ModificationStatus.Deleted)
      .forEach((existingAnno) => {
        const anno = convertToPixelAnnotation(existingAnno);
        const transformedAnnot = getAnnotationTransformed(anno.minX, anno.maxX);
        if (isOverlapping(transformedAnnot, { minX, maxX })) {
          minX = Math.min(transformedAnnot.minX, minX);
          maxX = Math.max(transformedAnnot.maxX, maxX);
          // Delete modified existing annotation
          if (existingAnno.modificationStatus === ModificationStatus.Saved) {
            // Mark saved annotation as to delete.
            existingAnno.modificationStatus = ModificationStatus.Deleted;

            // Mark annotations as changed
            reportSaveStatus(false);
          } else {
            // Remove annotation not yet saved to database
            const existingAnnoIdx = existingAnnotations.findIndex(
              (a) => a.id === existingAnno.id
            );
            if (existingAnnoIdx > -1) {
              existingAnnotations.splice(existingAnnoIdx, 1);
            }
          }
        }
      });

    finalizeAnnotation({ minX, maxX });
  };

  const removeOverlapsFromAnnotation = () => {
    let { minX, maxX } = getAnnotationTransformed(
      data.currentAnnotation.minX,
      data.currentAnnotation.maxX
    );
    const annos = getPixelAnnotations();
    for (const anno of annos.sort((a, b) => a.minX - b.minX)) {
      let tempAnno = getAnnotationTransformed(anno.minX, anno.maxX);
      let minAnno = Math.min(tempAnno.minX, tempAnno.maxX);
      let maxAnno = Math.max(tempAnno.minX, tempAnno.maxX);
      if (isOverlapping({ minX, maxX }, tempAnno)) {
        if (isInside(tempAnno, { minX, maxX })) {
          if (minAnno - minX > 0) {
            const newAnnotation = { minX, maxX: minAnno };
            finalizeAnnotation(newAnnotation);
          }
          minX = maxAnno;
        } else {
          if (maxX > minAnno && maxX < maxAnno) {
            maxX = minAnno;
          }
          if (minX < maxAnno && minX > minAnno) {
            minX = maxAnno;
          }
        }
      }
    }
    if (maxX - minX > 0) {
      finalizeAnnotation({ minX, maxX });
    }
  };

  /**
   * Deletes existing annotations in selected area.
   * @param {object} deletionArea Object containing minX and maxX of area to delete.
   */
  const deleteAnnotation = (deletionArea) => {
    deletionArea = getAnnotationTransformed(
      deletionArea.minX,
      deletionArea.maxX
    );
    let existingAnnotations = annotations;
    const newAnnotations = [];
    for (let existingAnno of existingAnnotations.filter(
      (a) => a.modificationStatus !== ModificationStatus.Deleted
    )) {
      let anno = convertToPixelAnnotation(existingAnno);
      anno = getAnnotationTransformed(anno.minX, anno.maxX);
      if (!isOverlapping(deletionArea, anno)) continue;

      // Deleting area inside of an annotation.
      if (isInside(deletionArea, anno)) {
        newAnnotations.push({
          minX: anno.minX,
          maxX: deletionArea.minX,
        });
        newAnnotations.push({
          minX: deletionArea.maxX,
          maxX: anno.maxX,
        });
      }
      // Deleting an overlapping portion of an annotation.
      else if (!isInside(anno, deletionArea)) {
        if (isValueInside(anno.minX, deletionArea)) {
          anno.minX = deletionArea.maxX;
        } else {
          anno.maxX = deletionArea.minX;
        }
        newAnnotations.push(anno);
      }
      // Delete modified existing annotation
      if (existingAnno.modificationStatus === ModificationStatus.Saved) {
        // Mark saved annotation as to delete.
        existingAnno.modificationStatus = ModificationStatus.Deleted;

        // Mark annotations as changed
        reportSaveStatus(false);
      } else {
        // Remove annotation not yet saved to database
        existingAnnotations.splice(
          existingAnnotations.indexOf(existingAnno),
          1
        );
      }
    }

    // Save all modified annotations
    for (const newAnno of newAnnotations) {
      finalizeAnnotation(newAnno, existingAnnotations);
    }

    // Only deleted annotations ->  Save those to delete
    if (newAnnotations.length === 0) setAnnotations(existingAnnotations);
  };

  /**
   * Decides on how to proceed after finishing an annotation drawing.
   */
  const endAnnotation = () => {
    switch (data.actionState) {
      case "drawing":
      case "grab":
      case "grabbing":
        if (removeOverlaps) {
          removeOverlapsFromAnnotation();
        } else {
          mergeOverlappingAnnotations();
        }
        break;

      case "deleting":
        deleteAnnotation(data.currentAnnotation);
        break;

      default:
        break;
    }

    data.currentAnnotation = null;
    data.actionState = null;
    updateCanvas();
  };
  //#endregion

  //#region User Interactions
  const onMouseDown = (e) => {
    // Do nothing with invisible annotations
    if (!structure.annotationsAreVisible) return;

    AudioViewer.setSelectedStructure(structure);

    setIsDrawing(true);
    let newActionState = null;
    if (e.buttons === 1) {
      newActionState = "drawing";
    } else if (e.buttons === 2) {
      newActionState = "deleting";
      if (cursor !== "inherit") {
        setCursor("inherit");
      }
    }
    if (newActionState !== null) {
      data.actionState = newActionState;
    }
    const x = getX(e);
    initializeAnnotation(x, newActionState);
    e.preventDefault();
    e.stopPropagation();
  };

  /**
   * Changes the look of the cursor depending on its proximity to an annotation.
   * @param {number} x Horizontal position of cursor.
   */
  const updateCursor = (x) => {
    let updatedCursor = "inherit";
    for (const audioAnno of annotations.filter(
      (anno) => anno.modificationStatus !== ModificationStatus.Deleted
    )) {
      const anno = convertToPixelAnnotation(audioAnno);
      if (isValueNearby(anno.minX, x) || isValueNearby(anno.maxX, x)) {
        updatedCursor = "w-resize";
        break;
      } else if (isValueInside(x, anno)) {
        updatedCursor = "grab";
        break;
      }
    }
    if (cursor !== updatedCursor) {
      setCursor(updatedCursor);
    }
  };

  /**
   * Updates cursor display, begins adjusts selected annotation, and updates the canvas dsiplay.
   * @param {event} e Mouse move event.
   */
  const onMouseMove = (e) => {
    // Do nothing with invisible annotations
    if (!structure.annotationsAreVisible) {
      setCursor("inherit");
      return;
    }

    const x = getX(e);
    setMouseX(x);
    if (data.actionState === null) {
      updateCursor(x);
    }
    if (!data.actionState) return;

    if (cursor === "grabbing" || cursor === "grab") {
      const delta = e.movementX;
      updateAnnotation(
        data.currentAnnotation.minX + delta,
        data.currentAnnotation.maxX + delta
      );
    } else {
      updateAnnotation(data.currentAnnotation.x1, x);
    }
    updateCanvas();
    e.preventDefault();
  };

  /**
   * Ends the annotation-drawing process.
   * @param {event} e Mouse event when letting go of mouse.
   */
  const onMouseUp = (e) => {
    // Do nothing with invisible annotations
    if (!structure.annotationsAreVisible) return;

    setIsDrawing(false);
    endAnnotation();
    e.preventDefault();
    e.stopPropagation();
  };
  //#endregion

  /**
   * Convert all finished annotations to pixel borders.
   * @returns {array} All annotations as annotations defined by pixels boundries.
   */
  const updateCanvas = () => {
    // Ensure the canvas is present before updating.
    if (!canvas.current) return;

    const ctx = canvas.current.getContext("2d");
    const w = ctx.canvas.width;
    const h = ctx.canvas.height;
    ctx.clearRect(0, 0, w, h);
    ctx.beginPath();
    ctx.strokeStyle = "#000";
    ctx.fillStyle = structure.color;
    for (let anno of getPixelAnnotations()) {
      ctx.rect(anno.minX, 3, anno.maxX - anno.minX, h - 3);
      ctx.fill();
      ctx.stroke();
    }
    ctx.closePath();
    if (data.currentAnnotation) {
      ctx.beginPath();
      if (data.actionState === "drawing") {
        ctx.fillStyle = "transparent";
      } else if (data.actionState === "deleting") {
        ctx.fillStyle = "rgba(0,0,0,0.8)";
      }
      ctx.rect(
        data.currentAnnotation.minX,
        3,
        data.currentAnnotation.maxX - data.currentAnnotation.minX,
        h - 3
      );
      ctx.closePath();
      ctx.fill();
      ctx.stroke();
    }
  };

  if (!isVisible) return <div />;
  return (
    <div
      style={{
        ...other.style,
        ...styles.root,
        background:
          AudioViewer.selectedStructure == structure ? "white" : "darkgrey",
      }}
      onMouseDown={() => AudioViewer.setSelectedStructure(structure)}
    >
      <Tooltip title={structure.label} disableInteractive placement="right">
        <div
          style={{
            ...styles.label,
            width: Math.max(0, AudioFile.labelWidth),
            color:
              AudioViewer.selectedStructure == structure ? "black" : "grey",
          }}
        >
          {structure.label}
        </div>
      </Tooltip>
      <div ref={canvasContainer} style={styles.canvasContainer}>
        {canvasWidth && (
          <canvas
            style={{ cursor: cursor }}
            width={getCanvasWidth()}
            ref={canvas}
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
            onContextMenu={(e) => {
              e.preventDefault();
            }}
          />
        )}
      </div>
    </div>
  );
}

AudioAnnotationItem.propTypes = {
  file: PropTypes.object.isRequired,
  structure: PropTypes.instanceOf(Structure).isRequired,
  saveNow: PropTypes.bool.isRequired,
  reportSaveStatus: PropTypes.func.isRequired,
  removeOverlaps: PropTypes.bool,
  mouseX: PropTypes.number.isRequired,
  setMouseX: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
  visibleTimeRange: PropTypes.arrayOf(PropTypes.number),
};

// Framework imports
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// External packages
import DockLayout from "rc-dock";
import "rc-dock/dist/rc-dock.css";

// HSA imports
import "./AudioSideBarDock.css";
import Structure from "../../common/components/Structure";
import StructureTree from "./StructureTree";
import AudioFileTree from "./AudioFileTree";
import AiTrainingTab from "./AiTrainingTab";

const Context = React.createContext();

function AudioSideBarDock(props) {
  const [init, setInit] = useState(false);
  const [layout, setLayout] = useState({
    dockbox: {
      mode: "horizontal",
      children: [
        {
          tabs: [
            { id: "filesTab" },
            { id: "structureTreeTab" },
            { id: "aiTrainingTab" },
          ],
        },
      ],
    },
  });

  let groups = {
    custom: {
      floatable: false,
      maximizable: false,
    },
  };

  const filesTab = {
    title: <div>FILES</div>,
    content: <Context.Consumer>{() => <AudioFileTree />}</Context.Consumer>,
    group: "custom",
  };

  const structureTreeTab = {
    title: <div>ROIS</div>,
    content: (
      <Context.Consumer>
        {(value) => (
          <StructureTree
            user={value.user}
            project={value.project}
            structures={value.structures}
            setStructures={value.setStructures}
            selectedStructure={value.selectedStructure}
            setSelectedStructure={value.setSelectedStructure}
            triggerAction={value.triggerAction}
          />
        )}
      </Context.Consumer>
    ),
    group: "custom",
  };

  const aiTrainingTab = {
    title: <div>AI</div>,
    content: (
      <Context.Consumer>
        {(value) => (
          <AiTrainingTab
            project={value.project}
            structures={value.structures}
            setStructures={value.setStructures}
          />
        )}
      </Context.Consumer>
    ),
  };

  useEffect(() => {
    if (!init) {
      setInit(true);
    }
  });

  const loadTab = (data) => {
    let { id } = data;
    switch (id) {
      case "structureTreeTab":
        return { ...structureTreeTab, id };
      case "filesTab":
        return { ...filesTab, id };
      case "aiTrainingTab":
        return { ...aiTrainingTab, id };
    }
    return { ...structureTreeTab, id };
  };

  const onLayoutChange = (newLayout) => {
    let newChilds = newLayout.dockbox.children;
    let oldChilds = layout.dockbox.children;
    if (JSON.stringify(newChilds) !== JSON.stringify(oldChilds)) {
      setLayout(newLayout);
      //   props.persistentStorage.save("SidebarLayout", newLayout);
    }
  };

  return (
    <Context.Provider value={props}>
      <DockLayout
        layout={layout}
        loadTab={loadTab}
        onLayoutChange={onLayoutChange}
        groups={groups}
        style={{
          position: "relative",
        }}
      />
    </Context.Provider>
  );
}

export default AudioSideBarDock; //withPersistentStorage(AudioSideBarDock);

AudioSideBarDock.propTypes = {
  persistentStorage: PropTypes.object,
  project: PropTypes.object.isRequired,
  user: PropTypes.object,
  structures: PropTypes.array,
  setStructures: PropTypes.func,
  selectedStructure: PropTypes.instanceOf(Structure),
  setSelectedStructure: PropTypes.func,
  triggerAction: PropTypes.func,
};

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
  DatasetTypeNames,
  LossFunctionNames,
  MetricsNames,
  OptimizerNames,
} from "../../../common/components/AITrainingSettings";
import Divider from "@mui/material/Divider";
import { Grid } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LinearProgress from "@mui/material/LinearProgress";
import { makeStyles } from "@mui/styles";
import MemoryIcon from "@mui/icons-material/Memory";
import PropTypes from "prop-types";
import React from "react";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import TuneIcon from "@mui/icons-material/Tune";
import Typography from "@mui/material/Typography";

// Styles
const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
    borderRadius: "5px",
    backgroundColor: "#f5f5f5",
    paddingTop: "16px",
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingBottom: "0px",
  },
  cardWrapper: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  divider: {
    "&.MuiDivider-root": {
      backgroundColor: "rgb(66, 132, 243)",
      opacity: "1",
    },
  },
  iconAdjust: {
    position: "relative",
    top: "-2px",
  },
  loading: {
    position: "relative",
    width: "90%",
    top: "80%",
    left: "5%",
    bottom: "20%",
    right: "5%",
  },
  loadingCard: {
    height: "calc(100vh - 160px)",
    width: "100%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
    borderRadius: "5px",
    backgroundColor: "#f5f5f5",
    paddingTop: "16px",
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingBottom: "14px",
  },
  scrollable: {
    height: "calc(100vh - 140px)",
    width: "100%",
    overflowY: "auto",
  },
}));

/**
 * Place that hosts settings of selected model in AI Viewer.
 */
export default function SelectedAIModelInformation(props) {
  const { loading, selectedAIModel } = props;
  const classes = useStyles();
  // const ExportTooltip = "Export all data as .json";
  const check = "...Looking for an update...";
  // const top = "top";

  const makeTopRow = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "32px",
        }}
      >
        <Typography variant="h6">
          <InfoOutlinedIcon className={classes.iconAdjust} /> Model Information
        </Typography>
      </Box>
    );
  };

  if (loading || selectedAIModel.settings.metaData === undefined) {
    return (
      <div className={classes.scrollable}>
        <div className={classes.cardWrapper}>
          <Card className={classes.loadingCard}>
            {makeTopRow()}
            <Divider className={classes.divider} />
            <CardContent className={classes.cardContent}>
              <br />
              <div>
                <b>
                  ML Model: &quot;
                  {selectedAIModel.settings.metaData.name.replace(
                    "Custom - ",
                    ""
                  )}
                  &quot;{" "}
                </b>
                <br />
                <br />
                <b>{check}</b>
                <br />
                <br />
                <br />
                <br />
                <LinearProgress className={classes.loading} />
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }

  if (!loading && selectedAIModel.settings.metaData !== undefined) {
    return (
      <div className={classes.scrollable}>
        <div className={classes.cardWrapper}>
          <Card className={classes.card}>
            {makeTopRow()}
            <Divider className={classes.divider} />
            <CardContent className={classes.cardContent}>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  ML Model
                </Grid>
                <Grid item xs={3}>
                  &quot;
                  {selectedAIModel.settings.metaData.name.replace(
                    "Custom - ",
                    ""
                  )}
                  &quot;
                </Grid>
                <Grid item xs={3}>
                  Project
                </Grid>
                <Grid item xs={3}>
                  {selectedAIModel.settings.projectType}
                </Grid>
                <Grid item xs={3}>
                  Version
                </Grid>
                <Grid item xs={3}>
                  &quot;{selectedAIModel.settings.metaData.version}&quot;
                </Grid>
                <Grid item xs={3}>
                  Structures
                </Grid>
                <Grid item xs={3}>
                  {selectedAIModel.settings.structures
                    .map((obj) => obj.label)
                    .join(", ")}
                </Grid>
                <Grid item xs={3}>
                  Description
                </Grid>
                <Grid item xs={3}>
                  &quot;{selectedAIModel.settings.metaData.description}&quot;
                </Grid>
                <Grid item xs={3}>
                  Starting timestamp
                </Grid>
                <Grid item xs={3}>
                  {new Date(selectedAIModel.information.creationDate)
                    .toDateString()
                    .replace(" ", ", ")}
                  ,{" "}
                  {new Date(
                    selectedAIModel.information.creationDate
                  ).toLocaleTimeString()}
                </Grid>
                <Grid item xs={3}>
                  New ML Model
                </Grid>
                <Grid item xs={3}>
                  &quot;{String(selectedAIModel.settings.metaData.isNewModel)}
                  &quot;
                </Grid>
                <Grid item xs={3}>
                  Completion timestamp
                </Grid>
                <Grid item xs={3}>
                  {selectedAIModel.information.completionDate === undefined ||
                  selectedAIModel.information.completionDate === ""
                    ? ""
                    : `${new Date(selectedAIModel.information.completionDate)
                        .toDateString()
                        .replace(" ", ", ")}, ${new Date(
                        selectedAIModel.information.completionDate
                      ).toLocaleTimeString()}`}
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={3}></Grid>
              </Grid>
            </CardContent>
          </Card>

          <Grid
            container
            spacing={3}
            style={{
              marginTop: "6px",
            }}
          >
            <Grid item xs={6}>
              <Card className={classes.card}>
                <Typography variant="h6">
                  <SettingsApplicationsIcon className={classes.iconAdjust} />{" "}
                  Model Parameters
                </Typography>
                <Divider className={classes.divider} />
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      Dataset Type
                    </Grid>
                    <Grid item xs={6}>
                      {
                        DatasetTypeNames[
                          selectedAIModel.settings.datasetParameters.datasetType
                        ]
                      }
                    </Grid>
                    <Grid item xs={6}>
                      Input Channels
                    </Grid>
                    <Grid item xs={6}>
                      {String(
                        selectedAIModel.settings.modelParameters.inputChannels
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      Spatial Dimensions
                    </Grid>
                    <Grid item xs={6}>
                      {String(
                        selectedAIModel.settings.modelParameters.spatialDims
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      Image Width
                    </Grid>
                    <Grid item xs={6}>
                      {String(
                        selectedAIModel.settings.modelParameters.imageWidth
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      Image Height
                    </Grid>
                    <Grid item xs={6}>
                      {String(
                        selectedAIModel.settings.modelParameters.imageHeight
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      Number of Classes
                    </Grid>
                    <Grid item xs={6}>
                      {String(
                        selectedAIModel.settings.modelParameters.numberOfClasses
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      Pyramid Level
                    </Grid>
                    <Grid item xs={6}>
                      {String(
                        selectedAIModel.settings.modelParameters.pyramidLevel
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={6}>
              <Card className={classes.card}>
                <Typography variant="h6">
                  <TuneIcon className={classes.iconAdjust} /> Training
                  Parameters
                </Typography>
                <Divider className={classes.divider} />
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      Epochs
                    </Grid>
                    <Grid item xs={6}>
                      {selectedAIModel.settings.trainingParameters.epochs}
                    </Grid>
                    <Grid item xs={6}>
                      Early Stopping
                    </Grid>
                    <Grid item xs={6}>
                      {
                        selectedAIModel.settings.trainingParameters
                          .earlyStopping
                      }
                    </Grid>
                    <Grid item xs={6}>
                      Batch Size
                    </Grid>
                    <Grid item xs={6}>
                      {selectedAIModel.settings.trainingParameters.batchSize}
                    </Grid>
                    <Grid item xs={6}>
                      Metrics
                    </Grid>
                    <Grid item xs={6}>
                      {selectedAIModel.settings.trainingParameters.metrics
                        .map((metric) => MetricsNames[metric])
                        .join(", ")}
                    </Grid>
                    <Grid item xs={6}>
                      Loss Functions
                    </Grid>
                    <Grid item xs={6}>
                      {selectedAIModel.settings.trainingParameters.lossFunctions
                        .map((lossFunction) => LossFunctionNames[lossFunction])
                        .join(", ")}
                    </Grid>
                    <Grid item xs={6}>
                      Optimizers
                    </Grid>
                    <Grid item xs={6}>
                      {
                        OptimizerNames[
                          selectedAIModel.settings.trainingParameters.optimizer
                        ]
                      }
                      {OptimizerNames[
                        selectedAIModel.settings.trainingParameters.optimizer
                      ] === "AdamW"
                        ? "(eight)"
                        : ""}
                    </Grid>
                    <Grid item xs={6}>
                      Learning Rate
                    </Grid>
                    <Grid item xs={6}>
                      {String(
                        selectedAIModel.settings.trainingParameters.learningRate
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Card
            className={classes.card}
            style={{
              marginTop: "34px",
            }}
          >
            <Typography variant="h6">
              <MemoryIcon className={classes.iconAdjust} /> System Information
            </Typography>
            <Divider className={classes.divider} />
            <CardContent className={classes.cardContent}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  OS
                </Grid>
                <Grid item xs={6}>
                  {selectedAIModel.information.systemInformation.os}
                </Grid>
                <Grid item xs={6}>
                  CPU
                </Grid>
                <Grid item xs={6}>
                  {selectedAIModel.information.systemInformation.cpu}
                </Grid>
                <Grid item xs={6}>
                  GPU
                </Grid>
                <Grid item xs={6}>
                  {selectedAIModel.information.systemInformation.gpu}
                </Grid>
                <Grid item xs={6}>
                  RAM
                </Grid>
                <Grid item xs={6}>
                  {String(selectedAIModel.information.systemInformation.ram)}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <div></div>;
}

SelectedAIModelInformation.propTypes = {
  loading: PropTypes.bool,
  selectedAIModel: PropTypes.object,
};

import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";

import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const styles = {
  root: {},
  selectContainer: {
    margin: "10px 0",
  },
};

class CustomListSelector extends React.Component {
  render() {
    const { classes, optionsList, selectedIdx, onChange } = this.props;
    return (
      <div className={classes.root}>
        <FormControl
          className={classes.selectContainer}
          component="fieldset"
          sx={{ m: 1, minWidth: 150 }}
          margin="dense"
          fullWidth
        >
          <InputLabel htmlFor={optionsList[selectedIdx]}>
            {this.props.label}
          </InputLabel>
          <Select
            name="SelectListItem"
            label={this.props.label}
            value={selectedIdx}
            onChange={(e) => onChange(e.target.value)}
          >
            {optionsList.map((value, idx) => (
              <MenuItem key={idx} value={idx}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }
}

CustomListSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  selectedIdx: PropTypes.number.isRequired,
  optionsList: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(CustomListSelector);

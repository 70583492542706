import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
// define the component's styling
const styles = () => ({
  resizesBorder: {
    height: "100%",
    width: "100%",
    cursor: "col-resize",
  },
  //invisible over whole screen, so mouse is always tracked
  overlayBorder: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 9999999,
    cursor: "col-resize",
  },
});

class LocalVerticalResizeBorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resizeMode: false,
      borderWidth: 8,
      newWidth: props.targetWidth,
    };
  }

  handleMouseDown = (e) => {
    this.setState({ resizeMode: true, lastPageX: e.pageX, initPageX: e.pageX });
  };

  handleMouseMove = (e) => {
    e.preventDefault();
    if (!this.state.resizeMode) return;

    const deltaX = this.props.leftBorder
      ? this.state.lastPageX - e.pageX
      : e.pageX - this.state.lastPageX;
    const newWidth = this.state.newWidth + deltaX;

    // No min set
    if (this.props.min === undefined) {
      this.setState({ lastPageX: e.pageX, newWidth });
      return;
    }

    if (newWidth > this.props.min) {
      this.props.resizeWidth(newWidth);
    } else if (this.props.targetWidth > this.props.min) {
      this.props.resizeWidth(this.props.min);
    }

    this.setState({ lastPageX: e.pageX, newWidth });
  };

  handleMouseEnd = () => {
    if (!this.state.resizeMode) return;

    this.setState({ resizeMode: false });
    this.props.resizeWidth(this.props.targetWidth);
  };

  render() {
    const { classes } = this.props;
    //const { borderWidth } = this.state;
    return (
      <div
        style={{
          height: "100%",
          width: 8,
          zIndex: 1,
        }}
      >
        <div
          className={
            this.state.resizeMode
              ? classes.overlayBorder
              : classes.resizesBorder
          }
          onMouseDown={(e) => this.handleMouseDown(e)}
          onMouseMove={(e) => this.handleMouseMove(e)}
          onMouseLeave={(e) => this.handleMouseEnd(e)}
          onMouseUp={(e) => this.handleMouseEnd(e)}
        ></div>
      </div>
    );
  }
}

// define the component's interface
LocalVerticalResizeBorder.propTypes = {
  classes: PropTypes.object.isRequired,
  resizeWidth: PropTypes.func.isRequired,
  targetWidth: PropTypes.number.isRequired,
  min: PropTypes.number,
  leftBorder: PropTypes.bool,
};

export default withStyles(styles)(LocalVerticalResizeBorder);

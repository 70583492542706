import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import {
  Grid,
  FormControl,
  TextField,
  Typography,
  Slider,
  IconButton,
} from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";

const styles = {
  root: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
  },
  sliderContainer: {
    margin: 0,
    width: "100%",
    marginTop: -16,
  },
  input: {
    width: 55,
  },
  iconButton: {
    height: 40,
    marginTop: 16,
  },
};

class CustomSlider extends React.Component {
  render() {
    const { params, classes } = this.props;
    return (
      <div className={classes.root}>
        <div>
          <FormControl key={params.key} component="fieldset" fullWidth>
            <Typography gutterBottom>{params.label} </Typography>
            <Grid
              className={classes.sliderContainer}
              container
              spacing={2}
              alignItems="center"
            >
              <Grid item xs>
                <Slider
                  min={this.props.min}
                  max={this.props.max}
                  step={params.step}
                  value={params.value}
                  onChange={(e) => {
                    params.onSliderChange(e.target.value);
                  }}
                  onDoubleClick={params.onDoubleClick}
                  onChangeCommitted={params.onSliderchangeComitted}
                />
              </Grid>
              <Grid item>
                <TextField
                  className={classes.input}
                  name="Textfield"
                  variant="standard"
                  size="small"
                  value={params.value}
                  margin="dense"
                  error={params.error}
                  onChange={(e) => {
                    params.onChange(parseFloat(e.target.value));
                  }}
                  type="number"
                  inputProps={{
                    step: params.step,
                    min: this.props.min,
                    max: this.props.max,
                    role: "numberInput",
                  }}
                />
              </Grid>
            </Grid>
          </FormControl>
        </div>
        <IconButton
          className={classes.iconButton}
          onClick={() => window.openWarningDialog(params.info)}
        >
          <InfoIcon />
        </IconButton>
      </div>
    );
  }
}

CustomSlider.propTypes = {
  classes: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default withStyles(styles)(CustomSlider);

// Framework imports
import React, { useState, createContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

// HSA KIT Components
import FileRenderer from "./FileRenderer";
import FileToolBar from "./FileToolBar";
import ImagePreview from "./ImagePreview";
import Foldertree from "./Foldertree.jsx";
import Backend from "../common/utils/Backend";
import LocalVerticalResizeBorder from "../common/components/LocalVerticalResizeBorder";

import { withPersistentStorage } from "../viewer/contexts/PersistentStorageContext";
import { withTiles } from "../viewer/contexts/TilesContext";

import { Tooltip, IconButton } from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
// import LinkIcon from "@mui/icons-material/Link";
// import LinkOffIcon from "@mui/icons-material/LinkOff";

/**
 * Collected (reusable) styles for page elements.
 */
const styles = {
  root: {
    width: "100%",
    height: "100%",
    background: "#EBEBEB",
    overflow: "hidden",
    position: "relative",
    display: "grid",
    gridTemplateColumns: "1fr auto 5px auto 5px auto",
  },
  dropfieldRoot: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0, 0, 0, 0.514)",
    color: "rgb(255, 255, 255)",
    zIndex: 100,
    pointerEvents: "none",
    border: "dashed white 4px",
    textAlign: "center",
    paddingTop: "calc(50vh - 74px)",
    fontSize: "20px",
  },
  rendererGridRoot: {
    background: "#EBEBEB",
    position: "relative",
    width: "100%",
    height: "100%",
    overflowY: "inherit",
    overflowX: "inherit",
  },
  splitscreenGridRoot: {
    flexGrow: 0,
    margin: 0,
    position: "relative",
    background: "#EBEBEB",
  },
  activeIndicatorBorder: {
    position: "absolute",
    pointerEvents: "none",
    zIndex: 100,
    height: "100%",
    width: "100%",
    border: "solid #0673C1 4px",
  },
  excludeButton: {
    position: "absolute",
    top: 5,
    right: 5,
    zIndex: 100,
  },
  attachButton: {
    position: "absolute",
    top: 5,
    right: 40,
    zIndex: 100,
    color: "#666",
  },
};

export const FileViewerContext = createContext();

//to fix:
//-replace objects with maps (maybe)
//-check viewer functionalities
//-persistent storage
//-scene switch
//-chaining??
//-fullscreen??
//-histogram??
//-file compatibilty ~zstack
//-highlight of splitscreenfile in preview
//-screenshot data gathering?
//-remove/rewrite renderer code!?

/**
 * Overall parent component for File Viewer.
 * This component contains all parts of the File viewer and will be called with the /File_view route.
 * @returns React Object for File Viewer.
 */
function FileViewer(props) {
  const [selectedFolder, setSelFolder] = useState(null);
  const [selectedFile, setSelFile] = useState(null);
  const [previewWidth, setPreviewWidth] = useState(210);
  const [oldPreviewWidth, setOldPreviewWidth] = useState(0);
  const [treeWidth, setTreeWidth] = useState(300);
  const [oldTreeWidth, setOldTreeWidth] = useState(0);
  const [splitscreenFileIds, setSplitscreenFileIds] = useState([]);
  const [activeFileId, setActiveFileId] = useState(null);
  const [showSplitscreenDropzone, setShowSplitscreenDropzone] = useState(false);
  const [containerKey, setContainerKey] = useState(new Date().getTime());
  const [rendererDict, setRendererDict] = useState({});
  const [zoomObjectDict, setZoomObjectDict] = useState({});

  const context = {
    selectedFolder,
    setSelFolder,
    selectedFile,
    setSelFile,
    previewWidth,
    setPreviewWidth,
    oldPreviewWidth,
    setOldPreviewWidth,
    treeWidth,
    setTreeWidth,
    oldTreeWidth,
    setOldTreeWidth,
    activeFileId,
    splitscreenFileIds,
    rendererDict,
    setRendererDict,
    setContainerKey,
    zoomObjectDict,
    setZoomObjectDict,
    updateDimensions,
  };

  let mousePosition = {
    mouseX: 0,
    mouseY: 0,
  };

  useEffect(() => {
    window.addEventListener("resize", () => updateDimensions());
    window.addEventListener("keydown", keyDown);
    return () => {
      window.removeEventListener("resize", () => updateDimensions());
      window.addEventListener("keydown", keyDown);
    };
  }, []);

  useEffect(() => {
    if (selectedFile === null) return;
    handleFileChange(false, selectedFile);
    updateDimensions();
  }, [selectedFile]);

  useEffect(() => {
    document.onmousemove = (e) => {
      mousePosition = {
        mouseX: e.pageX,
        mouseY: e.pageY,
      };
    };
  });

  const getMousePosition = () => {
    return [mousePosition.mouseX, mousePosition.mouseY];
  };

  const keyDown = (e) => {
    console.log("keydown", e);
  };

  const updateDimensions = () => {
    let savedT = 0;
    setContainerKey(new Date().getTime());
    if (rendererDict[activeFileId]?.state) {
      savedT = rendererDict[activeFileId].state.t;
    }
    if (savedT > 0 && rendererDict[activeFileId])
      rendererDict[activeFileId].updateT(savedT);
    handleSavedZoomObjects();
  };

  const handleSavedZoomObjects = () => {
    for (const fileId of splitscreenFileIds) {
      let zoomData = props.persistentStorage.load("zoomObjectFV" + fileId);
      if (zoomData) {
        setZoomObjectDict({
          ...zoomObjectDict,
          [fileId]: structuredClone(zoomData),
        });
      } else {
        setZoomObjectDict({
          ...zoomObjectDict,
          [fileId]: {
            zoomRoi: false,
            zoomLeft: 0,
            zoomRight: 0,
            zoomTop: 0,
            zoomBottom: 0,
          },
        });
      }
    }
  };

  const onSaveScreenshot = () => {
    // const file = this.state.project.files.find(
    //   (file) => file.id === this.state.activeFileId
    // );

    let img = rendererDict[activeFileId].canvas;

    // let ctx = img.getContext("2d");

    // ctx.save();
    // let a = ctx.getTransform().a;
    // let d = ctx.getTransform().d;
    // let e = ctx.getTransform().e;
    // let f = ctx.getTransform().f;
    // ctx.setTransform(1, 0, 0, 1, 0, 0);

    // let fontSize = 20;
    // ctx.font = fontSize + "px Arial";

    // // Box-Position
    // let x = 10;
    // let y = 10;
    // let width = ctx.measureText(projectName).width + 10;
    // let height = parseInt(fontSize, 10) + 10;

    // // Draw Box-Border
    // ctx.fillStyle = "black";
    // let thickness = 1;
    // ctx.fillRect(
    //   x - thickness,
    //   y - thickness,
    //   width + thickness * 2,
    //   height + thickness * 2
    // );

    // // Draw Box
    // // ctx.globalAlpha = 0.8;
    // ctx.fillStyle = "white";
    // ctx.fillRect(x, y, width, height);

    // // Add Text
    // // ctx.globalAlpha = 1;
    // ctx.textAlign = "left";
    // ctx.textBaseline = "top";
    // ctx.fillStyle = "black";
    // ctx.fillText(projectName, 15, 15);

    // ctx.setTransform(a, 0, 0, d, e, f);
    // ctx.restore();

    img = img.toDataURL("image/jpeg", 0.9);

    // if (
    //   this.state.project.type.includes("HistoPointCounting") ||
    //   this.state.project.type.includes("HistoClassification")
    // ) {
    //   const fileName = file.fileName.split(".").slice(0, -1).join(".");
    //   let lastIndex = file.sourcePath.replace(/\\/g, "/").lastIndexOf("/");
    //   const folderPath = file.sourcePath.substring(0, lastIndex + 1);
    //   Backend.saveScreenshot(
    //     folderPath,
    //     fileName,
    //     this.dataURItoBlob(img),
    //     (response) => {
    //       window.showSuccessSnackbar(
    //         "Screenshot saved here: " + response.savePath
    //       );
    //     }
    //   );
    // } else {
    let link = document.createElement("a");

    // projekt_methode_filename_SceneNr_001.png
    let screenShotName = `${selectedFile}_S.jpg`;

    link.download = screenShotName;
    link.href = img.replace("image/jpeg", "image/octet-stream");
    link.click();
    // }
  };

  const triggerZoomAction = (action, n) => {
    switch (action) {
      case "zoomFit":
        rendererDict[activeFileId].zoomFit();
        break;
      case "oneToN":
        rendererDict[activeFileId].zoomOneToN(n);
        break;
      case "increase":
        rendererDict[activeFileId].zoomDelta(-1);
        break;
      case "decrease":
        rendererDict[activeFileId].zoomDelta(1);
        break;
      default:
        break;
    }
  };

  const handleFileChange = async (dropTriggered, file) => {
    let fileId = await fetchFileId(file);
    if (dropTriggered || splitscreenFileIds.length === 0) {
      addToSplitscreen(fileId);
    } else {
      removeRenderer(activeFileId);
      changeFile(fileId);
    }
    setActiveFileId(fileId);
    updateDimensions();
  };

  const removeRenderer = (fileId) => {
    let dict = rendererDict;
    delete dict[fileId];
    setRendererDict(dict);
  };

  const fetchFileId = async (file) => {
    let fileId = await Backend.getFileUuid(file);
    if (!fileId.uuid || typeof fileId.uuid !== "string") return;
    return fileId.uuid;
  };

  const addToSplitscreen = (fileId) => {
    let fileIds = splitscreenFileIds.slice();
    fileIds.push(fileId);
    setSplitscreenFileIds(fileIds);
    updateDimensions();
  };

  const onExcludeSplitscreen = (index, e) => {
    let ids = splitscreenFileIds.slice();
    let activeId = activeFileId;

    removeRenderer(ids[index]);
    if (activeFileId === ids[index]) {
      ids.splice(index, 1);
      activeId = ids[Math.max(index - 1, 0)];
    } else {
      ids.splice(index, 1);
    }

    //persistent storage stuff
    //chain stuff
    //...
    setActiveFileId(activeId);
    setSplitscreenFileIds(ids);
    updateDimensions();
    e.stopPropagation();
  };

  const changeFile = (newFileId) => {
    let fileIds = splitscreenFileIds.slice();
    let idx = fileIds.indexOf(newFileId);

    if (idx === -1) {
      let currentIdx = fileIds.indexOf(activeFileId);
      fileIds[currentIdx] = newFileId;
      setActiveFileId(newFileId);
      setSplitscreenFileIds(fileIds);
    }
  };

  const onDropFile = (e) => {
    e.preventDefault();
    let path = e.dataTransfer.getData("Text");
    handleFileChange(true, path);
    setShowSplitscreenDropzone(false);
  };

  const setActiveView = (currentFileId) => {
    if (activeFileId === currentFileId) return;
    setActiveFileId(currentFileId);
  };

  const getSplitscreenGridLayout = () => {
    return splitscreenFileIds.length === 4
      ? 6
      : splitscreenFileIds.length > 2
      ? 4
      : splitscreenFileIds.length > 1
      ? 6
      : 12;
  };

  const getSplitscreenGridLayoutHeight = () => {
    return splitscreenFileIds.length === 4
      ? "50%"
      : splitscreenFileIds.length > 6
      ? "33.333333333%"
      : splitscreenFileIds.length > 3
      ? "50%"
      : "100%";
  };

  return (
    <FileViewerContext.Provider value={context}>
      <Grid container style={{ ...styles.root }}>
        <Grid
          container
          style={{
            ...styles.rendererGridRoot,
            flexBasis: "calc(100% - " + (treeWidth + previewWidth) + "px)",
          }}
          onDragEnter={() => {
            setShowSplitscreenDropzone(true);
          }}
          onDragLeave={() => {
            setShowSplitscreenDropzone(false);
          }}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => {
            onDropFile(e);
          }}
          key={containerKey}
        >
          {showSplitscreenDropzone && (
            <div style={{ ...styles.dropfieldRoot }}>
              Drop file here for split screen.
            </div>
          )}
          {splitscreenFileIds.map((currentFileId, index) => (
            <Grid
              item
              key={index}
              xs={getSplitscreenGridLayout()}
              style={{
                ...styles.splitscreenGridRoot,
                border: splitscreenFileIds.length > 1 && "1px solid",
                height: getSplitscreenGridLayoutHeight(),
                zIndex: currentFileId === activeFileId ? "0" : "1",
              }}
              onClick={
                splitscreenFileIds.length > 1
                  ? () => setActiveView(currentFileId)
                  : undefined
              }
            >
              <React.Fragment>
                {currentFileId === activeFileId &&
                  splitscreenFileIds.length > 1 && (
                    <div style={{ ...styles.activeIndicatorBorder }} />
                  )}
                {splitscreenFileIds.length > 1 && (
                  <Tooltip title={"Exclude Scene"}>
                    <IconButton
                      style={{ ...styles.excludeButton, color: "#666" }}
                      size="small"
                      onClick={(e) => onExcludeSplitscreen(index, e)}
                    >
                      <RemoveCircleIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {/* {splitscreenFileIds.length > 1 && (
                  <Tooltip title={"Chain Scene"}>
                    <IconButton
                      style={{ ...styles.attachButton }}
                      size="small"
                      onClick={(e) =>
                        // this.onChainSplitscreen(index, currentFileId, e)
                        console.log("chain", e)
                      }
                    >
                      {currentFileId === activeFileId ? ( // this.chainList[index]
                        <LinkIcon style={{ color: "#0673C1" }} />
                      ) : (
                        <LinkOffIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                )} */}
                <FileRenderer
                  currentFileId={currentFileId}
                  index={index}
                  getMousePosition={getMousePosition}
                />
              </React.Fragment>
            </Grid>
          ))}
        </Grid>
        <FileToolBar
          triggerZoomAction={triggerZoomAction}
          onSaveScreenshot={onSaveScreenshot}
          updateDimensions={updateDimensions}
          splitscreenCount={splitscreenFileIds.length}
          rendererRef={rendererDict[activeFileId]}
        />
        <div>
          <LocalVerticalResizeBorder
            leftBorder={true}
            targetWidth={previewWidth}
            min={0}
            resizeWidth={(newWidth) => {
              setPreviewWidth(newWidth);
            }}
          />
        </div>
        <ImagePreview />
        <div>
          <LocalVerticalResizeBorder
            leftBorder={true}
            targetWidth={treeWidth}
            min={0}
            resizeWidth={(newWidth) => {
              setTreeWidth(newWidth);
            }}
          />
        </div>
        <Foldertree />
      </Grid>
    </FileViewerContext.Provider>
  );
}

FileViewer.propTypes = {
  persistentStorage: PropTypes.object,
  tiles: PropTypes.object,
};

export default withPersistentStorage(withTiles(FileViewer));

import {
  updateDrawLayer,
  updateLayer,
  findSiblingRoiLayers,
  findSameLayer,
} from "../../utils/PolygonUtil";
import { Button } from "@mui/material";
import { Typography, FormControl, FormLabel, Slider } from "@mui/material";
import Tool from "./Tool";
import React from "react";
import OverlapConfigForm from "./ConfigForms/OverlapConfigForm";
import Backend from "../../../common/utils/Backend";

const toolStates = {
  idle: "idle",
  drawingRect: "drawingRect",
  analysing: "analysing",
  ready: "ready",
};

class RegionSAMTool extends Tool {
  preventMiddleMousePos = true; //prevent middle mouse pos from being set, read in Renderer.jsx
  toolName = "AI Segmentation Tool";
  noConfig = false;
  removeOverlap = null;
  removeOverlapSame = null;
  tempRemoveOverlap = null;
  tempRemoveOverlapSame = null;
  penOpacity = 1;
  points = [];
  lastSamCall = null;
  startRectPoint = null;
  endRectPoint = null;
  toolState = toolStates.idle;

  setLayer(obj) {
    this.ome = obj.ome;
    this.isBrightfield = obj.ome.channels[0].type === "brightfield";
    this.ctx = obj.ctx; //context
    this.canvas = obj.ctx.canvas; //canvas
    this.layer = obj.layer;
    this.roiLayers = obj.roiLayers;
    let layerResults = findSameLayer(obj.structures, obj.selectedLayer);
    this.selectedLayer = layerResults[0];
    this.parentLayer = layerResults[1];
    this.originalSelectedLayer = obj.selectedLayer;
    this.structures = obj.structures;
    this.drawLayer = obj.drawLayer;
    this.spinloader = obj.spinloader;

    const project = obj.viewerConfig_project;
    if (this.fileId !== obj.fileId) {
      this.fileId = obj.fileId;
      this.points = [];
      this.resetVisuals();
    }
    this.fileId = obj.fileId;
    this.file = obj.project.files.find((f) => f.id === this.fileId);

    if (this.removeOverlap === null) {
      this.removeOverlap = project.projectProperties["PreventOverlap"];
    }
    if (this.tempRemoveOverlap === null) {
      this.tempRemoveOverlap = project.projectProperties["PreventOverlap"];
    }
    if (this.removeOverlapSame === null) {
      this.removeOverlapSame = project.projectProperties["PreventOverlapSame"];
    }

    // set removeOverlap
    // save current settings (in tempRemoveOverlap) if structure gets changed
    if (obj.selectedLayer === 0) {
      if (!this.noConfig) {
        this.noConfig = true;
        this.tempRemoveOverlap = this.removeOverlap;
        this.tempRemoveOverlapSame = this.removeOverlapSame;
        this.removeOverlap = false;
        this.removeOverlapSame = false;
        window.forceSidebarUpdate();
      }
    } else {
      if (this.noConfig) {
        this.noConfig = false;
        this.removeOverlap = this.tempRemoveOverlap;
        this.removeOverlapSame = this.tempRemoveOverlapSame;
        window.forceSidebarUpdate();
      }
    }
    window.forceSidebarUpdate();
  }

  setPreviewRect() {}

  getPosition = () => {
    return {
      x: -this.ctx.getTransform().e,
      y: -this.ctx.getTransform().f,
    };
  };

  updateToolState = (newState) => {
    this.toolState = newState;
    window.forceSidebarUpdate();
  };

  setPolygonsWithSAM = (points = null) => {
    const isPreview = points !== null;
    if (!isPreview) {
      points = this.points;
    }
    this.lastSamCall = performance.now();
    const samData = {
      fileId: this.fileId,
      fileName: this.file?.sourcePath,
      scene: this.ome.scene,
      p1: this.startRectPoint,
      p2: this.endRectPoint,
      points: points,
    };

    this.updateToolState(toolStates.analysing);

    Backend.useSAM(samData).then((response) => {
      this.updateToolState(toolStates.ready);
      this.spinloader.hide();
      if (response.contours && response.contours.length > 0) {
        if (isPreview) {
          this.previewPolygons = response.contours;
        } else {
          this.polygons = response.contours;
        }
        this.updateDrawing();
        if (this.points.length === 1) {
          window.forceSidebarUpdate();
        }
      }
    });
  };

  updateDrawing = () => {
    this.drawLayer.regionRois = []; //only one region will be drawn to the draw layer, also needed to reduce size
    if (typeof this.polygons === "undefined" || this.points.length === 0) {
      this.polygons = [];
    }

    this.drawRegion = {
      regions: this.polygons.map((polygon) => {
        return polygon;
      }),
      inverted: false,
    };
    this.drawLayer.clear = this.clear;
    updateDrawLayer(
      this.drawLayer,
      this.drawRegion,
      false,
      this.color,
      this.subtype,
      this.name
    );
  };

  getTopLeftPoint = () => {
    return {
      x: Math.min(this.startRectPoint.x, this.endRectPoint.x),
      y: Math.min(this.startRectPoint.y, this.endRectPoint.y),
    };
  };

  getBottomRightPoint = () => {
    return {
      x: Math.max(this.startRectPoint.x, this.endRectPoint.x),
      y: Math.max(this.startRectPoint.y, this.endRectPoint.y),
    };
  };

  mouse(params) {
    let {
      event,
      p,
      color,
      subtype,
      name,
      positionInRoiLayer,
      fullyLoaded,
      renderer,
    } = params;
    this.color = color;
    this.subtype = subtype;
    this.name = name;
    this.positionInRoiLayer = positionInRoiLayer;
    this.fullyLoaded = fullyLoaded;
    this.renderer = renderer;
    if (
      event.type === "mousedown" &&
      (event.button === 0 || event.button === 2)
    ) {
      if (this.isPointOutsideRect(p)) {
        this.updateToolState(toolStates.drawingRect);
        this.startRectPoint = p;
        this.endRectPoint = p;
        this.points = [];
        this.previewPolygons = null;
      } else if (this.toolState !== toolStates.drawingRect) {
        this.lineFlag = true;
        const isForeground = event.button === 0;
        this.points.push({ x: p.x, y: p.y, isForeground });
        this.setPolygonsWithSAM();
      }
    } else if (event.type === "mousemove") {
      if (this.toolState === toolStates.drawingRect && !this.lineFlag) {
        this.endRectPoint = p;
      }
      if (
        this.lastSamCall &&
        performance.now() - this.lastSamCall > 300 &&
        this.toolState === toolStates.ready &&
        this.isDrawingpointInsideRect(p)
      ) {
        this.setPolygonsWithSAM([{ x: p.x, y: p.y, isForeground: true }]);
      }
    } else if (
      event.type === "mouseup" ||
      ((this.toolState === toolStates.drawingRect || this.lineFlag) &&
        event.type === "mouseleave")
    ) {
      if (this.isPointOutsideRect(p) && this.lineFlag) {
        this.lineFlag = false;
      } else if (this.isDrawingpointInsideRect(p)) {
        this.lineFlag = false;
        this.updateDrawing();
      } else if (this.toolState === toolStates.drawingRect) {
        this.clear = event.button === 2;

        this.endRectPoint = p;
        this.validateRect();
      }
    }
  }

  validateRect = () => {
    const minWidth = 100;
    const minHeight = 100;
    if (
      Math.abs(this.startRectPoint.x - this.endRectPoint.x) < minWidth ||
      Math.abs(this.startRectPoint.y - this.endRectPoint.y) < minHeight
    ) {
      this.startRectPoint = null;
      this.endRectPoint = null;
      this.updateToolState(toolStates.idle);
      this.resetVisuals();
      window.showWarningSnackbar("Please select a larger area");
      return;
    }
    this.updateToolState(toolStates.ready);
    this.updateDrawing();
    this.spinloader.show();
    this.setPolygonsWithSAM([]); // init image
  };

  isPointOutsideRect = (p) => {
    let isOutside = true;
    if (this.startRectPoint && this.endRectPoint) {
      let tlp = this.getTopLeftPoint();
      let brp = this.getBottomRightPoint();
      isOutside = p.x < tlp.x || p.x > brp.x || p.y < tlp.y || p.y > brp.y;
    }
    return isOutside;
  };

  isDrawingpointInsideRect = (p) => {
    let isInside = false;
    if (this.startRectPoint && this.endRectPoint) {
      let tlp = this.getTopLeftPoint();
      let brp = this.getBottomRightPoint();
      isInside = p.x > tlp.x && p.x < brp.x && p.y > tlp.y && p.y < brp.y;
    }
    return isInside;
  };

  drawRectangle = (ctx, scale) => {
    if (this.startRectPoint && this.endRectPoint) {
      let topLeftPoint = this.getTopLeftPoint();
      let w = Math.abs(this.startRectPoint.x - this.endRectPoint.x);
      let h = Math.abs(this.startRectPoint.y - this.endRectPoint.y);
      if (ctx) {
        ctx.beginPath();
        ctx.globalAlpha = 1.0;
        ctx.strokeStyle = "#0673C1";
        ctx.lineWidth = 4 / scale;
        ctx.rect(topLeftPoint.x, topLeftPoint.y, w, h);
        ctx.stroke();
        ctx.strokeStyle = "#ffffff";
        ctx.lineWidth = 2 / scale;
        ctx.rect(topLeftPoint.x, topLeftPoint.y, w, h);
        ctx.stroke();
        ctx.closePath();

        ctx.beginPath();
        ctx.fill();
        ctx.closePath();

        if (this.points.length > 0) {
          for (let point of this.points) {
            this.drawPlus(
              ctx,
              point,
              point.isForeground ? "#00ff00" : "#ff0000"
            );
          }
        }

        ctx.globalAlpha = 1.0;
      }
    }
  };

  drawRectangleCross = (ctx, mousePosition) => {
    ctx.beginPath();
    ctx.globalAlpha = 1.0;
    ctx.strokeStyle = this.isBrightfield ? "#000000" : "#ffffff";
    ctx.beginPath();
    ctx.moveTo(mousePosition.x, 0);
    ctx.lineTo(mousePosition.x, 1000000);
    ctx.moveTo(0, mousePosition.y);
    ctx.lineTo(1000000, mousePosition.y);
    ctx.stroke();
    ctx.closePath();
  };

  drawPlus = (ctx, p, color) => {
    const scale = 1 / ctx.getTransform().a;
    const radius = 10 * scale;
    ctx.beginPath();
    ctx.globalAlpha = 1.0;
    ctx.strokeStyle = color;
    ctx.lineWidth = 2 * scale;
    ctx.moveTo(p.x - radius, p.y);
    ctx.lineTo(p.x + radius, p.y);
    ctx.moveTo(p.x, p.y - radius);
    ctx.lineTo(p.x, p.y + radius);
    ctx.stroke();
    ctx.closePath();
  };

  // previewPolygons: [[x1, y1], [x2, y2], ... [xn, yn ]]
  drawPreviewPolygon = (ctx) => {
    if (this.points.length === 0 && this.previewPolygons) {
      const poly = this.previewPolygons[0];

      ctx.beginPath();

      ctx.moveTo(poly[0][0], poly[0][1]);
      for (let i = 1; i < poly.length; i++) {
        const [x, y] = poly[i];
        ctx.lineTo(x, y);
      }

      // Close the path to form a closed polygon and fill it with a semi-transparent color
      ctx.fillStyle = "rgba(0, 0, 0, 0.5)";
      ctx.fill();
      ctx.closePath();
    }
  };

  drawPoligonOutline = (ctx) => {
    if (this.polygons?.length > 0) {
      const scale = ctx.getTransform().a;
      ctx.beginPath();
      ctx.globalAlpha = 1.0;
      ctx.lineWidth = 2 / scale;
      const poly = this.polygons[0];
      ctx.moveTo(poly[0][0], poly[0][1]);
      for (let i = 1; i < poly.length; i++) {
        const [x, y] = poly[i];
        ctx.lineTo(x, y);
      }

      // Draw the polygon with the desired color
      ctx.lineWidth = 2 / scale;
      ctx.strokeStyle = this.color;
      ctx.stroke();
      ctx.closePath();
    }
  };

  drawCustomCursor(ctx, mousePosition) {
    const scale = ctx.getTransform().a;
    this.drawRectangle(ctx, scale);

    if (this.isPointOutsideRect(mousePosition)) {
      this.drawRectangleCross(ctx, mousePosition);
    } else if (this.isDrawingpointInsideRect(mousePosition)) {
      this.drawPlus(
        ctx,
        mousePosition,
        this.isBrightfield ? "#000000" : "#ffffff"
      );
    }
    this.drawPreviewPolygon(ctx);
    this.drawPoligonOutline(ctx);
  }

  onKeyDown(e) {
    if (e.code === "Enter") {
      this.onApply();
      return;
    }
    if (e.code === "Escape") {
      this.onCancel();
      return;
    }
    window.forceSidebarUpdate();
  }

  onApply = () => {
    this.drawRegion.regions = [];
    if (this.drawLayer.regionRois.length > 0) {
      this.drawRegion.regions = this.drawLayer.regionRois;

      let overlapRoiLayers = [];
      if (this.removeOverlapSame) {
        overlapRoiLayers.push(this.roiLayers[this.selectedLayer]);
      }
      if (this.removeOverlap) {
        let siblingRoiLayers = findSiblingRoiLayers(
          this.structures,
          this.selectedLayer,
          this.roiLayers
        );
        siblingRoiLayers.map((layer) => overlapRoiLayers.push(layer));
      }

      updateLayer(
        this.layer,
        this.drawRegion,
        this.clear,
        this.color,
        this.subtype,
        this.name,
        this.roiLayers[this.selectedLayer].tree,
        this.positionInRoiLayer,
        this.fullyLoaded,
        false,
        this.roiLayers[this.parentLayer],
        this.structures[this.originalSelectedLayer].id,
        overlapRoiLayers,
        this.clickedOnRoi
      );
      this.drawLayer.regionRois = [];
    }
    this.resetVisuals();
  };

  onCancel = () => {
    this.resetVisuals();
  };

  resetVisuals = () => {
    if (this.points.length === 0) {
      this.startRectPoint = null;
      this.endRectPoint = null;
      this.updateToolState(toolStates.idle);
    }
    this.points = [];
    this.polygons = [];
    this.drawLayer.regionRois = [];
    this.drawRegion = {
      regions: [],
      inverted: false,
    };
    this.previewPolygons = null;
    updateDrawLayer(
      this.drawLayer,
      this.drawRegion,
      false,
      this.color,
      this.subtype,
      this.name
    );
    window.forceSidebarUpdate();
  };

  exit() {}

  setRectWholeScene = () => {
    this.startRectPoint = { x: 0, y: 0 };
    this.endRectPoint = { x: this.ome.sizeX, y: this.ome.sizeY };
    this.spinloader.show();
    this.setPolygonsWithSAM([]);
    window.forceSidebarUpdate();
  };

  renderConfiguration() {
    return (
      <div style={{ margin: "12px" }}>
        <Typography variant="h6">{this.toolName}:</Typography>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">
            {"Line opacity = " + this.penOpacity}
          </FormLabel>
          <Slider
            min={1}
            max={10}
            value={this.penOpacity * 10}
            onChange={(e, v) => {
              this.penOpacity = v / 10;
              window.forceSidebarUpdate();
            }}
          />
        </FormControl>
        <OverlapConfigForm
          removeOverlap={this.removeOverlap}
          removeOverlapSame={this.removeOverlapSame}
          onChangeRemoveOverlap={(e) => (this.removeOverlap = e)}
          onChangeRemoveOverlapSame={(e) => (this.removeOverlapSame = e)}
        />
        <Button
          sx={{ marginBottom: "8px" }}
          fullWidth
          variant="contained"
          disabled={this.endRectPoint !== null}
          onClick={this.setRectWholeScene}
        >
          Set Rectangle for whole Image
        </Button>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            disabled={this.toolState === toolStates.idle}
            style={{ marginRight: "5px", width: "50%" }}
            variant="contained"
            color="primary"
            onClick={this.onApply}
          >
            Apply [Enter]
          </Button>
          <Button
            disabled={this.toolState === toolStates.idle}
            style={{ marginLeft: "5px", width: "50%" }}
            variant="contained"
            color="secondary"
            onClick={this.onCancel}
          >
            Cancel [Esc]
          </Button>
        </div>
      </div>
    );
  }
}

export default RegionSAMTool;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

import withStyles from "@mui/styles/withStyles";
import Backend from "../common/utils/Backend";
import { Paper } from "@mui/material";

import UserHistoryTable from "./components/UserHistoryTable";
import { convertDate } from "../common/utils/Localization";

const styles = () => ({
  root: {
    padding: 10,
    height: "100%",
  },
  paper: {
    height: "100%",
    width: 1000,
    padding: "10px 10px 25px",
    margin: "0 auto",
  },
});

function UserHistoryPage(props) {
  const [userActions, setUserActions] = useState([]);
  const [date, setDate] = useState(new Date());
  const [firstDate, setFirstDate] = useState(new Date());

  const updateTable = (date) => {
    Backend.getUserActions(date, (res) => {
      if (res.status === 403) {
        window.showErrorSnackbar("No permission to access user history");
      } else {
        setFirstDate(convertDate(res.firstDate));
        setDate(convertDate(res.date));
        setUserActions(res.userActions);
      }
    });
  };

  useEffect(() => {
    updateTable(new Date());
  }, []);
  const { classes } = props;
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <UserHistoryTable
          userActions={userActions}
          date={date}
          firstDate={firstDate}
          updateTable={updateTable}
        />
      </Paper>
    </div>
  );
}

UserHistoryPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(UserHistoryPage));

// Framework imports
import React, { useContext, useEffect, useRef, useState } from "react";
import { PropTypes } from "prop-types";

// HSA imports
import AudioAnnotationItem from "./AudioAnnotationItem";
import { AudioViewerContext } from "../AudioViewer";
import { AudioFileContext } from "./AudioFileContainer";
import { parentStructures } from "../../common/components/Structure";
import { isInt } from "../../common/utils/Utils";

/**
 * Collected (reusable) styles for page elements.
 */
const styles = {
  root: {
    width: "100%",
    background: "#fff",
  },
  cursorContainer: {
    position: "absolute",
    display: "grid",
    top: 0,
    height: "100%",
    width: "100%",
    pointerEvents: "none",
  },
  cursor: {
    zIndex: 10,
    position: "relative",
    top: 0,
    height: "100%",
    width: 0,

    borderLeft: "2px solid black",
  },
};

/**
 * Displays audio annotations of a given audio file / stream.
 * Allows the creation, modification, and deletion of annotations.
 * @param {object} file The file being annotated.
 * @returns React node for AudioAnnotations
 */
export default function AudioAnnotations(props) {
  const { file, labelWidth, timestampPosition, visibleTimeRange, ...other } =
    props;

  const AudioViewer = useContext(AudioViewerContext);
  const AudioFile = useContext(AudioFileContext);
  const structuresRef = useRef(AudioViewer.structures);
  const selectedStructureRef = useRef(AudioViewer.selectedStructure);
  const [savedStructures, setSavedStructures] = useState({});
  const [mouseX, setMouseX] = useState(0);
  const disableKeyboardShortcutsRef = useRef(
    AudioFile.disableKeyboardShortcuts
  );

  // Initial load and unload
  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, []);

  useEffect(() => {
    disableKeyboardShortcutsRef.current = AudioFile.disableKeyboardShortcuts;
  }, [AudioFile.disableKeyboardShortcuts]);

  // Update selectedStructure for handler added on start
  useEffect(() => {
    selectedStructureRef.current = AudioViewer.selectedStructure;
  }, [AudioViewer.selectedStructure]);

  // Update structures for handler added on start
  useEffect(() => {
    structuresRef.current = AudioViewer.structures;
  }, [AudioViewer.structures]);

  //Update structure save status array on change of structures
  useEffect(() => {
    setSavedStructures((prevSaveStatus) => {
      let newSaveStatus = prevSaveStatus;
      AudioViewer.structures.forEach((s) => {
        // Structures begin as saved
        newSaveStatus[s.id] =
          s.id in newSaveStatus ? newSaveStatus[s.id] : true;
      });
      return newSaveStatus;
    });
  }, [AudioViewer.structures]);

  useEffect(() => {}, [visibleTimeRange]);

  /**
   * Decide what happens on any keypress event.
   * @param {KeyboardEvent} e Keypress event.
   */
  const handleKeyPress = (e) => {
    if (disableKeyboardShortcutsRef.current) return;
    switch (e.code) {
      case "ArrowUp":
        // Select structure above currently selected
        switchStructure(-1);
        break;
      case "ArrowDown":
        // Select structure below currently selected
        switchStructure(1);
        break;
      default:
        return;
    }
  };

  /**
   * Sets another structure as the selected one, relative to the currently selected.
   * Loops around at end/beginning.
   * @param {int} direction The direction to look for the next structure, relative to the currently selected.
   */
  const switchStructure = (direction) => {
    if (!isInt(direction)) {
      throw TypeError(
        `direction must be of type int, received ${typeof direction}: ${direction}`
      );
    }
    if (selectedStructureRef.current === null) {
      if (!structuresRef.current[0]) {
        window.showWarningSnackbar("No structures present.");
        return;
      }

      AudioViewer.setSelectedStructure(structuresRef.current[0]);
      return;
    }

    const currentlySelectedIndex = structuresRef.current.findIndex(
      (s) => selectedStructureRef.current.id === s.id
    );

    // Loop around at end/beginning.
    const newlySelectedStructure =
      structuresRef.current[
        (structuresRef.current.length +
          ((currentlySelectedIndex + direction) %
            structuresRef.current.length)) %
          structuresRef.current.length
      ];
    AudioViewer.setSelectedStructure(newlySelectedStructure);
  };

  /**
   * Updates the status of structures when modifying and/or saving.
   * @param {int} structureId Structure id of status to update.
   * @param {bool} structureSaveStatus New save status. true = saved, false = not saved.
   */
  const reportStructureSaveStatus = (structureId, structureSaveStatus) => {
    let tmpStatus = savedStructures;
    tmpStatus[structureId] = structureSaveStatus;
    setSavedStructures(tmpStatus);

    // Check if any structure is unsaved
    const allStructuresSaved = !Object.values(tmpStatus).some((val) => !val);

    // Mark file as not fully saved
    AudioViewer.reportFileSaveStatus(file.id, allStructuresSaved);
  };

  return (
    <div style={{ ...other.style, ...styles.root, position: "relative" }}>
      {AudioFile.audio !== null &&
        AudioViewer.structures.map((structure, idx) => {
          // Structures are visible when all their parents are unfolded.
          // Top-level structures are always visble.
          const parents = parentStructures(structure, AudioViewer.structures);
          parents.pop(); // remove structure itself
          const isVisible =
            structure.parentId === null || parents.every((p) => p.isUnfolded);

          return (
            <AudioAnnotationItem
              key={idx}
              file={file}
              structure={structure}
              setMouseX={setMouseX}
              mouseX={mouseX}
              saveNow={
                AudioViewer.saveNow &&
                (savedStructures ? !savedStructures[structure.id] : false)
              }
              reportSaveStatus={(status) =>
                reportStructureSaveStatus(structure.id, status)
              }
              removeOverlaps={AudioViewer.excludeToolActive}
              isVisible={isVisible}
              visibleTimeRange={visibleTimeRange}
            />
          );
        })}

      {/* Local Cursor over all audio file elements */}
      <div
        style={{
          ...styles.cursorContainer,
          gridTemplateColumns: `auto 1fr`,
        }}
      >
        <div style={{ width: labelWidth - 1 }} />
        <div
          style={{
            ...styles.cursor,
            left: `${timestampPosition * 100}%`,
          }}
        />
      </div>
    </div>
  );
}

AudioAnnotations.propTypes = {
  file: PropTypes.object.isRequired,
  disableKeyboardShortcuts: PropTypes.bool,
  labelWidth: PropTypes.number,
  hoverBarVisible: PropTypes.bool,
  hoverPositionX: PropTypes.number,
  timestampPosition: PropTypes.number,
  visibleTimeRange: PropTypes.array,
};

import Tool from "./Tool";
import React from "react";
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Typography,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Backend from "../../../common/utils/Backend";

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

class StreamViewTool extends Tool {
  name = "Stream";
  includeBaseROI = true;
  activeTab = 0;
  isStreaming = false;
  streamUrl = "";

  setLayer(obj) {
    this.ctx = obj.ctx;
    this.project = obj.project;
    this.streamUrl =
      "https://stream.hs-analysis.com?target=" +
      this.project.id +
      "&name=" +
      this.project.name +
      " (" +
      this.project.viewerConfig.project.label +
      ")";
    this.rendererDict = obj.rendererDict;
    this.fileId = obj.fileId;
  }

  setPreviewRect() {}

  handleChange = () => {};

  exit() {}

  uploadRendererContent = () => {
    let img = this.rendererDict[this.fileId].canvas;
    img = img.toDataURL("image/jpeg", 0.9);
    Backend.streamRenderer(dataURItoBlob(img), this.project.id, () => {
      if (this.isStreaming) {
        setTimeout(this.uploadRendererContent, 10);
      }
    });
  };

  toggleStream = () => {
    if (this.isStreaming) {
      this.isStreaming = false;
    } else {
      this.isStreaming = true;
      this.uploadRendererContent();
    }
    window.forceSidebarUpdate();
  };

  handleCopyLink = () => {
    navigator.clipboard.writeText(this.streamUrl);
    window.showSuccessSnackbar("Stream link copyied to clipboard!");
  };

  renderConfiguration() {
    return (
      <div>
        <Typography variant="h6">{this.name}:</Typography>
        <FormControl fullWidth sx={{ m: 1, margin: 0 }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">
            Stream link
          </InputLabel>
          <OutlinedInput
            fullWidth
            type="text"
            value={this.streamUrl}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="copy link to clipboard"
                  onClick={this.handleCopyLink}
                  edge="end"
                >
                  <ContentCopyIcon />
                </IconButton>
              </InputAdornment>
            }
            label="Stream link"
          />
        </FormControl>
        <Button
          style={{ marginTop: "5px" }}
          fullWidth
          variant="contained"
          color="primary"
          onClick={this.toggleStream}
        >
          {this.isStreaming ? (
            <React.Fragment>
              <StopIcon /> Stop Stream
            </React.Fragment>
          ) : (
            <React.Fragment>
              <PlayArrowIcon /> Start Stream
            </React.Fragment>
          )}
        </Button>
      </div>
    );
  }
}

export default StreamViewTool;

// Framework imports
import React, {
  useContext,
  useState,
  useEffect,
  createContext,
  useRef,
} from "react";
import { PropTypes } from "prop-types";

// External packages
import { Grid, Typography, Tooltip } from "@mui/material";
import { Howl } from "howler";

// HSA Components
import Backend from "../../common/utils/Backend";

// Audio Viewer Components
import AudioAnnotations from "./AudioAnnotations";
import AudioControlBar from "./AudioControlBar";
import MagnitudeSpectrogram from "./MagnitudeSpectrogram";
import { AudioViewerContext } from "../AudioViewer";

/**
 * Collected (reusable) styles for page elements.
 */
// const styles = {
//   root: {
//     width: "100%",
//     position: "relative",
//     zIndex: 1,
//   },
// };

/**
 * The step called within requestAnimationFrame to update the playback position.
 */
const step = (audio, callback) => {
  // Determine our current seek position.
  var seek = audio.seek() || 0;
  callback(seek);

  // If the audio is still playing, continue stepping.
  if (audio.playing()) {
    setTimeout(() => step(audio, callback), 30);
  }
};

/**
 * Makes selected data available for all children of AudioFileContainer.
 * Used for information that must be synced over all components per audio file,
 * such as the file itself, the playback position, and annotations.
 */
export const AudioFileContext = createContext();

/**
 * Contains all visualisation elements of a audio file / stream.
 * @param {object} file The file being used.
 * @param {int} labelWidth Optional. The space to keep to the left for labels. Defaults to 100.
 * @returns React node for AudioAnnotationItem
 */
export default function AudioFileContainer(props) {
  const { file, labelWidth = 100 } = props;
  const AudioViewer = useContext(AudioViewerContext);

  // Media
  const audioFileSrc = useRef(null);
  const audio = useRef(null);
  const waveSurfer = useRef(null);
  const [timestamp, setTimestamp] = useState(0);
  const [timestampPosition, setTimestampPosition] = useState(0);
  const [visibleTimeRange, setVisibleTimeRange] = useState([0, 0]);

  // Layout
  const container = useRef();
  const { containerWidth } = AudioViewer;
  const [disableKeyboardShortcuts, setDisableKeyboardShortcuts] =
    useState(false);
  const [contentWidth, setContentWidth] = useState();
  const [hoverPositionX, setHoverPositionX] = useState(0);
  const [hoverBarVisible, setHoverBarVisible] = useState(false);
  const forceUpdate = React.useState({})[1].bind(null, {});

  const updateVisibleTimeRange = (newValue) => {
    if(newValue[0] !== visibleTimeRange[0] || newValue[1] !== visibleTimeRange[1]){
      setVisibleTimeRange(newValue);
    }
  }

  useEffect(() => {
    if (visibleTimeRange[1] === 0 && audio.current) {
      updateVisibleTimeRange([0, audio.current.duration()]);
      return;
    }
    if (audio.current) {
      const currentDuration = audio.current.duration();
      let newPosition;
      if (audio.current) {
        newPosition =
          (timestamp - visibleTimeRange[0]) /
          (visibleTimeRange[1] - visibleTimeRange[0]);
      } else {
        newPosition = timestamp / currentDuration;
      }
      setTimestampPosition(newPosition);
    }
  }, [timestamp, visibleTimeRange]);

  useEffect(() => {
    // Load and store assigned audio file once on first mount.
    Backend.loadAudioFile(
      file.id,
      (fileUrl) => {
        audioFileSrc.current = fileUrl;
        const tmpHowl = new Howl({
          src: [fileUrl],
          format: [file.sourcePath.split(".").pop()],
          volume: 0.1,
          onload: () => {
            audio.current = tmpHowl;
            forceUpdate();
          },
          onplay: () => {
            waveSurfer.current.play();

            // step(audio.current, setTimestamp);
          },
          onpause: () => {
            waveSurfer.current.pause();
          },
          onseek: () => {
            step(audio.current, setTimestamp)
          },
        });
      },
      (err) => {
        window.openErrorDialog(err);
      }
    );

    // the return function will be called when the component unmounts
    return () => {
      audio.current.stop();
      // Remove audio file when unmounting container.
      audio.current.unload();
      window.URL.revokeObjectURL(audioFileSrc.current);
    };
  }, []);

  // Check for resized window
  useEffect(() => {
    calcWidths();
  }, [containerWidth]);

  /**
   * Takes floating-point seconds and formats them to min:seconds:ms
   * @param {number} t Timestamp.
   * @returns {string} Formatted timestamp.
   */
  const valueLabelFormat = (t) => {
    const minutes = Math.floor(t / 60);
    let seconds = Math.floor(t % 60);
    if (seconds < 10) seconds = "0" + seconds;
    let ms = Math.floor((t % 1) * 100);
    if (ms < 10) ms = "0" + ms;
    let result = minutes + ":" + seconds + "," + ms;
    return result;
  };

  const setWaveSurfer = (ws) => {
    waveSurfer.current = ws;
    forceUpdate();
  };

  const updateTimeStampPosition = (timestamp) => {
    setTimestamp(timestamp);
  };

  const calcWidths = () => {
    const boundingBox = container.current.getBoundingClientRect();
    setContentWidth(boundingBox?.width ? boundingBox.width - labelWidth : 0);
  };

  // Set up context for all children
  const context = {
    file: file,
    audio: audio.current,
    timestamp,
    audioFileSrc: audioFileSrc.current,
    updateTimeStampPosition,
    labelWidth,
    contentWidth,
    valueLabelFormat,
    disableKeyboardShortcuts,
  };

  const handleMouseMove = (e) => {
    const startX = labelWidth + 10;
    setHoverPositionX(Math.max(0, e.pageX - startX));
  };

  const handleMouseEnter = () => {
    setHoverBarVisible(true);
    handleContainerFocus(true)
  };

  const handleMouseLeave = () => {
    setHoverBarVisible(false);
  };

  const getTimeStamp = (xPos) => {
    if (audio.current) {
      return (xPos * audio.current.duration()) / contentWidth;
    }
    return xPos;
  };

  const pixelToTime = (x) => {
    let result = x;
    if (audio.current) {
      result = valueLabelFormat(getTimeStamp(x));
    }
    return result;
  };

  const handleContainerFocus = (value) => {
    setDisableKeyboardShortcuts(!value);
    if(value) {
      container.current.focus();
    }
  }

  return (
    <Grid
      tabIndex={0} // Make container focusable
      container
      onBlur={() => handleContainerFocus(false)}
      ref={container}
    >
      <Typography>{file.fileName}</Typography>
      <AudioFileContext.Provider value={context}>
        {audio.current &&  (
          <React.Fragment>
            <AudioControlBar
              getWaveSurfer={() => waveSurfer.current}
              timestamp={timestamp}
            />
            <Tooltip
              title={hoverPositionX > 0 ? pixelToTime(hoverPositionX) : ""}
              placement="top"
              followCursor
            >
              <div
                style={{
                  position: "relative",
                  width: "100%",
                }}
                onMouseMove={handleMouseMove}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <MagnitudeSpectrogram
                  leftWidth={labelWidth}
                  timestamp={timestamp}
                  setWaveSurfer={setWaveSurfer}
                  setVisibleTimeRange={updateVisibleTimeRange}
                />
                <AudioAnnotations
                  timestampPosition={timestampPosition}
                  hoverPositionX={hoverPositionX}
                  hoverBarVisible={hoverBarVisible}
                  labelWidth={labelWidth}
                  file={file}
                  visibleTimeRange={visibleTimeRange}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "grid",
                    gridTemplateColumns: "auto 1fr",
                    pointerEvents: "none",
                  }}
                >
                  <div style={{ width: labelWidth }}></div>
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      zIndex: 10,
                    }}
                  >
                    <div
                      style={{
                        display: hoverBarVisible ? "block" : "none",
                        position: "absolute",
                        top: 0,
                        left: hoverPositionX,
                        height: "100%",
                        width: 0,
                        border: "1px solid rgba(0,0,0,0.5)",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </Tooltip>
          </React.Fragment>
        )}
      </AudioFileContext.Provider>
    </Grid>
  );
}

AudioFileContainer.propTypes = {
  file: PropTypes.object.isRequired,
  labelWidth: PropTypes.number,
};

// Copyright HS Analysis GmbH, 2023
// Author: Lukas Buess

// HSA imports
import AITrainingSettings from "./AITrainingSettings.jsx";
import { AITrainingInformation } from "./AITrainingInformation.jsx";

/**
 * Information of an AI Training.
 * @param {AITrainingSettings} settings Settings used to train the AI model.
 * @param {AITrainingInformation} information Information about the AI training (e.g. progress, hardware, ...).
 */
export class AITrainingDataContainer {
  constructor(
    settings = new AITrainingSettings(),
    information = new AITrainingInformation()
  ) {
    // Input validation
    if (!(settings instanceof AITrainingSettings))
      throw TypeError(
        `settings must be of type AITrainingSettings, received ${typeof settings}: ${settings}`
      );
    if (!(information instanceof AITrainingInformation))
      throw TypeError(
        `information must be of type AITrainingInformation, received ${typeof information}: ${information}`
      );

    // Set parameters
    this.settings = settings;
    this.information = information;
  }
}

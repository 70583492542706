import React, { Component } from "react";
import PropTypes from "prop-types";

import { v4 as uuidv4 } from "uuid";

import { withRouter } from "react-router-dom";

import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Backend from "../common/utils/Backend";
import {
  Tabs,
  Tab,
  Paper,
  Container,
  CssBaseline,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
} from "@mui/material";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    height: "calc(100vh - 64px - 48px)",
    overflow: "hidden",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
  },
  link: {
    display: "inline-block",
    margin: 5,
  },
});

const LogFilesTabComp = (props) => {
  const { logFiles, logFilesPython, classes } = props;

  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Typography variant="h5">Analysis Log Files:</Typography>
          {logFilesPython.map((c) => (
            <a
              className={classes.link}
              key={uuidv4()}
              target="_blank"
              rel="noopener noreferrer"
              href={
                "api/about/logfile_python?name=" + window.decodeURIComponent(c)
              }
              download
            >
              {c}
            </a>
          ))}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h5">Core Log Files:</Typography>
          {logFiles.map((c) => (
            <a
              className={classes.link}
              key={uuidv4()}
              target="_blank"
              rel="noopener noreferrer"
              href={"api/about/logfile?name=" + window.decodeURIComponent(c)}
              download
            >
              {c}
            </a>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

LogFilesTabComp.propTypes = {
  classes: PropTypes.object,
  logFiles: PropTypes.array,
  logFilesPython: PropTypes.array,
};

const LogFilesTab = withStyles(styles)(LogFilesTabComp);

const AboutTab = (props) => {
  const { version } = props;

  return (
    <div>
      <Typography variant="h5">Version:</Typography>
      <br />
      <p>
        Copyright &copy; {new Date().getFullYear()} HS Analysis GmbH - All
        Rights Reserved
      </p>
      <p>HSA Kit Version {version} (64-bit)</p>
      <p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://www.hs-analysis.com"
        >
          https://www.hs-analysis.com
        </a>
      </p>
      <p>
        <a href="mailto:support@hs-analysis.com">support@hs-analysis.com</a>
      </p>
      <p>This software is for research only.</p>
      {/* hidden, because not working anymore, needs to be fixed */}
      {/* <Typography variant="h5">
        Intranet URL ({localhostMode ? "enabled" : "disabled"}):
      </Typography>
      {localhostMode && (
        <p>
          <a href={intranetUrl}>{intranetUrl}</a>
        </p>
      )} */}
    </div>
  );
};

AboutTab.propTypes = {
  version: PropTypes.string,
  intranetUrl: PropTypes.string,
  localhostMode: PropTypes.bool,
};

const HelpTab = (props) => {
  const { workflows } = props;

  return (
    <div>
      <Typography variant="h5">Handbook:</Typography>
      <br />
      <p>
        <a target="_blank" href="api/about/pdf?name=Handbook.pdf" download>
          Handbook.pdf
        </a>
      </p>
      <Typography variant="h5">Workflows:</Typography>
      <br />
      {workflows.map((c) => (
        <p key={uuidv4()}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={"api/about/pdf?name=" + window.decodeURIComponent(c)}
            download
          >
            {c}
          </a>
        </p>
      ))}
      <br />
      <Typography variant="h5">Help:</Typography>
      <br />
      <p>
        <a href="mailto:support@hs-analysis.com">support@hs-analysis.com</a>
      </p>
    </div>
  );
};

HelpTab.propTypes = {
  workflows: PropTypes.array,
};

function ReleaseNotesTab(props) {
  const releaseNotes = props.releaseNotes;

  return (
    <div>
      <Typography variant="h5">ReleaseNotes:</Typography>
      <br />
      <Table>
        <TableBody>
          {releaseNotes.map((c) => (
            <TableRow key={uuidv4()}>
              <TableCell align="left">{c.version}</TableCell>
              <TableCell align="left">
                <ul>
                  {c.notes.map((note) => (
                    <li key={uuidv4()}>{note}</li>
                  ))}
                </ul>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

ReleaseNotesTab.propTypes = {
  releaseNotes: PropTypes.array,
};

class AboutPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      releaseNotes: "[]",
      version: "1.0.0",
      activeTab: 0,
      buildDate: null,
      canAccessLogFiles: false,
      filteredWorkflows: [],
    };
    this.scrollRef = React.createRef();
    Backend.getAbout((result) => {
      this.setState(result);
      this.setProjectTypeWorkflows(result);
    });

    Backend.getAbout((result) => {
      console.log("about result:", result);
      this.setState(result);
    });

    Backend.getCurrentUser((user) => {
      this.setState({
        canAccessLogFiles: user.group.canAccessLogFiles,
      });
    });
  }

  setProjectTypeWorkflows = (aboutResult) => {
    if (aboutResult.workflows) {
      const workflows = aboutResult.workflows;
      Backend.getLicensingInfo((license) => {
        let filteredWorkflows = [];
        if (license.activeModules.length > 0) {
          filteredWorkflows = workflows.filter((workflow) => {
            return license.activeModules.some((activeModule) =>
              workflow.toLowerCase().includes(activeModule.label.toLowerCase())
            );
          });
        } else {
          filteredWorkflows = workflows;
        }

        this.setState({ filteredWorkflows });
      });
    }
  };

  handleChange = (event, newValue) => {
    this.scrollRef.current.scrollTo(0, 0);
    this.setState({ activeTab: newValue });
  };

  render() {
    const { classes } = this.props;
    const {
      releaseNotes,
      version,
      activeTab,
      filteredWorkflows,
      intranetUrl,
      localhostMode,
      logFiles,
      logFilesPython,
      canAccessLogFiles,
    } = this.state;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <Container maxWidth="md">
          <Paper className={classes.paper}>
            <Tabs
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              value={activeTab}
              onChange={this.handleChange}
            >
              <Tab label="About" />
              <Tab label="Help / Handbooks" />
              <Tab label="Release Notes" />
              {canAccessLogFiles && <Tab label="Log Files" />}
            </Tabs>
            <div
              ref={this.scrollRef}
              style={{
                padding: 20,
                maxHeight: "calc(86vh - 64px - 48px)",
                overflow: "auto",
              }}
            >
              {activeTab === 0 && (
                <AboutTab
                  version={version}
                  intranetUrl={intranetUrl}
                  localhostMode={localhostMode}
                />
              )}
              {activeTab === 1 && <HelpTab workflows={filteredWorkflows} />}
              {activeTab === 2 && (
                <ReleaseNotesTab releaseNotes={releaseNotes} />
              )}
              {activeTab === 3 && canAccessLogFiles && (
                <LogFilesTab
                  logFiles={logFiles}
                  logFilesPython={logFilesPython}
                />
              )}
            </div>
          </Paper>
        </Container>
      </div>
    );
  }
}

AboutPage.propTypes = {
  classes: PropTypes.object.isRequired,
  releaseNotes: PropTypes.array,
};

export default withRouter(withStyles(styles)(AboutPage));

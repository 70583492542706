import Backend from "../../../common/utils/Backend";

export const handleDownload = (finalSelectedModel) => {
  Backend.getModelMetadata("hsa_models", false, (res) => {
    const fetchedData = res.models;
    const model = fetchedData.find(
      (item) => item.meta_data.name === finalSelectedModel
    );
    const jsonStr = JSON.stringify(model, null, 2); // stringify with pretty-print
    const blob = new Blob([jsonStr], { type: "text/json" });
    const blobURL = window.URL.createObjectURL(blob);

    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download", finalSelectedModel + ".json");

    document.body.appendChild(tempLink);
    tempLink.click();

    // Clean-up
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  });
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Box,
  Checkbox,
  Tooltip,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Backend from "../../common/utils/Backend";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles({
  root: {
    // autocomplete autside dialog possible
    "& .MuiPaper-root": {
      overflow: "visible",
    },
    "& .MuiAutocomplete-popper": {
      border: "2px solid #0673C1",
      borderRadius: "5px",
    },
  },
});

function UserDetailsDialog(props) {
  const classes = useStyles();
  const [user, setUser] = useState(null);
  const [selectedGroups, setSelectedGroups] = useState([]);

  useEffect(() => {
    // dialog open
    if (props.open && props.user.id) {
      Backend.getUserGroups(props.user.id, (response) => {
        let user = JSON.parse(JSON.stringify(props.user));
        let selectedGroups = response;
        setUser(user);
        setSelectedGroups(selectedGroups);
      });
    } else {
      setUser(JSON.parse(JSON.stringify(props.user)));
      setSelectedGroups([]);
    }
  }, [props.open, props.user]);

  const handleChange = (e) => {
    const newUser = { ...user, [e.target.name]: e.target.value };
    setUser(newUser);
  };

  return (
    <Dialog
      className={classes.root}
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">User Details</DialogTitle>
      {user && (
        <DialogContent>
          <Box component="form" noValidate autoComplete="off">
            <TextField
              autoFocus
              margin="dense"
              name="fullName"
              label="Full Name"
              fullWidth
              value={user.fullName}
              onChange={handleChange}
            />
            <TextField
              disabled={!props.create}
              margin="dense"
              name="email"
              label="Email"
              type="email"
              fullWidth
              value={user.email}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="password"
              label="Password"
              type="password"
              autoComplete="on"
              fullWidth
              value={user.password ? user.password : ""}
              onChange={handleChange}
            />
            <Tooltip
              disableInteractive
              title={
                props.canEditUserGroups ? "Edit User Group" : "No Permission!"
              }
            >
              <span>
                <Autocomplete
                  disabled={!props.canEditUserGroups}
                  disablePortal
                  style={{ marginTop: 5 }}
                  options={props.groups}
                  multiple
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={selectedGroups}
                  onChange={(event, newValue) => {
                    setSelectedGroups(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Groups" name="Textfield" />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        name="CheckboxOption"
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                />
              </span>
            </Tooltip>
          </Box>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => props.onApply(user, selectedGroups)}
          color="primary"
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}

UserDetailsDialog.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
  user: PropTypes.object,
  groups: PropTypes.array,
  onClose: PropTypes.func,
  onApply: PropTypes.func,
  create: PropTypes.bool,
  canEditUserGroups: PropTypes.bool,
};

export default UserDetailsDialog;

import React from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";
import { IconButton } from "@mui/material";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import DayPicker from "./DayPicker";

const styles = () => ({
  root: {
    userSelect: "none",
    display: "inline-block",
  },
  navButton: {
    position: "relative",
    top: 12,
  },
});

const isSameDay = (someDate, compareDate) => {
  return (
    someDate.getDate() == compareDate.getDate() &&
    someDate.getMonth() == compareDate.getMonth() &&
    someDate.getFullYear() == compareDate.getFullYear()
  );
};

function DayPagination(props) {
  const handleBefore = () => {
    let date = new Date(props.date.getTime());
    date.setDate(date.getDate() - 1);
    props.updateTable(date);
  };

  const handleNext = () => {
    let date = new Date(props.date.getTime());
    date.setDate(date.getDate() + 1);
    props.updateTable(date);
  };
  const { classes } = props;
  return (
    <div className={classes.root}>
      <IconButton
        className={classes.navButton}
        onClick={handleBefore}
        disabled={isSameDay(props.date, props.firstDate)}
      >
        <NavigateBefore />
      </IconButton>
      <DayPicker
        date={props.date}
        firstDate={props.firstDate}
        updateTable={props.updateTable}
      />
      <IconButton
        className={classes.navButton}
        onClick={handleNext}
        disabled={isSameDay(props.date, new Date())}
      >
        <NavigateNext />
      </IconButton>
    </div>
  );
}

DayPagination.propTypes = {
  classes: PropTypes.object.isRequired,
  date: PropTypes.object.isRequired,
  firstDate: PropTypes.object.isRequired,
  updateTable: PropTypes.func.isRequired,
};

export default withStyles(styles)(DayPagination);

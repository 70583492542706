// Copyright HS-Analysis GmbH, 2019

// Framework imports
import React, { useRef } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// External imports
import classNames from "classnames";

// Material UI imports
import { lighten, darken } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import CloudDownload from "@mui/icons-material/CloudDownload";
import CloudUpload from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopy from "@mui/icons-material/FileCopy";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SearchIcon from "@mui/icons-material/Search";
import Merge from "@mui/icons-material/MergeType";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import TimerIcon from "@mui/icons-material/Timer";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";

// HSA imports
import "./../../css/tooltip.css";
import { authenticationService } from "../../common/services";
import { viewerType } from "../../common/utils/Utils";
import { withSpinloader } from "../../common/components/Spinloader";
import Backend from "../../common/utils/Backend";
import FindFilesDialog, { ProjectActionMode } from "../dialogs/FindFilesDialog";
import ProjectsTableRow from "./ProjectsTableRow";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: "readableId", numeric: false, disablePadding: false, label: "ID" },
  { id: "thumbnail", numeric: false, disablePadding: true, label: "Preview" },
  { id: "name", numeric: false, disablePadding: true, label: "Name" },
  { id: "creator", numeric: false, disablePadding: false, label: "Creator" },
  { id: "type", numeric: true, disablePadding: false, label: "Method" },
  { id: "state", numeric: true, disablePadding: false, label: "State" },
  {
    id: "creationDateTime",
    numeric: true,
    disablePadding: false,
    label: "Created",
  },
  { id: "actions", numeric: false, disablePadding: false, label: "Actions" },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              name="CheckboxAllProjects"
              checked={numSelected > 0}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {rows.map(
            (row) => (
              <TableCell
                key={row.id}
                align={row.numeric ? "right" : "left"}
                padding={row.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  disableInteractive
                  title="Sort"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing(1),
    display: "grid",
    gridTemplateColumns: "auto auto 1fr auto auto auto",
  },
  searchIcon: {
    fill: "lightgray",
    marginTop: 8,
    marginLeft: 20,
  },
  searchField: {
    marginLeft: 6,
    marginTop: 0,
    maxWidth: 300,
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
});

let EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    classes,
    onDeleteClick,
    onRunSelectedClick,
    onTimedRunSelectedClick,
    onDuplicateSelectedClick,
    onMergeSelectedClick,
    onImportClick,
    onExportClick,
    searchText,
    onChangeFilter,
    user,
  } = props;
  const canOpenProject = true; //TODO: Fix user.group.canOpenProject

  const fileUploader = useRef(null);

  const onChangeFile = (event) => {
    props.spinloader.show();
    event.stopPropagation();
    event.preventDefault();
    onImportClick(event.target.files);
    event.target.value = "";
  };

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="subtitle1">Projects</Typography>
        )}
      </div>
      <SearchIcon className={classes.searchIcon} />
      <TextField
        fullWidth
        variant="standard"
        name="Textfield"
        className={classes.searchField}
        key={"searchText"}
        margin="normal"
        label={"Search Projects"}
        value={searchText}
        onChange={onChangeFilter}
      />

      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <div style={{ width: "max-content" }}>
            <Tooltip
              disableInteractive
              title={canOpenProject ? "Export projects" : "No Permission!"}
            >
              <span>
                <IconButton
                  disabled={canOpenProject !== true}
                  aria-label="Export"
                  onClick={onExportClick}
                  size="large"
                >
                  <CloudDownload />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              disableInteractive
              title={
                user.group.canRunJob
                  ? "Run selected projects within time"
                  : "No Permission!"
              }
            >
              <span>
                <IconButton
                  aria-label="Timer"
                  disabled={!user.group.canRunJob}
                  onClick={onTimedRunSelectedClick}
                  size="large"
                >
                  <TimerIcon />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip disableInteractive title="Merge">
              <>
                <IconButton
                  aria-label="Merge"
                  onClick={onMergeSelectedClick}
                  size="large"
                  disabled={numSelected < 2}
                >
                  <Merge />
                </IconButton>
              </>
            </Tooltip>

            <Tooltip disableInteractive title="Duplicate">
              <IconButton
                aria-label="Duplicate"
                onClick={onDuplicateSelectedClick}
                size="large"
              >
                <FileCopy />
              </IconButton>
            </Tooltip>

            <Tooltip
              disableInteractive
              title={
                user.group.canRunJob
                  ? "Run selected projects now"
                  : "user.group.canRunJob"
              }
            >
              <span>
                <IconButton
                  disabled={!user.group.canRunJob}
                  aria-label="Play"
                  onClick={onRunSelectedClick}
                  size="large"
                >
                  <PlayArrowIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip disableInteractive title="Delete selected projects">
              <IconButton
                aria-label="Delete"
                onClick={onDeleteClick}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          <Tooltip
            disableInteractive
            title={
              user.group === null || user.group?.canImportProjects !== true
                ? "No Permission"
                : "Import existing project"
            }
          >
            <span>
              <IconButton
                disabled={
                  user.group === null || user.group?.canImportProjects !== true
                }
                aria-label="Import"
                onClick={() => fileUploader.current.click()}
                size="large"
              >
                <CloudUpload />
              </IconButton>
            </span>
          </Tooltip>
        )}
        <input
          type="file"
          accept=".hsa"
          ref={fileUploader}
          onChange={onChangeFile}
          style={{ display: "none" }}
        />
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onDeleteClick: PropTypes.func,
  onImportClick: PropTypes.func,
  onExportClick: PropTypes.func,
  onRunSelectedClick: PropTypes.func,
  onTimedRunSelectedClick: PropTypes.func,
  searchText: PropTypes.string,
  onChangeFilter: PropTypes.func,
  onDuplicateSelectedClick: PropTypes.func,
  onMergeSelectedClick: PropTypes.func,
  spinloader: PropTypes.object,
  user: PropTypes.object,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = () => ({
  root: {
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateRows: "auto auto 1fr",
  },
  table: {
    minWidth: 1020,
  },
  tableRow: {
    cursor: "pointer",
  },
  tableWrapper: {
    overflowX: "auto",
    height: "100%",
  },
  creatingJobBar: {
    backgroundColor: darken("#216DC0", 0.3),
  },
  input: {
    width: "calc(100% - 48px)",
  },
  progressBarContainer: {
    position: "relative",
  },
  progressBar: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    lineHeight: "20px",
    textAlign: "center",
  },
});

class ProjectsTable extends React.Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
    this.state = {
      order: "desc",
      orderBy: "changeDateTime",
      selected: [],
      page: 0,
      searchText: "",
      topScrollPos: 0,
      bottomScrollPos: 0,
    };
    this.tableContainerRef = React.createRef();
  }

  componentDidMount = () => {
    this._isMounted = true;
    const tableContainerEl = this.tableContainerRef.current;
    tableContainerEl.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
    Backend.loadAvailableProjectTypes((e) => {
      this.setState({
        projectTypes: e,
        projectTypesNames: e.map((projectType) => projectType.name),
      });
    });
  };

  componentWillUnmount = () => {
    const tableContainerEl = this.tableContainerRef.current;
    tableContainerEl.removeEventListener("scroll", this.handleScroll);
    this._isMounted = false;
  };

  handleScroll = () => {
    const scrollTop = this.tableContainerRef.current.scrollTop;
    // const scrollHeight = this.tableContainerRef.current.scrollHeight;
    const clientHeight = this.tableContainerRef.current.clientHeight;

    this.setState({
      topScrollPos: scrollTop,
      bottomScrollPos: scrollTop + clientHeight,
    });
  };

  setMountedState = (stateObject, callback) => {
    if (this._isMounted) {
      this.setState(stateObject, callback);
    }
  };

  onChangeFilter = (e) => {
    let searchText = e.target.value;
    this.setState({ searchText });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      this.setState(() => ({
        selected: this.props.projects
          .filter((project) => {
            return this.isJobVisible(project);
          })
          .map((project) => project.id),
      }));
    } else {
      this.setState({ selected: [] });
    }
  };

  handleSelectOneClick = (e, project) => {
    let selectedObject = this.state.selected;
    let selectedIndex = selectedObject.indexOf(project.id);
    if (selectedIndex !== -1) selectedObject.splice(selectedIndex, 1);
    else selectedObject.push(project.id);
    this.setState(() => ({
      selected: selectedObject,
    }));
    e.stopPropagation();
  };

  handleClick = (event, project) => {
    if (!project.canOpen) {
      window.showWarningSnackbar("Opening this project forbidden by owner!");
    } else if (this.props.serverIsRunning) {
      if (
        project.state === "error" ||
        project.state === "success" ||
        project.state === "ready" ||
        project.state === "pending" ||
        project.state === "timed" ||
        project.state === "running"
      ) {
        this.props.history.push(viewerType(project.type) + project.id);
      }
    } else {
      window.showWarningSnackbar("Local API Server not ready!");
    }
  };

  handleContextmenu = (event, project) => {
    if (!this.isSelected(project.id)) {
      const { selected } = this.state;
      selected.push(project.id);
      this.setState({ selected });
    }
    event.preventDefault();
    return false;
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  onRunSelectedClick = () => {
    let projects = this.props.projects;
    let projectIds = [];
    for (let project of projects) {
      for (let id of this.state.selected) {
        if (id === project.id && project.canRun) {
          projectIds.push(id);
        }
      }
    }
    Backend.setProjectsPending(projectIds, () => {
      this.setState({ selected: [] });
    });
  };

  onTimedRunSelectedClick = () => {
    let projects = this.props.projects;
    let projectIds = [];
    for (let project of projects) {
      for (let id of this.state.selected) {
        if (id === project.id && project.canRun) {
          projectIds.push(id);
        }
      }
    }
    Backend.setProjectsTimed(projectIds, () => {
      console.log("projects state changed to timed:", projectIds);
      this.setState({ selected: [] });
    });
  };

  /**
   * Import / Export Projects
   */
  onImportClick = (files) => {
    Backend.importProjects(files[0], (res) => {
      if (res.status === 403) {
        window.showErrorSnackbar("No Permission to import projects!");
      } else {
        this.ImportProjectsDialog.show(res);
        this.setState({ selected: [] });
      }
      this.props.spinloader.hide();
    });
  };

  onExportClick = () => {
    this.props.spinloader.show();
    let exportName = "";
    let numSelected = 0;
    for (let project of this.props.projects) {
      if (this.state.selected.includes(project.id)) {
        if (numSelected === 0) {
          exportName += project.name;
        }
        numSelected++;
      }
    }
    if (numSelected > 1) {
      exportName += "+" + (numSelected - 1);
    }

    Backend.exportProjects(this.state.selected, exportName, () => {
      this.props.spinloader.hide();
      this.setState({ selected: [] });
    });
  };

  /**
   * Delete selected projects
   */
  onDeleteClick = () => {
    window.openResponseDialog(
      "Do you really want to delete this project?",
      (response) => {
        if (response) {
          Backend.deleteProjects(this.state.selected, () => {
            this.setState({ selected: [] });
          });
          // get projectnames for selected projectIDs
          let projects = this.props.projects.filter((project) =>
            this.state.selected.includes(project.id)
          );
          let projectNames = [];
          projects.forEach(function (element) {
            projectNames.push(element.name);
          });
          Backend.deleteGalleryExport(projectNames, () => {
            this.setState({ selected: [] });
          });
        }
      }
    );
  };

  onDuplicateSelectedClick = () => {
    Backend.duplicateProjects(this.state.selected, () => {
      this.setState({ selected: [] });
      this.props.handleActiveTabChange(0);
    });
  };

  onMergeSelectedClick = () => {
    let projectType = null;
    let projectTypesEqual = true;
    for (const selectedProjectId of this.state.selected) {
      const selectedProject = this.props.projects.find(
        (project) => project.id === selectedProjectId
      );
      if (projectType === null) {
        projectType = selectedProject.type;
      } else {
        if (projectType !== selectedProject.type) {
          projectTypesEqual = false;
          break;
        }
      }
    }
    if (projectTypesEqual) {
      Backend.mergeProjects(this.state.selected, (result) => {
        if (!result.success) {
          window.showErrorSnackbar("Merge failed!");
        }
        this.setState({ selected: [] });
        this.props.handleActiveTabChange(0);
      });
    } else {
      window.showErrorSnackbar("Merge not possible. Types are not equal!");
    }
  };

  handleChange = (event, value) => {
    if (value !== this.props.activeTab) {
      if (value === 1) {
        window.document.title = `Finished - HSA Kit ${window.version}`;
      } else {
        window.document.title = `Projects - HSA Kit ${window.version}`;
      }
      this.props.handleActiveTabChange(value);
      if (this.state.selected.length > 0) {
        this.setState({
          selected: [],
        });
      }
    }
  };

  countFinished(countOther) {
    let result = this.props.projects.length;
    if (this.props.projects.length > 0) {
      result = this.props.projects.reduce(
        (acc, cur) => acc + (cur.state === "success" ? 1 : 0),
        0
      );
    }
    if (countOther) {
      result = this.props.projects.length - result;
    }
    return result;
  }

  isInView = (idx) => {
    const { topScrollPos, bottomScrollPos } = this.state;
    const headerSpace = 60;
    const rowHeight = 81; //including bottom border

    const idxTop = rowHeight * idx + headerSpace;
    const idxBottom = idxTop + rowHeight;

    return idxBottom >= topScrollPos && idxTop <= bottomScrollPos;
  };

  isJobVisible = (row) => {
    const { activeTab } = this.props;
    const { searchText } = this.state;
    let showJob = false;
    //filter if pending or finished
    if (searchText !== "") {
      if (!row.name.toLowerCase().includes(searchText.toLowerCase())) {
        return false;
      }
    }
    if (activeTab === 1) {
      showJob = row.state === "success";
    } else {
      showJob = row.state !== "success";
    }
    if (this.state.projectTypesNames) {
      //filter if job project module exists (release version)
      if (showJob) {
        return this.state.projectTypesNames.includes(row.type);
      } else {
        return false;
      }
    }
    return false;
  };

  render() {
    const { classes, projects, activeTab, spinloader, user } = this.props;
    const { order, orderBy, selected, searchText } = this.state;

    if (typeof this.props.user.group === "undefined") {
      authenticationService.logout();
    }

    const sortedProjects = stableSort(
      projects,
      getSorting(order, orderBy)
    ).filter((row) => {
      return this.isJobVisible(row);
    });

    return (
      <Paper className={classes.root}>
        <FindFilesDialog
          ref={(c) => (this.ImportProjectsDialog = c)}
          projectActionMode={ProjectActionMode.Import}
        />
        <FindFilesDialog
          ref={(c) => (this.FindMissingFiles = c)}
          projectActionMode={ProjectActionMode.Update}
        />
        <Tabs
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          value={activeTab}
          onChange={this.handleChange}
        >
          <Tab key="0" label={"Pending (" + this.countFinished(true) + ")"} />
          <Tab key="1" label={"Finished (" + this.countFinished(false) + ")"} />
        </Tabs>
        <EnhancedTableToolbar
          numSelected={selected.length}
          onDeleteClick={this.onDeleteClick}
          onRunSelectedClick={this.onRunSelectedClick}
          onTimedRunSelectedClick={this.onTimedRunSelectedClick}
          onDuplicateSelectedClick={this.onDuplicateSelectedClick}
          onMergeSelectedClick={this.onMergeSelectedClick}
          onImportClick={this.onImportClick}
          onExportClick={this.onExportClick}
          onChangeFilter={this.onChangeFilter}
          searchText={searchText}
          spinloader={spinloader}
          user={user}
        />

        <TableContainer
          className={classes.tableWrapper}
          ref={this.tableContainerRef}
        >
          <Table
            stickyHeader
            className={classes.table}
            style={{ tableLayout: "fixed" }}
          >
            <colgroup>
              <col width="50px" />
              <col width="50px" />
              <col width="70px" />
              <col />
              <col width="70px" />
              <col width="180px" />
              <col width="100px" />
              <col width="180px" />
              <col width="130px" />
            </colgroup>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={projects.length}
              activeTab={this.props.activeTab}
              spinloader={this.props.spinloader}
            />
            <TableBody>
              {sortedProjects.map((project, idx) => {
                const isSelected = this.isSelected(project.id);
                if (this.isInView(idx)) {
                  return (
                    <ProjectsTableRow
                      key={project.id}
                      classes={classes}
                      user={user}
                      projectTypes={this.state.projectTypes}
                      isSelected={isSelected}
                      project={project}
                      onRunRowClick={this.onRunRowClick}
                      handleClick={this.handleClick}
                      handleSelectOneClick={this.handleSelectOneClick}
                      onEditProject={this.props.onEditProject}
                      history={this.props.history}
                      handleActiveTabChange={this.props.handleActiveTabChange}
                    />
                  );
                } else {
                  return <tr key={project.id} style={{ height: 81 }}></tr>;
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }
}

ProjectsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  projects: PropTypes.array,
  activeTab: PropTypes.number,
  handleActiveTabChange: PropTypes.func,
  onEditProject: PropTypes.func,
  spinloader: PropTypes.object,
  serverIsRunning: PropTypes.bool,
  history: PropTypes.object,
  user: PropTypes.object,
};

export default withRouter(withSpinloader(withStyles(styles)(ProjectsTable)));

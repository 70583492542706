import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Paper, Fab } from "@mui/material";
import CreateCaseDialog from "../../home/dialogs/CreateCaseDialog";
import DynamicCaseTable from "./DynamicCaseTable";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const CasesTable = (props) => {
  const { caseColumnsData, cases, onDeleteRow, updateCases } = props;
  const classes = useStyles();
  const [editCase, setEditCase] = useState(null);
  const [isCreateCaseDialogOpen, setIsCreateCaseDialogOpen] = useState(false);

  const onEditRow = (e, row) => {
    e.stopPropagation();
    setEditCase(row);
  };

  useEffect(() => {
    if (!isCreateCaseDialogOpen) {
      setEditCase(null);
    }
  }, [isCreateCaseDialogOpen]);

  useEffect(() => {
    if (editCase) {
      setIsCreateCaseDialogOpen(true);
    }
  }, [editCase]);

  const handleCaseDialogOpen = () => setIsCreateCaseDialogOpen(true);

  return (
    <Paper className={classes.root}>
      {caseColumnsData.length > 0 && (
        <DynamicCaseTable
          cases={cases}
          columns={caseColumnsData}
          onDeleteRow={onDeleteRow}
          onEditRow={onEditRow}
        />
      )}
      <CreateCaseDialog
        editCase={editCase}
        open={isCreateCaseDialogOpen}
        setOpen={(value) => setIsCreateCaseDialogOpen(value)}
        updateCases={updateCases}
      />
      <Fab
        className={classes.fab}
        color="primary"
        aria-label="add"
        onClick={handleCaseDialogOpen}
      >
        <AddIcon />
      </Fab>
    </Paper>
  );
};

CasesTable.propTypes = {
  caseColumnsData: PropTypes.array.isRequired,
  cases: PropTypes.array.isRequired,
  onDeleteRow: PropTypes.func.isRequired,
  updateCases: PropTypes.func.isRequired,
};

export default CasesTable;

import React from "react";
import PropTypes from "prop-types";

import { Tabs, Tab, Container, Paper } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import SystemContainer from "./components/SystemContainer";
import UsersContainer from "./components/UsersContainer";
import GroupsContainer from "./components/GroupsContainer";
import ImporterContainer from "./components/ImporterContainer";
import ModuleCreationContainer from "./components/ModuleCreationContainer";
import Backend from "../common/utils/Backend";
import CaseSettingsContainer from "./components/CaseSettingsContainer";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    height: "calc(100vh - 64px)",
    overflow: "auto",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
  },
  rootContent: {
    margin: 0,
    width: "100%",
  },
});

const ActiveTab = Object.freeze({
  Users: 0,
  Groups: 1,
  Cases: 2,
  System: 3,
  Import: 4,
  ModuleCreation: 5,
});

class AdminPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      groups: [],
      users: [],
      userGroupIds: [],
      isSuperAdmin: false,
      activeTab: ActiveTab.Users,
      user: {
        group: {
          canEditGroups: false,
          canEditUserGroups: false,
          canEditUsers: false,
          canSetJobRunTimes: false,
          canCreateModules: false,
          canUseSystemActions: false,
          canEditAutoImportSettings: false,
        },
      },
    };
  }

  componentDidMount() {
    this.updateUserList();
    this.updateUser();
  }

  updateUser = () => {
    Backend.getCurrentUser((user) => {
      this.setState({
        user,
      });
    });
  };

  countPermissions = (group) => {
    let result = Object.values(group).reduce((accumulator, currentValue) => {
      if (currentValue === true) return accumulator + 1;
      else return accumulator;
    }, 0);
    return result;
  };

  updateGroups = () => {
    this.updateUser();
    Backend.loadGroupList((data) => {
      const groups = data.sort((a, b) => {
        if (a.name === "Super Admin") return -1;
        if (b.name === "Super Admin") return 1;
        return this.countPermissions(b) - this.countPermissions(a);
      });
      this.setState({ groups }, this.updateUserList);
    });
  };

  updateUserList = () => {
    Backend.loadUserList((data) => {
      this.setState({ users: data });
    });
    Backend.getCurrentUserGroups((response) => {
      const { groups } = this.state;

      const userGroupIds = response.map((item) => item.id);

      let isSuperAdmin = false;
      if (groups.length > 0) {
        const superAdminId = groups.find(
          (group) => group.name === "Super Admin"
        ).id;
        isSuperAdmin = userGroupIds.includes(superAdminId);
      }

      this.setState({ userGroupIds, isSuperAdmin });
    });
  };

  saveClick = () => {};

  onTabChange = (event, newValue) => {
    this.setState({ activeTab: newValue });
  };

  render() {
    const { user, userGroupIds, activeTab } = this.state;
    const { classes } = this.props;
    let activeComponent;
    switch (activeTab) {
      case ActiveTab.Users:
        activeComponent = (
          <UsersContainer
            users={this.state.users}
            groups={this.state.groups}
            updateGroups={this.updateGroups}
            updateUserList={this.updateUserList}
            canEditUsers={user.group.canEditUsers}
            canEditUserGroups={user.group.canEditUserGroups}
          />
        );
        break;
      case ActiveTab.Groups:
        activeComponent = (
          <GroupsContainer
            groups={this.state.groups}
            updateGroups={this.updateGroups}
            user={user}
            isSuperAdmin={this.state.isSuperAdmin}
            userGroupIds={userGroupIds}
          />
        );
        break;
      case ActiveTab.Cases:
        activeComponent = <CaseSettingsContainer />;
        break;
      case ActiveTab.System:
        activeComponent = (
          <SystemContainer
            canSetJobRunTimes={user.group.canSetJobRunTimes}
            canUseSystemActions={user.group.canUseSystemActions}
          />
        );
        break;
      case ActiveTab.Import:
        activeComponent = (
          <ImporterContainer
            canEditAutoImportSettings={user.group.canEditAutoImportSettings}
          />
        );
        break;
      case ActiveTab.ModuleCreation:
        activeComponent = <ModuleCreationContainer />;
        break;
      default:
        activeComponent = null;
    }
    return (
      <div className={classes.root}>
        <Container maxWidth="md">
          <Paper className={classes.paper}>
            <Tabs
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              value={activeTab}
              onChange={this.onTabChange}
            >
              <Tab label="Users" />
              <Tab label="Groups" />
              <Tab label="Case Settings" />
              <Tab label="System Actions" />
              <Tab label="Auto Import Settings" />
              {user.group.canCreateModules && (
                <Tab label="Create Project Module" />
              )}
            </Tabs>
            {activeComponent}
          </Paper>
        </Container>
      </div>
    );
  }
}

AdminPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminPage);

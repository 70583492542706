import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import withTheme from "@mui/styles/withTheme";

import Backend from "../../common/utils/Backend";

const styles = {};

class SceneImage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {};

  componentDidUpdate() {
    const { file, scroll, selectedWithKey, galleryMounted } = this.props;
    // if file is selected scroll to top
    if (file.selectedInGallery && (selectedWithKey || !galleryMounted)) {
      scroll(this.image.getBoundingClientRect().top);
    }
  }

  handleImageClick = (e) => {
    const { classificationId, files, file } = this.props;

    if (e.nativeEvent.which === 3) {
      // right click
      file.classId = classificationId[2];
    }
    if (e.nativeEvent.which === 1) {
      // left click
      file.classId = classificationId[1];
    }

    // make file selectedIngallery true and other files false
    files.forEach((f) => {
      f.selectedInGallery = false;
    });
    file.selectedInGallery = true;
    this.props.setSelectedWithKey(false);

    this.props.updateGallery();
  };

  getColorFromId = (classId) => {
    const { structures } = this.props;
    return structures.find((element) => element.id == classId).color;
  };

  getParentColor = () => {
    const { structures, selectedLayer } = this.props;
    return structures[selectedLayer].color;
  };

  render() {
    const { file, showFileNames } = this.props;

    return (
      <div
        style={{
          border: file.classId
            ? file.selectedInGallery
              ? "4px dashed " + this.getColorFromId(file.classId)
              : "4px solid " + this.getColorFromId(file.classId)
            : file.selectedInGallery
            ? "4px dashed " + this.getParentColor()
            : "4px solid " + this.getParentColor(),
          width: "100%",
          display: "grid",
          gridTemplateRows: "auto 1fr",
        }}
      >
        <div
          style={{
            borderBottom: file.classId
              ? showFileNames
                ? "2px solid " + this.getColorFromId(file.classId)
                : ""
              : showFileNames
              ? "2px solid " + this.getParentColor()
              : "",
          }}
        >
          {showFileNames ? file.fileName : ""}
        </div>
        <img
          style={{
            width: "100%",
            objectFit: "contain",
          }}
          ref={(i) => (this.image = i)}
          src={Backend.renderThumbnail(file.id)}
          alt=""
          onClick={this.handleImageClick}
          onContextMenu={(e) => {
            this.handleImageClick(e);
            e.preventDefault();
          }}
        />
      </div>
    );
  }
}

// define the component's interface
SceneImage.propTypes = {
  file: PropTypes.object,
  structures: PropTypes.array,
  selectedLayer: PropTypes.number,
  imageSize: PropTypes.number,
  classificationId: PropTypes.array,
  scroll: PropTypes.func,
  files: PropTypes.array,
  updateGallery: PropTypes.func,
  setSelectedWithKey: PropTypes.func,
  selectedWithKey: PropTypes.bool,
  showFileNames: PropTypes.bool,
  galleryMounted: PropTypes.bool,
};

export default withTheme(withStyles(styles)(SceneImage));

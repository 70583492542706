import React, { Component } from "react";

import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import { authenticationService } from "../common/services";
import Backend from "../common/utils/Backend";

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    // When screen sizes surpasses the theshold, change the look
    [theme.breakpoints.up(600 + 3 * 2 * 8)]: {
      // [theme.breakpoints.up(500 + theme.spacing(3 * 2))]: {
      // In the mui v5 update, theme.spacing returns "48px" instead of 48.
      // Therefore, hardcoding the default theme.spacing multiplyer of 8.
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
});

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        email: "",
        password: "",
      },
      isSubmitting: false,
      users: null,
      isUsername: false,
      emailErrorText: "Email or Username is not valid",
    };

    // if mounted in Storybook and test users should be used
    if (props.testUserList) {
      console.log("No LicensingInfo loaded, since test users used!");
    } else {
      // redirect if license is not VALID
      Backend.getLicensingInfo((license) => {
        if (license.licenseStatus !== "VALID") {
          authenticationService.logout();
          this.props.history.push("/licensing");
        }
      });
      // redirect to home if already logged in
      if (authenticationService.currentUserValue) {
        this.props.history.push("/");
      }
    }
  }

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validateUserOrEmail = (userList, value) => {
    // Backend get all users
    // check if valid user
    let valid = false;
    this.setState({ users: userList });

    // allow (and trim) spaces at the start and end of the email/mail + make sure only one space in name
    // https://stackoverflow.com/questions/16974664/remove-extra-spaces-in-string-javascript
    value = value.replace(/\s{2,}/g, " ").trim();

    userList.forEach((u) => {
      if (value === u.fullName) {
        this.setState({ isUsername: true });
        valid = true;
      } else if (value === u.email) {
        this.setState({ isUsername: false });
        valid = true;
      }
    });

    if (this.validateEmail(value)) {
      this.setState({ emailErrorText: "No User found for given Email" });
    } else {
      this.setState({
        emailErrorText: "No User found for given Username",
      });
    }
    return valid;
  };

  componentDidMount() {
    const { testUserList } = this.props;
    // if mounted in Storybook and test users should be used
    if (testUserList) {
      ValidatorForm.addValidationRule("isUserOrEmail", (value) =>
        this.validateUserOrEmail(testUserList, value)
      );
    } else {
      //Create Custom Validation Rule to allow User Name and e-mail
      Backend.getUserList((userList) => {
        ValidatorForm.addValidationRule("isUserOrEmail", (value) =>
          this.validateUserOrEmail(userList, value)
        );
      });
    }
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule("isUserOrEmail");
  }

  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  handleSubmit = () => {
    this.setState({
      isSubmitting: true,
    });

    const { formData, isUsername, users } = this.state;
    let { email } = formData;

    // allow (and trim) spaces at the start and end of the email/mail + make sure only one space in name
    // https://stackoverflow.com/questions/16974664/remove-extra-spaces-in-string-javascript
    email = email.replace(/\s{2,}/g, " ").trim();

    if (isUsername) {
      users.forEach((user) =>
        user.fullName === email ? (email = user.email) : email
      );
    }

    authenticationService.login(email, formData.password).then(
      () => {
        // window.location.reload(true); //clear cache
        const { from } = this.props.location.state || {
          from: { pathname: "/" },
        };
        this.props.history.push(from);
      },
      (error) => {
        this.setState({
          error: error,
          isSubmitting: false,
        });
      }
    );
  };

  render() {
    const { classes } = this.props;
    const { isSubmitting, error, formData, emailErrorText } = this.state;

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign In
          </Typography>
          <ValidatorForm className={classes.form} onSubmit={this.handleSubmit}>
            {error && (
              <Typography
                align="center"
                variant="caption"
                style={{ color: "#ff0000" }}
              >
                {error}
              </Typography>
            )}
            <TextValidator
              fullWidth
              margin="normal"
              id="email"
              name="email"
              label="Username/Email"
              type="text"
              validators={["required", "isUserOrEmail"]}
              errorMessages={["this field is required", emailErrorText]}
              onChange={this.handleChange}
              value={formData.email}
              inputProps={{ role: "username" }}
              InputLabelProps={{ shrink: true }}
            />
            <TextValidator
              fullWidth
              margin="normal"
              id="password"
              name="password"
              label="password"
              type="password"
              suggested="current password"
              validators={["required"]}
              errorMessages={["this field is required"]}
              onChange={this.handleChange}
              value={formData.password}
              inputProps={{ role: "password" }}
              InputLabelProps={{ shrink: true }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isSubmitting}
            >
              Sign In
            </Button>
          </ValidatorForm>
        </Paper>
      </main>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
  testUserList: PropTypes.array,
};

export default withRouter(withStyles(styles)(LoginPage));

// Copyright HS Analysis GmbH, 2023
// Author: Valentin Haas

// Framework imports
import React from "react";
import PropTypes from "prop-types";

// External imports

// Material UI imports
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tooltip from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

// HSA Imports
import {
  BatchSizeSelection,
  DatasetApproachSelection,
  DatasetCreationOptions,
  EarlyStoppingLimitSelection,
  EpochSelection,
  LearningRateSelection,
  LossFunctionSelection,
  MetricsSelection,
  OptimizerSelection,
  ImageSizeSelection,
  ImageInputChannelSelection,
  ImagePyramidLevelSelection,
  AudioNumberOfClassesSelection,
  AudioSequenceLengthSelection,
  AudioSequenceOverlapSelection,
} from "./SettingOptions";
import { TrainingDataTypes } from "../../../common/components/AITrainingSettings";

const styles = {
  accordion: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "1fr auto",
  },
};

/**
 * The most commonly adjusted settings for the dataset and model. Should be open by default.
 * Remaining props are passed on to child components.
 * @param {boolean} open Whether the accordion is open or not.
 * @param {function} updateOpen Function called to update the open state of the accordion in the parent component.
 * @returns {JSX.Element} The settings as an accordion component.
 */
export function CommonlyUsedCategory(props) {
  const { open, updateOpen } = props;

  const settings = [
    <DatasetApproachSelection key={"dataset-approach"} {...props} />,
    <EpochSelection key={"epochs"} {...props} />,
  ];

  return (
    <Accordion
      expanded={open}
      onChange={(_, isExpanded) => updateOpen(isExpanded)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div style={styles.accordion}>
          <Typography>Common Settings</Typography>
          <Tooltip
            placement="left"
            title={"Commonly adjusted settings. Can be kept as default."}
          >
            <HelpOutlineIcon />
          </Tooltip>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          {settings.map((setting, idx) => (
            <Grid key={idx} item xs={12} sm={4}>
              <FormControl fullWidth>{setting}</FormControl>
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

CommonlyUsedCategory.propTypes = {
  open: PropTypes.bool.isRequired,
  updateOpen: PropTypes.func.isRequired,
};

/**
 * For image datasets, the pyramid levels that can be selected are calculated.
 * @param {OME} ome The the image ome object.
 * @returns {Array} Array of pyramid level objects that can be selected.
 */
const getPyramidLevels = (ome) => {
  const dropdownPyramidLevels = [{ level: -1, description: "default" }];
  if (ome.maxLevel > 0) {
    for (let i = ome.maxLevel; i >= 0; i--) {
      let zoomFactor = 2 ** (ome.maxLevel - i);
      let levelData = {
        level: i,
        description: "1 : " + zoomFactor,
      };
      if (i === 0) {
        levelData.description += " (Thumbnail)";
      }
      dropdownPyramidLevels.push(levelData);
    }
  }
  return dropdownPyramidLevels;
};

/**
 * Generates the image dataset settings for an image dataset.
 * @param {Object} ome The OME object of the image.
 * @returns {Array} Array of components that can be used to select the image dataset settings.
 */
const getImageDatasetSettings = (props) => {
  const { ome } = props;

  return [
    <ImageSizeSelection key={"image-size"} {...props} />,
    <ImagePyramidLevelSelection
      key={"pyramid-level"}
      pyramidLevels={getPyramidLevels(ome)}
      {...props}
    />,
    <ImageInputChannelSelection key={"input-channels"} {...props} />,
  ];
};

/**
 * Generates the audio dataset settings for an audio dataset.
 * @param {Object}
 * @returns {Array} Array of components that can be used to select the audio dataset settings.
 */
const getAudioDatasetSettings = (props) => {
  console.log(props);
  return [
    <AudioSequenceLengthSelection key={"sequence-length"} {...props} />,
    <AudioSequenceOverlapSelection key={"sequence-overlap"} {...props} />,
    <AudioNumberOfClassesSelection key={"number-of-classes"} {...props} />,
  ];
};

/**
 * Advanced settings specific to the dataset. Should be closed by default.
 * Will be different depending on the type of the training data.
 * Remaining props are passed on to child components.
 * @param {boolean} open Whether the accordion is open or not.
 * @param {function} updateOpen Function called to update the open state of the accordion in the parent component.
 * @param {TrainingDataTypes} trainingDataType The type of the training data.
 * @returns {JSX.Element} The settings as an accordion component.
 */
export function AdvancedDatasetSettings(props) {
  const { open, updateOpen, trainingDataType } = props;

  const settings = [
    <DatasetCreationOptions key={"dataset-creation-options"} {...props} />,
  ];
  switch (trainingDataType) {
    case TrainingDataTypes.Image:
      settings.push(...getImageDatasetSettings(props));
      break;

    case TrainingDataTypes.Audio:
      settings.push(...getAudioDatasetSettings(props));
      break;

    default:
      break;
  }

  return (
    <Accordion
      expanded={open}
      onChange={(_, isExpanded) => updateOpen(isExpanded)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div style={styles.accordion}>
          <Typography>Advanced Dataset Settings</Typography>
          <Tooltip
            placement="left"
            title={
              "Configure dataset creation settings for compatibility with different model configurations"
            }
          >
            <HelpOutlineIcon />
          </Tooltip>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          {settings.map((setting, idx) => (
            <Grid key={idx} item xs={12} sm={4}>
              <FormControl fullWidth>{setting}</FormControl>
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

AdvancedDatasetSettings.propTypes = {
  open: PropTypes.bool.isRequired,
  updateOpen: PropTypes.func.isRequired,
  trainingDataType: PropTypes.oneOf(Object.values(TrainingDataTypes))
    .isRequired,
};

/**
 * Advanced settings specific to the model. Should be closed by default.
 * Remaining props are passed on to child components.
 * @param {boolean} open Whether the accordion is open or not.
 * @param {function} updateOpen Function called to update the open state of the accordion in the parent component.
 * @returns {JSX.Element} The settings as an accordion component.
 */
export function AdvancedModelSettings(props) {
  const { open, updateOpen } = props;

  const settings = [
    <BatchSizeSelection key={"batch-size"} {...props} />,
    <EarlyStoppingLimitSelection key={"early-stopping"} {...props} />,
    <LearningRateSelection key={"learning-rate"} {...props} />,
    <LossFunctionSelection key={"loss-function"} {...props} />,
    <MetricsSelection key={"metrics"} {...props} />,
    <OptimizerSelection key={"optimizer"} {...props} />,
  ];

  return (
    <Accordion
      expanded={open}
      onChange={(_, isExpanded) => updateOpen(isExpanded)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div style={styles.accordion}>
          <Typography>Advanced Model Settings</Typography>
          <Tooltip
            placement="left"
            title={
              "Independently configure model settings regardless of the dataset used."
            }
          >
            <HelpOutlineIcon />
          </Tooltip>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          {settings.map((setting, idx) => (
            <Grid key={idx} item xs={12} sm={4}>
              <FormControl fullWidth>{setting}</FormControl>
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

AdvancedModelSettings.propTypes = {
  open: PropTypes.bool.isRequired,
  updateOpen: PropTypes.func.isRequired,
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Backend from "../../common/utils/Backend";

const useStyles = makeStyles({
  root: {
    position: "relative",
    maxWidth: "100%",
    maxHeight: "100%",
    pointerEvents: "none",
  },
  deleteArea: {
    position: "absolute",
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    border: "2px solid red",
    background: "rgba(0, 0, 0, 0.5)",

    pointerEvents: "none",
  },
});

function ScanStream({ streamKey, deleteArea }) {
  let deleteAreaStyle = deleteArea
    ? {
        left: Math.min(deleteArea.p1.x, deleteArea.p2.x),
        top: Math.min(deleteArea.p1.y, deleteArea.p2.y),
        width: Math.abs(deleteArea.p1.x - deleteArea.p2.x),
        height: Math.abs(deleteArea.p1.y - deleteArea.p2.y),
      }
    : {};
  const classes = useStyles();
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    const socket = Backend.getScanSocket();

    socket.on(streamKey, (src) => {
      // Release memory for the previous image
      setImageSrc("");

      // Set the new image source
      setImageSrc("data:image/jpeg;base64," + src);
    });

    return () => {
      // Clean up the effect
      setImageSrc("");
      socket.disconnect();
    };
  }, []);

  return (
    <div className={classes.imgStyle}>
      <img src={imageSrc} alt="stream medium" />
      {deleteArea && (
        <div className={classes.deleteArea} style={deleteAreaStyle}></div>
      )}
    </div>
  );
}

ScanStream.propTypes = {
  streamKey: PropTypes.string.isRequired,
  deleteArea: PropTypes.object,
};

export default ScanStream;

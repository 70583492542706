// Framework imports
import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";

// External packages
import TreeItem from "@mui/lab/TreeItem";
import { Tooltip } from "@mui/material";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

// HSA Packages
import Backend from "../../common/utils/Backend";
import { FileViewerContext } from "../FileViewer";

/**
 * Collected (reusable) styles for page elements.
 */
const styles = {
  root: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    width: "300px",
    overflow: "hidden",
  },
  treeItem: {
    width: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
};

/**
 * Folder of foldertree.
 * @param {string} path Path of folder to get files and folders from. Defaults to "".
 * @returns React render component.
 */
export default function RecursiveFolderEntity({ entity }) {
  const [dirContent, setDirContent] = useState([]);
  const fileViewer = useContext(FileViewerContext);

  useEffect(() => {
    if (entity.type !== "file") {
      Backend.walkDir(entity.path, true, (content) => setDirContent(content));
    }
  }, []);

  return entity.type === "directory" ? (
    <TreeItem
      nodeId={entity.path}
      label={<FolderEntity entity={entity} />}
      onClick={() => fileViewer.setSelFolder(entity)}
    >
      {dirContent.map((item) => (
        <RecursiveFolderEntity key={item.path} entity={item} />
      ))}
    </TreeItem>
  ) : (
    <TreeItem
      key={entity.path}
      nodeId={entity.path}
      label={<FolderEntity entity={entity} />}
      onClick={() => {
        fileViewer.setSelFile(entity.path);
      }}
    />
  );
}

RecursiveFolderEntity.propTypes = {
  entity: PropTypes.object.isRequired,
};

/**
 *styling of treeitems
 */
export function FolderEntity({ entity }) {
  const fileViewer = useContext(FileViewerContext);
  const path = entity.path;
  const endOfPath = path.substring(path.lastIndexOf("/") + 1);
  const isSel = path === fileViewer.selectedFile;

  return (
    <div
      style={{
        ...styles.root,
        background: isSel ? "rgb(176,196,222)" : "none",
      }}
    >
      {entity.type === "directory" ? (
        <FolderOpenOutlinedIcon sx={{ paddingRight: "5px" }} />
      ) : (
        <InsertDriveFileOutlinedIcon sx={{ paddingRight: "5px" }} />
      )}
      <Tooltip
        title={endOfPath}
        styles={{ width: "100%", textAlign: "center" }}
      >
        <div style={styles.treeItem}>{endOfPath}</div>
      </Tooltip>
    </div>
  );
}

FolderEntity.propTypes = {
  entity: PropTypes.object.isRequired,
};

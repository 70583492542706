import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  IconButton,
  Grid,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  List,
  Tooltip,
  DialogActions,
} from "@mui/material";
import Backend from "../../common/utils/Backend";
import { Folder, ArrowUpward } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  list: {
    height: 400,
    border: "2px solid rgb(218, 218, 218)",
    minHeight: "400px",
    maxHeight: "500px",
    width: "100%",
    overflowY: "auto",
  },
  content: { paddingTop: 0, width: 800 },
  folderIcon: { color: "#FFE896" },
  itemText: { overflow: "hidden", textOverflow: "ellipsis" },
}));

const FolderChooser = (props) => {
  const { onClose, storagePath, open, isRelative, title } = props;
  const classes = useStyles();
  const [dirfiles, setDirFiles] = useState([]);
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    if (open) {
      updateFolderList("");
    }
  }, [open]);

  const updateFolderList = (currentPath) => {
    // load list of available project types from backend
    Backend.walkDir(currentPath, isRelative, (e) => {
      let filteredDirFiles = e.filter((entry) => {
        if (entry.type === "directory") {
          let sameMrxsFile = e.find((element) => {
            return element.path === entry.path + ".mrxs";
          });
          return typeof sameMrxsFile === "undefined";
        } else {
          return true;
        }
      });
      // write received info into state
      setCurrentPath(currentPath);
      setDirFiles(filteredDirFiles);
    });
  };

  return (
    <Dialog onClose={() => onClose()} maxWidth="lg" open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container>
          <List dense={true} className={classes.list}>
            {currentPath && (
              <ListItem>
                <ListItemIcon>
                  <Tooltip
                    disableInteractive
                    title={`Up to "${
                      currentPath.substring(0, currentPath.lastIndexOf("/")) ||
                      "root"
                    }"`}
                  >
                    <IconButton
                      onClick={() => {
                        updateFolderList(
                          currentPath.substring(0, currentPath.lastIndexOf("/"))
                        );
                      }}
                      size="large"
                    >
                      <ArrowUpward />
                    </IconButton>
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={currentPath} />
              </ListItem>
            )}
            {dirfiles.map((e, id) => {
              if (e.type === "file") {
                return null;
              }
              let path =
                e.path.length < 5 ? e.path : e.path.replace(/^.*[\\/]/, "");
              return (
                <ListItemButton
                  key={id}
                  onClick={() => updateFolderList(e.path)}
                >
                  <ListItemIcon className={classes.folderIcon}>
                    <Folder />
                  </ListItemIcon>
                  <ListItemText primary={path} className={classes.itemText} />
                </ListItemButton>
              );
            })}
          </List>
        </Grid>
        <br />
        <Grid container>
          <TextField
            fullWidth
            name="Textfield"
            label="Folder"
            value={storagePath + currentPath}
            InputProps={{ readOnly: true }}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onClose(currentPath)} color="primary" autoFocus>
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FolderChooser.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  storagePath: PropTypes.string.isRequired,
  isRelative: PropTypes.bool.isRequired,
};

export default FolderChooser;

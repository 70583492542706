import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import PropTypes from "prop-types";
import classNames from "classnames";
import DragIndicator from "@mui/icons-material/DragIndicator";
import Draggable from "react-draggable";

import { withScanViewerContext } from "../contexts/ScanViewerContext";
import ScanStream from "./ScanStream";

const styles = {
  root: {
    position: "absolute",
    left: 0,
    bottom: 0,
    height: 170,
    width: 220,
    border: "2px solid rgb(85, 85, 85)",
    opacity: 0.8,
    textAlign: "center",
    userSelect: "none",
  },
  streamImg: {
    background: "black",
    maxWidth: "100%",
    maxHeight: "100%",
    display: "inline-block",
    pointerEvents: "all",
  },
  dragIndicator: {
    color: "#fff",
    position: "absolute",
    top: 5,
    right: 5,
    cursor: "grab",
  },
  grabbing: {
    cursor: "grabbing",
  },
};

/**
 * class to render minimap and handle interaction
 */
class ScanMinimap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      grabbing: false,
    };
    this.minimapRef = React.createRef();
    this.x = 0;
    this.y = 0;
  }

  /**
   * by clicking on the minimap move the rectangle and send data to scanViewer
   * @param {ActionEvent} e Mouse event
   */
  onMinimapClick = (e) => {
    const parentRect = this.minimapRef.current.getBoundingClientRect();
    const x = e.clientX - parentRect.x;
    const y = e.clientY - parentRect.y;
    this.props.scanViewerContext.sendMinimapClickPosition(x, y);
  };

  /**
   * Change position of minimap canvas
   * @param {AcitonEvent} e Mouse event
   * @param {Object} ui object with x,y coordinate of mouse location
   */
  handleDrag = (e, ui) => {
    this.x = ui.x;
    this.y = ui.y;
  };

  // handle dragging of the minimap region rectangle
  mouseDown = (event) => {
    this.onMinimapClick(event);
    this.dragging = true;
    event.preventDefault();
  };

  // handle dragging of the minimap region rectangle
  mouseMove = (event) => {
    if (this.dragging == true) {
      this.onMinimapClick(event);
    }
    event.preventDefault();
  };

  mouseUp = (event) => {
    this.dragging = false;
    event.preventDefault();
  };

  render() {
    const { grabbing } = this.state;
    const { classes } = this.props;
    const { mapVisible } = this.props.scanViewerContext;
    return (
      <Draggable
        handle=".handle"
        defaultPosition={{ x: 5, y: -15 }}
        onStart={() => this.setState({ grabbing: true })}
        onDrag={this.handleDrag}
        onStop={() => this.setState({ grabbing: false })}
      >
        <div
          style={{
            visibility: mapVisible ? "visible" : "hidden",
            pointerEvents: "all",
          }}
          ref={this.minimapRef}
          className={classes.root}
        >
          <div
            onMouseDown={this.mouseDown}
            onMouseMove={this.mouseMove}
            onMouseUp={this.mouseUp}
          >
            <ScanStream
              className={classes.streamImg}
              streamKey="scan_stream_minimap"
            />
          </div>

          <DragIndicator
            className={classNames(
              "handle",
              classes.dragIndicator,
              grabbing && classes.grabbing
            )}
          />
        </div>
      </Draggable>
    );
  }
}

ScanMinimap.propTypes = {
  classes: PropTypes.object.isRequired,
  scanViewerContext: PropTypes.object.isRequired,
  src: PropTypes.string,
};

export default withScanViewerContext(withStyles(styles)(ScanMinimap));

import React, { Component } from "react";
import PropTypes from "prop-types";

import Pagination from "@mui/material/Pagination";

import {
  getContainedRegionRois,
  getParentIndexLayer,
} from "../../utils/StructuresUtils";
import { pointInside } from "../../utils/PolygonUtil";

import {
  Tooltip,
  IconButton,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
} from "@mui/material";

import { Delete } from "@mui/icons-material";
import Tool from "./Tool";
import RBush from "rbush";
import CustomRangeSlider from "../../../globalComponents/CustomRangeSlider";

class FilterAnnotationsTool extends Tool {
  name = "Filter Annotations";
  selection = null;
  selectionIdx = 0;
  smallerIdx = -2;
  biggerIdx = -2;
  sortedTreeItems = [];
  checkJumps = true;
  regionRois = [];
  tree = new RBush();
  structure = null;
  parentIndex = 0;
  randSliderKey = 0;
  rangeSliderParams = {
    key: "0",
    label: "",
    min: 0,
    max: 10000,
    valueMin: 0,
    valueMax: 10000,
    step: 1,
    onKeyEnter: () => this.updateRangeMode(),
    onSliderChange: (value) => {
      this.rangeSliderParams.valueMin = value[0];
      this.rangeSliderParams.valueMax = value[1];
      window.forceSidebarUpdate();
    },
    onSliderchangeComitted: () => this.updateRangeMode(),
  };

  updateRangeMode = () => {
    if (this.sortedTreeItems && this.sortedTreeItems.length > 0) {
      let smallerIdx = -2;
      let biggerIdx = -2;
      for (let i = 0; i < this.sortedTreeItems.length; i++) {
        const treeItem = this.sortedTreeItems[i];
        if (
          treeItem.roi.area > this.rangeSliderParams.valueMin &&
          smallerIdx === -2
        ) {
          smallerIdx = i - 1;
        }
        if (
          treeItem.roi.area > this.rangeSliderParams.valueMax &&
          biggerIdx === -2
        ) {
          biggerIdx = i;
          break;
        }
      }
      if (smallerIdx !== this.smallerIdx && smallerIdx >= 0) {
        this.smallerIdx = smallerIdx;
        this.selectionIdx = this.smallerIdx;
        this.selection = this.sortedTreeItems[this.selectionIdx];
        if (this.checkJumps) window.zoomToRect(this.selection.roi.bounds);
        window.forceSidebarUpdate();
      } else if (biggerIdx !== this.biggerIdx && biggerIdx >= 0) {
        this.biggerIdx = biggerIdx;
        this.selectionIdx = this.biggerIdx;
        this.selection = this.sortedTreeItems[this.selectionIdx];
        if (this.checkJumps) window.zoomToRect(this.selection.roi.bounds);
        window.forceSidebarUpdate();
      } else if (smallerIdx < 0 || biggerIdx < 0) {
        if (smallerIdx > 0) {
          this.selection = this.sortedTreeItems[smallerIdx];
        } else if (biggerIdx > 0) {
          this.selection = this.sortedTreeItems[biggerIdx];
        }
        this.smallerIdx = smallerIdx;
        this.biggerIdx = biggerIdx;
        window.forceSidebarUpdate();
      }
    }
  };

  setLayer(obj) {
    this.structures = obj.structures;
    this.layer = obj.layer;
    this.roiLayers = obj.roiLayers;
    this.selectedLayer = obj.selectedLayer;
    this.structure = obj.structures[obj.selectedLayer];
    this.regionRois = getContainedRegionRois(
      this.structure,
      this.structures,
      this.roiLayers
    );
    this.parentIndex = getParentIndexLayer(this.structure, this.structures);
    this.tree.clear();
    this.tree.load(this.regionRois.map((item) => item.treeItem));
    this.updateSortedTreeItems();
    this.groupPermissions = obj.groupPermissions;
  }

  setPreviewRect() {}

  findSmallestTreeItem(p) {
    let minArea = Number.MAX_SAFE_INTEGER;
    let resultItem;
    if (this.structures[this.selectedLayer].visible) {
      let treeItems = this.tree.search({
        minX: p.x,
        minY: p.y,
        maxX: p.x,
        maxY: p.y,
      });
      for (let treeItem of treeItems) {
        let b = treeItem.roi.bounds;

        if (pointInside(p, treeItem.roi)) {
          let bArea = (b.right - b.left) * (b.bottom - b.top);
          if (bArea < minArea) {
            minArea = bArea;
            resultItem = treeItem;
          }
        }
      }
    }
    return resultItem;
  }

  deleteSmaller = (idx) => {
    let historyItem = [];
    let histId = this.structures[this.parentIndex].id;
    this.biggerIdx -= idx + 1;
    let idxToDelete = idx;
    const roiLayer = this.roiLayers[this.parentIndex];
    while (idxToDelete >= 0) {
      const treeItemToDelete = this.sortedTreeItems[idxToDelete];
      roiLayer.tree.remove(treeItemToDelete);
      this.tree.remove(treeItemToDelete);
      historyItem.push({ add: false, id: histId, roi: treeItemToDelete.roi });
      idxToDelete--;
    }
    this.regionRois = this.tree.all().map((treeItem) => treeItem.roi);
    this.roiLayers[this.parentIndex].layer.regionRois = this.roiLayers[
      this.parentIndex
    ].tree
      .all()
      .map((treeItem) => treeItem.roi);

    this.updateSortedTreeItems();
    this.selection = null;
    this.smallerIdx = -2;
    window.forceSidebarUpdate();
    window.projectHistory.add(historyItem);
  };

  deleteBigger = (idx) => {
    let historyItem = [];
    let histId = this.structures[this.parentIndex].id;
    let idxToDelete = idx;
    const roiLayer = this.roiLayers[this.parentIndex];
    while (idxToDelete < this.sortedTreeItems.length) {
      const treeItemToDelete = this.sortedTreeItems[idxToDelete];
      roiLayer.tree.remove(treeItemToDelete);
      this.tree.remove(treeItemToDelete);
      historyItem.push({ add: false, id: histId, roi: treeItemToDelete.roi });
      idxToDelete++;
    }
    this.regionRois = this.tree.all().map((treeItem) => treeItem.roi);
    this.roiLayers[this.parentIndex].layer.regionRois = this.roiLayers[
      this.parentIndex
    ].tree
      .all()
      .map((treeItem) => treeItem.roi);

    this.updateSortedTreeItems();
    this.selection = null;
    this.biggerIdx = -2;
    window.forceSidebarUpdate();
    window.projectHistory.add(historyItem);
  };

  deleteSelection() {
    let historyItem = [];
    let histId = this.structures[this.parentIndex].id;
    this.roiLayers[this.parentIndex].tree.remove(this.selection);
    this.tree.remove(this.selection);
    historyItem.push({ add: false, id: histId, roi: this.selection.roi });
    this.regionRois = this.tree.all().map((treeItem) => treeItem.roi);
    this.roiLayers[this.parentIndex].layer.regionRois = this.roiLayers[
      this.parentIndex
    ].tree
      .all()
      .map((treeItem) => treeItem.roi);

    this.updateSortedTreeItems();

    if (this.selectionIdx < this.sortedTreeItems.length) {
      this.selection = this.sortedTreeItems[this.selectionIdx];
      if (this.checkJumps) window.zoomToRect(this.selection.roi.bounds);
    } else if (this.sortedTreeItems.length > 0) {
      this.selectionIdx = this.sortedTreeItems.length - 1;
      this.selection = this.sortedTreeItems[this.selectionIdx];
      if (this.checkJumps) window.zoomToRect(this.selection.roi.bounds);
    } else {
      this.selection = null;
    }
    window.forceSidebarUpdate();
    window.projectHistory.add(historyItem);
  }
  /**
   * handle shortcuts
   * @param {ActionEvent} event Event when keyboard button is pressed
   */
  onKeyDown(event) {
    if (
      event.key === "Delete" &&
      this.selection &&
      this.groupPermissions.canAnnotate
    ) {
      this.deleteSelection();
    }
  }

  mouse(params) {
    let { event, p } = params;
    this.curX = p.x;
    this.curY = p.y;

    // mouse middle button
    if (event.button === 1) {
      return;
    }

    if (event.type === "mousedown") {
      let treeItem = this.findSmallestTreeItem(p);
      this.selection = treeItem;
      this.updateSortedTreeItems();
      if (this.selection) {
        this.selectionIdx = this.selection.sortIdx;
        this.smallerIdx = -2;
        this.biggerIdx = -2;
        window.forceSidebarUpdate();
      }
    }
  }

  /**
   * Draw a custom cursor
   */
  drawCustomCursor(ctx) {
    if (this.selection) {
      ctx.beginPath();
      ctx.globalAlpha = 1.0;
      ctx.strokeStyle = "#ffffff";
      let b = this.selection.roi.bounds;
      // no custom cursor
      ctx.rect(b.left, b.top, b.right - b.left, b.bottom - b.top);
      ctx.stroke();
      ctx.closePath();
    }
  }

  /**
   * Sorts all rois of the selected structure by area, should they not already be sorted.
   */
  updateSortedTreeItems = () => {
    if (this.sortedTreeItems !== this.regionRois) {
      this.sortedTreeItems = this.tree.all().sort((treeItem1, treeItem2) => {
        return treeItem1.roi.area - treeItem2.roi.area;
      });
      for (let i = 0; i < this.sortedTreeItems.length; i++) {
        this.sortedTreeItems[i]["sortIdx"] = i;
      }
      if (this.sortedTreeItems.length > 0) {
        const valueIsMin =
          this.rangeSliderParams.min === this.rangeSliderParams.valueMin;
        const valueIsMax =
          this.rangeSliderParams.max === this.rangeSliderParams.valueMax;
        this.rangeSliderParams.min = parseInt(
          this.sortedTreeItems[0].roi.area,
          10
        );
        if (
          this.rangeSliderParams.valueMin < this.rangeSliderParams.min ||
          valueIsMin
        ) {
          this.rangeSliderParams.valueMin = this.rangeSliderParams.min;
        }
        this.rangeSliderParams.max =
          parseInt(
            this.sortedTreeItems[this.sortedTreeItems.length - 1].roi.area,
            10
          ) + 1;
        if (
          this.rangeSliderParams.valueMax > this.rangeSliderParams.max ||
          valueIsMax
        ) {
          this.rangeSliderParams.valueMax = this.rangeSliderParams.max;
        }
      }
      window.forceSidebarUpdate();
    }
  };

  selectRegion = (v) => {
    this.updateSortedTreeItems();
    this.selectionIdx = v - 1;
    this.selection = this.sortedTreeItems[this.selectionIdx];
    if (this.selection && this.selection.roi) {
      if (this.checkJumps) window.zoomToRect(this.selection.roi.bounds);
      window.forceSidebarUpdate();
    }
  };

  toggleJumps = () => {
    this.checkJumps = !this.checkJumps;
    window.forceSidebarUpdate();
  };

  exit() {}

  renderConfiguration = () => {
    return (
      <div>
        <Typography variant="h6">{this.name}:</Typography>
        <ConfigForm
          selectionIdx={this.selectionIdx}
          smallerIdx={this.smallerIdx}
          biggerIdx={this.biggerIdx}
          sortedTreeItems={this.sortedTreeItems}
          selectRegion={(v) => this.selectRegion(v)}
          delete={() => this.deleteSelection()}
          regionRois={this.regionRois}
          selection={this.selection}
          checkJumps={this.checkJumps}
          toggleJumps={() => this.toggleJumps()}
          groupPermissions={this.groupPermissions}
          rangeSliderParams={this.rangeSliderParams}
          deleteSmaller={this.deleteSmaller}
          deleteBigger={this.deleteBigger}
        />
      </div>
    );
  };
}

class ConfigForm extends Component {
  render() {
    const smallerSelectionCount =
      this.props.selectionIdx < 0 ? 0 : this.props.selectionIdx + 1;
    const biggerSelectionCount =
      this.props.selectionIdx < 0
        ? 0
        : this.props.regionRois.length - this.props.selectionIdx;
    const smallerMinSliderCount =
      this.props.smallerIdx < 0 ? 0 : this.props.smallerIdx;
    const biggerMaxSliderCount =
      this.props.biggerIdx < 0
        ? 0
        : this.props.regionRois.length - this.props.biggerIdx - 1;
    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              name="CheckboxJumps"
              color="primary"
              checked={this.props.checkJumps}
              onChange={() => {
                this.props.toggleJumps();
              }}
              value={this.props.checkJumps}
            />
          }
          label="Jump to selection"
        />
        {this.props.selection &&
        this.props.smallerIdx < 0 &&
        this.props.biggerIdx < 0 ? (
          <div>
            <div>Selection:</div>
            <ul>
              <li>Area: {this.props.selection.roi.area.toFixed(2)} px</li>
            </ul>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridGap: "8px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                disabled={smallerSelectionCount <= 0}
                onClick={() =>
                  this.props.deleteSmaller(this.props.selectionIdx)
                }
              >
                Delete {smallerSelectionCount} <br />
                smaller Rois
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={biggerSelectionCount <= 0}
                onClick={() => this.props.deleteBigger(this.props.selectionIdx)}
              >
                {" "}
                Delete {biggerSelectionCount} <br />
                bigger Rois
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <div>Range:</div>
            <ul>
              {this.props.sortedTreeItems[this.props.smallerIdx] ? (
                <li>
                  First ROI smaller than minimum value:
                  <ul>
                    <li>
                      Area:{" "}
                      {this.props.sortedTreeItems[
                        this.props.smallerIdx
                      ].roi.area.toFixed(2)}{" "}
                      px
                    </li>
                  </ul>
                </li>
              ) : (
                <li>No ROI is smaller than minimum value!</li>
              )}
              {this.props.sortedTreeItems[this.props.biggerIdx] ? (
                <li>
                  First ROI bigger than maximum value:
                  <ul>
                    <li>
                      Area:{" "}
                      {this.props.sortedTreeItems[
                        this.props.biggerIdx
                      ].roi.area.toFixed(2)}{" "}
                      px
                    </li>
                  </ul>
                </li>
              ) : (
                <li>No ROI is bigger than minimum value!</li>
              )}
            </ul>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridGap: "8px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                disabled={smallerMinSliderCount <= 0}
                onClick={() => this.props.deleteSmaller(this.props.smallerIdx)}
              >
                Delete {smallerMinSliderCount} <br />
                smaller Rois
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={biggerMaxSliderCount <= 0}
                onClick={() => this.props.deleteBigger(this.props.biggerIdx)}
              >
                {" "}
                Delete {biggerMaxSliderCount} <br />
                bigger Rois
              </Button>
            </div>
          </div>
        )}

        <CustomRangeSlider
          key={this.props.randSliderKey}
          params={this.props.rangeSliderParams}
        />
        <TextField
          fullWidth
          name="Textfield"
          label="Sorted object number:"
          type="number"
          value={this.props.selectionIdx + 1}
          margin="normal"
          size="small"
          onChange={(e) => {
            this.props.selectRegion(e.target.value);
          }}
          variant="outlined"
          InputProps={{
            inputProps: { min: 1, max: this.props.regionRois.length },
          }}
        />
        <br />
        <div style={{ display: "grid", gridTemplateColumns: "1fr auto" }}>
          <div style={{ marginTop: 10 }}>
            {" "}
            <Pagination
              page={this.props.selectionIdx + 1}
              size="small"
              variant="outlined"
              shape="rounded"
              onChange={(e, v) => {
                this.props.selectRegion(v);
              }}
              count={this.props.regionRois.length}
            />
          </div>

          <Tooltip
            disableInteractive
            title={
              this.props.groupPermissions.canAnnotate
                ? "Delete selected Structure (Del)"
                : "No Permission!"
            }
          >
            <span>
              <IconButton
                disabled={!this.props.groupPermissions.canAnnotate}
                onClick={() => {
                  this.props.delete();
                }}
                size="large"
              >
                <Delete />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      </div>
    );
  }
}

ConfigForm.propTypes = {
  randSliderKey: PropTypes.number,
  checkJumps: PropTypes.bool,
  toggleJumps: PropTypes.func,
  selectionIdx: PropTypes.number,
  smallerIdx: PropTypes.number,
  biggerIdx: PropTypes.number,
  sortedTreeItems: PropTypes.array,
  selection: PropTypes.object,
  selectRegion: PropTypes.func,
  regionRois: PropTypes.array,
  delete: PropTypes.func,
  groupPermissions: PropTypes.object,
  rangeSliderParams: PropTypes.object,
  deleteSmaller: PropTypes.func,
  deleteBigger: PropTypes.func,
};

export default FilterAnnotationsTool;

import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import PropTypes from "prop-types";

import { withScanViewerContext } from "../contexts/ScanViewerContext";
import CustomSlider from "../../globalComponents/CustomSlider";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Tooltip,
  TextField,
} from "@mui/material";

import {
  PlayArrow,
  Stop,
  Info,
  Home,
  ArrowLeft,
  ArrowRight,
  ArrowDropUp,
  ArrowDropDown,
  ArrowUpward,
  LockOpen,
  Link,
  ArrowDownward,
  CenterFocusStrong,
  HdrAuto,
  LinkOff,
} from "@mui/icons-material";

const styles = {
  root: {
    padding: 20,
    height: "100%",
    overflowY: "auto",
  },
  OpticsRow: {
    display: "grid",
    gridTemplateColumns: "1fr auto auto",
    width: "100%",
    marginTop: 10,
  },
  OpticsSelector: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "100%",
  },
  vignetteRow: {
    display: "grid",
    gridTemplateColumns: "1fr auto auto",
    width: "100%",
    marginTop: 10,
    marginBottom: 10,
  },
  textButton: {
    lineHeight: "30px",
  },
  checkboxLabel: {
    margin: "0px !important",
  },
  textfieldNumber: {
    width: "90px",
  },
  featurePointRow: {
    display: "grid",
    gridTemplateColumns: "1fr auto auto",
    width: "100%",
    marginTop: 15,
  },
  infoButton: {
    height: 40,
    marginTop: 16,
  },
  automationButton: {
    height: 40,
    width: 40,
    margin: 10,
  },
  automationButtonContainer: {
    width: 60,
    display: "inline-block",
  },
  automationTextfieldContainer: {
    width: 155,
    display: "inline-block",
  },
  automationLongTextfieldContainer: {
    width: 360,
    display: "inline-block",
  },
  automationTextfield: {
    height: 40,
    margin: 15,
  },
};

/**
 * Class to render Camera Settings, read them and send them to backend
 */
class ScanMicroscopeSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
      vignetteState: "",
      // checkedFeaturePoint: false, // for DEBUG
      // numFeaturePoints: 3, // for DEBUG
    };

    // sets the colors (RGB) to the chosen white balance
    this.lightSourceParams = {
      label: "Light Source",
      selectedValue: "Daylight6500K",
      info: [
        "Sets the light source preset.",
        "The colors in the image will be corrected so that they are appropriate for the selected light source.",
      ],
      options: [
        {
          name: "Off",
          label: "Off",
        },
        {
          name: "Daylight5000K",
          label: "Daylight (5000 Kelvin)",
        },
        {
          name: "Daylight6500K",
          label: "Daylight (6500 Kelvin)",
        },
        {
          name: "Tungsten2800K",
          label: "Tungsten (2800 Kelvin)",
        },
      ],
      onChange: (value) => {
        this.lightSourceParams.selectedValue = value;
        if (value === "Off") {
          this.sliderParams.red.value = 1.0;
          this.sliderParams.green.value = 1.0;
          this.sliderParams.blue.value = 1.0;
        } else if (value === "Daylight5000K") {
          this.sliderParams.red.value = 1.57349;
          this.sliderParams.green.value = 1.0;
          this.sliderParams.blue.value = 2.2749;
        } else if (value === "Daylight6500K") {
          this.sliderParams.red.value = 1.82056;
          this.sliderParams.green.value = 1.0;
          this.sliderParams.blue.value = 1.94189;
        } else if (value === "Tungsten2800K") {
          this.sliderParams.red.value = 1.06372;
          this.sliderParams.green.value = 1.0;
          this.sliderParams.blue.value = 4.17261;
        }
        this.forceUpdate();
        this.sendParams();
      },
    };

    this.sliderParams = {
      frameRate: {
        type: "Slider",
        label: "Frame Rate",
        info: [
          "Acquisition frame rate of the camera in frames per second.",
          "Double click to reset value to default.",
        ],
        value: 30,
        minKey: "cameraMinFramerate",
        maxKey: "cameraMaxFramerate",
        step: 1,
        standardValue: 30,
      },
      exposureTime: {
        type: "Slider",
        label: "Exposure Time",
        info: [
          "Value of the shutter speed the camera uses.",
          "The higher the shutter speed, the darker the image ",
        ],
        value: 250,
        minKey: "cameraMinExposureTime",
        maxKey: "cameraMaxExposureTime",
        standardValue: 250,
      },
      gain: {
        type: "Slider",
        label: "Gain",
        info: [
          "Value of the currently selected gain in dB.",
          "Double click to reset value to default.",
        ],
        value: 0.1,
        minKey: "cameraMinGain",
        maxKey: "cameraMaxGain",
        step: 0.1,
        standardValue: 0.1,
      },
      gamma: {
        type: "Slider",
        label: "Gamma",
        info: [
          "Gamma correction to be applied.",
          "Gamma correction allows you to optimize the brightness of acquired images for display on a monitor.",
          "Double click to reset value to default.",
        ],
        value: 1.0,
        minKey: "cameraMinGamma",
        maxKey: "cameraMaxGamma",
        step: 0.01,
        standardValue: 1.0,
      },
      blackValue: {
        type: "Slider",
        label: "Black Value",
        info: [
          "Black level value to be applied to the currently selected sensor tap.",
          "Double click to reset value to default.",
        ],
        value: 0,
        minKey: "cameraMinBlackValue",
        maxKey: "cameraMaxBlackValue",
        step: 0.1,
        standardValue: 0,
      },
      red: {
        type: "Slider",
        label: "Red",
        info: [
          "Sets the light source preset.",
          "The colors in the image will be corrected so that they are appropriate for the red light source.",
          "Double click to reset value to default.",
        ],
        value: 1.82,
        minKey: "cameraColorMin",
        maxKey: "cameraColorMax",
        step: 0.01,
      },
      green: {
        type: "Slider",
        label: "Green",
        info: [
          "Sets the light source preset.",
          "The colors in the image will be corrected so that they are appropriate for the green light source.",
          "Double click to reset value to default.",
        ],
        value: 1.0,
        minKey: "cameraColorMin",
        maxKey: "cameraColorMax",
        step: 0.01,
      },
      blue: {
        type: "Slider",
        label: "Blue",
        info: [
          "Sets the light source preset.",
          "The colors in the image will be corrected so that they are appropriate for the blue light source.",
          "Double click to reset value to default.",
        ],
        value: 1.94,
        minKey: "cameraColorMin",
        maxKey: "cameraColorMax",
        step: 0.01,
      },
    };

    this.sendParams();
    window.addEventListener("keydown", this.keyDown);
  }
  /**
   * create json with important values and send to backend
   */
  sendParams() {
    let paramJson = {
      lightSource: this.lightSourceParams.selectedValue,
      pixelSizeCamera: this.props.scanViewerContext.pixelSizeCamera,
    };
    for (const [key, value] of Object.entries(this.sliderParams)) {
      paramJson[key] = value.value;
    }
    // Backend.configureMicroscopeCamera(paramJson);
    this.props.scanViewerContext.changeMicroscopeParams(paramJson);
  }

  /**
   * Handle keyboard shortcuts
   * @param {ActionEvent} e Keyboard keys
   */
  keyDown = (e) => {
    let elem = null;
    if (e.ctrlKey) {
      switch (e.key) {
        case "ArrowLeft": // ctrl + left
          elem = document.getElementById("xMinusButton");
          break;
        case "ArrowRight": // ctrl + right
          elem = document.getElementById("xPlusButton");
          break;
        case "ArrowUp": // ctrl + up
          elem = document.getElementById("yPlusButton");
          break;
        case "ArrowDown": // ctrl + down
          elem = document.getElementById("yMinusButton");
          break;
        case "PageUp": // ctrl + page up
          elem = document.getElementById("zPlusButton");
          break;
        case "PageDown": // ctrl + page down
          elem = document.getElementById("zMinusButton");
          break;
        case "a": // ctrl + a
          elem = document.getElementById("autoFocusButton");
          break;
        default:
          break;
      }
    } else {
      return;
    }

    if (elem !== null) {
      elem.click();
      e.preventDefault();
    }
  };

  render() {
    const { classes, scanViewerContext } = this.props;
    const {
      vignetteState,
      // checkedFeaturePoint, numFeaturePoints // for DEBUG
    } = this.state;
    const { automationVisible, automatedMicroscopeStatus } =
      this.props.scanViewerContext;
    return (
      <div className={classes.root}>
        <h2>Settings</h2>
        {/* not in use at the moment */}
        {/* <CustomSelector params={this.lightSourceParams} /> */}
        {Object.entries(this.sliderParams).map(([key, value], idx) => {
          let params = value;
          params.onChange = (value) => {
            this.sliderParams[key].value = value;
            this.forceUpdate();
            if (
              value >= scanViewerContext[params.minKey] &&
              value <= scanViewerContext[params.maxKey]
            ) {
              params.error = false;
              this.sendParams();
            } else {
              params.error = true;
            }
          };
          params.onSliderChange = (value) => {
            this.sliderParams[key].value = value;
            this.forceUpdate();
            this.sendParams();
          };
          params.onDoubleClick = () => {
            if (this.sliderParams[key].label === "Red") {
              switch (this.lightSourceParams.selectedValue) {
                case "Off":
                  this.sliderParams[key].value = 1.0;
                  break;
                case "Daylight5000K":
                  this.sliderParams[key].value = 1.57349;
                  break;
                case "Daylight6500K":
                  this.sliderParams[key].value = 1.82056;
                  break;
                case "Tungsten2800K":
                  this.sliderParams[key].value = 1.06372;
                  break;
              }
            } else if (this.sliderParams[key].label === "Green") {
              this.sliderParams[key].value = 1.0;
            } else if (this.sliderParams[key].label === "Blue") {
              switch (this.lightSourceParams.selectedValue) {
                case "Off":
                  this.sliderParams[key].value = 1.0;
                  break;
                case "Daylight5000K":
                  this.sliderParams[key].value = 2.2749;
                  break;
                case "Daylight6500K":
                  this.sliderParams[key].value = 1.94189;
                  break;
                case "Tungsten2800K":
                  this.sliderParams[key].value = 4.17261;
                  break;
              }
            } else {
              this.sliderParams[key].value =
                this.sliderParams[key].standardValue;
            }
            this.forceUpdate();
            this.sendParams();
          };
          return (
            <CustomSlider
              key={idx}
              params={params}
              min={this.props.scanViewerContext[params.minKey]}
              max={this.props.scanViewerContext[params.maxKey]}
            />
          );
        })}

        <div className={classes.vignetteRow}>
          <FormGroup>
            <FormControlLabel
              className={classes.checkboxLabel}
              control={
                <Checkbox
                  name="CheckboxVignette"
                  disabled={
                    !(
                      vignetteState === "ready" || vignetteState === "filtering"
                    )
                  }
                  checked={vignetteState === "filtering"}
                  onChange={() => {
                    const value =
                      vignetteState === "ready" ? "filtering" : "ready";
                    scanViewerContext.setVignetteState(value);
                    this.setState({ vignetteState: value });
                  }}
                />
              }
              label="Filter Vignette"
            />
          </FormGroup>
          <Button
            className={classes.textButton}
            disabled={vignetteState === "filtering"}
            onClick={() => {
              const value =
                vignetteState === "" || vignetteState === "ready"
                  ? "recording"
                  : "ready";
              scanViewerContext.setVignetteState(value);
              this.setState({ vignetteState: value });
            }}
          >
            {vignetteState === "recording" ? <Stop /> : <PlayArrow />}{" "}
            {vignetteState === "recording"
              ? "Stop Recording"
              : "Record Vignette"}
          </Button>
          <IconButton
            className={classes.iconButton}
            onClick={() =>
              window.openWarningDialog([
                "To filter the vignette, a vignette must be recorded first:",
                "1. Navigate to a mostly white position.",
                "2. Record Vignette.",
                "3. Navigate around until shown vignette is not changing anymore.",
                "4. Stop Recording.",
                "5. Filter Vignette is now possible.",
              ])
            }
          >
            <Info />
          </IconButton>
        </div>
        <div>
          <TextField
            name="Textfield"
            className={classes.automationTextfield}
            style={{ width: "100%" }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                this.props.scanViewerContext.changeImportParams({
                  action: "filepath",
                  data: e.target.value,
                });
              }
            }}
            label="import Filepath"
            defaultValue="C:\Users\philgrun\Documents\HSA-Web\Source\HSA-KIT\modules\hsa\2022-11-30T17-27-40_wip-project.npy"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div hidden={!automationVisible}>
          <div className={classes.automationButtonContainer}>
            <Tooltip
              placement="left"
              title={
                automatedMicroscopeStatus === "notConnected"
                  ? "Connect"
                  : "Exit"
              }
            >
              <IconButton
                className={classes.automationButton}
                onClick={() => {
                  automatedMicroscopeStatus === "notConnected"
                    ? scanViewerContext.useAutomatedMicroscope("connect")
                    : scanViewerContext.useAutomatedMicroscope("exit");
                }}
              >
                {automatedMicroscopeStatus === "notConnected" ? (
                  <Link />
                ) : (
                  <LinkOff />
                )}
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.automationButtonContainer}></div>
          <div
            className={classes.automationButtonContainer}
            style={{ background: "lightgray" }}
          >
            <Tooltip placement="left" title="Y+">
              <IconButton
                id="yPlusButton"
                className={classes.automationButton}
                onClick={() => {
                  scanViewerContext.useAutomatedMicroscope("y_up");
                }}
              >
                <ArrowDropUp />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.automationButtonContainer}></div>
          <div className={classes.automationButtonContainer}>
            <Tooltip placement="left" title="Z+">
              <IconButton
                id="zPlusButton"
                className={classes.automationButton}
                onClick={() => {
                  scanViewerContext.useAutomatedMicroscope("z_up");
                }}
              >
                <ArrowUpward />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.automationButtonContainer}>
            <Tooltip placement="left" title="Auto Scan">
              <IconButton
                className={classes.automationButton}
                onClick={() => {
                  scanViewerContext.useAutomatedMicroscope("autoscan");
                }}
              >
                <HdrAuto />
              </IconButton>
            </Tooltip>
          </div>
          <br />
          <div className={classes.automationButtonContainer}>
            <Tooltip placement="left" title="Unlock">
              <IconButton
                className={classes.automationButton}
                onClick={() => {
                  scanViewerContext.useAutomatedMicroscope("unlock");
                }}
              >
                <LockOpen />
              </IconButton>
            </Tooltip>
          </div>
          <div
            className={classes.automationButtonContainer}
            style={{ background: "lightgray" }}
          >
            <Tooltip placement="left" title="X-">
              <IconButton
                id="xMinusButton"
                className={classes.automationButton}
                onClick={() => {
                  scanViewerContext.useAutomatedMicroscope("x_left");
                }}
              >
                <ArrowLeft />
              </IconButton>
            </Tooltip>
          </div>
          <div
            className={classes.automationButtonContainer}
            style={{ background: "lightgray" }}
          >
            <Tooltip placement="left" title="Y-">
              <IconButton
                id="yMinusButton"
                className={classes.automationButton}
                onClick={() => {
                  scanViewerContext.useAutomatedMicroscope("y_down");
                }}
              >
                <ArrowDropDown />
              </IconButton>
            </Tooltip>
          </div>
          <div
            className={classes.automationButtonContainer}
            style={{ background: "lightgray" }}
          >
            <Tooltip placement="left" title="X+">
              <IconButton
                id="xPlusButton"
                className={classes.automationButton}
                onClick={() => {
                  scanViewerContext.useAutomatedMicroscope("x_right");
                }}
              >
                <ArrowRight />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.automationButtonContainer}>
            <Tooltip placement="left" title="Z-">
              <IconButton
                id="zMinusButton"
                className={classes.automationButton}
                onClick={() => {
                  scanViewerContext.useAutomatedMicroscope("z_down");
                }}
              >
                <ArrowDownward />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.automationButtonContainer}>
            <Tooltip placement="left" title="Auto Focus">
              <IconButton
                id="autoFocusButton"
                className={classes.automationButton}
                onClick={() => {
                  scanViewerContext.useAutomatedMicroscope("autofocus");
                }}
              >
                <CenterFocusStrong />
              </IconButton>
            </Tooltip>
          </div>
          <br />
          <div className={classes.automationButtonContainer}>
            <Tooltip placement="left" title="Home">
              <IconButton
                id="homeButton"
                className={classes.automationButton}
                onClick={() => {
                  scanViewerContext.useAutomatedMicroscope("home");
                }}
              >
                <Home />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.automationButtonContainer}>
            <Tooltip placement="left" title="Calibration">
              <Button
                id="calibrationButton"
                className={classes.automationButton}
                onClick={() => {
                  scanViewerContext.useAutomatedMicroscope("calibration");
                }}
              >
                Calibration
              </Button>
            </Tooltip>
          </div>
          <br />
          <div className={classes.automationTextfieldContainer}>
            <TextField
              name="Textfield"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  scanViewerContext.useAutomatedMicroscope(
                    "change_x_stepsize",
                    {
                      stepsize: e.target.value,
                    }
                  );
                }
              }}
              className={classes.automationTextfield}
              label="X Stepsize [mm]"
              type="number"
              inputProps={{
                step: "0.1",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue="0.4"
            />
          </div>
          <div className={classes.automationTextfieldContainer}>
            <TextField
              name="Textfield"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  scanViewerContext.useAutomatedMicroscope(
                    "change_x_velocity",
                    {
                      velocity: e.target.value,
                    }
                  );
                }
              }}
              className={classes.automationTextfield}
              label="X Velocity [mm/min]"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue="10"
            />
          </div>
          <br />
          <div className={classes.automationTextfieldContainer}>
            <TextField
              name="Textfield"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  scanViewerContext.useAutomatedMicroscope(
                    "change_y_stepsize",
                    {
                      stepsize: e.target.value,
                    }
                  );
                }
              }}
              className={classes.automationTextfield}
              label="Y Stepsize [mm]"
              type="number"
              inputProps={{
                step: "0.1",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue="0.5"
            />
          </div>
          <div className={classes.automationTextfieldContainer}>
            <TextField
              name="Textfield"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  scanViewerContext.useAutomatedMicroscope(
                    "change_y_velocity",
                    {
                      velocity: e.target.value,
                    }
                  );
                }
              }}
              className={classes.automationTextfield}
              label="Y Velocity [mm/min]"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue="10"
            />
          </div>
          <div className={classes.automationTextfieldContainer}>
            <TextField
              name="Textfield"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  scanViewerContext.useAutomatedMicroscope(
                    "change_z_stepsize",
                    {
                      stepsize: e.target.value,
                    }
                  );
                }
              }}
              className={classes.automationTextfield}
              label="Z Stepsize [mm]"
              type="number"
              inputProps={{
                step: "0.05",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue="0.1"
            />
          </div>
          <div className={classes.automationTextfieldContainer}>
            <TextField
              name="Textfield"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  scanViewerContext.useAutomatedMicroscope(
                    "change_z_velocity",
                    {
                      velocity: e.target.value,
                    }
                  );
                }
              }}
              className={classes.automationTextfield}
              label="Z Velocity [mm/min]"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue="20"
            />
          </div>
          <br />
          <div className={classes.automationLongTextfieldContainer}>
            <TextField
              name="Textfield"
              className={classes.automationTextfield}
              style={{ width: "100%" }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  scanViewerContext.useAutomatedMicroscope("execute_gcode", {
                    gcode: e.target.value,
                  });
                }
              }}
              label="G-code"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <br />
          <div className={classes.automationLongTextfieldContainer}>
            <TextField
              name="Textfield"
              style={{ margin: 15, width: "100%" }}
              multiline
              rows={18}
              disabled
              value="Hier könnte Ihre Werbung stehen. 
              aca1920-40uc: 1,2mm y entspricht der Bildhöhe
              1,8 mm x enspricht der Bildbreite"
              label="Executed code"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <br />
          <div>
            <Button
              style={{ left: 10 }}
              onClick={() => {
                scanViewerContext.useAutomatedMicroscope("show_config");
              }}
            >
              show Configuration
            </Button>
          </div>
        </div>
        {/* for DEBUG use: */}
        {/* <div className={classes.featurePointRow}>
          <FormGroup row={true}>
            <FormControlLabel
              className={classes.checkboxLabel}
              control={
                <Checkbox
                  name="CheckboxFeaturePoint"
                  value={checkedFeaturePoint}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    this.setState({ checkedFeaturePoint: checked });
                    scanViewerContext.setFeaturePointsState(
                      checked,
                      numFeaturePoints
                    );
                  }}
                />
              }
              label="Show Feature Points"
            />
          </FormGroup>

          <TextField
            name="Textfield"
            className={classes.textfieldNumber}
            label="Point Count"
            type="number"
            size="small"
            value={numFeaturePoints}
            InputProps={{ inputProps: { max: 5, min: 1 } }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              const numberFeaturePoints = e.target.value;
              this.setState({ numFeaturePoints: numberFeaturePoints });
              scanViewerContext.setFeaturePointsState(
                checkedFeaturePoint,
                numberFeaturePoints
              );
            }}
          />

          <IconButton
            className={classes.iconButton}
            onClick={() =>
              window.openWarningDialog([
                "Activates feature points and sets the number of feature points to be displayed.",
                "Feature points are points, that the computer uses for orientation. The Scanner needs them to compare the position of different images.",
                "This function is mainly for development use.",
              ])
            }
          >
            <Info />
          </IconButton>
        </div> */}
      </div>
    );
  }
}

ScanMicroscopeSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  scanViewerContext: PropTypes.object.isRequired,
};

export default withScanViewerContext(
  withStyles(styles)(ScanMicroscopeSettings)
);

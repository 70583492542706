import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import PropTypes from "prop-types";
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
} from "@mui/material";
import { withScanViewerContext } from "../contexts/ScanViewerContext";

const styles = {
  cardStyle: {
    position: "absolute",
    top: 10,
    left: 0,
    minWidth: 235,
    width: 570,
    maxWidth: "calc(100% - 16px)",
    margin: 8,
  },
  previousButtonStyle: {
    position: "absolute",
    left: 11,
  },
  nextButtonStyle: {
    position: "absolute",
    left: 91,
  },
  cardContentStyle: {
    padding: 16,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  cardActionsStyle: {
    height: 37,
    padding: 0,
    "&:last-child": {
      paddingBottom: 8,
    },
  },
  closeButtonStyle: {
    position: "absolute",
    right: 8,
  },
};

const cameraSettings = {
  heading: "Camera settings",
  info: [
    "Start by adjusting the camera settings",
    "by adjusting the picture by using the sliders.",
    "",
    "",
    "",
    "",
    "",
  ],
};
const recordVignette = {
  heading: "Record vignette",
  info: [
    "Click on the fluorescence button, if you want to scan fluorescence. And press NEXT",
    "Record the vignette to compensate camera errors.",
    "1. Move the visible picture to a mostly white area.",
    "2. Click on Record Vignette.",
    "3. Move the microscope table for about 1 to 2 seconds in different directions.",
    "4. Click on Stop Recording.",
    "5. Click on Filter Vignette.",
  ],
};
const fluorescenceSettings = {
  heading: "Fluorescence",
  info: [
    "1. Add channels by typing in the name and pressing Enter or the + symbol",
    "2. Select the channel you want to scan and click on Play",
    "",
    "",
    "",
    "",
    "",
  ],
};
const startScan = {
  heading: "Start scan",
  info: [
    "1. Move to the position where you want to start your scan.",
    "2. Adjust the sharpness.",
    "3. Press the Play button and start your scan.",
    "",
    "",
    "",
    "",
  ],
};
const duringScan = {
  heading: "Scanning",
  info: [
    "If the rectangle is red, the picture is out of focus or the Scanner lost the picture.",
    "You can correct the sharpness benchmark in the Settings.",
    "If you finished scanning click on the Pause button.",
    "If you want to reset the scan, delete the last tiles, click on the Pause button.",
    "",
    "",
    "",
  ],
};
const pausedScan = {
  heading: "Paused",
  info: [
    "To save the scan click on the Save button at the bottom of the toolbar.",
    "If you want to remove tiles, right click on the tile.",
    "If you want to reset the scan, click on the Reset button.",
    "If you scan fluorescence, you can add, delete and select other channels in the fluorescence tab.",
    "",
    "",
  ],
};
const saveSettings = {
  heading: "Save settings",
  info: [
    "Select the correct lense and adapter settings.",
    "1. Input the file name, the created slide file should be called",
    "2. Select the directory where the file should be saved.",
    "3. Select whether white balancie should be applied, see preview image.",
    "4. Select the lense magnification you have used for your scan.",
    "5. Select the right adapter magnification the camera is attached to.",
    "",
  ],
};

/**
 * class to render comments and handle interaction
 */
class ScanComments extends Component {
  constructor(props) {
    super(props);
    this.texts = [
      //if changes happen here, make sure that right indeces are in ScanViewerContext (changeScanSettings)
      cameraSettings,
      recordVignette,
      fluorescenceSettings,
      startScan,
      duringScan,
      pausedScan,
      saveSettings,
    ];
  }

  render() {
    const { classes, scanViewerContext } = this.props;
    const { commentsVisible } = scanViewerContext;
    const count = scanViewerContext.commentCount;
    return (
      <div>
        <Card
          className={classes.cardStyle}
          sx={{ visibility: commentsVisible ? "visible" : "hidden" }}
        >
          <CardContent className={classes.cardContentStyle}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Tips:
            </Typography>
            <Typography variant="h5" component="div">
              {this.texts[count].heading}
            </Typography>
            <Typography component={"span"} variant="body2">
              {this.texts[count].info.map((row, idx) => {
                return (
                  <div key={idx} style={{ height: 20 }}>
                    {row}
                  </div>
                );
              })}
            </Typography>
          </CardContent>
          <CardActions className={classes.cardActionsStyle}>
            <Button
              className={classes.previousButtonStyle}
              disabled={
                count === 0 ||
                count ===
                  this.texts.findIndex((element) => element == duringScan) ||
                count ===
                  this.texts.findIndex((element) => element == pausedScan)
              }
              size="small"
              onClick={() => {
                scanViewerContext.setState({ commentCount: count - 1 });
              }}
            >
              Previous
            </Button>
            <Button
              className={classes.nextButtonStyle}
              disabled={
                count === this.texts.length - 1 ||
                count ===
                  this.texts.findIndex((element) => element == duringScan) ||
                count ===
                  this.texts.findIndex((element) => element == startScan) ||
                count ===
                  this.texts.findIndex((element) => element == pausedScan)
              }
              size="small"
              onClick={() => {
                scanViewerContext.setState({ commentCount: count + 1 });
              }}
            >
              Next
            </Button>
            <Button
              className={classes.closeButtonStyle}
              size="small"
              onClick={() => {
                scanViewerContext.setState({ commentsVisible: false });
              }}
            >
              Close
            </Button>
          </CardActions>
        </Card>
      </div>
    );
  }
}

ScanComments.propTypes = {
  classes: PropTypes.object.isRequired,
  scanViewerContext: PropTypes.object.isRequired,
};

export default withScanViewerContext(withStyles(styles)(ScanComments));

import React, { useState, useEffect } from "react";
import { Paper } from "@mui/material";
import CasesTable from "./components/CasesTable";
import Backend from "../common/utils/Backend";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    background: "#EEEEEE",
    width: "100%",
    height: "100%",
    padding: 5,
  },
  casesContainer: {
    height: "100%",
  },
  paper: {
    padding: 0,
    display: "flex",
    justifyContent: "center",
    overflow: "auto",
    height: "100%",
  },
});

// orders case columns lilke appsettingsCols, if col exist, otherwise at the end
const orderCaseCols = (appsettingsCols, allCaseCalls) => {
  return allCaseCalls.sort((a, b) => {
    const aIdx = appsettingsCols.findIndex((col) => col.name === a.id);
    const bIdx = appsettingsCols.findIndex((col) => col.name === b.id);
    if (aIdx === -1 && bIdx === -1) {
      return 0;
    } else if (aIdx === -1) {
      return 1;
    } else if (bIdx === -1) {
      return -1;
    } else {
      return aIdx - bIdx;
    }
  });
};

const CasesPage = () => {
  const classes = useStyles();
  const [cases, setCases] = useState([]);
  const [caseColumnsData, setCaseColumnsData] = useState([]);

  useEffect(() => {
    updateCases();
  }, []);

  const updateCases = () => {
    Promise.all([
      Backend.listCases(),
      Backend.readAppSettings()?.then((newAppsettings) => {
        return newAppsettings;
      }),
    ]).then(([casesResponse, appsettingsResponse]) => {
      const cases = casesResponse?.cases.map((item) => {
        const singleCase = item.data;
        singleCase.Files = item.filePaths;
        return singleCase;
      });
      const initCaseCols = appsettingsResponse?.caseColumns;

      setCases(cases);
      let caseColumnsData = [];
      if (cases.length > 0) {
        caseColumnsData = Object.keys(cases[0])
          .filter((key) => key !== "Id")
          .map((key) => ({ id: key, label: key, visible: false }));
      }

      const newCaseCols = caseColumnsData.map((caseColumn) => {
        const matchingCaseCol = initCaseCols?.find(
          (initCaseCol) => initCaseCol.name === caseColumn.id
        );
        if (matchingCaseCol && matchingCaseCol.id === "Files") {
          matchingCaseCol.visible = true;
        }
        return {
          id: caseColumn.id,
          label: matchingCaseCol?.label ?? caseColumn.label,
          visible: matchingCaseCol?.visible === "true" ?? false,
          items: matchingCaseCol?.items ?? [],
        };
      });

      // Make sure Files is always visible at the beginning
      newCaseCols.find((initCaseCol) => {
        if (initCaseCol.id === "Files") {
          initCaseCol.visible = true;
        }
      });

      const orderedNewCaseCols = orderCaseCols(initCaseCols, newCaseCols);
      setCaseColumnsData(orderedNewCaseCols);
    });
  };

  const onDeleteRow = (row) => {
    window.openResponseDialog("Delete Case forever?", (response) => {
      if (response) {
        Backend.deleteCase(row.Id).then((response) => {
          if (response?.success) {
            window.showSuccessSnackbar("Case deleted");
          } else {
            window.showErrorSnackbar("Case could not be deleted");
          }
          updateCases();
        });
      }
    });
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <CasesTable
          caseColumnsData={caseColumnsData}
          cases={cases}
          onDeleteRow={onDeleteRow}
          updateCases={updateCases}
        />
      </Paper>
    </div>
  );
};

export default CasesPage;

import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "@mui/material/SvgIcon";

function ExclusionBoxIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      <rect x="0" y="0" width="20" height="40" fill={props.color} />
      <rect x="25" y="0" width="20" height="40" fill={props.color} />
    </SvgIcon>
  );
}

ExclusionBoxIcon.propTypes = {
  color: PropTypes.string,
};

export default ExclusionBoxIcon;

import React, { Component } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

class CustomDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      title: "",
      content: "",
      dialogState: "confirm",
      responseFunction: null,
    };

    window.openResponseDialog = this.handleConfirmOpen;
    window.openErrorDialog = this.handleErrorOpen;
    window.openWarningDialog = this.handleWarningOpen;
    window.openConfirmationDialog = this.awaitUserConfirmation;
  }

  handleConfirmOpen = (title, responseFunction) => {
    this.setState({
      title: title,
      content: "",
      open: true,
      dialogState: "confirm",
      responseFunction: responseFunction,
    });
  };

  /**
   * Promt the user for a yes/no decision that will pause program execution until the choise is made.
   * @param {string} title The short title of the user-prompt to show.
   * @param {string} content Longer content in the body of the propmt.
   * @returns {Promise} A promise that returns the user choise as boolean.
   */
  awaitUserConfirmation = async (title, content) => {
    return new Promise((resolve) => {
      this.setState({
        title: title,
        content: content ? content : "",
        open: true,
        dialogState: "confirm",
        responseFunction: (confirmed) => resolve(confirmed),
      });
    });
  };

  handleErrorOpen = (content) => {
    this.setState({
      title: "Error",
      content: content,
      dialogState: "error",
      open: true,
    });
  };

  handleWarningOpen = (title) => {
    this.setState({
      title: title,
      content: "",
      dialogState: "warning",
      open: true,
    });
  };

  handleClose = (confirmed) => {
    this.setState(
      {
        open: false,
      },
      this.setState({ title: "", content: "", responseFunction: null })
    );
    if (this.state.dialogState === "confirm" && this.state.responseFunction) {
      this.state.responseFunction(confirmed);
    }
  };

  downloadError = () => {
    let filename = "hsa_error_log.txt";
    let text = this.state.content;
    let element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  render() {
    const { dialogState, open, title, content } = this.state;
    return (
      open && (
        <Dialog
          open={open}
          onClose={() => this.handleClose(false)}
          aria-labelledby="responsive-dialog-title"
        >
          {typeof title === "object" && title.length > 1 ? (
            <DialogTitle>
              {title.map((row, idx) => {
                return <div key={idx}>{row}</div>;
              })}
            </DialogTitle>
          ) : (
            <DialogTitle>{title}</DialogTitle>
          )}

          <React.Fragment>
            {(dialogState === "confirm" || dialogState === "error") && (
              <DialogContent style={{ overflow: "visible" }}>
                {dialogState === "confirm" && content !== "" && (
                  <DialogContentText>{content}</DialogContentText>
                )}
                {dialogState === "error" && (
                  <div style={{ width: 532 }}>
                    <TextField
                      label="Error Message"
                      name="Textfield"
                      multiline
                      fullWidth
                      value={content ? content : ""}
                      variant="outlined"
                    />
                  </div>
                )}
              </DialogContent>
            )}
          </React.Fragment>

          {/* Buttons */}
          <DialogActions>
            {dialogState === "confirm" && (
              <Button
                autoFocus
                onClick={() => this.handleClose(false)}
                color="primary"
              >
                Cancel
              </Button>
            )}
            {dialogState === "error" && (
              <Button onClick={() => this.downloadError()} color="primary">
                Download Log File
              </Button>
            )}
            <Button
              onClick={() => this.handleClose(true)}
              color="primary"
              autoFocus
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )
    );
  }
}

export default CustomDialog;

import React from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";

import clsx from "clsx";
import { styled } from "@mui/material/styles";
import { TableCell, Button } from "@mui/material";
import { AutoSizer, Column, Table } from "react-virtualized";
import { convertDate } from "../../common/utils/Localization";
import DayPagination from "./DayPagination";
import Backend from "../../common/utils/Backend";

const classes = {
  flexContainer: "ReactVirtualizedDemo-flexContainer",
  tableRow: "ReactVirtualizedDemo-tableRow",
  tableRowHover: "ReactVirtualizedDemo-tableRowHover",
  tableCell: "ReactVirtualizedDemo-tableCell",
  noClick: "ReactVirtualizedDemo-noClick",
};

const styles = () => ({
  root: {
    margin: 10,
    height: "100%",
    display: "grid",
    gridTemplateRows: "auto 1fr",
  },
  inlineBtn: {
    position: "relative",
    top: "12px",
  },

  // temporary right-to-left patch, waiting for
  // https://github.com/bvaughn/react-virtualized/issues/454
  "& .ReactVirtualized__Table__headerRow": {
    paddingRight: undefined,
  },
  [`& .${classes.flexContainer}`]: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
  },
  [`& .${classes.tableRow}`]: {
    cursor: "pointer",
  },
  [`& .${classes.tableCell}`]: {
    flex: 1,
  },
  [`& .${classes.noClick}`]: {
    cursor: "initial",
  },
});

class MuiVirtualizedTable extends React.PureComponent {
  static defaultProps = {
    headerHeight: 48,
    rowHeight: 48,
  };

  getRowClassName = ({ index }) => {
    const { onRowClick } = this.props;

    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };

  cellRenderer = ({ cellData, columnIndex }) => {
    const { columns, rowHeight, onRowClick } = this.props;
    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{ height: rowHeight }}
        align={
          (columnIndex != null && columns[columnIndex].numeric) || false
            ? "right"
            : "left"
        }
      >
        {cellData}
      </TableCell>
    );
  };

  headerRenderer = ({ label, columnIndex }) => {
    const { headerHeight, columns } = this.props;

    return (
      <TableCell
        component="div"
        className={clsx(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick
        )}
        variant="head"
        style={{ height: headerHeight }}
        align={columns[columnIndex].numeric || false ? "right" : "left"}
      >
        <span>{label}</span>
      </TableCell>
    );
  };

  render() {
    const { columns, rowHeight, headerHeight, ...tableProps } = this.props;
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width}
            rowHeight={rowHeight}
            gridStyle={{
              direction: "inherit",
            }}
            headerHeight={headerHeight}
            {...tableProps}
            rowClassName={this.getRowClassName}
          >
            {columns.map(({ dataKey, ...other }, index) => {
              return (
                <Column
                  key={dataKey}
                  headerRenderer={(headerProps) =>
                    this.headerRenderer({
                      ...headerProps,
                      columnIndex: index,
                    })
                  }
                  className={classes.flexContainer}
                  cellRenderer={this.cellRenderer}
                  dataKey={dataKey}
                  {...other}
                />
              );
            })}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

MuiVirtualizedTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      width: PropTypes.number.isRequired,
    })
  ).isRequired,
  headerHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  rowHeight: PropTypes.number,
};

const VirtualizedTable = styled(MuiVirtualizedTable)(styles);

const columns = [
  { dataKey: "time", label: "Time", width: 200 },
  { dataKey: "name", label: "Name", width: 120 },
  { dataKey: "actionType", label: "Action Type", width: 120 },
  { dataKey: "action", label: "Action", width: 500 },
];

function UserHistoryTable(props) {
  const { classes, userActions } = props;

  let rows = [];
  if (userActions) {
    rows = userActions.map((userAction, idx) => {
      userAction.id = idx;
      userAction.time = convertDate(userAction.time).toLocaleString();
      return userAction;
    });
  }

  const onDownload = (date) => {
    Backend.downloadUserActions(date);
  };

  return (
    <div className={classes.root}>
      <div>
        <DayPagination
          date={props.date}
          updateTable={props.updateTable}
          firstDate={props.firstDate}
        />
        <Button
          className={classes.inlineBtn}
          onClick={() => onDownload(props.date)}
        >
          Download as CSV File
        </Button>
      </div>
      <div>
        <VirtualizedTable
          rowCount={rows.length}
          rowGetter={({ index }) => rows[index]}
          columns={columns}
        />
      </div>
    </div>
  );
}

UserHistoryTable.propTypes = {
  classes: PropTypes.object.isRequired,
  date: PropTypes.object.isRequired,
  firstDate: PropTypes.object.isRequired,
  userActions: PropTypes.array.isRequired,
  updateTable: PropTypes.func.isRequired,
};

export default withStyles(styles)(UserHistoryTable);

import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";

import { IconButton, TextField, Tooltip } from "@mui/material";
import {
  PlayCircleOutline,
  PauseCircleOutline,
  ArrowLeft,
  ArrowRight,
} from "@mui/icons-material";

import {
  getFigure,
  predictStartPointIndex,
  traversalPolylineClockwise,
} from "../utils/GeometricInterpolation";
import { RegionROI } from "../utils/ROI";
import RBush from "rbush";

import TimeChart from "./TimeChart";

const tongueWidth = 30;

const styles = {
  root: {
    padding: 0,
  },

  stepWalker: {
    backgroundColor: "rgb(211,211,211)",
    position: "relative",
    height: 50,
  },

  container: {
    position: "relative",
    // add scrolling, if needed
    overflow: "auto",
    boxShadow: "0px -2px 10px 0px rgba(0,0,0,0.29)",
    //border: "2px solid rgb(0, 0, 200)",
    height: "calc(100% - 80px)",
    background: "white",
  },

  stepLeft: {
    position: "absolute",
    left: 5,
    top: 6,
    padding: 0,
    color: "rgb(6,115,193)",
  },

  timeCounter: {
    textAlign: "center",
    color: "rgb(6,115,193)",
  },

  modePanel: {
    backgroundColor: "rgb(211,211,211)",
    position: "relative",
    boxShadow: "0px -2px 10px 0px rgba(0,0,0,0.29)",
    height: 30,
  },

  interpolate: {
    padding: 0,
    borderWidth: 0,
    outline: 0,
    display: "inline-block",
    position: "relative",
    textAlign: "center",
    left: 10,
    fontSize: 12 + "px",
    top: 2,
    color: "rgb(6,115,193)",
    height: 20,
    width: 100,
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  framerate: {
    marginLeft: 20,
    color: "red",
    width: 60,
    "& div": {
      color: "black",
      marginTop: 4,
      width: 52,
    },
    "& label": {
      color: "rgb(6,115,193) !important",
      fontSize: 10,
      lineHeight: "12px",
      padding: 0,
      margin: 0,
    },
    "& input": {
      padding: "6px 0 0px",
      fontSize: 12,
    },
  },

  playButtons: {
    display: "flex",
    justifyContent: "center",
  },

  playPauseForward: {
    marginLeft: 10,
    display: "inline-block",
    padding: 0,
    color: "rgb(6,115,193)",
  },

  playPauseBackward: {
    display: "inline-block",
    padding: 0,
    color: "rgb(6,115,193)",
    transform: "scale(-1, 1)",
  },

  stepRight: {
    position: "absolute",
    right: 5,
    top: 6,
    padding: 0,
    color: "rgb(6,115,193)",
  },

  resizeTongue: {
    backgroundColor: "rgb(211,211,211)",
    position: "absolute",
    height: 9,
    width: tongueWidth,
    marginLeft: -tongueWidth / 2,
    bottom: 0,
    left: "50%",
    boxShadow: "0px -2px 10px -2px rgba(0,0,0,0.29)",
    //boxShadow: "1px -13px 19px 0px rgba(0,0,0,0.55)",
    cursor: "row-resize",
    zIndex: 999999,
  },

  resizingLine: {
    backgroundColor: "#888",
    marginTop: 4,
    marginLeft: 4,
    marginRight: 4,
    height: 1,
  },
};

const frameMinWidth = 14;
const frameMaxWidth = 28;

class TimeLineTool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensions: null,
      isResizing: false,
      resizePos: 0,
    };
  }

  componentDidMount() {
    document.addEventListener("mousemove", this.handleResizeMouseMove);
    document.addEventListener("mouseup", this.handleResizeMouseUp);
    this.setState({
      dimensions: {
        width: this.container.offsetWidth,
        height: this.container.offsetHeight,
      },
    });
  }

  componentWillUnmount() {
    document.removeEventListener("mousemove", this.handleResizeMouseMove);
    document.removeEventListener("mouseup", this.handleResizeMouseUp);
  }

  setPosByMouse(coordX) {
    const { dimensions } = this.state;
    const { ome, onChangeT } = this.props;
    let frameWidth = Math.floor(dimensions.width / ome.sizeT);
    if (frameWidth < frameMinWidth) {
      frameWidth = frameMinWidth;
    }
    if (frameWidth > frameMaxWidth) {
      frameWidth = frameMaxWidth;
    }
    // rect with borders in context of client window of the browser
    const rect = this.innerContainer.getBoundingClientRect();
    let x = Math.floor(
      (coordX - rect.left - this.innerContainer.clientLeft) / frameWidth
    );
    if (x < 0) {
      // x is frame number
      x = 0;
    }
    if (x >= ome.sizeT) {
      x = ome.sizeT - 1;
    }

    onChangeT(x);
  }

  handleMouseDown = (ev) => {
    if (ev.button !== 0) {
      return;
    }
    this.setPosByMouse(ev.clientX);
  };

  handleMouseMove = (ev) => {
    if (!(ev.buttons & 1)) {
      return;
    }
    this.setPosByMouse(ev.clientX);
  };

  searchPrevKeyFrame(curT, structureIndex, frameArray) {
    for (let i = curT - 1; i >= 0; i--) {
      const layer = this.findLayer(structureIndex, frameArray[i]);
      if (layer && layer.isKeyFrame) {
        return i;
      }
    }
    return -1;
  }

  searchNextKeyFrame(curT, structureIndex, frameArray) {
    for (let i = curT + 1; i < this.props.ome.sizeT; i++) {
      const layer = this.findLayer(structureIndex, frameArray[i]);
      if (layer && layer.isKeyFrame) {
        return i;
      }
    }
    return -1;
  }

  findLayer(structureIndex, frameArrayItem) {
    if (!frameArrayItem || !this.props.structures[structureIndex]) {
      return null;
    }
    for (let layer of frameArrayItem) {
      if (layer.id === this.props.structures[structureIndex].id) {
        return layer;
      }
    }
    return null;
  }

  getOrCreateFrameArrayItem(frame) {
    if (this.props.frameArray[frame]) {
      return this.props.frameArray[frame];
    }
    this.props.frameArray[frame] = [];
    return this.props.frameArray[frame];
  }

  getOrCreateRoiLayer(frameArrayItem, structureIndex) {
    const roiLayer = frameArrayItem.find(
      (x) => x.id === this.props.structures[structureIndex].id
    );
    if (roiLayer) {
      return roiLayer;
    }
    const newRoiLayer = {
      id: this.props.structures[structureIndex].id,
      layer: {
        inverted: false,
        regionRois: [],
      },
      tree: new RBush(),
    };
    frameArrayItem.push(newRoiLayer);
    return newRoiLayer;
  }

  handleInterpolation = () => {
    const frameArray = this.props.frameArray;
    const selectedLayer = this.props.selectedLayer;
    const curT = Math.floor(this.props.time);
    const layer = this.findLayer(selectedLayer, frameArray[curT]);
    if (!layer || (layer.isKeyFrame && layer.layer.regionRois.length > 0)) {
      return;
    }
    const prevKeyFrame = this.searchPrevKeyFrame(
      curT,
      selectedLayer,
      frameArray
    );
    const nextKeyFrame = this.searchNextKeyFrame(
      curT,
      selectedLayer,
      frameArray
    );
    if (prevKeyFrame < 0 || nextKeyFrame < 0) {
      return;
    }
    const regionRoisStart = this.findLayer(
      selectedLayer,
      frameArray[prevKeyFrame]
    ).layer.regionRois;
    const regionRoisEnd = this.findLayer(
      selectedLayer,
      frameArray[nextKeyFrame]
    ).layer.regionRois;
    const figureCount = Math.min(regionRoisStart.length, regionRoisEnd.length);
    const isSubtype =
      this.props.structures[this.props.selectedLayer].isSubtype &&
      this.props.structures[this.props.selectedLayer].classificationSubtype;
    const name = isSubtype
      ? this.props.structures[this.props.selectedLayer].label
      : "";
    const color = this.props.structures[this.props.selectedLayer].color;
    const pointsCount = 100;
    for (let frame = prevKeyFrame + 1; frame < nextKeyFrame; frame++) {
      const frameArrayItem = this.getOrCreateFrameArrayItem(frame);
      const requiredLayer = this.getOrCreateRoiLayer(
        frameArrayItem,
        selectedLayer
      );
      requiredLayer.layer.regionRois = [];
    }
    for (let figureIndex = 0; figureIndex < figureCount; figureIndex++) {
      const figureStart = regionRoisStart[figureIndex];
      const figureEnd = regionRoisEnd[figureIndex];
      const startPointIndexA = predictStartPointIndex(
        figureStart.regions,
        pointsCount
      );
      const startPointIndexB = predictStartPointIndex(
        figureEnd.regions,
        pointsCount
      );
      const figAIsClockwise = traversalPolylineClockwise(figureStart.regions);
      const figBIsClockwise = traversalPolylineClockwise(figureEnd.regions);
      for (let frame = prevKeyFrame + 1; frame < nextKeyFrame; frame++) {
        const interpolatedFigure = getFigure(
          figureStart.regions,
          figureEnd.regions,
          startPointIndexA,
          startPointIndexB,
          figAIsClockwise,
          figBIsClockwise,
          pointsCount,
          prevKeyFrame,
          nextKeyFrame,
          frame
        );
        const frameArrayItem = this.getOrCreateFrameArrayItem(frame);
        const requiredLayer = this.getOrCreateRoiLayer(
          frameArrayItem,
          selectedLayer
        );
        const regionRoi = new RegionROI({
          regions: interpolatedFigure,
          color1: color,
          subtype: isSubtype,
          name: name,
          structureId: this.props.structures[selectedLayer].id,
        });

        requiredLayer.layer.regionRois.push(regionRoi);
      }
    }
    this.forceUpdate();
  };

  handleResizeMouseDown = (e) => {
    this.setState({
      isResizing: true,
      resizePos: e.pageY,
    });
  };

  handleResizeMouseMove = (e) => {
    if (!this.state.isResizing) {
      return;
    }
    const newResizePos = e.pageY;
    const deltaY = this.state.resizePos - newResizePos;
    this.setState({
      resizePos: newResizePos,
    });
    this.props.resizeTimeLine(deltaY, false);
  };

  handleResizeMouseUp = (e) => {
    if (!this.state.isResizing) {
      return;
    }
    const newResizePos = e.pageY;
    const deltaY = this.state.resizePos - newResizePos;
    this.setState({
      resizePos: newResizePos,
      isResizing: false,
    });
    this.props.resizeTimeLine(deltaY, true);
  };

  renderContent() {
    const { dimensions } = this.state;
    const {
      //classes, // will be added by withStyles func
      // ome.sizeT --- count of frames in the videosequence
      ome,
      // time --- current frame
      time,
      structures,
      frameArray,
    } = this.props;
    let frameWidth = dimensions.width / ome.sizeT;
    let innerWidth = "100%";
    if (frameWidth < frameMinWidth) {
      frameWidth = frameMinWidth; // width of one frame in TimeLineTool
      innerWidth = frameMinWidth * ome.sizeT + "px";
    }

    if (frameWidth > frameMaxWidth) {
      frameWidth = frameMaxWidth;
      innerWidth = frameWidth * ome.sizeT + "px";
    }

    const frameSeparatorColor = "rgb(230, 230, 230)";
    const bgImage =
      `repeating-linear-gradient(to right, ${frameSeparatorColor}, ` +
      `${frameSeparatorColor} 2px, white 2px, ` +
      `white ${frameWidth}px)`;
    //console.log('structures', this.props.structures);
    const charts = [];
    if (structures && structures.length > 1) {
      const frameIndices = Object.keys(frameArray)
        .map((x) => +x)
        .sort((x, y) => x - y);
      for (
        let structureIndex = 1;
        structureIndex < structures.length;
        structureIndex++
      ) {
        const structure = structures[structureIndex];
        if (!structure.isUnfolded) {
          continue;
        }
        const keyFrames = {};
        let prevKeyFrame = -1;
        const interpolatedIntervals = [];
        if (frameArray) {
          for (let frameIndex of frameIndices) {
            if (frameArray[frameIndex]) {
              const frameInfo = frameArray[frameIndex];
              const roiLayer = frameInfo.find(
                (roiLayer) => roiLayer.id === structure.id
              );
              if (
                roiLayer &&
                /* roiLayer.layer && roiLayer.layer.regionRois
                && roiLayer.layer.regionRois.length > 0 && */ roiLayer.isKeyFrame
              ) {
                if (prevKeyFrame > -1 && frameIndex - prevKeyFrame !== 1) {
                  const frameInfo = frameArray[prevKeyFrame + 1];
                  if (frameInfo) {
                    const roiLayer = frameInfo.find(
                      (roiLayer) => roiLayer.id === structure.id
                    );
                    if (
                      roiLayer &&
                      roiLayer.layer &&
                      roiLayer.layer.regionRois &&
                      roiLayer.layer.regionRois.length > 0
                    ) {
                      interpolatedIntervals.push([prevKeyFrame, frameIndex]);
                    }
                  }
                }
                keyFrames[frameIndex] = true;
                prevKeyFrame = frameIndex;
              }
            }
          }
        }
        charts.push(
          <TimeChart
            key={structure.id}
            structure={structure}
            isSelected={structureIndex === this.props.selectedLayer}
            keyFrames={keyFrames}
            interpolatedIntervals={interpolatedIntervals}
            frameWidth={frameWidth}
          />
        );
      }
    }

    const addLength = charts.length * 50 + this.container.offsetHeight;

    //Fragment is used to return multiple elements on the highest hierarchy level
    return (
      <div
        onMouseDown={this.handleMouseDown}
        onMouseMove={this.handleMouseMove}
        style={{
          border: "1px",
          paddingTop: "1px",
          backgroundImage: bgImage,
          width: innerWidth,
          height: addLength + "px",
          position: "relative",
        }}
        ref={(el) => (this.innerContainer = el)}
      >
        {charts}

        <div
          style={{
            width: "2px",
            height: "100%",
            left: time * frameWidth + "px",
            top: "0px",
            // position will be defined as absolute relative to the closest html elem with position attr "relative"
            position: "absolute",
            backgroundColor: "black",
          }}
        ></div>
      </div>
    );
  }

  render() {
    const { dimensions } = this.state;
    const {
      classes,
      time,
      onStep,
      ome,
      onPlayPause,
      playing,
      playDirection,
      onChangeSr,
      sr,
    } = this.props;

    return (
      <div
        className={classes.root}
        style={{ height: this.props.timeLineHeight }}
      >
        <div style={{ position: "relative" }}>
          <div
            className={classes.resizeTongue}
            onMouseDown={this.handleResizeMouseDown}
          >
            <div className={classes.resizingLine}></div>
          </div>
        </div>
        <div className={classes.modePanel}>
          <input
            type="button"
            value="interpolate"
            onClick={this.handleInterpolation}
            className={classes.interpolate}
          />
          <Tooltip disableInteractive title={"Frames per minute: " + sr}>
            <TextField
              className={classes.framerate}
              name="Textfield"
              label="FPM"
              value={sr}
              min={1}
              max={1500}
              type="number"
              onChange={(e) =>
                onChangeSr(
                  Math.max(1, Math.min(1500, parseInt(e.target.value, 10)))
                )
              }
            />
          </Tooltip>
        </div>
        <div className={classes.stepWalker}>
          <IconButton
            className={classes.stepLeft}
            onClick={() => onStep(-1)}
            disabled={time <= 0}
            size="large"
          >
            <ArrowLeft color="primary" />
          </IconButton>
          <div className={classes.timeCounter}>
            {Math.floor(time) + 1}/{ome.sizeT}
          </div>
          <div className={classes.playButtons}>
            <IconButton
              className={classes.playPauseBackward}
              onClick={() =>
                playDirection < 0
                  ? onPlayPause(!playing, -1)
                  : onPlayPause(true, -1)
              }
              size="large"
            >
              {playing && playDirection < 0 ? (
                <PauseCircleOutline color="primary" />
              ) : (
                <PlayCircleOutline color="primary" />
              )}
            </IconButton>
            <IconButton
              className={classes.playPauseForward}
              onClick={() =>
                playDirection > 0
                  ? onPlayPause(!playing, 1)
                  : onPlayPause(true, 1)
              }
              size="large"
            >
              {playing && playDirection > 0 ? (
                <PauseCircleOutline color="primary" />
              ) : (
                <PlayCircleOutline color="primary" />
              )}
            </IconButton>
          </div>
          <IconButton
            className={classes.stepRight}
            onClick={() => onStep(1)}
            disabled={time >= ome.sizeT - 1}
            size="large"
          >
            <ArrowRight color="primary" />
          </IconButton>
        </div>
        <div className={classes.container} ref={(el) => (this.container = el)}>
          {dimensions && this.renderContent()}
        </div>
      </div>
    );
  }
}

// define the component's interface
TimeLineTool.propTypes = {
  classes: PropTypes.object.isRequired,
  ome: PropTypes.object,
  playing: PropTypes.bool,
  time: PropTypes.number,
  onPlayPause: PropTypes.func,
  onSeek: PropTypes.func,
  playDirection: PropTypes.number,
  onChangeSr: PropTypes.func,
  onChangeT: PropTypes.func,
  sr: PropTypes.number,
  onStep: PropTypes.func,
  structures: PropTypes.array,
  frameArray: PropTypes.object,
  selectedLayer: PropTypes.number,
  resizeTimeLine: PropTypes.func,
  timeLineHeight: PropTypes.number,
};

// withStyles is used to apply defined styles to TimeLineTool via passed classes
export default withStyles(styles)(TimeLineTool);

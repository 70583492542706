// Framework imports
import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";

// External imports
import {
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Add,
  Delete,
  GetApp,
  Publish,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";

// HSA Imports
import Structure, {
  exportStructures,
  importStructures,
  insertStructure,
  newStructureId,
} from "../../common/components/Structure";
import { AnnotationAction } from "../../common/components/RoiTypes";
import RecursiveStructureItem from "./StructureItem";
import { getUnusedColor } from "../../common/utils/RandomColorGenerator";

const styles = {
  root: {
    display: "grid",
    gridTemplateRows: "auto auto auto 1fr",
    height: "100%",
    overflow: "hidden",
  },
  structureButtons: {
    display: "grid",
    gridTemplateColumns: "1fr auto auto auto auto",
  },
  structureList: {
    overflow: "auto",
  },
  newStructure: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    paddingRight: "10px",
  },
};

/**
 * Displays all structures of a project in a collapsible manner.
 * @param {object} project The project the strucuture tree referrs to.
 * @param {object} user The current user.
 * @param {array} structures Optional. All structures of a project. Default: [].
 * @param {function} setStructures Optional. Update all structures of a project. Receives (array structures). Default: () => {}.
 * @param {Structure} selectedStructure Optional. The currently selected structure. Default: null.
 * @param {function} setSelectedStructure Optional. Set the currently selected structure. Receives (Structure structure). Default: () => {}.
 * @param {function} triggerAction Optional. Flag a structure to perform an AnnotationAction. Receives (Structure: structure, AnnotationAction: action). Default: () => {}.
 * @param {boolean} isLocked Optional. Flag if the structure tree is locked. When locked, no modifications are possible and symbols are hidden. Default: false.
 * @param {function} allowChoices Optional. Flag if the structure tree should allow choices. Default: false.
 * @param {boolean|function} structureSelectability Optional. Evalaute or determine if a structure is selectable. A function receives (Structure: structure) and returns (boolean: isSelectable).
 * @param {boolean} allStructuresEnabled Optional. Flag if all structures are enabled. Overrides structureSelectability. Default: true.
 * @returns React node for StructureTree.
 */
export default function StructureTree(props) {
  const {
    project,
    user,
    structures = [],
    setStructures = () => {},
    selectedStructure = null,
    setSelectedStructure = () => {},
    triggerAction = () => {},
    isLocked = false,
    allowChoices: isInSelectionMode = false,
    structureSelectability = true,
    allStructuresEnabled = true,
    trainingSettings = null,
    setTrainingSettings = () => {},
    ...other
  } = props;

  const [allRoisHidden, setAllRoisHidden] = useState(false);
  const [newStructureName, setNewStructureName] = useState("");

  useEffect(() => {
    updateStructureSelectability();
  }, [allStructuresEnabled]);

  /**
   * Toggles all structures to be either shown or hidden
   */
  const toggleAllRoisHidden = () => {
    const updatedStructures = structures.map((s) => {
      s.annotationsAreVisible = !allRoisHidden;
      return s;
    });
    setAllRoisHidden(!allRoisHidden);
    setStructures(updatedStructures);
  };

  /**
   * Determines if the structure can be selected.
   * @param {Structure} structure The structure to be checked.
   * @returns {bool} True if the structure can be selected, false otherwise.
   */
  const canBeChosen = (structure) => {
    if (typeof structureSelectability === "function") {
      return isInSelectionMode && structureSelectability(structure);
    }
    return isInSelectionMode && structureSelectability;
  };

  const updateStructureSelectability = () => {
    setStructures(
      structures.map((s) => {
        s.canBeChosen = canBeChosen(s);
        return s;
      })
    );
  };

  /**
   * Add a new structure to bottom of top-level structures.
   * @param {string} label The name of the new structure.
   */
  const addNewStructure = (label) => {
    if (isLocked) return;

    if (typeof label !== "string") {
      throw TypeError(
        `label must be of type string, received ${typeof label}: ${label}`
      );
    }

    // Do not allow structures without name
    if (label === "") {
      window.showWarningSnackbar("Please name the structure before adding it");
      return;
    }

    setStructures(
      insertStructure(
        new Structure(
          newStructureId(structures),
          label,
          true,
          getUnusedColor(structures),
          true,
          null,
          0,
          false,
          true,
          false,
          0,
          0
        ),
        structures
      )
    );
    setNewStructureName("");
  };

  return (
    <Grid sx={{ ...other.style, ...other.sx, ...styles.root }}>
      {!isLocked && (
        <Grid sx={styles.structureButtons}>
          <Typography
            variant="h6"
            sx={{ padding: "8px 0 0 20px" }}
          >{`Structures (${structures.length})`}</Typography>
          <Tooltip
            disableInteractive
            title={`Delete all annotations of ${
              selectedStructure ? selectedStructure.label : "selected structure"
            }`}
          >
            <IconButton
              onClick={() => {
                if (selectedStructure) {
                  triggerAction(selectedStructure, AnnotationAction.DeleteAll);
                } else {
                  window.showWarningSnackbar("Please select structure first.");
                }
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
          <Tooltip
            disableInteractive
            title={
              allRoisHidden > 0
                ? "Hide all structures [Shift] + [R]"
                : "Show all structures [Shift] + [R]"
            }
          >
            <IconButton onClick={toggleAllRoisHidden}>
              {allRoisHidden ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </Tooltip>
          <Tooltip disableInteractive title="Export structure tree">
            <IconButton
              onClick={() =>
                exportStructures(user.fullName, project, structures)
              }
            >
              <GetApp />
            </IconButton>
          </Tooltip>
          <Tooltip disableInteractive title={"Import structure tree"}>
            <IconButton
              onClick={() =>
                importStructures(
                  newStructureId(structures),
                  (newStructs) => {
                    setStructures(newStructs);
                    window.showSuccessSnackbar(
                      `Successfully imported ${newStructs.length} structures`
                    );
                  },
                  (err) => window.showErrorSnackbar(err)
                )
              }
            >
              <Publish />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
      <TreeView
        sx={styles.structureList}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={structures
          .filter((s) => s.isUnfolded)
          .map((s) => s.id.toString())}
        onNodeToggle={(_, expandedNodeIds) => {
          let newStructures = structures.map((s) => {
            s.isUnfolded = expandedNodeIds.includes(s.id.toString())
              ? true
              : false;
            return s;
          });
          setStructures(newStructures);
        }}
      >
        {structures
          .filter((s) => s instanceof Structure && s.parentId === null)
          .map((structure) => (
            <RecursiveStructureItem
              {...other}
              key={structure.id}
              structure={structure}
              structures={structures}
              setStructures={(structures) => setStructures(structures)}
              selectedStructure={selectedStructure}
              setSelectedStructure={setSelectedStructure}
              triggerAction={triggerAction}
              isLocked={isLocked}
              isInSelectionMode={isInSelectionMode}
              updateChoosability={() => updateStructureSelectability()}
              trainingSettings={trainingSettings}
              setTrainingSettings={setTrainingSettings}
            />
          ))}
      </TreeView>
      {!isLocked && (
        <Grid sx={styles.newStructure}>
          <Tooltip disableInteractive title="Add new structure">
            <IconButton
              onClick={() => addNewStructure(newStructureName)}
              size="large"
            >
              <Add />
            </IconButton>
          </Tooltip>
          <TextField
            variant="standard"
            name="Textfield"
            value={newStructureName}
            helperText={
              "Add new structure to bottom of list by entering its name"
            }
            onChange={(e) => setNewStructureName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                addNewStructure(newStructureName);
              }
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}

StructureTree.propTypes = {
  project: PropTypes.object.isRequired,
  user: PropTypes.object,
  structures: PropTypes.array,
  setStructures: PropTypes.func,
  selectedStructure: PropTypes.instanceOf(Structure),
  setSelectedStructure: PropTypes.func,
  triggerAction: PropTypes.func,
  isLocked: PropTypes.bool,
  allowChoices: PropTypes.bool,
  structureSelectability: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  allStructuresEnabled: PropTypes.bool,
  trainingSettings: PropTypes.object,
  setTrainingSettings: PropTypes.func,
};

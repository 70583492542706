import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import Backend from "../common/utils/Backend";
import withStyles from "@mui/styles/withStyles";

import Datasheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";

import XLSX from "xlsx";
import { Button, Tabs, Tab, Paper } from "@mui/material";

const styles = () => ({
  root: {
    width: "100%",
    height: "100%",
    "& .data-editor": {
      height: "24px !important",
      boxSizing: "border-box !important",
    },
    "& .value-viewer": {
      padding: "0 10px !important",
    },
    display: "grid",
    gridTemplateRows: "auto auto 1fr",
    overflow: "hidden",
  },
  resultTabContent: {
    height: "100%",
    overflowY: "auto",
  },
});

class Report extends Component {
  _isMounted = false;
  state = {
    activeTab: 0,
    settings: { tempPath: "" },
    project: undefined,
    charts: [],
  };
  setMountedState = (stateObject, callback) => {
    if (this._isMounted) {
      this.setState(stateObject, callback);
    }
  };
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    const chartFolderPath = "/" + this.props.id + "/charts/";
    Backend.walkProjectDirFiles(chartFolderPath, (charts) => {
      this.setMountedState({ charts });
    });

    Backend.loadProject(
      {
        id: this.props.id,
      },
      (project) => {
        this.setMountedState({ project: project });
        window.setNavigationbarTitle(
          `Results: ${project.name} (${project.viewerConfig.project.label})`
        );
      }
    );

    Backend.loadReport(
      this.props.id,
      (data) => {
        let workbook = XLSX.read(data, { type: "array" });

        if (
          workbook.SheetNames.length === 1 &&
          workbook.SheetNames[0] === "Sheet1" &&
          workbook.Sheets.Sheet1.A1.v.toLowerCase().startsWith("error")
        ) {
          window.showErrorSnackbar(workbook.Sheets.Sheet1.A1.v);
        }

        let sheets = workbook.SheetNames.map((sheet_name) =>
          XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name], {
            header: 1,
            raw: true,
          })
        );
        for (let i = 0; i < sheets.length; i++) {
          for (let j = 0; j < sheets[i].length; j++) {
            for (let k = 0; k < sheets[i][j].length; k++) {
              if (typeof sheets[i][j][k] === "undefined") {
                sheets[i][j][k] = "";
              }
            }
          }
        }
        const abc = [...Array(26)].map((val, i) => String.fromCharCode(i + 65));
        sheets = sheets.map((sheet) => {
          let rowLength = 0;
          let lastContentIdx = 0;
          for (let i = 0; i < sheet.length; i++) {
            if (sheet[i].length > 0) lastContentIdx = i;
            if (sheet[i].length > rowLength) rowLength = sheet[i].length;
          }
          sheet = sheet.filter((item, idx) => idx <= lastContentIdx);
          return sheet.map((row) => {
            row = row.map((cell) => {
              if (typeof cell === "number") {
                // if (sheet[0][i] && sheet[0][i].includes("%")) {
                //   cell *= 100;
                // }
                if (cell % 1 > 0 && cell < 1) {
                  cell = cell.toFixed(6);
                } else if (cell % 1 > 0) {
                  // Check if float precision exists
                  cell = cell.toFixed(2);
                }
              } else if (
                typeof cell === "string" &&
                cell.includes("Ausreißer ignorieren")
              ) {
                cell = "";
              }
              return { value: cell };
            });
            while (row.length < rowLength) {
              row.push({ value: "" });
            }
            return row;
          });
        });
        for (let sheet of sheets) {
          let addRow = new Array(sheet[0].length);
          addRow = sheet[0].map((v, i) => {
            return { value: abc[i], readOnly: true };
          });
          sheet = sheet.unshift(addRow);
        }
        for (let sheet of sheets) {
          for (let [index, row] of sheet.entries()) {
            row = row.unshift({
              value: index === 0 ? "" : index,
              readOnly: true,
            });
          }
        }

        this.setMountedState({
          workbook: workbook,
          array_sheets: sheets,
        });
      },
      (error) =>
        window.showErrorSnackbar(`Error loading results table: ${error}`)
    );
  }
  handleChange = (event, value) => {
    this.setMountedState({ activeTab: value });
  };
  render() {
    const { classes } = this.props;
    const { project } = this.state;
    return (
      <div className={classes.root}>
        <div>
          <Button
            color="primary"
            onClick={() => {
              Backend.downloadReport(this.props.id, project.name);
            }}
          >
            Download Excel File
          </Button>
          <span>
            (All Excel files are saved here: {this.state.settings.tempPath}
            reports/)
          </span>
        </div>
        <Tabs
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          value={this.state.activeTab}
          onChange={this.handleChange}
        >
          {this.state.workbook &&
            this.state.workbook.SheetNames.map((tab_name, index) => (
              <Tab key={index} label={tab_name} />
            ))}
          {this.state.charts.length > 0 && <Tab key={99} label="Charts" />}
        </Tabs>
        <div className={classes.resultTabContent}>
          {this.state.array_sheets &&
            this.state.array_sheets.map((array_sheet, index) => (
              <div
                key={index}
                style={{
                  display: index === this.state.activeTab ? "block" : "none",
                }}
              >
                <Paper
                  style={{
                    margin: "auto",
                    padding: "5px",
                    width: "fit-content",
                  }}
                >
                  <Datasheet
                    data={array_sheet}
                    valueRenderer={(cell) => cell.value}
                    onCellsChanged={() => {
                      return;
                    }}
                    overflow="clip"
                  />
                </Paper>
              </div>
            ))}
          {this.state.array_sheets && (
            <div
              key={199}
              style={{
                display:
                  this.state.array_sheets.length === this.state.activeTab
                    ? "block"
                    : "none",
              }}
            >
              <Paper style={{ margin: "auto", padding: "5px", maxWidth: 1000 }}>
                {this.state.charts.map((chart, idx) => (
                  <img
                    key={idx}
                    style={{
                      width: "100%",
                      display: "block",
                      margin: "0px",
                      objectFit: "contain",
                    }}
                    src={Backend.renderReportChart(chart.path)}
                    alt={chart.path}
                  />
                ))}
              </Paper>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Report.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
  history: PropTypes.object,
};

export default withRouter(withStyles(styles)(Report));

import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";
import {
  CircularProgress,
  IconButton,
  LinearProgress,
  Tooltip,
  Fade,
  Popper,
  Paper,
} from "@mui/material";
import { trackTransforms, getBaseLog } from "../viewer/utils/CanvasUtil";
import {
  configureImage,
  colorInImage,
} from "../viewer/utils/RendererUtils";
import Backend from "../common/utils/Backend";
import ScaleBar from "../viewer/components/ScaleBar";
import ZoomBar from "../viewer/components/ZoomBar";
import MiniMap from "../viewer/components/MiniMap";
import ZStackBar from "../viewer/components/ZStackBar";
import ImageInfo from "../viewer/components/ImageInfo";
import ToggleButton from "../viewer/components/ToggleButton";
import "react-resizable/css/styles.css";

import { withPersistentStorage } from "../viewer/contexts/PersistentStorageContext";
import { withTiles } from "../viewer/contexts/TilesContext";
import { withResultTab } from "../viewer/contexts/ResultTabContext";
import { ViewQuilt } from "@mui/icons-material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

// debug flags
const FPS = false;

const styles = {
  canvas: {
    position: "relative",
    display: "block",
    width: "100%",
    backgroundColor: "#D3D3D3",
  },

  root: {
    height: "100%",
  },

  heatmapContainer: {
    pointerEvents: "none",
    position: "absolute !important",
    zIndex: "9998",
    width: "100%",
    height: "100%",
  },

  toolbarButtonRoot: {
    color: "#666",
    position: "absolute",
    top: 5,
    right: 35,
    zIndex: 100,
  },
  toolbarButtonRootSingle: {
    color: "#666",
    position: "absolute",
    top: 5,
    right: 5,
    zIndex: 100,
  },
  toolbarButton: {
    position: "relative",
    display: "inline-block",

    width: 40,
    height: 40,
    padding: 8,
    margin: 0,
  },
  toolbarButtonIcon: {
    verticalAlign: "-4px",
  },
  toolbarButtonChecked: {
    width: 40,
    color: "#0673C1",
  },
  progress: {
    position: "absolute",
    margin: -20,
    left: "50%",
    top: "50%",
    zIndex: 1000,
  },
  fps: {
    position: "absolute",
    right: 50,
    top: 50,
    color: "white",
    fontSize: 20,
    pointerEvents: "none",
  },
  resizableContainer: {
    "& .react-resizable-handle": {
      pointerEvents: "all",
    },
    "& .react-resizable-handle-se::before": {
      content: "''",
      display: "block",
      position: "absolute",
      bottom: 3,
      right: 3,
      width: 6,
      height: 6,
      borderBottom: "1px solid #0673c1",
      borderRight: "1px solid #0673c1",
    },
  },
  fileNavLeftBtn: {
    position: "absolute",
    left: 5,
    top: "50%",
    marginTop: "-40px",
    "& svg": {
      fontSize: "80px",
    },
  },
  fileNavRightBtn: {
    position: "absolute",
    right: 5,
    top: "50%",
    marginTop: "-40px",
    "& svg": {
      fontSize: "80px",
    },
  },
};

Number.prototype.toBase = function (base) {
  var symbols =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
  var decimal = this;
  var conversion = "";

  if (base > symbols.length || base <= 1) {
    return false;
  }

  while (decimal >= 1) {
    conversion =
      symbols[decimal - base * Math.floor(decimal / base)] + conversion;
    decimal = Math.floor(decimal / base);
  }

  return base < 11 ? parseInt(conversion) : conversion;
};

const debounce = (func, delay) => {
  let inDebounce;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

class Renderer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    // transmit context to componentRef
    if (props.componentRef) props.componentRef(this);

    this.state = {
      previewWidth: 100,
      previewHeight: 100,
      dragStart: null,
      isDrawing: false,
      mouseOnCanvas: false,
      ome: props.ome,
      initialized: false,
      initializedZ: false,
      initialScale: 1,
      tMin: 0,
      tMax: 0,
      t: 0,
      z: Math.floor(props.ome.sizeZ / 2),
      minZ: 0,
      maxZ: props.ome.sizeZ - 1,
      fps: 0,
      // time playback
      sr: 60,
      // z playback
      zsr: 60,
      bufferSize: 100,
      playing: false,
      playingZ: false,
      playDirection: 1,
      playDirectionZ: 1,
      miniMapKey: 0,
      // tools
      rois: [],
      selectedROI: null,
      limitVisibleRegions: false,
      layerTrees: [],
      previewRectChanged: false,
      isBrightfield:
        props.ome &&
        props.ome.channels.length === 1 &&
        props.ome.channels[0].type === "brightfield",
      scaleBarData: null,
      startY: 0,
      showMiniMap: true,
      showObjectIdx: false,
      miniMapReady: false,
      open: false,
      anchorEl: null,
      displayTimeBar: false,
      displayZStackBar: false,
      showScaleBar: true,
      showTimeBar: false,
      showZStackBar: false,
      showImageInfo: true,
      showZoomBar: true,
      showResultTable: false,
      showFileNavButtons: false,
      hideMiniMap: false,
    };
    this.updateCounter = 0;
    this.visibleRegionsLimit = 3000;
    this.visibleRegionsRadius = 1000;
    this.structureRegionLimits = [];
    this.slowestStructureIdx = -1;
    this.fastestStructureIdx = -1;
    this.fileIdStateData = {};

    this.lineIdx = 0;
    this.keepRendering = true;

    this.lastMoveTime = performance.now();

    this.selROI = null;
    window.setNewSelRoi = this.setNewSelRoi;
    window.getMousePositionInImage = this.getMousePositionInImage;
    window.setZoomLevelForGridSize = this.setZoomLevelForGridSize;

    // frontend cached alpha channel images
    this.visibleImage = [];
    // frontend cached colored images
    this.coloredImages = [];

    this.hMat = [];
    this.imgRegistered = true;
    this.callbackCounter = 0;

    this.oldVisibleRegionCount = 0;

    this.gridTileCount = 0;

    // fps history to smooth fps counter update
    this.lastFps = Array.from(Array(10), () => 60);

    this.createBackgroundPattern();
    this.tempLayer = null;
  }

  setMountedState = (stateObject, callback) => {
    if (this._isMounted) {
      this.setState(stateObject, callback);
    }
  };

  createBackgroundPattern = () => {
    this.backgroundPattern = document.createElement("canvas");
    const patternContext = this.backgroundPattern.getContext("2d");

    // Give the pattern a width and height of 50
    const w = 30;
    const h = 30;
    this.backgroundPattern.width = w;
    this.backgroundPattern.height = h;

    // Give the pattern a background color and draw an arc
    patternContext.fillStyle = "transparent";
    patternContext.strokeStyle = "#999";
    patternContext.fillRect(0, 0, w, h);
    patternContext.lineWidth = 1;
    patternContext.moveTo(-w / 2, h);
    patternContext.lineTo(w, -h / 2);
    patternContext.moveTo(0, h + h / 2);
    patternContext.lineTo(w + w / 2, 0);
    patternContext.stroke();
  };

  componentDidMount = () => {
    this._isMounted = true;
    let stateObject = {};
    this.canvas = document.getElementById(this.props.canvasId);

    // get drawing context from canvas
    this.ctx = this.canvas.getContext("2d");

    // bind mouse wheel events
    this.canvas.addEventListener("DOMMouseScroll", this.mousewheel, true);
    this.canvas.addEventListener("mousewheel", this.mousewheel, {
      passive: false,
    });
    // bind mouse events
    window.addEventListener("mouseup", this.mouseup, { passive: true });
    window.addEventListener("mousemove", this.mousemove, { passive: true });

    // extend context with some fancy additional transformation methods
    trackTransforms(this.ctx);

    // converts the relative size css information into an absolute size which we can access
    this.canvas.width = this.canvas.offsetWidth;
    this.canvas.height = this.canvas.offsetHeight;

    // initialize position
    stateObject.lastX = this.canvas.width / 2;
    stateObject.lastY = this.canvas.height / 2;

    this.t0 = performance.now();

    let loadObject = [
      "hideMiniMap",
      "showObjectIdx",
      "showMiniMap",
      "showScaleBar",
      "showTimeBar",
      "showZStackBar",
      "showImageInfo",
      "showZoomBar",
      "showResultTable",
      "showFileNavButtons",
    ];

    let i = null;
    if (this.props.showFullscreen) {
      loadObject.forEach((element) => {
        i = this.props.persistentStorage.load(element + "Full");
        if (i === true || i === false) stateObject[element] = i;
      });
    } else {
      loadObject.forEach((element) => {
        i = this.props.persistentStorage.load(element);
        if (i === true || i === false) stateObject[element] = i;
      });
    }

    stateObject["displayTimeBar"] = this.props.displayTimeBar;
    stateObject["displayZStackBar"] = this.props.displayZStackBar;
    stateObject["showZStackBar"] = this.props.showZStackBar;
    // stateObject["showTimeBar"] = this.props.showTimeBar;

    this.setMountedState(stateObject, () => {
      this.checkMiniMapVisivility();
    });
    //set mouse postition to center of canvas
    this.mousePos = this.getMousePositionInImage();
    this.draw();
  };

  componentDidUpdate(prevProps, prevState) {
    const oldFileId = prevProps.fileId;
    const newFileId = this.props.fileId;

    // on file change => reset renderer cache
    if (oldFileId && oldFileId !== newFileId) {
      this.fileIdStateData[oldFileId] = prevState;

      if (this.fileIdStateData[newFileId]) {
        this.setMountedState(this.fileIdStateData[newFileId]);
      }

      setTimeout(() => this.zoomFit(), 100);
      this.setMountedState({
        miniMapKey: new Date().getTime(),
      });
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false;
    // unbind mouse wheel events
    this.canvas.removeEventListener("DOMMouseScroll", this.mousewheel);
    this.canvas.removeEventListener("mousewheel", this.mousewheel);
    // unbind mouse events
    window.removeEventListener("mouseup", this.mouseup, { passive: true });
    window.removeEventListener("mousemove", this.mousemove, { passive: true });
    this.keepRendering = false;
  };

  reset = () => {
    // resets renderer to initial state
    //TODO: adapt for Splitscreen, so that not all frames are reseted
    this.props.tiles.clearTiles();
    // frontend cached alpha channel images
    this.props.tiles.setVisibleImage([]);
    // frontend cached colored images
    this.props.tiles.setColoredImages([]);

    this.setMountedState({
      dragStart: null,
      playing: false,
      //initialized: false,
    });

    this.loadAllTiles();
    // this.props.setChangingFile(false);
    //this.props.setAIObjects();
  };

  getPage = (c, z, t) => {
    // calculate page index from coordinates
    return (
      Math.round(t) * this.props.ome.channels.length * this.props.ome.sizeZ +
      c * this.props.ome.sizeZ +
      Math.round(z)
    );
  };

  getPageForChannel = (c) => {
    // calculate page index from coordinates
    return (
      Math.round(this.state.t) *
        this.props.ome.channels.length *
        this.props.ome.sizeZ +
      Math.round(this.state.z) * this.props.ome.channels.length +
      c
    );
  };

  getMousePosition = () => {
    return [this.mousePosition.mouseX, this.mousePosition.mouseY];
  };

  getMousePositionInImage = () => {
    let x = this.props.getMousePosition()[0];
    let y = this.props.getMousePosition()[1];
    this.lastX = x - this.canvas.getBoundingClientRect().left;
    this.lastY = y - this.canvas.getBoundingClientRect().top; //- this.canvas.offsetTop
    // calculate mouse position in image coordinates
    let p1 = this.getPointInCanvas({
      x: this.lastX,
      y: this.lastY,
    });
    let p2 = this.getPointInCanvas({ x: 0, y: 0 });
    let p3 = this.getPosition();

    p1.x += p2.x - p3.x / this.getScale();
    p1.y += p2.y - p3.y / this.getScale();

    return p1;
  };

  getPositionInImage = (position) => {
    // calculate corner points top left (position==0), bottom right (position==1), center (position==2)
    let X;
    let Y;
    if (position === 0) {
      // top left
      X = this.canvas.getBoundingClientRect().left;
      Y = this.canvas.getBoundingClientRect().top; //64 - this.canvas.offsetTop + 32; // 32 is half of blue header
    } else if (position === 1) {
      // bottom right
      X = this.canvas.getBoundingClientRect().right;
      Y = this.canvas.getBoundingClientRect().bottom;
      //- this.canvas.offsetTop + 32; // 32 is half of blue header
    } else {
      // center
      X =
        (this.canvas.getBoundingClientRect().left +
          this.canvas.getBoundingClientRect().right) /
        2;
      Y =
        (this.canvas.getBoundingClientRect().top +
          this.canvas.getBoundingClientRect().bottom) /
        2;
      //+32; // 32 is half of blue header
    }

    // calculate mouse position in image coordinates
    let p1 = this.getPointInCanvas({
      x: X - this.canvas.getBoundingClientRect().left,
      y: Y - this.canvas.getBoundingClientRect().top,
    });
    let p2 = this.getPointInCanvas({ x: 0, y: 0 });
    let p3 = this.getPosition();
    p1.x += p2.x - p3.x / this.getScale();
    p1.y += p2.y - p3.y / this.getScale();

    return p1;
  };

  getPointInCanvasCoord = (x, y) => {
    let pt = {
      x: Math.round(x * this.ctx.getTransform().a + this.ctx.getTransform().e),
      y: Math.round(y * this.ctx.getTransform().d + this.ctx.getTransform().f),
    };
    return pt;
  };

  getPointInCanvas = (p) => {
    try {
      let tp = this.ctx.transformedPoint(p.x, p.y);
      return {
        x: tp.x,
        y: tp.y,
      };
    } catch (ex) {
      // Skip this frame
      // console.error(ex);
      return {
        x: -1,
        y: -1,
      };
    }
  };

  mousedown = (event) => {
    let p1 = this.getMousePositionInImage();
    this.mousePos = p1;
    this.props.tiles.chainLeaderId = this.props.canvasId;

    // start dragging if middle mouse button is down or not tool selected
    if (event.button === 1 || event.button === 0) {
      let dragStart = this.getPointInCanvas({ x: this.lastX, y: this.lastY });
      this.setMountedState({
        dragStart: dragStart,
      });
      if (this.props.isChained) {
        if (this.props.showFullscreen) {
          for (const value of Object.values(this.props.splitscreenFileIds)) {
            let fDragStart = this.props.rendererDict[
              "Full" + value
            ].getPointInCanvas({
              x: this.lastX,
              y: this.lastY,
            });
            this.props.rendererDict["Full" + value].setMountedState({
              dragStart: fDragStart,
            });
          }
        } else {
          for (const value of Object.values(this.props.chainListFileIds)) {
            let fDragStart = this.props.rendererDict[value].getPointInCanvas({
              x: this.lastX,
              y: this.lastY,
            });
            this.props.rendererDict[value].setMountedState({
              dragStart: fDragStart,
            });
          }
        }
      }

      this.canvas.style.cursor = "move";

      if ((event.button === 0 || event.button === 1) && event.altKey) {
        this.tempChainToggle = true;

        if (this.props.showFullscreen) {
          // this.props.fsChain();
          console.log("fsChain");
        } else {
          this.props.onChangeChain(
            false,
            this.props.splitscreenIdx,
            this.props.fileId
          );
        }
      }

      // dont forward any event upwards
      event.preventDefault();
      return false;
    } else {
      if (!this.state.isDrawing) {
        this.setMountedState({ isDrawing: true });
      }
    }
  };

  mouseBusy = () => {
    return !this.canvas.style.cursor !== "default";
  };

  mousemove = () => {
    //event
    // if (this.props.resultTab.getZoomLevelFixed()) {
    //   return;
    // }
    let p1 = this.getMousePositionInImage();
    if (this.state.isDrawing || this.state.mouseOnCanvas) {
      this.mousePos = p1;
    } else {
      this.middleMousePos();
    }
    if (this.dragROI) {
      // handle roi dragging
      this.dragROI.drag(p1, this.vw, this.vh);
    } else if (this.resizeROI) {
      // resize the roi if inside the image
      this.resizeROI.resize(p1, this.vw, this.vh);
    } else if (
      this.state.dragStart &&
      this.props.tiles.chainLeaderId === this.props.canvasId
    ) {
      // handle canvas dragging
      let pt = this.getPointInCanvas({ x: this.lastX, y: this.lastY });
      // translate canvas

      this.ctx.translate(
        pt.x - this.state.dragStart.x,
        pt.y - this.state.dragStart.y
      );
      if (this.props.isChained) {
        if (this.props.showFullscreen) {
          for (const value of Object.values(this.props.splitscreenFileIds)) {
            let fPt = this.props.rendererDict["Full" + value].getPointInCanvas({
              x: this.lastX,
              y: this.lastY,
            });
            this.props.rendererDict["Full" + value].chainMouseDrag(
              fPt.x,
              fPt.y
            );
          }
        } else {
          for (const value of Object.values(this.props.chainListFileIds)) {
            let fPt = this.props.rendererDict[value].getPointInCanvas({
              x: this.lastX,
              y: this.lastY,
            });
            this.props.rendererDict[value].chainMouseDrag(fPt.x, fPt.y);
          }
        }
      }
      this.zoomMoveActionDebounced();
    }

    this.lastMoveTime = performance.now();
  };

  chainMouseDrag = (x, y) => {
    if (this.props.tiles.chainLeaderId !== this.props.canvasId) {
      if (this.props.isChained) {
        this.ctx.translate(
          x - this.state.dragStart.x,
          y - this.state.dragStart.y
        );
      }
    }
  };

  mouseEnter = () => {
    this.setMountedState({ mouseOnCanvas: true });
  };

  mouseLeave = () => {
    this.setMountedState({ mouseOnCanvas: false });
  };

  middleMousePos = () => {
    let p1 = this.getPointInCanvas({ x: 0, y: 0 });
    let p2 = this.getPointInCanvas({
      x: this.canvas.width,
      y: this.canvas.height,
    });
    let centerX = p1.x + (p2.x - p1.x) / 2;
    let centerY = p1.y + (p2.y - p1.y) / 2;
    this.mousePos = { x: centerX, y: centerY };
  };

  mouseup = () => {
    // event
    let p1 = this.getMousePositionInImage();
    this.mousePos = p1;

    // stop dragging the canvas
    if (this.state.dragStart) {
      this.setMountedState({ dragStart: null });
      this.canvas.style.cursor = "default";
    }

    // stop dragging a roi
    if (this.dragROI) {
      this.dragROI.handleMouseUp();
      this.dragROI = null;
    }

    if (this.tempChainToggle) {
      this.tempChainToggle = false;
      if (this.props.showFullscreen) {
        // this.props.fsChain();
        console.log("fsChain");
      } else {
        this.props.onChangeChain(
          true,
          this.props.splitscreenIdx,
          this.props.fileId
        );
      }
    }

    // update view
    this.forceUpdate();
  };

  onScaleOnly = (event) => {
    let delta = 0;

    if (event.wheelDelta) {
      /* IE/Opera. */
      delta = -(event.wheelDelta / 120);
    } else if (event.detail) {
      /* Mozilla */
      delta = event.detail / 3;
    }

    if (delta) {
      // zoom in (1) or out (-1)
      this.zoomDirection = delta < 0 ? 1 : -1;

      // set destination scale value
      let newZoom = this.getScale() * Math.pow(2, this.zoomDirection);

      // max zoom out value
      this.zoomValueOut = (0.75 * this.canvas.height) / this.vh;

      if ((0.75 * this.canvas.width) / this.vw < this.zoomValueOut) {
        this.zoomValueOut = (0.75 * this.canvas.width) / this.vw;
      }

      if (this.props.isActive === false && this.props.isChained) {
        this.zoomValueOut = this.props.showFullscreen
          ? this.props.rendererDict["Full" + this.props.activeFileId]
              .zoomValueOut
          : this.props.rendererDict[this.props.activeFileId].zoomValueOut;
      }

      if (newZoom < this.zoomValueOut) {
        newZoom = this.zoomValueOut;
      }

      // Limit maximum zoom in value
      const maxZoomIn = 32;
      newZoom = newZoom > maxZoomIn ? maxZoomIn : newZoom;

      this.zoomTo = newZoom;
      // redraw ui
      this.forceUpdate();
    }

    // prevent other scroll actions
    if (event.preventDefault) {
      event.preventDefault();
    }
    event.returnValue = false;
  };

  mousewheel = (event) => {
    // save zoom destination point
    this.props.tiles.chainLeaderId = this.props.canvasId;
    if (
      this.state.initialized &&
      !event.ctrlKey
      // &&!this.props.resultTab.getZoomLevelFixed()
    ) {
      this.zoomPoint = {
        x: this.lastX,
        y: this.lastY, // - this.canvas.getBoundingClientRect().top,
      };
      this.onScaleOnly(event);
    }

    if (this.props.isChained) {
      if (this.props.showFullscreen) {
        for (const value of Object.values(this.props.splitscreenFileIds)) {
          this.props.rendererDict["Full" + value].chainMousewheel(
            event,
            this.zoomPoint
          );
        }
      } else {
        for (const value of Object.values(this.props.chainListFileIds)) {
          this.props.rendererDict[value].chainMousewheel(event, this.zoomPoint);
        }
      }
    }
    event.preventDefault();
  };

  chainMousewheel = (event, leaderZoomPoint) => {
    // save zoom destination point
    if (this.props.tiles.chainLeaderId !== this.props.canvasId) {
      if (this.props.isChained) {
        if (
          this.state.initialized &&
          !event.ctrlKey &&
          !event.shiftKey
          // &&!this.props.resultTab.getZoomLevelFixed()
        ) {
          this.zoomPoint = leaderZoomPoint;
          this.onScaleOnly(event);
        }
      }
      event.preventDefault();
    } else return;
  };

  miniMapZoom = (event, x, y) => {
    this.props.tiles.chainLeaderId = this.props.canvasId;
    if (
      this.state.initialized &&
      !event.ctrlKey
      // &&!this.props.resultTab.getZoomLevelFixed()
    ) {
      let ctx = this.miniMapRef.getCtx();
      let tpt = ctx.transformedPoint(x, y);
      let pt = {
        x: tpt.x * this.ctx.getTransform().a + this.ctx.getTransform().e,
        y: tpt.y * this.ctx.getTransform().d + this.ctx.getTransform().f,
      };
      pt.x = Math.round(pt.x);
      pt.y = Math.round(pt.y);
      if (
        pt.x < this.canvas.width &&
        pt.x > 0 &&
        pt.y < this.canvas.height &&
        pt.y > 0
      ) {
        this.zoomPoint = pt;
      } else {
        return;
      }
      this.onScaleOnly(event);
    }
    if (this.props.isChained) {
      let l = this.props.showFullscreen
        ? this.props.splitscreenFileIds
        : this.props.chainListFileIds;
      for (const value of Object.values(l)) {
        if (value !== this.props.fileId) {
          let r = this.props.showFullscreen
            ? this.props.rendererDict["Full" + value]
            : this.props.rendererDict[value];
          let c = r.miniMapRef.getCtx();
          let p = c.transformedPoint(x, y);
          let pt = {
            x: p.x * r.ctx.getTransform().a + r.ctx.getTransform().e,
            y: p.y * r.ctx.getTransform().d + r.ctx.getTransform().f,
          };
          pt.x = Math.round(pt.x);
          pt.y = Math.round(pt.y);
          if (
            pt.x < r.canvas.width &&
            pt.x > 0 &&
            pt.y < r.canvas.height &&
            pt.y > 0
          ) {
            r.zoomPoint = pt;
          } else {
            return;
          }
          r.chainMousewheel(event, pt);
        }
      }
    }
    event.preventDefault();
  };

  // returns the current zoom scale
  getScale = () => {
    return this.ctx ? this.ctx.getTransform().a : 1;
  };

  // returns position of our image
  getPosition = () => {
    return {
      x: -this.ctx.getTransform().e,
      y: -this.ctx.getTransform().f,
    };
  };

  // zoom out to show the whole image
  zoomOut = () => {
    // center image first
    let pt = this.getPointInCanvas({
      x: (this.canvas.width - this.vw) / 2,
      y: (this.canvas.height - this.vh) / 2,
    });
    this.ctx.translate(pt.x, pt.y);
    // calc our transformation offset
    pt = this.getPointInCanvas({
      x: this.canvas.width / 2,
      y: this.canvas.height / 2,
    });
    // move to origin
    this.ctx.translate(pt.x, pt.y);
    // calc scale factor so we can fit the whole image on our screen
    let factor = Math.min(
      this.canvas.height / this.vh,
      this.canvas.width / this.vw
    );
    // perform scaling
    this.ctx.scale(factor, factor);
    // restore our offset
    this.ctx.translate(-pt.x, -pt.y);
    // save initial scale into our state
    this.setMountedState({
      initialized: true,
      initialScale: factor,
    });
    // this.props.resultTab.setRendererInitialized(true);
  };

  moveTo = (e) => {
    // get curent position (center of screen) and transform into world coordinates
    let pt = this.getPointInCanvas({
      x: this.canvas.width / 2,
      y: this.canvas.height / 2,
    });
    // translate by the offset to the destination point
    this.ctx.translate(-(e.x - pt.x), -(e.y - pt.y));
    // redraw screen
    this.forceUpdate();
    this.zoomMoveAction();
  };

  chainMoveTo = (x, y) => {
    // get curent position (center of screen) and transform into world coordinates
    let pt = this.getPointInCanvas({
      x: this.canvas.width / 2,
      y: this.canvas.height / 2,
    });
    // translate by the offset to the destination point
    this.ctx.translate(-(x - pt.x), -(y - pt.y));
    // redraw screen
    this.forceUpdate();
    this.zoomMoveAction();
  };

  chainMouseMove = (x, y, e) => {
    if (this.props.isChained) {
      if (this.props.showFullscreen) {
        for (const value of Object.values(this.props.splitscreenFileIds)) {
          if (value !== this.props.fileId) {
            let ctx =
              this.props.rendererDict["Full" + value].miniMapRef.getCtx();
            let pt = ctx.transformedPoint(x, y);
            this.props.rendererDict["Full" + value].chainMoveTo(pt.x, pt.y, e);
          }
        }
      } else {
        for (const value of Object.values(this.props.chainListFileIds)) {
          if (value !== this.props.fileId) {
            let ctx = this.props.rendererDict[value].miniMapRef.getCtx();
            let pt = ctx.transformedPoint(x, y);
            this.props.rendererDict[value].chainMoveTo(pt.x, pt.y, e);
          }
        }
      }
    }
  };

  pushColoredImage = (c, tileId, visImg) => {
    let channel = this.props.histogramConfig.channels[c];
    if (
      (this.props.histogramConfig.channels.length < 2 &&
        channel.color === "#ffffff") ||
      channel.color === -1
    ) {
      // rgb channel
      if (!this.props.tiles.getColoredImage(tileId)) {
        let colImg = configureImage(visImg, channel);
        this.props.tiles.pushColoredImages(colImg, tileId);
        // this.pushRegTile(tileId, visImg, channel);
      }
    } else {
      // colored images cache
      if (!this.props.tiles.getColoredImage(tileId) && visImg.width > 0) {
        let colImg = colorInImage(visImg, channel);
        this.props.tiles.pushColoredImages(colImg, tileId);
        // this.pushRegTile(tileId, visImg, channel);
      }
    }
  };

  loadAllTiles = () => {
    for (let lv = 0; lv < Math.min(2, this.props.ome.maxLevel + 1); lv++) {
      // calculate the number of grid columns for this level
      let cols = Math.pow(2, lv);
      for (let x = 0; x < cols; x++) {
        for (let y = 0; y < cols; y++) {
          for (let c = 0; c < this.props.ome.channels.length; c++) {
            let page = this.getPageForChannel(c);
            // load image first if missing
            let tileId =
              page + "," + lv + "," + x + "," + y + "," + this.props.fileId;
            if (!this.props.tiles.getVisibleImage(tileId)) {
              let visImg = new Image();
              visImg.src = Backend.renderRegion({
                id: this.props.fileId,
                page: page,
                lv: lv,
                x: x,
                y: y,
              });
              visImg.onload = () => {
                this.pushColoredImage(c, tileId, visImg);
                this.props.tiles.pushVisibleImage(visImg, tileId);
                // set context width and height
                this.props.tiles.setImgWidth(visImg.width);
                this.props.tiles.setImgHeight(visImg.height);
              };
            } else if (this.props.tiles.getVisibleImage(tileId).complete) {
              // set context width and height
              let img = this.props.tiles.getVisibleImage(tileId);
              this.props.tiles.setImgWidth(img.width);
              this.props.tiles.setImgHeight(img.height);
              this.pushColoredImage(
                c,
                tileId,
                this.props.tiles.getVisibleImage(tileId)
              );
            }
          }
        }
      }
    }

    this.preloadNextFrames();

    // this.props.changeHiConfig(true);
    this.props.tiles.setHistogramConfig(this.props.histogramConfig);
  };

  preloadNextFrames = () => {
    if (this.state.playDirection > 0) {
      for (
        let t = this.state.t;
        t <
        Math.min(
          this.props.ome.sizeT - 1,
          this.state.t + this.state.bufferSize
        );
        t++
      ) {
        this.preloadFrame(this.state.z, t);
      }
    } else {
      for (
        let t = this.state.t;
        t >= Math.max(0, this.state.t - this.state.bufferSize);
        t--
      ) {
        this.preloadFrame(this.state.z, t);
      }
    }
  };

  preloadNextZFrames = () => {
    if (this.state.playDirectionZ > 0) {
      for (
        let z = this.state.z;
        z <
        Math.min(
          this.props.ome.sizeZ - 1,
          this.state.z + this.state.bufferSize
        );
        z++
      ) {
        this.preloadFrame(z, this.state.t);
      }
    } else {
      for (
        let z = this.state.z;
        z >= Math.max(0, this.state.z - this.state.bufferSize);
        z--
      ) {
        this.preloadFrame(z, this.state.t);
      }
    }
  };

  preloadFrame = (z, t) => {
    for (let lv = 0; lv < 1; lv++) {
      // calculate the number of grid columns for this level
      let cols = Math.pow(2, lv);
      for (let x = 0; x < cols; x++) {
        for (let y = 0; y < cols; y++) {
          for (let c = 0; c < this.props.ome.channels.length; c++) {
            let page = this.getPage(c, z, t);
            // load image first if missing
            let tileId =
              page + "," + lv + "," + x + "," + y + "," + this.props.fileId;
            if (!this.props.tiles.getVisibleImage(tileId)) {
              let visImg = new Image();
              visImg.src = Backend.renderRegion({
                id: this.props.fileId,
                page: page,
                lv: lv,
                x: x,
                y: y,
              });
              visImg.onload = () => {
                this.pushColoredImage(c, tileId, visImg);
              };
            } else if (this.props.tiles.getVisibleImage(tileId).complete) {
              this.pushColoredImage(
                c,
                tileId,
                this.props.tiles.getVisibleImage(tileId)
              );
            }
          }
        }
      }
    }
  };

  zoomToFactor = (factor) => {
    let scaleTarget = this.state.initialScale * factor;
    this.zoomTo = scaleTarget;
    this.zoomPoint = { x: this.canvas.width / 2, y: this.canvas.height / 2 };
    this.zoomDirection =
      this.getScale() < scaleTarget ? scaleTarget : -scaleTarget;

    setTimeout(() => this.forceUpdate(), 10);
  };

  zoomOriginal = () => {
    this.zoomOneToN(1);
  };

  zoomOneToN = (x) => {
    this.zoomTo = x;
    this.zoomPoint = { x: this.canvas.width / 2, y: this.canvas.height / 2 };
    this.zoomDirection = this.getScale() < x ? 1 : -1;

    setTimeout(() => this.forceUpdate(), 10);
  };

  zoomDelta = (delta) => {
    // zoom middle
    this.zoomPoint = { x: this.canvas.width / 2, y: this.canvas.height / 2 };
    // zoom in (1) or out (-1)
    this.zoomDirection = delta < 0 ? 1 : -1;

    // set destination scale value
    let newZoom = this.getScale() * Math.pow(1.2, this.zoomDirection);

    // max zoom out value
    if (newZoom < (0.75 * this.canvas.height) / this.vh) {
      newZoom = (0.75 * this.canvas.height) / this.vh;
    }

    // max zoom in value
    if (newZoom > (2 * this.vh) / this.canvas.height) {
      newZoom = (2 * this.vh) / this.canvas.height;
    }

    this.zoomTo = newZoom;

    // redraw ui
    this.forceUpdate();
  };

  zoomFit = () => {
    this.canvasTranform(1, 0, 0, 1, 0, 0);
    this.zoomOut();
    this.zoomMoveActionDebounced(); //to save zoomObject
  };

  /**
   * Performs a context.setTransform, whilst ensuring that the pixel
   * representation is according to zoomlevel
   * @param {*} a Horizontal scaling. A value of 1 results in no scaling.
   * @param {*} b Vertical skewing.
   * @param {*} c Horizontal skewing.
   * @param {*} d Vertical scaling. A value of 1 results in no scaling.
   * @param {*} e Horizontal translation (moving).
   * @param {*} f Vertical translation (moving).
   */
  canvasTranform = (a, b, c, d, e, f) => {
    // disable smoothing to see pixels of image
    if (this.getScale() > 5) {
      this.ctx.imageSmoothingEnabled = false;
    } else {
      this.ctx.imageSmoothingEnabled = true;
    }
    // Actual transformation
    this.ctx.setTransform(a, b, c, d, e, f);
  };

  // triggered by moving or zooming
  zoomMoveAction = () => {
    const p1 = this.getPointInCanvas({ x: 0, y: 0 });
    const p2 = this.getPointInCanvas({
      x: this.canvas.width,
      y: this.canvas.height,
    });
    // save current zoom and position for reload
    const zoomObject = {
      zoomRoi: true,
      zoomLeft: p1.x,
      zoomRight: p1.x + p2.x - p1.x,
      zoomTop: p1.y,
      zoomBottom: p1.y + p2.y - p1.y,
    };
    if (!this.props.showFullscreen) {
      this.props.persistentStorage.save(
        "zoomObjectFV" + this.props.fileId,
        zoomObject
      );
    }
  };

  zoomMoveActionDebounced = debounce(this.zoomMoveAction, 100);

  updateZ = (z, minZ = this.state.minZ, maxZ = this.state.maxZ) => {
    this.setMountedState({ z: z, minZ: minZ, maxZ: maxZ });
    this.props.updateGlobalZ(z, minZ, maxZ);
  };

  updateT = (t) => {
    this.setMountedState({ t: t });
    this.props.updateGlobalT(t);
  };

  drawVisibleImage = () => {
    // calc deltatime for animations and fps
    let t1 = performance.now();
    let dt = t1 - this.t0;
    this.t0 = t1;

    // calc smoothed fps
    this.lastFps.pop();
    this.lastFps = [1000 / dt, ...this.lastFps];
    this.fps = Math.round(
      this.lastFps.reduce((acc, val) => (acc += val)) / this.lastFps.length
    );
    if (FPS) {
      this.setMountedState({
        fps: this.fps,
      });
    }

    // time playback
    if (this.state.playing) {
      let newT =
        this.state.t + (dt / 60000) * this.state.sr * this.state.playDirection;
      if (newT >= this.props.ome.sizeT) {
        newT = 0;
      } else if (newT < 0) {
        newT = this.props.ome.sizeT - 0.001;
      }
      this.updateT(newT);
      this.preloadNextFrames();
    }

    // time playback
    if (this.state.playingZ) {
      let newZ =
        this.state.z +
        (dt / 60000) * this.state.zsr * this.state.playDirectionZ;
      if (newZ > this.props.ome.sizeZ - 1) {
        newZ = 0;
      } else if (newZ < 0) {
        newZ = this.props.ome.sizeZ - 1;
      } else {
        newZ = newZ < 0 ? this.props.ome.sizeZ - 1 : newZ;
      }
      this.updateZ(newZ);
      this.preloadNextZFrames();
    }

    // nothing to draw if we haven't loaded our meta data yet
    if ((!this.props.ome || !this.canvas) && this.keepRendering) {
      // request next animation frame
      requestAnimationFrame(() => this.draw());
      return;
    }

    // call the initialization if the viewer is not initalized yet
    if (!this.state.initialized) {
      // do we have our top region already loaded? (then -> this.vw is greater 0)
      if (this.imgLoaded && this.imgRegistered) {
        // do initial placement
        this.zoomOut();
      } else {
        this.loadAllTiles();
      }
    }

    // only set z once
    if (!this.state.initializedZ) {
      let z = this.props.persistentStorage.load("z");
      if (typeof z === "undefined") {
        z = Math.floor(this.props.ome.sizeZ / 2);
      }
      let minZ = this.props.persistentStorage.load("minZ");
      if (typeof minZ === "undefined") {
        minZ = 0;
      }
      let maxZ = this.props.persistentStorage.load("maxZ");
      if (typeof maxZ === "undefined") {
        maxZ = this.props.ome.sizeZ - 1;
      }
      this.updateZ(z, minZ, maxZ);
      this.props.tiles.setZLevel(Math.floor(this.props.ome.sizeZ / 2));
      this.setMountedState({
        minZ,
        maxZ,
        initializedZ: true,
      });
    }

    if (this.zoomTo) {
      if (typeof this.zoomPoint === "undefined") {
        this.zoomPoint = { x: this.lastX, y: this.lastY };
      }
      if (this.zoomDirection > 0 && this.getScale() < this.zoomTo) {
        // zoom in
        // calc our transformation offset
        let pt = this.getPointInCanvas({
          x: this.zoomPoint.x,
          y: this.zoomPoint.y,
        });

        this.ctx.translate(pt.x, pt.y);
        // calc scale factor (zoom per second)
        let factor = Math.pow(20, 40 / 1000);
        // perform scaling
        this.ctx.scale(factor, factor);
        if (this.getScale() > this.zoomTo) {
          this.canvasTranform(
            this.zoomTo,
            this.ctx.getTransform().b,
            this.ctx.getTransform().c,
            this.zoomTo,
            this.ctx.getTransform().e,
            this.ctx.getTransform().f
          );
        }
        // restore our offset
        this.ctx.translate(-pt.x, -pt.y);
        // update screen, update scalebar etc
        // this.updatePreviewRect(this.props.activeTool);
        this.zoomMoveActionDebounced();
      } else if (this.zoomDirection < 0 && this.getScale() > this.zoomTo) {
        // zoom out
        // calc our transformation offset
        let pt = this.getPointInCanvas({
          x: this.zoomPoint.x,
          y: this.zoomPoint.y,
        });
        this.ctx.translate(pt.x, pt.y);
        // calc scale factor (zoom per second)
        let factor = Math.pow(20, -40 / 1000);
        // perform scaling
        this.ctx.scale(factor, factor);

        if (this.getScale() < this.zoomTo) {
          this.canvasTranform(
            this.zoomTo,
            this.ctx.getTransform().b,
            this.ctx.getTransform().c,
            this.zoomTo,
            this.ctx.getTransform().e,
            this.ctx.getTransform().f
          );
        }
        // restore our offset
        this.ctx.translate(-pt.x, -pt.y);
        // update screen, update scalebar etc

        // this.updatePreviewRect(this.props.activeTool);
        this.zoomMoveActionDebounced();
      } else {
        // we reached the destination zoom level
        this.zoomTo = null;
      }
    }

    // get world size from ome metadata
    this.vw = this.props.ome.sizeX;
    this.vh = this.props.ome.sizeY;

    // Clear the entire canvas
    let p1 = this.getPointInCanvas({ x: 0, y: 0 });
    let p2 = this.getPointInCanvas({
      x: this.canvas.width,
      y: this.canvas.height,
    });
    this.ctx.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);
    this.ctx.save();
    this.canvasTranform(1, 0, 0, 1, 0, 0);
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.restore();

    this.ctx.fillStyle = "#000";
    this.ctx.fillRect(0, 0, this.vw, this.vh);

    // get pyramid level based on scaling
    this.level = getBaseLog(2, (this.getScale() / this.state.initialScale) * 2);
    // clip our level at lower bound
    if (this.level < 0 || this.level === Infinity) {
      this.level = 0;
    }
    // clip our level at upper bound
    if (this.level > this.props.ome.maxLevel) {
      this.level = this.props.ome.maxLevel;
    }

    let lv = Math.floor(this.level);

    // calculate the number of grid columns for this level
    let cols = Math.pow(2, lv);

    // get visbile column indices
    let visX = [];
    for (let i = 0; i < cols; i++) {
      if (
        this.getPosition().x / this.getScale() < this.vw * ((i + 1) / cols) &&
        (this.getPosition().x + this.canvas.width) / this.getScale() >
          this.vw * (i / cols)
      ) {
        visX.push(i);
      }
    }
    // out of bounds, add first region
    if (visX.length === 0) visX.push(0);

    // get visbile row indices
    let visY = [];
    for (let i = 0; i < cols; i++) {
      if (
        this.getPosition().y / this.getScale() < this.vh * ((i + 1) / cols) &&
        (this.getPosition().y + this.canvas.height) / this.getScale() >
          this.vh * (i / cols)
      ) {
        visY.push(i);
      }
    }
    // out of bounds, add first region
    if (visY.length === 0) visY.push(0);

    for (let x of visX) {
      for (let y of visY) {
        //let page = this.getPage();
        //for(let page = 0; page < this.props.histogramConfig.channels.length; page++) {
        for (let c = 0; c < this.props.histogramConfig.channels.length; c++) {
          let page = this.getPageForChannel(c);
          let channel = this.props.histogramConfig.channels[c];

          // skip disabled channels
          if (!channel.enabled) continue;
          // file +tile id im string
          // load image first if missing
          let tileId =
            page + "," + lv + "," + x + "," + y + "," + this.props.fileId;
          if (
            this.zoomTo == null &&
            !this.props.tiles.getVisibleImage(tileId) &&
            !this.state.isLoadingTiles &&
            !this.props.changingFile
            /// TODO: Commented out to fix splitscreen, why has it been added originally?
            //&& this.props.tiles.getFileId() === this.props.fileId
          ) {
            let visImg = new Image();
            visImg.src = Backend.renderRegion({
              id: this.props.fileId,
              page: page,
              lv: lv,
              x: x,
              y: y,
            });
            this.props.tiles.pushVisibleImage(visImg, tileId);
          }

          let img = this.props.tiles.getVisibleImage(tileId);
          this.imgLoaded = img && img.complete;
          // see https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/globalCompositeOperation
          const compositionModeFluor = "screen";
          const compositionModeBrightfield = "lighter";
          if (this.imgLoaded && this.imgRegistered) {
            // image is loaded -> draw it
            this.pushColoredImage(c, tileId, img);

            if (
              tileId.includes(this.props.activeFileId) &&
              (this.props.tiles.getImgWidth() !== img.width ||
                this.props.tiles.getImgHeight() !== img.height)
            ) {
              // set context width and height (for splitscreen and gallery)
              this.props.tiles.setImgWidth(img.width);
              this.props.tiles.setImgHeight(img.height);
            }

            // composition should be screen for fluorescence
            if (this.props.ome.channels[c].type === "fluorescence") {
              this.ctx.globalCompositeOperation = compositionModeFluor;
            } else {
              this.ctx.globalCompositeOperation = compositionModeBrightfield;
            }
            if (
              Object.prototype.toString.call(
                this.props.tiles.getColoredImage(tileId)
              ) === "[object HTMLCanvasElement]" &&
              this.props.tiles.getColoredImage(tileId)
            ) {
              // draw colored image layer
              this.ctx.drawImage(
                this.props.tiles.getColoredImage(tileId),
                (x * this.vw) / cols,
                (y * this.vh) / cols,
                this.vw / cols,
                this.vh / cols
              );
            }
          } else {
            // draw image of previus pyramid level instead
            let factor = 1;
            let parentlv = lv;
            while (parentlv > 0) {
              parentlv--;
              factor *= 2;
              // get id of that image
              tileId =
                page +
                "," +
                parentlv +
                "," +
                Math.floor(x / factor) +
                "," +
                Math.floor(y / factor) +
                "," +
                this.props.fileId;

              // image is loaded -> draw it
              let imgOld = this.props.tiles.getVisibleImage(tileId);

              if (imgOld && imgOld.complete) {
                this.pushColoredImage(c, tileId, imgOld);

                // composition should be screen for fluorescence
                // see https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/globalCompositeOperation
                this.ctx.globalCompositeOperation = compositionModeFluor;
                // draw colored image layer
                if (this.props.tiles.getColoredImage(tileId)) {
                  this.ctx.drawImage(
                    this.props.tiles.getColoredImage(tileId),
                    ((x % factor) * imgOld.width) / factor,
                    ((y % factor) * imgOld.height) / factor,
                    imgOld.width / factor,
                    imgOld.height / factor,
                    (x * this.vw) / cols,
                    (y * this.vh) / cols,
                    this.vw / cols,
                    this.vh / cols
                  );
                }
                break;
              }
            }
          }
        }
      }
    }
    // back to default overlay composition operation
    // see https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/globalCompositeOperation
    this.ctx.globalCompositeOperation = "source-over";
  };

  draw = () => {
    this.drawVisibleImage();

    let p2 = this.getPointInCanvas({
      x: this.canvas.width,
      y: this.canvas.height,
    });

    this.ctx.globalAlpha = 1.0;
    // draw scale bar
    if (this.state.showScaleBar && this.state.scaleBarData) {
      this.ctx.beginPath();
      this.ctx.strokeStyle = this.state.isBrightfield ? "black" : "white";
      const scaleFactor = 1 / this.ctx.getTransform().a;
      this.ctx.lineWidth = 3 * scaleFactor;
      const marginRight = 5 * scaleFactor;
      const marginBottom = 14 * scaleFactor;
      const scaleWidth = (this.state.scaleBarData.width - 2) * scaleFactor;
      const stopLineHeight = 16 * scaleFactor;
      const maxScaleWidth = this.state.scaleBarData.maxScaleWidth * scaleFactor;
      const delta = {
        x: this.state.scaleBarData.x * scaleFactor,
        y: this.state.scaleBarData.y * scaleFactor,
      };
      const center = {
        x: p2.x - marginRight - maxScaleWidth / 2 + delta.x,
        y: p2.y - marginBottom + delta.y,
      };
      this.ctx.moveTo(center.x - scaleWidth / 2, center.y);
      this.ctx.lineTo(center.x - scaleWidth / 2, center.y - stopLineHeight / 2);
      this.ctx.lineTo(center.x - scaleWidth / 2, center.y + stopLineHeight / 2);
      this.ctx.lineTo(center.x - scaleWidth / 2, center.y);
      this.ctx.lineTo(center.x + scaleWidth / 2, center.y);
      this.ctx.lineTo(center.x + scaleWidth / 2, center.y - stopLineHeight / 2);
      this.ctx.lineTo(center.x + scaleWidth / 2, center.y + stopLineHeight / 2);
      this.ctx.stroke();

      const fontSize = 14 * scaleFactor;
      const fontMargin = 6 * scaleFactor;
      this.ctx.fillStyle = this.state.isBrightfield ? "black" : "white";
      this.ctx.font = "bold " + fontSize + "px Arial";
      this.ctx.globalAlpha = 1.0;
      this.ctx.textAlign = "center";
      this.ctx.textBaseline = "bottom";
      this.ctx.fillText(
        this.state.scaleBarData.label,
        center.x,
        center.y - fontMargin
      );
    }

    // check if tiles are still loading from server
    let isLoadingTiles = false;
    for (let tileId in this.props.tiles.getVisibleImages()) {
      if (this.props.tiles.getVisibleImage(tileId).complete === false) {
        isLoadingTiles = true;
        break;
      }
    }
    if (isLoadingTiles !== this.state.isLoadingTiles) {
      let nowTime = performance.now();
      this.setMountedState(
        {
          isLoadingTiles: isLoadingTiles,
          nowTime: nowTime,
        },
        () => {}
      );
    }

    // request next animation frame
    if (this.keepRendering) {
      requestAnimationFrame(() => this.draw());
    }
  };

  setZoomLevelForGridSize = (gridSize) => {
    // make initial zoomlevel
    this.zoomFit();
    // zoom in that tile is size of viewer
    this.ctx.scale(gridSize, gridSize);
  };

  updateFLChannels = (e) => {
    this.props.tiles.setHistogramConfig(e);

    this.setMountedState({ histogramConfig: e });

    // invalidate all cached coloring images
    this.props.tiles.setColoredImages([]);

    this.loadAllTiles();
  };

  onPlayPause = (e, dir) => {
    this.setMountedState({ playing: e, playingZ: false, playDirection: dir });
  };

  onPlayPauseZ = (e, dir) => {
    this.setMountedState({ playingZ: e, playing: false, playDirectionZ: dir });
  };

  onSeek = (e, v) => {
    this.updateT(v);
  };

  onChangeT = (e) => {
    this.updateT(e);
  };

  onChangeZValue = (z, minZ = this.state.minZ, maxZ = this.state.maxZ) => {
    this.updateZ(z, minZ, maxZ);
    this.props.tiles.setZLevel(z);
    this.props.persistentStorage.save("z", z); //remember for use after project reload or reopen
    this.props.persistentStorage.save("minZ", minZ); //remember for use after project reload or reopen
    this.props.persistentStorage.save("maxZ", maxZ); //remember for use after project reload or reopen
  };

  onChangeZ = (e, v) => {
    let minZ = v[0];
    let z = v[1];
    let maxZ = v[2];
    this.onChangeZValue(z, minZ, maxZ);
  };

  onChangeSr = (e) => {
    this.setMountedState({ sr: e });
  };

  onChangeZSr = (e) => {
    this.setMountedState({ zsr: e });
  };

  onStep = (e) => {
    this.updateT(
      Math.min(this.props.ome.sizeT - 1, Math.max(0, this.state.t + e))
    );
  };

  onStepZ = (e) => {
    let zLevel = Math.min(
      this.props.ome.sizeZ - 1,
      Math.max(0, this.state.z + e)
    );
    this.onChangeZValue(zLevel);
  };

  onToggle = (key) => {
    let keyExtender = key.startsWith("show") ? "" : this.props.fileId;
    //e.g. "showMiniMap", true
    if (this.props.showFullscreen) {
      this.props.persistentStorage.save(
        key + "Full" + keyExtender,
        !this.state[key]
      );
    } else {
      this.props.persistentStorage.save(key + keyExtender, !this.state[key]);
    }
    this.setMountedState({ [key]: !this.state[key] });
  };

  getLayoutElement = (key) => {
    //used to get e.g. "showMiniMap" via rendererRef
    return this.state[key];
  };

  checkMiniMapVisivility = () => {
    const { hideMiniMap, showMiniMap } = this.state;
    const hideMiniMapThresholdWidth = 600;
    const hideMiniMapThresholdHeight = 400;
    const hideRestrictions =
      this.canvas.width < hideMiniMapThresholdWidth ||
      this.canvas.height < hideMiniMapThresholdHeight;
    let saveString = "hideMiniMap"; // + this.props.fileId;
    if (this.props.showFullscreen) {
      saveString = "hideMiniMapFull"; // + this.props.fileId;
    }
    if (hideRestrictions && !hideMiniMap && showMiniMap) {
      this.props.persistentStorage.save(saveString, true);
      this.onToggle("showMiniMap");
      this.setMountedState({ hideMiniMap: true });
    } else if (!hideRestrictions && hideMiniMap && !showMiniMap) {
      this.props.persistentStorage.save(saveString, false);
      this.onToggle("showMiniMap");
      this.setMountedState({ hideMiniMap: false });
    } else if (!hideRestrictions && hideMiniMap && showMiniMap) {
      this.props.persistentStorage.save(saveString, false);
      this.setMountedState({ hideMiniMap: false });
    }
  };

  render = () => {
    const { classes, fileId, ome } = this.props; //showTimeBar project

    const {
      miniMapKey,
      scaleBarData,
      open,
      anchorEl,
      displayZStackBar,
      showMiniMap,
      showScaleBar,
      showZStackBar,
      showImageInfo,
      showZoomBar,
      showFileNavButtons,
    } = this.state;

    return (
      <div className={classes.root} ref={(el) => (this.container = el)}>
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement="left-start"
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                style={{
                  position: "absolute",
                  top: 5,
                  right: 0,
                }}
              >
                <ToggleButton
                  classes={classes}
                  title={"Toggle Navigator"}
                  name={"showMiniMap"}
                  value={showMiniMap}
                  icon={"MapIcon"}
                  onToggle={this.onToggle}
                />
                {ome && typeof ome.physicalSizeX !== "undefined" && (
                  <ToggleButton
                    classes={classes}
                    title={"Toggle scalebar"}
                    name={"showScaleBar"}
                    value={showScaleBar}
                    icon={"faRuler"}
                    onToggle={this.onToggle}
                  />
                )}

                {displayZStackBar && (
                  <ToggleButton
                    classes={classes}
                    title={"Toggle Z-stack"}
                    name={"showZStackBar"}
                    value={showZStackBar}
                    icon={"faLayerGroup"}
                    onToggle={this.onToggle}
                  />
                )}
                <ToggleButton
                  classes={classes}
                  title={"Toggle image info"}
                  name={"showImageInfo"}
                  value={showImageInfo}
                  icon={"faInfo"}
                  onToggle={this.onToggle}
                />
                {
                  <ToggleButton
                    classes={classes}
                    title={"Toggle zoom info bar"}
                    name={"showZoomBar"}
                    value={showZoomBar}
                    icon={"LinearScaleIcon"}
                    onToggle={this.onToggle}
                  />
                }
              </Paper>
            </Fade>
          )}
        </Popper>

        {this.props.splitscreenCount !== 1 && !this.props.showFullscreen && (
          <Tooltip disableInteractive title="Adjust layout">
            <IconButton
              className={
                this.props.splitscreenCount === 1
                  ? classes.toolbarButtonRootSingle
                  : classes.toolbarButtonRoot
              }
              size="small"
              onClick={(e) =>
                this.setMountedState({
                  open: !this.state.open,
                  anchorEl: e.target,
                })
              }
            >
              <ViewQuilt />
            </IconButton>
          </Tooltip>
        )}
        <canvas
          id={this.props.canvasId}
          className={classes.canvas}
          style={{
            height: "100%",
            userSelect: "none",
          }}
          onContextMenu={(event) => event.preventDefault()}
          onMouseDown={this.mousedown}
          onMouseEnter={this.mouseEnter}
          onMouseLeave={this.mouseLeave}
        />
        {this.state.isLoadingTiles && (
          <LinearProgress
            style={{
              position: "fixed",
              top: 64,
              left: 0,
              right: this.props.rightSpace,
            }}
          />
        )}
        {FPS && (
          <p style={{ color: "#0673C1" }} className={classes.fps}>
            {this.state.fps + " FPS"}
          </p>
        )}
        {!this.state.initialized && (
          <CircularProgress className={classes.progress} />
        )}
        {this.state.initialized && showMiniMap && (
          // !this.props.resultTab.getZoomLevelFixed() &&
          <MiniMap
            componentRef={(c) => (this.miniMapRef = c)}
            fileId={fileId}
            key={miniMapKey}
            pointerEvents={
              !(
                (this.canvas.style.cursor !== "default")
                // ||
                // this.props.drawLayer.regionRois.length > 0
              )
            }
            ome={this.props.ome}
            histogramConfig={this.props.histogramConfig}
            visibleImage={this.props.tiles.getVisibleImages()}
            coloredImages={this.props.tiles.getColoredImages()}
            position={this.getPosition()}
            zoom={this.getScale()}
            canvas={this.canvas}
            canvasId={this.props.canvasId}
            onMoveTo={this.moveTo}
            onScaleOnly={this.onScaleOnly}
            getPageForChannel={this.getPageForChannel}
            chainMouseMove={this.chainMouseMove}
            zoomMouseWheel={this.miniMapZoom}
          />
        )}

        {this.state.initialized && showZoomBar && (
          <ZoomBar
            key={fileId + "ZoomBar"}
            zoom={this.getScale() / this.state.initialScale}
            changeValue={(v) => this.zoomToFactor(v)}
            clickable={true}
          />
        )}
        {this.state.initialized && showScaleBar && (
          <ScaleBar
            key={fileId + "ScaleBar"}
            pointerEvents={!(this.canvas.style.cursor !== "default")}
            zoom={this.getScale()}
            ome={this.props.ome}
            setScaleBarData={(data) => {
              if (
                scaleBarData === null ||
                scaleBarData.width !== data.width ||
                scaleBarData.label !== data.label ||
                scaleBarData.x !== data.x ||
                scaleBarData.y !== data.y
              ) {
                this.setMountedState({ scaleBarData: data });
              }
            }}
          />
        )}
        {this.state.initialized && showZStackBar && this.props.ome.sizeZ > 1 && (
          <ZStackBar
            key={fileId + "ZStackBar"}
            pointerEvents={
              !(
                (this.canvas.style.cursor !== "default")
                // ||
                // this.props.drawLayer.regionRois.length > 0
              )
            }
            z={this.state.z}
            minZ={this.state.minZ}
            maxZ={this.state.maxZ}
            playing={this.state.playingZ}
            onPlayPause={this.onPlayPauseZ}
            zsr={this.state.zsr}
            onChangeZ={this.onChangeZ}
            onChangeZValue={this.onChangeZValue}
            onChangeZSr={this.onChangeZSr}
            playDirection={this.state.playDirectionZ}
            ome={this.props.ome}
            onStep={this.onStepZ}
          />
        )}
        {this.state.initialized && showImageInfo && (
          <ImageInfo ome={this.props.ome} />
        )}
        {showFileNavButtons && (
          <React.Fragment>
            <div className={classes.fileNavLeftBtn}>
              <Tooltip
                disableInteractive
                placement="top"
                followCursor
                title="Open previous file [Ctrl] + [<=]"
              >
                <span>
                  <IconButton
                    disabled={true}
                    onClick={() => {
                      this.props.openPrevFile();
                    }}
                  >
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </div>

            <div className={classes.fileNavRightBtn}>
              <Tooltip
                disableInteractive
                placement="top"
                followCursor
                title="Open previous file [Ctrl] + [=>]"
              >
                <span>
                  <IconButton disabled={true} onClick={this.props.openNextFile}>
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };
}

// define the component's interface
Renderer.propTypes = {
  activeFileId: PropTypes.string,
  canvasId: PropTypes.string,
  chainListFileIds: PropTypes.object,
  changingFile: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  componentRef: PropTypes.func,
  displayTimeBar: PropTypes.bool,
  displayZStackBar: PropTypes.bool,
  fileId: PropTypes.string,
  histogramConfig: PropTypes.object,
  isActive: PropTypes.bool,
  isChained: PropTypes.bool,
  ome: PropTypes.object,
  onChangeChain: PropTypes.func,
  onSelectFile: PropTypes.func,
  openNextFile: PropTypes.func,
  openPrevFile: PropTypes.func,
  persistentStorage: PropTypes.object,
  rendererDict: PropTypes.object,
  rightSpace: PropTypes.number,
  showFullscreen: PropTypes.bool,
  showZStackBar: PropTypes.bool,
  splitscreenCount: PropTypes.number,
  splitscreenFileIds: PropTypes.array,
  splitscreenIdx: PropTypes.number,
  tiles: PropTypes.object,
  tzoomROI1: PropTypes.func,
  updateGlobalT: PropTypes.func,
  updateGlobalZ: PropTypes.func,
  zoomBottom: PropTypes.number,
  zoomLeft: PropTypes.number,
  zoomROI: PropTypes.bool,
  zoomRight: PropTypes.number,
  zoomTop: PropTypes.number,
  getMousePosition: PropTypes.func,
};

export default withPersistentStorage(
  withTiles(withResultTab(withStyles(styles)(Renderer)))
);

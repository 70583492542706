// Copyright: HS Analysis GmbH
// Author: Valentin Haas
// Taken from https://mui.com/material-ui/react-tree-view/#contentcomponent-prop

// Framework imports
import React from "react";
import PropTypes from "prop-types";

// External packages
import clsx from "clsx";
import Typography from "@mui/material/Typography";
import TreeItem, { useTreeItem } from "@mui/lab/TreeItem";

/**
 * TreeItem component that differntiales between click on icon and click on label.
 * Basically a copy of the original TreeItem component with the difference that the
 * click on the label can be handled separately using the handleSelection function.
 */
const ClickAwareTreeItemContent = React.forwardRef(
  function ClickAwareTreeItemContent(props, ref) {
    const {
      classes,
      className,
      label,
      nodeId,
      icon: iconProp,
      expansionIcon,
      displayIcon,
    } = props;

    const {
      disabled,
      expanded,
      selected,
      focused,
      handleExpansion,
      handleSelection,
      preventSelection,
    } = useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (event) => {
      preventSelection(event);
    };

    const handleExpansionClick = (event) => {
      handleExpansion(event);
    };

    const handleSelectionClick = (event) => {
      handleSelection(event);
    };

    return (
      <div
        className={clsx(className, classes.root, {
          [classes.expanded]: expanded,
          [classes.selected]: selected,
          [classes.focused]: focused,
          [classes.disabled]: disabled,
        })}
        onMouseDown={handleMouseDown}
        ref={ref}
      >
        <div onClick={handleExpansionClick} className={classes.iconContainer}>
          {icon}
        </div>
        <Typography
          onClick={handleSelectionClick}
          component="div"
          className={classes.label}
        >
          {label}
        </Typography>
      </div>
    );
  }
);

ClickAwareTreeItemContent.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object.isRequired,
  /**
   * className applied to the root element.
   */
  className: PropTypes.string,
  /**
   * The icon to display next to the tree node's label. Either a parent or end icon.
   */
  displayIcon: PropTypes.node,
  /**
   * The icon to display next to the tree node's label. Either an expansion or collapse icon.
   */
  expansionIcon: PropTypes.node,
  /**
   * The icon to display next to the tree node's label.
   */
  icon: PropTypes.node,
  /**
   * The tree node label.
   */
  label: PropTypes.node,
  /**
   * The id of the node.
   */
  nodeId: PropTypes.string.isRequired,
};

/**
 * A TreeItem that does not catch a click event when clicking on the label.
 * This allows to handle the click on the label separately from an icon click.
 * @param {object} props Same props as TreeItem.
 * @returns {JSX.Element} The ClickAwareTreeItem component.
 */
export default function ClickAwareTreeItem(props) {
  return <TreeItem ContentComponent={ClickAwareTreeItemContent} {...props} />;
}

// Copyright: HS Analysis GmbH, 2023
// Author: Valentin Haas

// Framework imports
import React, { useState } from "react";
import PropTypes from "prop-types";

// External packages
import { ValidatorForm } from "react-material-ui-form-validator";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import MobileStepper from "@mui/material/MobileStepper";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

// HSA packages

const styles = {
  dialog: {
    overflowY: "hidden",
    minHeight: "100%",
    "& .MuiPaperRoot": {
      maxWidth: "none",
    },
    "& .MuiDialogContentRoot": {
      padding: 0,
    },
  },
  dialogContent: {
    padding: 0,
  },
  content: {
    width: 900,
    height: 500,
    overflowY: "auto",
  },
  stepper: {
    width: "76%",
    left: "12%",
    position: "relative",
    marginBottom: "-50px",
    paddingBottom: "10px",
    marginTop: "25px",
    background: "#fafafa",
  },
};

/**
 * General dialog with a stepper and a form.
 * Takes an array of steps and displays them in the stepper.
 * The content of the steps is displayed in the form.
 * @param {boolean} isOpen Whether the dialog is open or not.
 * @param {function} setOpen Function to set the open state of the dialog.
 * @param {array} steps Array of steps to display in the dialog. Steps are objects with a title and content.
 * @param {function} onSubmit Async function to call when the form is completed and the submit button is pressed. In case of error, should return the page to jump to.
 * @param {string} submitMessage Optional. Message to display on the submit button. Default: "Submit"
 * @param {function} onNext Optional. Function to call when the next button is pressed. Called with the currently active step. Default: () => {}
 * @param {function} onBack Optional. Function to call when the back button is pressed. Called with the currently active step. Default: () => {}
 *
 * @returns {JSX.Element} A dialog with a stepper and a form.
 */
export default function StepperDialog(props) {
  const {
    isOpen,
    setOpen,
    steps = [],
    onSubmit,
    submitMessage = "Submit",
    onNext = () => {},
    onBack = () => {},
    ...other
  } = props;
  const [activeStep, setActiveStep] = useState(0);

  /**
   * Continues to the next step. If the last step is reached, the onSubmit function is called.
   * @returns {void}
   */
  const handleNext = async () => {
    // If it has a validate function, validate the current step
    if ("validate" in steps[activeStep]) {
      const validated = await steps[activeStep].validate();
      if (!validated) {
        return;
      }
    }
    if (activeStep === steps.length - 1) {
      await onSubmit()
        .then(() => {
          setOpen(false);
        })
        .catch((pageToJumpTo) => {
          if (pageToJumpTo !== null && pageToJumpTo !== undefined) {
            setActiveStep(pageToJumpTo);
          }
        });
      return;
    }

    onNext(activeStep);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  /**
   * Goes back to the previous step. If the first step is reached, nothing happens.
   */
  const handleBack = () => {
    onBack(activeStep);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Dialog
      open={isOpen === undefined ? false : isOpen}
      onClose={() => setOpen(false)}
      keepMounted
      style={styles.dialog}
      maxWidth="lg"
    >
      <DialogContent>
        <ValidatorForm onSubmit={handleNext}>
          <DialogTitle>{steps[activeStep]?.title ?? ""}</DialogTitle>
          <DialogContent style={{ ...styles.content, ...other.style }}>
            {steps[activeStep]?.content ?? <>Fail</>}
          </DialogContent>
        </ValidatorForm>
        <div style={{ background: "#fafafa" }}>
          <Stepper
            alternativeLabel
            nonLinear
            activeStep={activeStep}
            sx={styles.stepper}
          >
            {steps.map((step, index) => {
              const stepProps = {};
              const buttonProps = {};
              return (
                <Step key={index} {...stepProps}>
                  <StepButton
                    onClick={async () => {
                      // If it has a validate function, validate the current step
                      if ("validate" in steps[activeStep]) {
                        const validated = await steps[activeStep].validate();
                        if (!validated) {
                          return;
                        }
                      }
                      setActiveStep(index);
                    }}
                    {...buttonProps}
                  >
                    {step.title ?? index}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>

          <MobileStepper
            variant="dots"
            steps={steps.length}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button size="small" type="submit" onClick={handleNext}>
                {activeStep === steps.length - 1
                  ? submitMessage
                  : steps[activeStep]?.nextButton ?? "Next"}
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                size="small"
                disabled={activeStep < 1}
                onClick={handleBack}
              >
                <KeyboardArrowLeft />
                {steps[activeStep]?.backButton ?? "Back"}
              </Button>
            }
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

StepperDialog.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitMessage: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
};

import * as React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

const styles = () => ({
  root: {
    width: 150,
    margin: "0 5px",
    display: "inline-block",
  },
});

function DayPicker(props) {
  const { classes } = props;
  const handleChange = (newValue) => {
    props.updateTable(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3} className={classes.root}>
        <DesktopDatePicker
          label="Date"
          inputFormat="MM/dd/yyyy"
          value={props.date}
          maxDate={new Date()}
          minDate={props.firstDate}
          onChange={handleChange}
          renderInput={(params) => {
            return <TextField name="Textfield" {...params} />;
          }}
        />
      </Stack>
    </LocalizationProvider>
  );
}

DayPicker.propTypes = {
  classes: PropTypes.object.isRequired,
  date: PropTypes.object.isRequired,
  firstDate: PropTypes.object.isRequired,
  updateTable: PropTypes.func.isRequired,
};

export default withStyles(styles)(DayPicker);

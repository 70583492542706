// Framework imports
import React, { useEffect, useState, useContext } from "react";

// External packages
import { Button, Grid } from "@mui/material";
import TreeView from "@mui/lab/TreeView";
import { Box } from "@mui/system";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// HSA KIT Components
import RecursiveFolderEntity from "./components/FolderEntity.jsx";
import Backend from "../common/utils/Backend.jsx";
import { FileViewerContext } from "./FileViewer";

/**
 * Collected (reusable) styles for page elements.
 */
const styles = {
  root: {
    height: "100%",
    width: 301,
    background: "#fff",
    // borderLeft: "5px solid  #EBEBEB",
    display: "grid",
    gridTemplateRows: "auto 1fr",
    overflow: "hidden",
  },
  button: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
};

/**
 * Vertical Foldertree for quick folder selection.
 */
export default function Foldertree() {
  const fileViewer = useContext(FileViewerContext);
  const [topLevelEntities, setTLEs] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [openFolder, setOpenFolder] = useState([]);

  /*
  Loading Folder list and set Foldertree width
  Trigger: Initialise
  */
  useEffect(() => {
    Backend.walkDir("", true, (entities) => {
      // console.debug(entities);
      setTLEs(entities);
    });
    // fileViewer.setTreeWidth(styles.root.width);
  }, []);

  /*
  Function for Button "Open Previous/Collapse All" 
  */
  const handleCollapseAll = () => {
    setOpenFolder(expanded);
    if (expanded.length > 0) {
      setExpanded([]);
    } else {
      setExpanded(openFolder);
    }
  };

  return (
    <Grid sx={{ ...styles.root, width: fileViewer.treeWidth }}>
      <Button style={{ ...styles.button }} onClick={handleCollapseAll}>
        {expanded.length > 0 ? "Collapse All" : "Open Previous"}
      </Button>
      <Box sx={{ height: "100%", overflow: "auto" }}>
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={expanded}
          onNodeToggle={(event, nodeIds) => setExpanded(nodeIds)}
        >
          {topLevelEntities.map((tle) => (
            <RecursiveFolderEntity key={tle.path} entity={tle} />
          ))}
        </TreeView>
      </Box>
    </Grid>
  );
}

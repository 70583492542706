import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import { Tooltip, IconButton, Divider } from "@mui/material";
import {
  Save,
  Videocam,
  Map,
  GridOn,
  Pause,
  PlayArrow,
  RestartAlt,
  ZoomIn,
  ZoomOut,
  Comment,
  Flare,
  VideogameAsset,
  BlurOn,
  GetApp,
  Publish,
} from "@mui/icons-material";
import CustomSvgIcon from "../../globalComponents/CustomSvgIcon";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BugReportIcon from "@mui/icons-material/BugReport";
import { withScanViewerContext } from "../contexts/ScanViewerContext";
import SelectFileDialog from "./SelectFileDialog";

const styles = (theme) => ({
  root: {
    height: "100%",
    display: "grid",
    overflow: "hidden",
    gridTemplateRows: "1fr auto",
  },
  toolbarButton: {
    width: 40,
    color: theme.palette.primary.inactive,
  },
  toolbarButtonActive: {
    width: 40,
    color: theme.palette.primary.main,
  },
});

class ScanToolBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileDialogOpen: false,
      mapVisible: true,
      cameraVisible: true,
      gridVisible: false,
      debugDataVisible: false,
      commentsVisible: false,
      automationVisible: false,
    };
    this.showWIPIcons = false;
    window.addEventListener("keydown", this.keyDown);
  }

  onSave = () => {
    this.setState({ fileDialogOpen: false });
    this.props.scanViewerContext.createWSI();
  };

  onPlayPause = () => {
    if (this.props.scanViewerContext.slideScanning) {
      if (!this.props.sidebarVisible) {
        this.props.onToggleSidebar();
      }
      this.props.scanViewerContext.changeScanState("scan_pause");
    } else {
      if (this.props.sidebarVisible) {
        this.props.onToggleSidebar();
      }
      this.props.scanViewerContext.changeScanState("scan_start");
    }
  };

  /**
   * Handle keyboard shortcuts
   * @param {ActionEvent} e Keyboard keys
   */
  keyDown = (e) => {
    let elem = null;
    if (e.ctrlKey) {
      switch (e.key) {
        case "s":
          elem = document.getElementById("saveButtonScanToolBar");
          break;
        case "+": //ctrl +
          elem = document.getElementById("zoomInScanToolBar");
          break;
        case "-": // ctrl -
          elem = document.getElementById("zoomOutScanToolBar");
          break;
        case "=": //ctrl digit[0]
          elem = document.getElementById("zoomResetScanToolBar");
          break;
        case "0": //ctrl numpad[0]
          elem = document.getElementById("zoomResetScanToolBar");
          break;
      }
    } else {
      return;
    }
    if (elem !== null) {
      elem.click();
      e.preventDefault();
    }
  };

  render() {
    const { classes, onToggleSidebar } = this.props;
    const {
      mapVisible,
      cameraVisible,
      gridVisible,
      debugDataVisible,
      commentsVisible,
      automationVisible,
      focusPeakingOn,
      changeScanState,
      slideScanning,
      sideBarContent,
    } = this.props.scanViewerContext;
    const context = this.props.scanViewerContext;
    return (
      <div className={classes.root}>
        <div>
          {slideScanning ? (
            <Tooltip disableInteractive placement="left" title="Pause scan">
              <IconButton
                className={classes.toolbarButtonActive}
                onClick={this.onPlayPause}
                size="large"
              >
                <Pause />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip disableInteractive placement="left" title="Start scan">
              <IconButton
                className={classes.toolbarButtonActive}
                onClick={this.onPlayPause}
                size="large"
              >
                <PlayArrow />
              </IconButton>
            </Tooltip>
          )}
          <Divider />
          <Tooltip
            disableInteractive
            placement="left"
            title={cameraVisible ? "Hide Camera Stream" : "Show Camera Stream"}
          >
            <IconButton
              className={
                cameraVisible
                  ? classes.toolbarButtonActive
                  : classes.toolbarButton
              }
              onClick={context.toggleCamera}
              size="large"
            >
              <Videocam />
            </IconButton>
          </Tooltip>
          <Tooltip
            disableInteractive
            placement="left"
            title={mapVisible ? "Hide Minimap" : "Show Minimap"}
          >
            <IconButton
              className={
                mapVisible ? classes.toolbarButtonActive : classes.toolbarButton
              }
              onClick={context.toggleMinimap}
              size="large"
            >
              <Map />
            </IconButton>
          </Tooltip>
          <Tooltip
            disableInteractive
            placement="left"
            title={gridVisible ? "Hide Grid" : "Show Grid"}
          >
            <IconButton
              className={
                gridVisible
                  ? classes.toolbarButtonActive
                  : classes.toolbarButton
              }
              onClick={context.toggleGrid}
              size="large"
            >
              <GridOn />
            </IconButton>
          </Tooltip>
          <Tooltip
            disableInteractive
            placement="left"
            title={debugDataVisible ? "Hide Debug Data" : "Show Debug Data"}
          >
            <IconButton
              className={
                debugDataVisible
                  ? classes.toolbarButtonActive
                  : classes.toolbarButton
              }
              onClick={context.toggleDebugData}
              size="large"
            >
              <BugReportIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            disableInteractive
            placement="left"
            title={commentsVisible ? "Hide Comments" : "Show Comments"}
          >
            <IconButton
              className={
                commentsVisible
                  ? classes.toolbarButtonActive
                  : classes.toolbarButton
              }
              onClick={context.toggleComments}
              size="large"
            >
              <Comment />
            </IconButton>
          </Tooltip>
          {this.showWIPIcons &&
            ((
              <Tooltip
                disableInteractive
                placement="left"
                title={
                  sideBarContent === "fluorescence"
                    ? "Brightfield"
                    : "Fluorescence"
                }
              >
                <IconButton
                  className={
                    sideBarContent === "fluorescence"
                      ? classes.toolbarButtonActive
                      : classes.toolbarButton
                  }
                  onClick={context.toggleFluorescence}
                  size="large"
                >
                  <Flare />
                </IconButton>
              </Tooltip>
            ),
            (
              <Tooltip
                disableInteractive
                placement="left"
                title={"automated Microscope"}
              >
                <IconButton
                  className={
                    automationVisible
                      ? classes.toolbarButtonActive
                      : classes.toolbarButton
                  }
                  onClick={context.toggleAutomation}
                  size="large"
                >
                  <VideogameAsset />
                </IconButton>
              </Tooltip>
            ),
            (
              <Tooltip
                disableInteractive
                placement="left"
                title={"Focus Peaking"}
              >
                <IconButton
                  className={
                    focusPeakingOn
                      ? classes.toolbarButtonActive
                      : classes.toolbarButton
                  }
                  onClick={context.toggleFocusPeaking}
                  size="large"
                >
                  <BlurOn />
                </IconButton>
              </Tooltip>
            ))}
        </div>
        <div>
          <Divider />
          <Tooltip
            disableInteractive
            placement="left"
            title="Zoom In [ctrl]+[+]"
          >
            <span>
              <IconButton
                id="zoomInScanToolBar"
                className={classes.toolbarButton}
                onClick={context.zoomIn}
                size="large"
              >
                <ZoomIn className={classes.toolbarButtonIcon} />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            disableInteractive
            placement="left"
            title="Zoom Out [ctrl]+[-]"
          >
            <span>
              <IconButton
                id="zoomOutScanToolBar"
                className={classes.toolbarButton}
                onClick={context.zoomOut}
                size="large"
              >
                <ZoomOut className={classes.toolbarButtonIcon} />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            disableInteractive
            placement="left"
            title="Zoom Reset [ctrl]+[0]"
          >
            <span>
              <IconButton
                id="zoomResetScanToolBar"
                className={classes.toolbarButton}
                onClick={context.zoomReset}
                size="large"
              >
                <CustomSvgIcon
                  name="ResetZoomIcon"
                  width="22.86"
                  height="23.8"
                  stroke="currentColor"
                  vertAlign="-4px"
                />
              </IconButton>
            </span>
          </Tooltip>
          <Divider />
          <Tooltip disableInteractive placement="left" title="Reset scan">
            <IconButton
              className={classes.toolbarButton}
              onClick={() => changeScanState("scan_reset")}
              size="large"
            >
              <RestartAlt />
            </IconButton>
          </Tooltip>
          <Divider />
          <Tooltip disableInteractive placement="left" title="Toggle sidebar">
            <IconButton
              className={classes.toolbarButton}
              onClick={onToggleSidebar}
              size="medium"
            >
              <FontAwesomeIcon icon={faExchangeAlt} />
            </IconButton>
          </Tooltip>
          {this.showWIPIcons &&
            ((<Divider />),
            (
              <Tooltip disableInteractive placement="left" title="Import WIP">
                <IconButton
                  className={classes.toolbarButtonActive}
                  onClick={() =>
                    window.openResponseDialog(
                      "Do you really want to import a project? The current project will be deleted.",
                      (response) => {
                        if (response) {
                          this.props.scanViewerContext.importWIP();
                        }
                      }
                    )
                  }
                  size="large"
                >
                  <GetApp />
                </IconButton>
              </Tooltip>
            ),
            (
              <Tooltip disableInteractive placement="left" title="Export WIP">
                <IconButton
                  className={classes.toolbarButtonActive}
                  onClick={() => this.props.scanViewerContext.exportWIP()}
                  size="large"
                >
                  <Publish />
                </IconButton>
              </Tooltip>
            ))}
          <Divider />
          <Tooltip
            disableInteractive
            placement="left"
            title="Save scan into file"
          >
            <IconButton
              className={classes.toolbarButtonActive}
              id="saveButtonScanToolBar"
              onClick={() => this.setState({ fileDialogOpen: true })}
              size="large"
            >
              <Save />
            </IconButton>
          </Tooltip>
          <SelectFileDialog
            open={this.state.fileDialogOpen}
            onConfirm={this.onSave}
            onClose={() => this.setState({ fileDialogOpen: false })}
            fileFormats={["hsasld"]}
          />
        </div>
      </div>
    );
  }
}

ScanToolBar.propTypes = {
  classes: PropTypes.object.isRequired,
  scanViewerContext: PropTypes.object.isRequired,
  onToggleSidebar: PropTypes.func.isRequired,
  sidebarVisible: PropTypes.bool,
};

export default withScanViewerContext(withStyles(styles)(ScanToolBar));

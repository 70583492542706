// Copyright HS Analysis GmbH, 2023
// Author: Valentin Haas

// Description: Standardized Roi Types for HSA KIT.
// For ROI documentation see https://hsa.atlassian.net/wiki/spaces/HSAKIT/pages/16116235173917/Annotations+Data+Structure

// Keep in sync with C# and js frontend:
// Source\HSA-KIT\Database\Model\RoiTypes.cs
// Source\HSA-KIT\ClientApp\src\common\components\RoiTypes.jsx
// Source\HSA-KIT\modules\hsa\core\models\roi_types.py

// External packages
import { v4 as uuidv4 } from "uuid";

// HSA imports
import Backend from "../utils/Backend";
import { isInt, isUuid, downloadJsonObjectAsFile } from "../utils/Utils";
import { convertDateToShortIsoString } from "../../common/utils/Localization";
import { calcBoundingBoxFullObject } from "../../viewer/utils/PolygonUtil";
import Structure from "./Structure";

//#region DataType Enumerators
/**
 * Limits and describes possible modification statuses.
 */
export const ModificationStatus = Object.freeze({
  // Must be updated together with backend
  Saved: 0,
  Added: 1,
  Deleted: 2,
});

/**
 * Limits and describes annotation types.
 */
export const RoiType = Object.freeze({
  // Must be updated together with backend
  ImageRoi: 0,
  AudioRoi: 1,
});

/**
 * Limits and decribes possible image annotation types statuses.
 */
export const ImageAnnotationType = Object.freeze({
  // Must be updated together with backend
  Area: 0,
  Object: 1,
  Comments: 2,
});

/**
 * Implemented actions concerning annotations to be executed.
 */
export const AnnotationAction = Object.freeze({
  // Must be updated together with backend
  Save: 0,
  Export: 1,
  Import: 2,
  DeleteAll: 3,
});
//#endregion

//#region Roi Actions

/**
 * Save all passed rois to database.
 * Update rois in frontend based on response from backend.
 * @param {uuid} projectId Project id to save annotations for.
 * @param {object} file File to save annotations for.
 * @param {Structure} structure Structure to save annotations for.
 * @param {RoiType} annotationType Type of rois to save.
 * @param {array} annotations Annotations to save.
 * @param {function} callback Optional: Always execute after receiving and processing save response. Receives (array: newAnnotations).
 * @param {function} error Optional: Execute on error. Receives (object: error).
 */
export function saveAnnotations(
  projectId,
  file,
  structure,
  annotationType,
  annotations,
  callback = () => {},
  error = () => {}
) {
  if (!isUuid(projectId)) {
    throw TypeError(
      `projectId must be a valid UUID, received ${typeof projectId}: ${projectId}`
    );
  }
  if (typeof file !== "object") {
    throw TypeError(
      `file must be of type object, received ${typeof file}: ${file}`
    );
  }
  if (!(structure instanceof Structure)) {
    throw TypeError(
      `structure must be of type Structure, received ${typeof structure}: ${structure}`
    );
  }
  if (!Object.values(RoiType).includes(annotationType)) {
    `annotationType unknown, received ${typeof annotationType}: ${annotationType}`;
  }
  if (!Array.isArray(annotations)) {
    throw TypeError(
      `annotations must be an array, received ${typeof annotations}: ${annotations}`
    );
  }

  Backend.saveAnnotationsToDb(
    projectId,
    RoiType.AudioRoi,
    annotations,
    (data) => {
      // Read potentially updated roi ids.
      let oldIds = [];
      let newIds = [];

      data.roiUpdate.reassignedIds.forEach((idTuple) => {
        oldIds.push(idTuple[0]);
        newIds.push(idTuple[1]);
      });

      annotations = annotations.map((a) => {
        // Mirror potentially updated roi ids.
        if (oldIds.includes(a.id)) {
          a.id = newIds[oldIds.indexOf(a.id)];
        }

        // Everything was successfull -> Mark all annotations as saved.
        switch (a.modificationStatus) {
          case ModificationStatus.Saved:
            return a;

          case ModificationStatus.Added:
            a.modificationStatus = ModificationStatus.Saved;
            return a;

          case ModificationStatus.Deleted:
            // Exclude successfully deleted rois.
            break;

          default:
            break;
        }
      });

      // Remove undefineds
      annotations = annotations.filter((a) => a);
      console.debug(
        `Saved annotations for file "${file.fileName}", structure ${structure.label}: ${data.success}`
      );
      callback(annotations);
    },
    (err) => {
      // Some annotations failed to save to database.
      if (err.warning) {
        let oldIds = [];
        let newIds = [];

        // Read potentially updated roi ids.
        err.roiUpdate.reassignedIds.forEach((idTuple) => {
          oldIds.push(idTuple[0]);
          newIds.push(idTuple[1]);
        });

        annotations = annotations.map((a) => {
          // Mirror potentially updated roi ids.
          if (oldIds.includes(a.id)) {
            a.id = newIds[oldIds.indexOf(a.id)];
          }

          if (a.modificationStatus === ModificationStatus.Saved) {
            return a;
          }
          // Keep unsuccessfully added or unsuccessfully deleted rois unchanged
          else if (
            err.roiUpdate.failedToAdd.includes(a.id) ||
            err.roiUpdate.failedToDelete.includes(a.id)
          ) {
            return a;
          }
          // Set successfully added rois as saved.
          else if (a.modificationStatus === ModificationStatus.Added) {
            a.modificationStatus = ModificationStatus.Saved;
            return a;
          }
          // Exclude successfully deleted rois.
        });

        // Remove undefineds
        annotations = annotations.filter((a) => a);

        window.showWarningSnackbar(err.warning);
        console.debug(err);
        error(err);
        callback(annotations);
      }
      // The save process went wrong somewhere
      else if (err.error) {
        window.showErrorSnackbar(
          `Error saving annotations of file "${file.fileName}", structure ${structure.label}:\n${err.error}`
        );
        error(err);
      }
      // Good luck
      else {
        window.showErrorSnackbar(
          `Unkonwn error saving annotations of file "${file.fileName}", structure ${structure.label}"`
        );
        error(err);
      }
    }
  );
}

/**
 * Export all annotations in frontend to .annhsa file.
 * Triggers download of the .annhsa file.
 * @param {string} username Name of the user exporting the annotations.
 * @param {object} project Origin project of the rois.
 * @param {object} file File corresponding to the rois.
 * @param {Structure} structure Structure corresponding to the rois.
 * @param {RoiType} annotationType Type of rois to save.
 * @param {array} annotations All annotations.
 * @return {object} Success status of export.
 */
export function exportAnnotations(
  username,
  project,
  file,
  structure,
  annotationType,
  annotations
) {
  if (typeof username !== "string") {
    throw TypeError(
      `username must be of type string, received ${typeof username}: ${username}`
    );
  }
  if (typeof project !== "object") {
    throw TypeError(
      `project must be of type object, received ${typeof project}: ${project}`
    );
  }
  if (typeof file !== "object") {
    throw TypeError(
      `file must be of type object, received ${typeof file}: ${file}`
    );
  }
  if (!(structure instanceof Structure)) {
    throw TypeError(
      `structure must be of type Structure, received ${typeof structure}: ${structure}`
    );
  }
  if (!Object.values(RoiType).includes(annotationType)) {
    `annotationType unknown, received ${typeof annotationType}: ${annotationType}`;
  }
  if (!Array.isArray(annotations)) {
    throw TypeError(
      `annotations must be an array, received ${typeof annotations}: ${annotations}`
    );
  }

  try {
    // Export rois with meta info
    const timestamp = convertDateToShortIsoString(Date.now());
    downloadJsonObjectAsFile(
      {
        version: 1.0,
        timestamp: timestamp,
        user: username,
        project: project,
        file: {
          id: file.id,
          label: file.fileName,
        },
        structure: {
          id: structure.id,
          label: structure.label,
          isStructure: structure.isStructure,
        },
        annotationType: annotationType,
        annotations: annotations,
      },
      `${timestamp}_${project.name}_${file.fileName}_${structure.label}_(${annotations.length})_${username}.annhsa`
    );

    return {
      success: true,
      msg: `Successfully exported ${annotations.length} annotations of structure ${structure.label}`,
    };
  } catch (err) {
    return {
      success: false,
      msg: `Failed to export ${annotations.length} annotations of structure ${structure.label}`,
      error: err,
    };
  }
}

/**
 * Open a file selection window and import annotations from selected file to frontend.
 * Deletes all previous annotations.
 * @param {RoiType} annotationType The expected annotation type.
 * @param {array} existingAnnotations The previously exisiting annotations. Will all be set as to delete.
 * @param {object} file File corresponding to the rois.
 * @param {Structure} structure Structure corresponding to the rois.
 * @param {function} callback Always executes after loading and processing annotations. Receives (array: annotations).
 * @param {function} error Optional: Execute on error. Receives (object: error).
 */
export async function importAnnotations(
  annotationType,
  existingAnnotations,
  file,
  structure,
  callback,
  error = () => {}
) {
  if (!Object.values(RoiType).includes(annotationType)) {
    `annotationType unknown, received ${typeof annotationType}: ${annotationType}`;
  }
  if (!Array.isArray(existingAnnotations)) {
    throw TypeError(
      `existingAnnotations must be an array, received ${typeof existingAnnotations}: ${existingAnnotations}`
    );
  }
  if (typeof file !== "object") {
    throw TypeError(
      `file must be of type object, received ${typeof file}: ${file}`
    );
  }
  if (!(structure instanceof Structure)) {
    throw TypeError(
      `structure must be of type Structure, received ${typeof structure}: ${structure}`
    );
  }

  // Warn about unsaved existing annotations
  const unsaved = existingAnnotations.filter(
    (a) => a.modificationStatus !== ModificationStatus.Saved
  ).length;
  if (unsaved > 0) {
    const continueImport = await window.openConfirmationDialog(
      "Unsaved annotations",
      `You have ${unsaved} unsaved modified ${
        unsaved === 1 ? "annotation" : "annotations"
      } in the structure "${structure.label}". 
      An import will irreversibly remove all unsaved annotations. 
      Import anyway?`
    );
    if (!continueImport) return;
  }

  // Import window
  const inputElem = document.createElement("INPUT");
  inputElem.setAttribute("type", "file");
  inputElem.setAttribute("onChange", "file");
  inputElem.setAttribute("accept", ".annhsa");
  inputElem.onchange = (e) => {
    // Analyse the incoming file, abort on no file.
    let files = e.target.files;
    if (files.length <= 0) return;

    let fr = new FileReader();
    fr.onload = async (e) => {
      try {
        const data = JSON.parse(e.target.result);
        // Data validation checks
        // TODO: Refactor into some kind of async .then functions
        if (data.annotationType !== annotationType) {
          throw TypeError(
            `Annotation type mismatch: was expecting "${annotationType}", imported file is of type "${data.annotationType}"`
          );
        }

        // Warn about file change
        if (data.file.id !== file.id && data.file.label !== file.fileName) {
          const continueImport = await window.openConfirmationDialog(
            "File Mismatch",
            `The annotations were created for a different file:\n"${data.file.label}". 
            You are attempting to import it to the file:\n"${file.fileName}". 
            An import will remove all existing annotations. 
            Import anyway?`
          );
          if (!continueImport) return;
        }

        // Warn about structure change
        if (
          data.structure.id !== structure.id &&
          data.structure.label !== structure.label
        ) {
          const continueImport = await window.openConfirmationDialog(
            "Structure Mismatch",
            `The annotations were created for a different structure: "${data.structure.label}". 
            You are attempting to import it to the structure: "${structure.label}". 
            An import will remove all existing annotations. 
            Import anyway?`
          );
          if (!continueImport) return;
        }

        // Warn about 0-length imported annotations
        if (data.annotations.length === 0) {
          const continueImport = await window.openConfirmationDialog(
            "No annotatations to import",
            `There are no annotations included in uploaded file. 
            An import will remove all existing annotations. 
            Import anyway?`
          );
          if (!continueImport) return;
        }

        const importedAnnotations = [];
        switch (annotationType) {
          case RoiType.ImageRoi:
            data.annotations.forEach((a) =>
              importedAnnotations.push(
                new ImageRoi(
                  uuidv4(),
                  file.id,
                  structure.id,
                  a.isAiAnnotated,
                  a.user,
                  ModificationStatus.Added,
                  a.z,
                  a.t,
                  a.coordinates,
                  a.annotationType,
                  a.minX,
                  a.minY,
                  a.maxX,
                  a.maxY
                )
              )
            );
            break;

          case RoiType.AudioRoi:
            data.annotations.forEach((a) =>
              importedAnnotations.push(
                new AudioRoi(
                  uuidv4(),
                  file.id,
                  structure.id,
                  a.isAiAnnotated,
                  a.user,
                  ModificationStatus.Added,
                  a.startTime,
                  a.endTime,
                  a.minFreq,
                  a.maxFreq,
                  a.channels
                )
              )
            );
            break;

          default:
            break;
        }

        // Set all existing annotations as deleted
        existingAnnotations.forEach(
          (anno) => (anno.modificationStatus = ModificationStatus.Deleted)
        );

        callback([...existingAnnotations, ...importedAnnotations]);
      } catch (err) {
        const errmsg = `Failed to import the annotations of type ${annotationType} from file ${files[0].name}:\n${err}`;
        console.debug(errmsg);
        error(errmsg);
      }
    };
    // Only accept the first element
    fr.readAsText(files.item(0));
  };
  inputElem.click();
  inputElem.remove();
}

/**
 * After a confirming prompt, delete all annotations of a structure in frontend.
 * @param {array} existingAnnotations The previously exisiting annotations. Will all be set as to delete.
 * @param {Structure} structure Structure corresponding to the rois.
 * @param {function} callback Save updated annotations after modfication. Receives (array: annotations).
 * @param {bool} forceDelete Optional. Delete all annotations with out asking user first. Defaults to false.
 */
export async function deleteAllAnnotations(
  existingAnnotations,
  structure,
  callback,
  forceDelete = false
) {
  if (!Array.isArray(existingAnnotations)) {
    throw TypeError(
      `existingAnnotations must be an array, received ${typeof existingAnnotations}: ${existingAnnotations}`
    );
  }
  if (!(structure instanceof Structure)) {
    throw TypeError(
      `structure must be of type Structure, received ${typeof structure}: ${structure}`
    );
  }
  if (typeof forceDelete !== "boolean") {
    throw TypeError(
      `forceDelete must be a boolean, received ${typeof forceDelete}: ${forceDelete}`
    );
  }
  const continueDeletion =
    forceDelete ||
    (await window.openConfirmationDialog(
      "Delete all Annotations",
      `This action will delete all annotations of the structure "${structure.label}". Proceed?`
    ));
  if (!continueDeletion) return;

  const updatedAnnotations = existingAnnotations.map((a) => {
    a.modificationStatus = ModificationStatus.Deleted;
    return a;
  });

  callback(updatedAnnotations);
}

//#endregion

//#region General Roi definitions
/**
 * Basic information every type of region of interest (ROI) must contain.
 */
class Roi {
  // Must be updated together with backend
  /**
   * Create a ROI object with all parameters.
   * @param {Guid} id Id of the ROI.
   * @param {Guid} fileId Id of the associated scene.
   * @param {uint} structure Id of associated structure.
   * @param {bool} isAiAnnotated Whether or not this annotation is AI generated.
   * @param {ModificationStatus} modificationStatus Status of modification of any single annotation.
   * @param {Guid} user User that created this annotation.
   */
  constructor(
    id,
    fileId,
    structure,
    isAiAnnotated,
    modificationStatus = ModificationStatus.Added,
    user
  ) {
    // Input validation
    if (!isUuid(id)) {
      throw TypeError(
        `id must be a non-nil UUID, received ${typeof id}: ${id}`
      );
    }
    if (!isUuid(fileId)) {
      throw TypeError(
        `fileId must be a non-nil UUID, received ${typeof fileId}: ${fileId}`
      );
    }
    if (!isInt(structure) || structure < 0) {
      throw TypeError(
        `structure must be an integer >= 0, received ${typeof structure}: ${structure}`
      );
    }
    if (typeof isAiAnnotated !== "boolean") {
      throw TypeError(
        `isAiAnnotated must be a boolean, received ${typeof isAiAnnotated}: ${isAiAnnotated}`
      );
    }
    if (!Object.values(ModificationStatus).includes(modificationStatus)) {
      throw TypeError(
        `Invalid modificationStatus, received ${typeof modificationStatus}: ${modificationStatus}`
      );
    }
    if (!isUuid(user)) {
      throw TypeError(
        `user must be a non-nil UUID, received ${typeof user}: ${user}`
      );
    }

    // Value assignment
    this.id = id;
    this.fileId = fileId;
    this.structure = structure;
    this.isAiAnnotated = isAiAnnotated;
    this.modificationStatus = modificationStatus;
    this.user = user;
  }
}
//#endregion

//#region ImageRois
/**
 * A ROI in an image or video, whose coordinates comply with the GeoJSON standard.
 */
export class ImageRoi extends Roi {
  // Must be updated together with backend
  /**
   * Create an ImageRoi object with all parameters.
   * @param {Guid} id Id of the ROI.
   * @param {Guid} fileId Id of the associated scene.
   * @param {Structure} structure Associated strucuture.
   * @param {bool} isAiAnnotated Whether or not this annotation is AI generated.
   * @param {ModificationStatus} modificationStatus Status of modification of any single annotation. 0: Saved to DB, 1: Added or modified, 2: Deleted
   * @param {Guid} user User that created this annotation.
   * @param {number} z Z-Layer in a z-Stack image.
   * @param {number} t Timeframe in a video.
   * @param {JSON | string} coordinates The coordinates of the polygon, complies with the GeoJSON format. Can be passed as string, will be converted to json.
   * @param {ImageAnnotationType} annotationType Defines the type of annotations.
   * @param {number} minX Optional. Minimum X value of the polygon bounding box.
   * @param {number} minY Optional. Minimum Y value of the polygon bounding box.
   * @param {number} maxX Optional. Maximum X value of the polygon bounding box.
   * @param {number} maxY Optional. Maximum Y value of the polygon bounding box.
   */
  constructor(
    id,
    fileId,
    structure,
    isAiAnnotated,
    user,
    modificationStatus,
    z,
    t,
    coordinates,
    annotationType,
    minX,
    minY,
    maxX,
    maxY
  ) {
    super(id, fileId, structure, isAiAnnotated, modificationStatus, user);

    // Input validation
    if (!isInt(z) || z < 0) {
      throw TypeError(`z must be an integer >= 0, received ${typeof z}: ${z}`);
    }
    if (!isInt(t) || z < 0) {
      throw TypeError(`t must be an integer >= 0, received ${typeof t}: ${t}`);
    }
    if (typeof coordinates !== "object" && typeof coordinates !== "string") {
      throw TypeError(
        `Invalid coordinates, received ${typeof coordinates}: ${coordinates}`
      );
    }
    if (!Object.values(ImageAnnotationType).includes(annotationType)) {
      throw TypeError(
        `Invalid annotationType, received ${typeof annotationType}: ${annotationType}`
      );
    }
    if (typeof minX !== "number" && minX !== undefined)
      throw TypeError(
        `minX must be a number, received ${typeof minX}: ${minX}`
      );
    if (typeof minY !== "number" && minX !== undefined)
      throw TypeError(
        `minY must be a number, received ${typeof minY}: ${minY}`
      );
    if (typeof maxX !== "number" && minX !== undefined)
      throw TypeError(
        `maxX must be a number, received ${typeof maxX}: ${maxX}`
      );
    if (typeof maxY !== "number" && minX !== undefined)
      throw TypeError(
        `maxY must be a number, received ${typeof maxY}: ${maxY}`
      );

    // Value assignment
    this.z = z;
    this.t = t;
    this.coordinates =
      typeof coordinates === "string" ? JSON.parse(coordinates) : coordinates;
    this.annotationType = annotationType;

    // Should the values not be passed on, they must be calculated.
    if (minX && minY && maxX && maxY) {
      this.minX = minX;
      this.minY = minY;
      this.maxX = maxX;
      this.maxY = maxY;
    } else {
      // Calculate bounding box
      let rect = calcBoundingBoxFullObject(this.coordinates);
      // Assuming coordinate origin in top left corner
      // . -- x -->
      // |
      // y
      // |
      // V
      this.minX = rect.left;
      this.minY = rect.top;
      this.maxX = rect.right;
      this.maxY = rect.bottom;
    }
  }

  /**
   * Returns an item that can be added to rTree or rBush.
   * Prevents the need to manually define such a tree item every time.
   * @returns Treeitem with bounding box and reference to roi.
   */
  getTreeItem() {
    return {
      minX: this.minX,
      minY: this.minY,
      maxX: this.maxX,
      maxY: this.maxY,
      roi: this,
    };
  }

  /**
   * Create a new ImageRoi from an object without needing to define all parameters individually.
   * Accepts camelCase and PascalCase properties, with camelCase being the default.
   * Additional properties will be ignored,
   * missing properties will use defaults or throw error per ImageRoi constructor.
   * @param {object} obj An object containing all necessary properties of a ImageRoi.
   * @returns {ImageRoi} A new ImageRoi.
   */
  static fromObject = (obj) => {
    return new ImageRoi(
      obj.id ?? obj.Id,
      obj.fileId ?? obj.FileId,
      obj.structure ?? obj.Structure,
      obj.isAiAnnotated ?? obj.IsAiAnnotated,
      obj.user ?? obj.User,
      obj.modificationStatus ?? obj.ModificationStatus,
      obj.z ?? obj.Z,
      obj.t ?? obj.T,
      obj.coordinates ?? obj.Coordinates,
      obj.annotationType ?? obj.AnnotationType,
      obj.minX ?? obj.MinX,
      obj.minY ?? obj.MinY,
      obj.maxX ?? obj.MaxX,
      obj.maxY ?? obj.MaxY
    );
  };
}
//#endregion

//#region AudioRois
export class AudioRoi extends Roi {
  // Must be updated together with backend
  /**
   * Create a AudioRoi object with all parameters.
   * @param {Guid} id Id of the ROI.
   * @param {Guid} fileId Id of the associated scene.
   * @param {Structure} structure Associated strucuture.
   * @param {bool} isAiAnnotated Whether or not this annotation is AI generated.
   * @param {ModificationStatus} modificationStatus Status of modification of any single annotation.
   * @param {Guid} user User that created this annotation.
   * @param {number} startTime Timestamp of annotation beginning in seconds.
   * @param {number} endTime Timestamp of annotation end in seconds.
   * @param {number} minFreq Minimum frequency of annotation in Hertz.
   * @param {number} maxFreq Maximum frequency of annotation in Hertz.
   * @param {array} channels All channels the annotation is present in.
   */
  constructor(
    id,
    fileId,
    structure,
    isAiAnnotated,
    user,
    modificationStatus,
    startTime,
    endTime,
    minFreq,
    maxFreq,
    channels
  ) {
    super(id, fileId, structure, isAiAnnotated, modificationStatus, user);

    // Input validation
    if (typeof startTime !== "number" || startTime < 0) {
      throw TypeError(
        `startTime must be a number >= 0, received ${typeof startTime}: ${startTime}`
      );
    }
    if (typeof endTime !== "number" || endTime < 0) {
      throw TypeError(
        `endTime must be a number >= 0, received ${typeof endTime}: ${endTime}`
      );
    }
    if (typeof minFreq !== "number" || minFreq < 0) {
      throw TypeError(
        `minFreq must be a number >= 0, received ${typeof minFreq}: ${minFreq}`
      );
    }
    if (typeof maxFreq !== "number" || maxFreq < 0) {
      throw TypeError(
        `maxFreq must be a number >= 0, received ${typeof maxFreq}: ${maxFreq}`
      );
    }
    if (!Array.isArray(channels)) {
      throw TypeError(
        `channels must be an array, received ${typeof channels}: ${channels}`
      );
    }
    channels.forEach((c) => {
      if (!isInt(c) || c < 0) {
        throw TypeError(
          `All channels must be an integer >= 0, received ${typeof c}: ${c}`
        );
      }
    });

    // Value assignments
    // Ensure startTime comes before endtime
    if (startTime < endTime) {
      this.startTime = startTime;
      this.endTime = endTime;
    } else if (startTime > endTime) {
      // Switch times
      this.startTime = endTime;
      this.endTime = startTime;
    } else {
      // startTime === endTime
      throw new RangeError("startTime and endTime must not be identical.");
    }

    // Ensure minFreq comes before maxFreq
    if (minFreq < maxFreq) {
      this.minFreq = minFreq;
      this.maxFreq = maxFreq;
    } else if (minFreq > maxFreq) {
      // Switch frequencies
      this.minFreq = maxFreq;
      this.maxFreq = minFreq;
    } else {
      // minFreq === maxFreq
      throw new RangeError("minFreq and maxFreq must not be identical.");
    }

    this.channels = channels;
  }

  /**
   * Create a new AudioRoi from an object without needing to define all parameters individually.
   * Accepts camelCase and PascalCase properties, with camelCase being the default.
   * Additional properties will be ignored,
   * missing properties will use defaults or throw error per AudioRoi constructor.
   * @param {object} obj An object containing all necessary properties of a AudioRoi.
   * @returns {AudioRoi} A new AudioRoi.
   */
  static fromObject = (obj) => {
    return new AudioRoi(
      obj.id ?? obj.Id,
      obj.fileId ?? obj.FileId,
      obj.structure ?? obj.Structure,
      obj.isAiAnnotated ?? obj.IsAiAnnotated,
      obj.user ?? obj.User,
      obj.modificationStatus ?? obj.ModificationStatus,
      obj.startTime ?? obj.StartTime,
      obj.endTime ?? obj.EndTime,
      obj.minFreq ?? obj.MinFreq,
      obj.maxFreq ?? obj.MaxFreq,
      obj.channels ?? obj.Channels
    );
  };
}

//#endregion

import React, { useState, useEffect } from "react";
import BarChartIcon from "@mui/icons-material/BarChart";
import Backend from "../common/utils/Backend";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SettingsIcon from "@mui/icons-material/Settings";

import { Grid } from "@mui/material";

import AIModelsList from "./components/AIModelsList";
import AIModelsFiltering from "./components/AIModelsFiltering";
import AITrainingSettings, {
  TrainingParameters,
  ModelMetaData,
  DatasetParameters,
  ImageDatasetMeta,
} from "../common/components/AITrainingSettings";
import {
  AITrainingInformation,
  SystemInformation,
} from "../common/components/AITrainingInformation";
import { AITrainingDataContainer } from "../common/components/AITrainingDataContainer";
import { Tab, Tabs, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { CloudDownload, SimCardDownload } from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";

import ReplayIcon from "@mui/icons-material/Replay";
import { makeStyles } from "@mui/styles";
import SelectedAIModelInformation from "./components/dashboard-representation/SelectedAIModelInformation";
import ApacheEChartsTimeSeries from "./components/custom-tensorboard-representation/ApacheEChartsTimeSeries";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress";
import { handleDownload } from "./components/dashboard-representation/Utils";
import { downloadNZip } from "./components/custom-tensorboard-representation/Utils";
import Tooltip from "@mui/material/Tooltip";
import Structure from "../common/components/Structure";

const useStyles = makeStyles(() => ({
  blueButton: {
    border: "none",
    textAlign: "center",
    verticalAlign: "middle",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "14px",
    transitionDuration: "0s",
    color: "rgb(6, 115, 193)",
  },
  divider: {
    "&.MuiDivider-root": {
      backgroundColor: "rgb(66, 132, 243)",
      opacity: "1",
    },
  },
  greyButton: {
    border: "none",
    textAlign: "center",
    verticalAlign: "middle",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "14px",
    transitionDuration: "0s",
  },
  iconAdjust: {
    position: "relative",
    top: "-2px",
  },
}));

const addOneIfOdd = (nKeys) => {
  return nKeys % 2 == 0 ? nKeys : nKeys + 1;
};

/**
 * Overall parent component for AI Viewer.
 * This component contains all parts of the ai viewer and will be called with the /ai_view route.
 */
export default function AIViewer() {
  const [selectedModel, setSelectedModel] = useState("");
  const [trainedModels, setTrainedModels] = useState([]);
  const [loadingOne, setLoadingOne] = useState(true);
  const [importingAIModel, setImportingAIModel] = useState(false);
  const [exportingAIModel, setExportingAIModel] = useState(false);
  const [exportingAIModelDataset, setExportingAIModelDataset] = useState(false);
  const [deletingAIModel, setDeletingAIModel] = useState(false);
  const [modelFilterValue, setModelFilterValue] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);

  const [chartDataKeys, setChartDataKeys] = useState([]);
  const [error, setError] = useState(null);
  const [m, setM] = useState(0);
  const [n, setN] = useState(0);
  const [chartData, setChartData] = useState({});
  const classes = useStyles();

  useEffect(() => {
    fetchData(true);
    return () => {};
  }, []);

  const fetchData = async (showLoading = false) => {
    if (showLoading) setLoadingOne(true);
    try {
      if (selectedTab === 0) {
        Backend.getModelMetadata("hsa_models", false, (res) => {
          setTrainedModels(res.newModels);
        });
      }

      if (selectedTab === 1) {
        Backend.loadTensorboardScalarData(selectedModel, (res) => {
          setChartData(res);
          const resKeys = Object.keys(res);
          const resKeysLength = resKeys.length;
          setChartDataKeys(resKeys);
          setM(addOneIfOdd(resKeysLength));
          setN(resKeysLength);
        });
      }
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      console.log("Data fetched successfully!");
      setTimeout(() => setLoadingOne(false), 625);
    }
  };

  useEffect(() => {
    if (selectedTab === 1) {
      fetchData(true);
      setLoadingOne(true);
      setTimeout(() => setLoadingOne(false), 625);
    }

    return () => {};
  }, [selectedModel, selectedTab]);

  const AIModels = [];

  const nTrainedModels = trainedModels.length;
  if (nTrainedModels > 0) {
    for (let i = 0; i < nTrainedModels; i++) {
      const model = trainedModels[i];

      // get AITrainingSettings
      const metaData = new ModelMetaData({
        name: model.settings.meta_data.name,
        version: model.settings.meta_data.version,
        description: model.settings.meta_data.description,
        isNewModel: model.settings.meta_data.is_new_model,
      });
      const datasetParameters = new DatasetParameters({
        datasetApproach: model.settings.dataset_parameters.dataset_approach,
        datasetType: model.settings.dataset_parameters.dataset_type,
        datasetOnly: model.settings.dataset_parameters.dataset_only,
        useExistingDataset:
          model.settings.dataset_parameters.use_existing_dataset,
      });
      const trainingParameters = new TrainingParameters({
        epochs: model.settings.training_parameters.epochs,
        earlyStopping: model.settings.training_parameters.early_stopping,
        batchSize: model.settings.training_parameters.batch_size,
        metrics: model.settings.training_parameters.metrics,
        lossFunctions: model.settings.training_parameters.loss_function,
        optimizer: model.settings.training_parameters.optimizer,
        learningRate: model.settings.training_parameters.learning_rate,
      });
      const modelParameters = new ImageDatasetMeta({
        datasetType: model.settings.model_parameters.dataset_type,
        inputChannels: model.settings.model_parameters.input_channels,
        spatialDims: model.settings.model_parameters.spatial_dims,
        imageWidth: model.settings.model_parameters.image_width,
        imageHeight: model.settings.model_parameters.image_height,
        numberOfClasses: model.settings.model_parameters.number_of_classes,
        pyramidLevel: model.settings.model_parameters.pyramid_level,
      });

      const trainingsettings = new AITrainingSettings(
        model.settings.project_id,
        model.settings.project_type ? model.settings.project_type : "",
        metaData,
        datasetParameters,
        trainingParameters,
        modelParameters,
        model.settings.structures.map((structure) =>
          Structure.fromObject(structure)
        )
      );

      // get AITrainingInformation
      const systemInformation = new SystemInformation(
        model.information.system_information
      );

      const trainingInformation = new AITrainingInformation(
        model.information.creation_date,
        model.information.completion_date,
        systemInformation,
        model.information.training_status,
        model.information.error_message
      );

      // initialize AIModelDataContainer
      const AIModelDataContainer = new AITrainingDataContainer(
        trainingsettings,
        trainingInformation
      );

      AIModels.push(AIModelDataContainer);
    }

    AIModels.sort((a, b) => {
      return (
        Date.parse(b.information.creationDate) -
        Date.parse(a.information.creationDate)
      );
    });
  }

  const getFilteredAIModels = (filterInput, AIModels) => {
    return AIModels.filter((model) => {
      return model.settings.metaData.name
        .toLowerCase()
        .includes(filterInput.toLowerCase());
    });
  };

  const filteredModels = getFilteredAIModels(modelFilterValue, AIModels);

  const handleFilterChange = (event) => {
    setModelFilterValue(event.target.value);
  };

  const updateSelectedModel = () => {
    if (filteredModels.length === 0) {
      setSelectedModel("");
      return "";
    }

    setSelectedModel(filteredModels[0].settings.metaData.name);
    return filteredModels[0].settings.metaData.name;
  };

  const handleImportModelSelection = (e) => {
    let files = e.target.files;
    setImportingAIModel(true);
    Backend.importAIModels(files[0], (result) => {
      if (!result.success) {
        window.showErrorSnackbar("Import failed");
      } else if (result.fileIsNew) {
        window.showSuccessSnackbar("Successfully imported model");
      } else {
        window.showWarningSnackbar("Model already exists");
      }
      setImportingAIModel(false);
      fetchData(true);
    });
  };

  const handleExportModel = () => {
    setExportingAIModel(true);
    var aiModel = {
      Name: selectedModel,
    };
    Backend.exportAIModel(JSON.stringify(aiModel), (result) => {
      console.log("Exported model", result);
      setExportingAIModel(false);
    });
  };

  const handleExportModelDataset = () => {
    setExportingAIModelDataset(true);
    var aiModel = {
      Name: selectedModel,
    };
    Backend.exportAIModelDataset(JSON.stringify(aiModel), (result) => {
      console.log("Exported model dataset", result);
      setExportingAIModelDataset(false);
    });
  };

  const handleDeleteModel = () => {
    window.openResponseDialog(
      "Do you really want to delete this model?",
      (response) => {
        if (response) {
          setDeletingAIModel(true);
          Backend.deleteAIModel(selectedModel, (result) => {
            if (result.successful) {
              window.showSuccessSnackbar(result.information);
            } else {
              window.showErrorSnackbar(result.information);
            }
            setDeletingAIModel(false);
            fetchData(true);
          });
        }
      }
    );
  };

  const styleTwo = { height: "calc(100vh - 140px)", background: "#FFFFFF" };
  if (AIModels.length == 0) {
    return (
      <>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="calc(100vh - 140px)"
        >
          <Typography variant="h6">No AI models available</Typography>
          <Box display="flex" alignItems="center">
            <Tooltip
              title={"Import Model"}
              placement="bottom"
              disableInteractive
            >
              <IconButton
                color="primary"
                aria-label="import"
                onClick={() => document.getElementById("importAIModel").click()}
                disabled={loadingOne}
              >
                <CloudUploadIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <input
            type="file"
            id="importAIModel"
            style={{
              position: "absolute",
              top: "-150px",
            }}
            accept=".modelhsa"
            onChange={handleImportModelSelection}
            disabled={
              importingAIModel ||
              exportingAIModel ||
              exportingAIModelDataset ||
              deletingAIModel
            }
          />
        </Box>
      </>
    );
  }

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const setChartBlocks = (selectedTab, finalSelectedModel) => {
    const styleOne = { height: "100%", background: "rgb(235,235,235)" };
    switch (selectedTab) {
      case 0:
        return (
          <div style={styleOne}>
            <Box>
              <SelectedAIModelInformation
                loading={loadingOne}
                selectedAIModel={AIModels.find(
                  (item) => item.settings.metaData.name === finalSelectedModel
                )}
              ></SelectedAIModelInformation>
            </Box>
          </div>
        );
      case 1:
        return (
          <div style={styleOne}>
            <Box>
              <ApacheEChartsTimeSeries
                chartData={chartData}
                chartDataKeys={chartDataKeys}
                error={error}
                loading={loadingOne}
                selectedModel={finalSelectedModel}
                m={m}
                n={n}
              />
            </Box>
          </div>
        );
      default:
        return <></>;
    }
  };

  if (AIModels.length > 0) {
    const finalSelectedModel = filteredModels.some(
      (model) => model.settings.metaData.name === selectedModel
    )
      ? selectedModel
      : updateSelectedModel();
    const chartBlocks = setChartBlocks(selectedTab, finalSelectedModel);

    return (
      <>
        <Grid container spacing={0} style={{ flex: 1 }}>
          <Grid
            container
            direction="column"
            item
            xs={9}
            style={{
              height: "100%",
            }}
          >
            <Grid
              style={{
                height: "76px",
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingLeft: "8px",
                paddingRight: "8px",
                borderBottom: "1px solid rgb(235,235,235)",
              }}
            >
              <Card
                style={{
                  width: "240px",
                  color: "#0673C1",
                  userSelect: "none",
                }}
              >
                {loadingOne && <span>Loading...</span>}
                {!loadingOne && (
                  <span>
                    &quot;{selectedModel.replace("Custom - ", "")}
                    &quot;
                  </span>
                )}
              </Card>
              <Tabs
                style={{ width: "50%", alignSelf: "flex-end" }}
                value={selectedTab}
                onChange={handleChange}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab
                  icon={<InfoOutlinedIcon />}
                  iconPosition="start"
                  label="Model Information"
                  disabled={
                    loadingOne ||
                    importingAIModel ||
                    exportingAIModel ||
                    exportingAIModelDataset ||
                    deletingAIModel
                  }
                />
                <Tab
                  icon={<BarChartIcon />}
                  iconPosition="start"
                  label="Training Statistics"
                  disabled={
                    loadingOne ||
                    importingAIModel ||
                    exportingAIModel ||
                    exportingAIModelDataset ||
                    deletingAIModel
                  }
                />
                <Tab
                  icon={<SettingsIcon />}
                  iconPosition="start"
                  label="Model Settings (coming soon)"
                  disabled
                />
              </Tabs>
              <span
                style={{
                  width: "240px",
                }}
              ></span>
            </Grid>
            <Grid item>{chartBlocks}</Grid>
          </Grid>

          {/* Main Part*/}
          <Grid item xs={3} style={styleTwo}>
            <Grid
              style={{
                height: "76px",
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid rgb(235,235,235)",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    width: "48px",
                    height: "76px",
                    backgroundColor: "white",
                    borderRight: "8px solid rgb(235,235,235)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    title={"Refresh"}
                    placement="left"
                    disableInteractive
                  >
                    <span>
                      <IconButton
                        color="primary"
                        className={classes.iconButton}
                        onClick={() => {
                          fetchData(true);
                        }}
                        disabled={loadingOne}
                      >
                        <ReplayIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>
                <div
                  style={{
                    flex: 1,
                    height: "76px",
                    width: "calc(25vw - 48px)",
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <input
                    type="file"
                    id="importAIModel"
                    style={{
                      position: "absolute",
                      top: "-150px",
                    }}
                    accept=".modelhsa"
                    onChange={handleImportModelSelection}
                    disabled={
                      importingAIModel ||
                      exportingAIModel ||
                      exportingAIModelDataset ||
                      deletingAIModel
                    }
                  />
                  <AIModelsFiltering
                    loading={
                      importingAIModel ||
                      exportingAIModel ||
                      exportingAIModelDataset ||
                      deletingAIModel
                    }
                    modelFilterValue={modelFilterValue}
                    onFilterChange={handleFilterChange}
                  ></AIModelsFiltering>
                </div>
              </div>
            </Grid>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "48px",
                  backgroundColor: "white",
                  borderRight: "8px solid rgb(235,235,235)",
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    width: "48px",
                    backgroundColor: "white",
                    borderRight: "8px solid rgb(235,235,235)",
                    borderBottom: "1px solid rgb(235,235,235)",
                    textAlign: "center",
                  }}
                >
                  <Tooltip
                    title={"Export Model Information as .json"}
                    placement="left"
                    disableInteractive
                  >
                    <span>
                      <IconButton
                        className={classes.iconButton}
                        onClick={() => handleDownload(finalSelectedModel)}
                        disabled={loadingOne}
                      >
                        <InfoOutlinedIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip
                    title={
                      "Export all Training Statistics data and images as .zip"
                    }
                    placement="left"
                    disableInteractive
                  >
                    <span>
                      <IconButton
                        className={classes.iconButton}
                        onClick={() =>
                          downloadNZip(n, chartDataKeys, chartData)
                        }
                        disabled={loadingOne}
                      >
                        <BarChartIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip
                    title={"Export Model Information as .json"}
                    placement="left"
                    disableInteractive
                  >
                    <span>
                      <IconButton
                        className={classes.iconButton}
                        onClick={() => handleDownload(finalSelectedModel)}
                        disabled={true}
                      >
                        <SettingsIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>
                <div
                  style={{
                    width: "48px",
                    backgroundColor: "white",
                    borderRight: "8px solid rgb(235,235,235)",
                    borderBottom: "1px solid rgb(235,235,235)",
                    textAlign: "center",
                  }}
                >
                  <Tooltip
                    title={"Import Model"}
                    placement="left"
                    disableInteractive
                  >
                    <span>
                      <IconButton
                        color="primary"
                        className={classes.iconButton}
                        aria-label="import"
                        onClick={() =>
                          document.getElementById("importAIModel").click()
                        }
                        disabled={loadingOne}
                      >
                        <CloudUploadIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip
                    title={"Export Model"}
                    placement="left"
                    disableInteractive
                  >
                    <span>
                      <IconButton
                        color="primary"
                        className={classes.iconButton}
                        onClick={handleExportModel}
                        disabled={loadingOne}
                      >
                        <CloudDownload />
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>
                <div
                  style={{
                    width: "48px",
                    backgroundColor: "white",
                    borderRight: "8px solid rgb(235,235,235)",
                    borderBottom: "1px solid rgb(235,235,235)",
                    textAlign: "center",
                  }}
                >
                  <Tooltip
                    title={"Export Model Dataset"}
                    placement="left"
                    disableInteractive
                  >
                    <span>
                      <IconButton
                        className={classes.iconButton}
                        onClick={handleExportModelDataset}
                        disabled={loadingOne}
                      >
                        <SimCardDownload />
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>

                <div
                  style={{
                    marginTop: "auto", // This pushes the div to the bottom
                    width: "48px",
                    backgroundColor: "white",
                    borderRight: "8px solid rgb(235,235,235)",
                    borderTop: "1px solid rgb(235,235,235)",
                  }}
                >
                  <Tooltip
                    title={"Delete Model"}
                    placement="left"
                    disableInteractive
                  >
                    <span>
                      <IconButton
                        className={classes.iconButton}
                        onClick={handleDeleteModel}
                        disabled={loadingOne}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                {importingAIModel && (
                  <div>
                    <b>{"Importing AI Model ..."}</b>
                    <LinearProgress className={classes.loading} />
                  </div>
                )}
                {exportingAIModel && (
                  <div>
                    <b>{"Exporting AI Model ..."}</b>
                    <LinearProgress className={classes.loading} />
                  </div>
                )}
                {exportingAIModelDataset && (
                  <div>
                    <b>{"Exporting AI Model Dataset ..."}</b>
                    <LinearProgress className={classes.loading} />
                  </div>
                )}
                {deletingAIModel && (
                  <div>
                    <b>{"Deleting AI Model ..."}</b>
                    <LinearProgress className={classes.loading} />
                  </div>
                )}
                <AIModelsList
                  loading={
                    loadingOne ||
                    importingAIModel ||
                    exportingAIModel ||
                    exportingAIModelDataset ||
                    deletingAIModel
                  }
                  AIModels={filteredModels}
                  selectedModel={
                    filteredModels.some(
                      (model) => model.settings.metaData.name === selectedModel
                    )
                      ? selectedModel
                      : updateSelectedModel()
                  }
                  setSelectedModel={setSelectedModel}
                ></AIModelsList>
              </div>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}

// AIViewer.propTypes = {};

// Copyright HS Analysis GmbH, 2023
// Author: Lukas Buess

// HSA imports

// Keep in sync with:
// Source\HSA-KIT\modules\hsa\core\ai\....py
// Source\HSA-KIT\ClientApp\src\common\components\....jsx

/**
 * All dataset specific parameters of an AI Training.
 * @param {Object} systemInformation The system information object.
 * @param {String} systemInformation.os Operating system. Default: "No Information!".
 * @param {String} systemInformation.cpu Name of CPU. Default: "No Information!".
 * @param {String} systemInformation.gpu Name of GPU. Default: "No Information!".
 * @param {String} systemInformation.ram Amount of RAM. Default: "No Information!".
 */
export class SystemInformation {
  constructor(systemInformation) {
    let os = "No Information!";
    let cpu = "No Information!";
    let gpu = "No Information!";
    let ram = "No Information!";

    if (systemInformation) {
      os = systemInformation.os || os;
      cpu = systemInformation.cpu || cpu;
      gpu = systemInformation.gpu || gpu;
      ram = systemInformation.ram || ram;
    }

    this.os = os;
    this.cpu = cpu;
    this.gpu = gpu;
    this.ram = ram;
  }
}

/**
 * Information of an AI Training.
 * @param {String} creationDate Date when training was started.
 * @param {String} completionDate Date when training was completed.
 * @param {SystemInformation} systemInformation Information about the system used for training.
 * @param {String} trainingStatus status of the AI Training.
 * @param {String} errorMessage Error message if training failed.
 */
export class AITrainingInformation {
  constructor(
    creationDate = "",
    completionDate = "",
    systemInformation = new SystemInformation(),
    trainingStatus = "pending",
    errorMessage = ""
  ) {
    // Input validation
    if (typeof creationDate !== "string")
      throw TypeError(
        `creationDate must be of type string, received ${typeof creationDate}: ${creationDate}`
      );
    if (typeof completionDate !== "string")
      throw TypeError(
        `completionDate must be of type string, received ${typeof completionDate}: ${completionDate}`
      );
    if (!(systemInformation instanceof SystemInformation))
      throw TypeError(
        `systemInformation must be of type SystemInformation, received ${typeof systemInformation}: ${systemInformation}`
      );
    if (typeof trainingStatus !== "string")
      throw TypeError(
        `trainingStatus must be of type string, received ${typeof trainingStatus}: ${trainingStatus}`
      );
    if (typeof errorMessage !== "string")
      throw TypeError(
        `errorMessage must be of type string, received ${typeof errorMessage}: ${errorMessage}`
      );

    // Set parameters
    this.creationDate = creationDate;
    this.completionDate = completionDate;
    this.systemInformation = systemInformation;
    this.trainingStatus = trainingStatus;
    this.errorMessage = errorMessage;
  }
}

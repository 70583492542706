import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Backend from "../../common/utils/Backend";
import {
  TextField,
  Typography,
  CardActions,
  Button,
  Card,
  CardContent,
  Tooltip,
  ListItem,
  List,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
  IconButton,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import FolderChooser from "./FolderChooser";

const ImporterContainer = ({ canEditAutoImportSettings }) => {
  const [settings, setSettings] = useState(null);
  const [showFolderChooser, setShowFolderChooser] = useState(false);

  useEffect(() => {
    Backend.readImporterSettings((data) => {
      // convert string to array
      if (data.importerFolders) data.folders = data.importerFolders.split(";");
      else data.folders = [];
      setSettings(data);
    });
  }, []);

  const saveClick = () => {
    const newSettings = { ...settings };
    newSettings.importerFolders = newSettings.folders.join(";");
    Backend.writeImporterSettings(newSettings, (res) => {
      if (res.status === 403) {
        window.showErrorSnackbar("No permission to edit auto import settings!");
      } else if (res.success === true) {
        window.showSuccessSnackbar("Auto Import Settings saved!");
      } else {
        window.openErrorSnackbar("Auto Import Settings saving failed!");
        console.log(res);
      }
    });
  };
  const addDirectoryClick = () => {
    setShowFolderChooser(true);
  };

  const handleClose = (path) => {
    if (path) {
      const newSettings = { ...settings };
      newSettings.folders.push(path);
      setSettings(newSettings);
    }
    setShowFolderChooser(false);
  };

  const removeDirectoryClick = (path) => {
    const newSettings = { ...settings };
    newSettings.folders = newSettings.folders.filter((e) => e !== path);
    setSettings(newSettings);
  };

  if (!settings) return null;
  return (
    <Card>
      <CardContent>
        <Typography variant="h6">Auto Import Settings</Typography>
        <List dense={true}>
          {settings.folders.map((folder, folderIdx) => (
            <ListItem key={folderIdx}>
              <ListItemAvatar>
                <Avatar>
                  <FolderIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={folder}
                secondary={settings.storagePath + folder}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => removeDirectoryClick(folder)}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <Button
          disabled={canEditAutoImportSettings !== true}
          size="small"
          color="primary"
          startIcon={<AddIcon />}
          onClick={addDirectoryClick}
        >
          Add Directory
        </Button>
        <FolderChooser
          title="Choose Directory for Auto Import"
          open={showFolderChooser}
          isRelative={true}
          storagePath={settings.storagePath}
          onClose={handleClose}
        />
        <br />
        <br />
        <Tooltip disableInteractive title="Start timed imports">
          <span>
            <TextField
              disabled={canEditAutoImportSettings !== true}
              id="time_start"
              label="Start"
              type="time"
              defaultValue={settings.importerStart}
              onChange={(e) => {
                const newSettings = { ...settings };
                newSettings.importerStart = e.target.value;
                setSettings(newSettings);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              name="Textfield"
            />
          </span>
        </Tooltip>

        <span> </span>
        <Tooltip disableInteractive title="Stop starting timed imports">
          <span>
            <TextField
              disabled={canEditAutoImportSettings !== true}
              id="time_end"
              label="End"
              type="time"
              defaultValue={settings.importerEnd}
              onChange={(e) => {
                const newSettings = { ...settings };
                newSettings.importerEnd = e.target.value;
                setSettings(newSettings);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              name="Textfield"
            />
          </span>
        </Tooltip>
        <br />
        <br />
        <Tooltip disableInteractive title="Date and Time of last Import">
          <span>
            <TextField
              disabled={canEditAutoImportSettings !== true}
              id="time_end"
              label="Newer than"
              type="datetime-local"
              defaultValue={settings.importerNewerThan}
              onChange={(e) => {
                const newSettings = { ...settings };
                newSettings.importerNewerThan = e.target.value;
                setSettings(newSettings);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              name="Textfield"
            />
          </span>
        </Tooltip>
      </CardContent>
      <CardActions>
        <Button
          variant="outlined"
          disabled={canEditAutoImportSettings !== true}
          size="small"
          color="primary"
          onClick={saveClick}
        >
          Save
        </Button>
      </CardActions>
    </Card>
  );
};

ImporterContainer.propTypes = {
  canEditAutoImportSettings: PropTypes.bool.isRequired,
};

export default ImporterContainer;

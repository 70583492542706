import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Backend from "../../common/utils/Backend";
import { withSpinloader } from "../../common/components/Spinloader";
import {
  TextField,
  Typography,
  CardActions,
  Button,
  Card,
  CardContent,
  Tooltip,
} from "@mui/material";
// import { makeStyles } from "@mui/styles";

// const useStyles = makeStyles({
//   root: {
//     flexGrow: 1,
//     width: "100%",
//     height: "calc(100vh - 64px - 48px)",
//     overflow: "hidden",
//   },
//   paper: {
//     padding: 20,
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//   },
// });

function SystemContainer(props) {
  const [settings, setSettings] = useState(null);
  // const classes = useStyles();

  useEffect(() => {
    Backend.readAppSettings().then((data) => {
      setSettings(data);
    });
  }, []);

  const saveClick = () => {
    const tempSettings = { ...settings };
    const { storagePath, tempPath, galleryOutputPath } = tempSettings;

    let collection = [storagePath, tempPath, galleryOutputPath];
    for (var [i, e] of collection.entries()) {
      e = e.replace(/\\/g, "/");
      while (e.includes("//")) {
        e = e.replace("//", "/");
      }
      if (!e.endsWith("/")) {
        e = e + "/";
      }
      collection[i] = e;
    }
    tempSettings["storagePath"] = collection[0];
    tempSettings["tempPath"] = collection[1];
    tempSettings["galleryOutputPath"] = collection[2];

    setSettings(tempSettings);

    Backend.writeAppSettings(settings, (data) => {
      console.log(data);
    });
    window.location.reload();
  };

  const deleteTempFilesClick = () => {
    window.openResponseDialog(
      "Delete temporary files folder? Imported files have to be imported again. Projects will not be affected.",
      (response) => {
        if (response) {
          props.spinloader.show();
          Backend.deleteTempFiles((result) => {
            props.spinloader.hide();
            if (result.status && result.status === 403) {
              window.showErrorSnackbar("No permission to use system actions!");
            } else {
              if (result.success) {
                if (result.deleted) {
                  window.showSuccessSnackbar("Temp files folder deleted!");
                } else {
                  window.showWarningSnackbar("No femp files folder found!");
                }
              } else {
                window.showErrorSnackbar("Temp files folder deleting failed!");
              }
            }
          });
        }
      }
    );
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">System Actions</Typography>
        <Tooltip
          disableInteractive
          title={
            props.canUseSystemActions
              ? "Delete Temp Files for faster rendering!"
              : "No Permission!"
          }
        >
          <span>
            <Button
              onClick={deleteTempFilesClick}
              disabled={props.canUseSystemActions !== true}
            >
              Delete Temp Files
            </Button>
          </span>
        </Tooltip>

        <Typography variant="h6">Job Scheduler</Typography>
        <br />

        {settings && (
          <Tooltip
            disableInteractive
            title={
              props.canSetJobRunTimes ? "Start timed jobs" : "No Permission!"
            }
          >
            <span>
              <TextField
                disabled={!props.canSetJobRunTimes}
                name="Textfield"
                id="time_start"
                label="Start"
                type="time"
                defaultValue={settings.schedulerStart}
                onChange={(e) => {
                  const tempSettings = { ...settings };
                  tempSettings.schedulerStart = e.target.value;
                  setSettings(tempSettings);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
            </span>
          </Tooltip>
        )}

        <span> </span>
        {settings && (
          <Tooltip
            disableInteractive
            title={
              props.canSetJobRunTimes
                ? "Stop starting timed jobs"
                : "No Permission!"
            }
          >
            <span>
              <TextField
                disabled={props.canSetJobRunTimes !== true}
                name="Textfield"
                id="time_end"
                label="End"
                type="time"
                defaultValue={settings.schedulerEnd}
                onChange={(e) => {
                  const tempSettings = { ...settings };
                  tempSettings.schedulerEnd = e.target.value;
                  setSettings(tempSettings);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
            </span>
          </Tooltip>
        )}
      </CardContent>
      <CardActions>
        <Tooltip
          disableInteractive
          title={
            props.canSetJobRunTimes ? "Set Job to run later" : "No Permission!"
          }
        >
          <span>
            <Button
              variant="outlined"
              disabled={props.canSetJobRunTimes !== true}
              size="small"
              color="primary"
              onClick={saveClick}
            >
              Save
            </Button>
          </span>
        </Tooltip>
      </CardActions>
    </Card>
  );
}

SystemContainer.propTypes = {
  spinloader: PropTypes.object,
  canSetJobRunTimes: PropTypes.bool,
  canUseSystemActions: PropTypes.bool,
};

export default withSpinloader(SystemContainer);

// Framework components
import React from "react";
import { PropTypes } from "prop-types";

// Material UI components
import { List } from "@mui/material";

// Custom components
import AIModelListItem from "./AIModelListItem";
import "./AIModelsList.css";

/**
 * List of models in AI Viewer.
 */
export default function AIModelsList({
  loading,
  AIModels,
  selectedModel,
  setSelectedModel,
}) {
  const handleStopTraining = () => {
    // Dummy function for stopping training
    console.log("Stopping training...");
  };

  return (
    <List style={{ paddingTop: "0px", paddingBottom: "0px" }}>
      <div className="scrollable-sidebar">
        {AIModels.map((item, index) => (
          <AIModelListItem
            key={index}
            name={item.settings.metaData.name}
            onStopTraining={() => handleStopTraining(index)}
            selected={item.settings.metaData.name === selectedModel}
            model={item}
            setSelectedModel={setSelectedModel}
            AIModels={AIModels}
            loading={loading}
          />
        ))}
      </div>
    </List>
  );
}

AIModelsList.propTypes = {
  loading: PropTypes.bool,
  AIModels: PropTypes.array.isRequired,
  selectedModel: PropTypes.string,
  setSelectedModel: PropTypes.func,
};

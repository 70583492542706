import React, { useEffect } from "react";
import PropTypes from "prop-types";

// HSA
import Backend from "../../../common/utils/Backend";

// mui
import { DialogContent, TextField, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  root: {},
  dialogContent: {
    paddingTop: 5,
    maxWidth: 900,
    height: 562,
  },
  textField: {
    marginBottom: 10,
  },
});

const StepCaseForm = (props) => {
  const { updateTrigger, caseColumns, setCaseColumns, editCase } = props;
  const classes = useStyles();
  const today = new Date().toISOString().slice(0, 10);

  useEffect(() => {
    Backend.readAppSettings().then((response) => {
      if (response.caseColumns) {
        updateCaseColumns(
          response.caseColumns.map((item) => {
            if (item.items === null) item.items = [];
            item.value = item.defaultValue;
            if (item.type === "Date" && item.defaultValue === "Current Date") {
              item.value = today;
            }
            // push a new ref into the refs array for each case column
            return item;
          })
        );
      }
    });
  }, []);

  useEffect(() => {
    if (caseColumns.length === 0) return;
    const updatedCaseColumns = caseColumns.map((col, idx) => {
      const element = document.getElementById("row" + idx);
      const textFieldValue = element.value;

      col.value = textFieldValue ? textFieldValue : element.innerText;
      return col;
    });
    setCaseColumns(updatedCaseColumns);
  }, [updateTrigger]);

  const updateCaseColumns = (newCaseColumns) => {
    if (editCase) {
      let newColumns = newCaseColumns.map((column) => {
        let newColumn = { ...column };
        if (editCase[column.name]) {
          newColumn.value = editCase[column.name];
        }
        return newColumn;
      });
      setCaseColumns(newColumns);
    } else {
      setCaseColumns(newCaseColumns);
    }
  };

  const getFieldList = () => {
    return caseColumns.map(({ type, label, value, items }, idx) => {
      const TextFieldProps = {
        fullWidth: true,
        label,
        defaultValue: value,
        id: "row" + idx,
      };

      if (type === "Text area") {
        return (
          <TextField
            key={idx + value}
            name="Textfield"
            {...TextFieldProps}
            multiline
            minRows="1"
            className={classes.textField}
          />
        );
      } else if (type === "Options") {
        return (
          <TextField
            key={idx + value}
            name="Textfield"
            {...TextFieldProps}
            select
            className={classes.textField}
          >
            {items.map((itemValue, itemIdx) => (
              <MenuItem key={`${idx}_${itemIdx}`} value={itemValue.label}>
                {itemValue.label}
              </MenuItem>
            ))}
          </TextField>
        );
      } else if (type === "Date") {
        return (
          <TextField
            key={idx + value}
            {...TextFieldProps}
            type="date"
            InputLabelProps={{ shrink: true }}
            style={{ marginBottom: 10 }}
            name="Textfield"
          />
        );
      } else {
        return (
          <TextField
            key={idx + value}
            {...TextFieldProps}
            className={classes.textField}
            name="Textfield"
          />
        );
      }
    });
  };

  return (
    <DialogContent className={classes.dialogContent}>
      {getFieldList()}
    </DialogContent>
  );
};

StepCaseForm.propTypes = {
  editCase: PropTypes.object,
  updateTrigger: PropTypes.bool,
  caseColumns: PropTypes.array,
  setCaseColumns: PropTypes.func,
};

export default StepCaseForm;

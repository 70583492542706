// Copyright HS Analysis GmbH, 2023
// Author: Valentin Haas

// Description: Structure model for annotation categorization.

// Keep in sync with C# and js frontend:
// Source\HSA-KIT\Database\Model\Structure.cs
// Source\HSA-KIT\ClientApp\src\common\components\Structure.jsx
// Source\HSA-KIT\modules\hsa\core\models\structure.py

// HSA imports
import {
  isInt,
  isHexColor,
  downloadJsonObjectAsFile,
  isObject,
  requestFileInput,
} from "../utils/Utils";
import { convertDateToShortIsoString } from "../utils/Localization";

/**
 * Structure class used for annotation categorization.
 * For structure documentation see https://hsa.atlassian.net/wiki/spaces/HSAKIT/pages/16116235173917/Annotations+Data+Structure
 */
export default class Structure {
  /**
   * Maximum id used, to avoid duplicate ids.
   */
  static maxId = 0;
  static __setMaxId = (val) => (Structure.maxId = val);

  /**
   * Create a Structure object with all parameters.
   * @param {uint} id Unique identifier for the structure.
   * @param {string} label Visible name of structure.
   * @param {boolean} isStructure Distinction between class and structure. Defaults to true.
   * @param {string} color Color of the structure. Defaults to #FFFFFF.
   * @param {boolean} isDynamic Whether or not the user is allowed to delete/add substructures/classes to this structure. Defaults to true.
   * @param {uint} parentId Parent key, should the structure be a substructure. Defaults to null.
   * @param {uint} sameLevelRank Defines te order in which multiple strucutures on the same level with the same parent are displayed. Defaults to 0.
   * @param {boolean} isUnfolded Whether or not the children of a structure are visible. Defaults to false.
   * @param {boolean} annotationsAreVisible Whether or not the annotations of the structure are shown. Defaults to true.
   * @param {boolean} hasChildren Whether or not a structure has children. Defaults to false.
   * @param {uint} nestingDepth How deep a structure is nested inside other structures. Defaults to 0.
   * @param {ulong} totalObjectCount Annotation count over all contained r-Trees. Defaults to 0.
   * @param {boolean} canBeChosen Optional. Whether or not the structure can be chosen. Defaults to true.
   * @param {boolean} isChosen Optional. Whether or not the structure is chosen. Defaults to false.
   * @param {List[object]} toolSettings Optional. Which tools are activated for this structure and their parameters. Defaults to null.
   * @param {List[object]} optionalParams Optional. Project-specific optional parameters not covered by the attributes above. Defaults to null.
   */
  constructor(
    id,
    label,
    isStructure = true,
    color = "#FFFFFF",
    isDynamic = true,
    parentId = null,
    sameLevelRank = 0,
    isUnfolded = false,
    annotationsAreVisible = true,
    hasChildren = false,
    nestingDepth = 0,
    totalObjectCount = 0,
    canBeChosen = true,
    isChosen = false,
    toolSettings = null,
    optionalParams = null
  ) {
    // Input validation
    if (!isInt(id) || id <= 0)
      throw TypeError(
        `id must be of type integer > 0, received ${typeof id}: ${id}`
      );
    if (typeof label !== "string")
      throw TypeError(
        `label must be of type string, received ${typeof label}: ${label}`
      );
    if (typeof isStructure !== "boolean")
      throw TypeError(
        `isStructure must be of type boolean, received ${typeof isStructure}: ${isStructure}`
      );
    if (!isHexColor(color) && label !== "Base ROI")
      throw TypeError(
        `color must be a valid hexColor, received ${typeof color}: ${color}`
      );
    if (typeof isDynamic !== "boolean")
      throw TypeError(
        `isDynamic must be of type boolean, received ${typeof isDynamic}: ${isDynamic}`
      );
    if (parentId !== null && (!isInt(parentId) || parentId <= 0))
      throw TypeError(
        `parentId must be of type integer >= 0, received ${typeof parentId}: ${parentId}`
      );
    if (!isInt(sameLevelRank) || sameLevelRank < 0) {
      throw TypeError(
        `sameLevelRank must be of type integer >= 0, received ${typeof sameLevelRank}: ${sameLevelRank}`
      );
    }
    if (typeof isUnfolded !== "boolean")
      throw TypeError(
        `isUnfolded must be of type boolean, received ${typeof isUnfolded}: ${isUnfolded}`
      );
    if (typeof annotationsAreVisible !== "boolean")
      throw TypeError(
        `annotationsAreVisible must be of type boolean, received ${typeof annotationsAreVisible}: ${annotationsAreVisible}`
      );
    if (typeof hasChildren !== "boolean")
      throw TypeError(
        `hasChildren must be of type boolean, received ${typeof hasChildren}: ${hasChildren}`
      );
    if (!isInt(nestingDepth) || nestingDepth < 0)
      throw TypeError(
        `nestingDepth must be of type integer >= 0, received ${typeof nestingDepth}: ${nestingDepth}`
      );
    if (!isInt(totalObjectCount) || totalObjectCount < 0)
      throw TypeError(
        `totalObjectCount must be of type integer >= 0, received ${typeof totalObjectCount}: ${totalObjectCount}`
      );
    if (canBeChosen !== null && !(typeof canBeChosen === "boolean"))
      throw TypeError(
        `canBeChosen must be of type boolean, received ${typeof canBeChosen}: ${canBeChosen}`
      );
    if (!(typeof isChosen === "boolean"))
      throw TypeError(
        `isChosen must be of type boolean, received ${typeof isChosen}: ${isChosen}`
      );

    // Value assignments
    this.id = id;
    this.label = label;
    this.isStructure = isStructure;
    this.color = color;
    this.isDynamic = isDynamic;
    this.parentId = parentId;
    this.sameLevelRank = sameLevelRank;
    this.isUnfolded = isUnfolded;
    this.annotationsAreVisible = annotationsAreVisible;
    this.hasChildren = hasChildren;
    this.nestingDepth = nestingDepth;
    this.totalObjectCount = totalObjectCount;
    this.canBeChosen = canBeChosen;
    this.isChosen = isChosen;
    this.toolSettings = toolSettings;
    this.optionalParams = optionalParams;

    if (id > Structure.maxId) Structure.__setMaxId(id);
  }

  /**
   * Create a new structure from an object without needing to define all parameters individually.
   * Accepts camelCase and PascalCase properties, with camelCase being the default.
   * Additional properties will be ignored,
   * missing properties will use defaults or throw error per Structure contructor.
   * @param {object} obj An object containing all necessary properties of a structure.
   * @returns {Structure} A new structure
   */
  static fromObject = (obj) => {
    return new Structure(
      obj.id ?? obj.Id,
      obj.label ?? obj.Label,
      obj.isStructure ?? obj.IsStructure,
      obj.color ?? obj.Color,
      obj.isDynamic ?? obj.IsDynamic,
      obj.parentId ?? obj.ParentId,
      obj.sameLevelRank ?? obj.SameLevelRank,
      obj.isUnfolded ?? obj.IsUnfolded,
      obj.annotationsAreVisible ?? obj.AnnotationsAreVisible,
      obj.hasChildren ?? obj.HasChildren,
      obj.nestingDepth ?? obj.NestingDepth,
      obj.totalObjectCount ?? obj.TotalObjectCount,
      obj.canBeChosen ?? obj.CanBeChosen,
      obj.isChosen ?? obj.IsChosen,
      obj.toolSettings ?? obj.ToolSettings,
      obj.optionalParams ?? obj.OptionalParams
    );
  };

  /**
   * Create an independent (deep) copy of a structure.
   * @param {Structure} structure Structure to copy.
   * @returns {Structure} Copy of structure.
   */
  static copy = (structure) => {
    if (!(structure instanceof Structure))
      throw TypeError(
        `structure must be of type Structure, recieved ${typeof structure}: ${structure}`
      );
    return new Structure(
      structure.id,
      structure.label,
      structure.isStructure,
      structure.color,
      structure.isDynamic,
      structure.parentId,
      structure.sameLevelRank,
      structure.isUnfolded,
      structure.annotationsAreVisible,
      structure.hasChildren,
      structure.nestingDepth,
      structure.totalObjectCount,
      structure.canBeChosen,
      structure.isChosen,
      structure.toolSettings,
      structure.optionalParams
    );
  };
}

//#region Structure properties
/**
 * Gets a new, free structureId that is one larger than the largest id already present.
 * @param {Array} structures All existing structures.
 * @returns {uint} New, free structure id.
 */
export function newStructureId(structures) {
  if (!Array.isArray(structures))
    throw TypeError(
      `structures must be of type Array, received ${typeof structures}: ${structures}`
    );

  const highestIdInStructures = Math.floor(
    structures
      .map((s) => s.id)
      .reduce((a, b) => Math.max(Math.abs(a), Math.abs(b)), 0)
  );
  return Math.max(Structure.maxId, highestIdInStructures) + 1;
}

/**
 * Perform a check whether a structure can move in a certain direction.
 * @param {Structure} structure The structure to move.
 * @param {int} direction Number of steps to move, postive numbers lower the structure, negative numbers raise it.
 * @param {Array} structures The existing structures.
 * @returns {bool} Whether the intended move is allowed.
 */
export function structureCanMove(structure, direction, structures) {
  if (!(structure instanceof Structure)) {
    throw TypeError(
      `structure must be of type Structure, recieved ${typeof structure}: ${structure}`
    );
  }
  if (!isInt(direction)) {
    throw TypeError(
      `direction must be of type int, received ${typeof direction}: ${direction}`
    );
  }
  if (!Array.isArray(structures)) {
    throw TypeError(
      `structures must be of type Array, received ${typeof structures}: ${structures}`
    );
  }

  // No movement requested
  if (direction === 0) return true;

  // Sort siblings by rank
  let siblings = structures
    .filter((s) => s.parentId === structure.parentId)
    .sort((a, b) => a.sameLevelRank - b.sameLevelRank);

  // Structure not in structures
  if (
    siblings.length === 0 ||
    !siblings.some((s) => s.id === structure.id && s.label === structure.label)
  ) {
    throw Error(`Structure ${structure.label} not found in structures`);
  }

  let sameLevelIndex = siblings.findIndex((s) => s.id === structure.id);

  // Check if new position would be in bounds of array
  if (
    sameLevelIndex + direction < 0 ||
    sameLevelIndex + direction > siblings.length - 1
  ) {
    return false;
  } else {
    return true;
  }
}

/**
 * Finds all parents of a structure.
 * Returns them in the order beginning from top and ending at given structure.
 * @param {Structure} structure The structure to find all parents for.
 * @param {Array} structures Existing structures.
 * @returns {Array} All parent structures, including the structure itself.
 */
export function parentStructures(structure, structures) {
  if (!(structure instanceof Structure))
    throw TypeError(
      `structure must be of type Structure, recieved ${typeof structure}: ${structure}`
    );
  if (!Array.isArray(structures))
    throw TypeError(
      `structures must be of type Array, received ${typeof structures}: ${structures}`
    );

  let parentStructs = [];

  // Recursively traverse structure tree upwards
  if (structure.parentId !== null) {
    parentStructs.push(
      ...parentStructures(
        structures.find((s) => s.id === structure.parentId),
        structures
      ),
      structure
    );
    return parentStructs;
  } else {
    return [structure];
  }
}

/**
 * Finds all children of a structure, beginning at the given structure and
 * ending with child-free structures.
 * @param {Structure} structure The structure to find all children for.
 * @param {Array} structures Existing structures.
 * @returns {Array} All child structures, including the structure itself.
 */
export function childStructures(structure, structures) {
  if (!(structure instanceof Structure))
    throw TypeError(
      `structure must be of type Structure, recieved ${typeof structure}: ${structure}`
    );
  if (!Array.isArray(structures))
    throw TypeError(
      `structures must be of type Array, received ${typeof structures}: ${structures}`
    );

  // Bottom end of structure tree
  if (!structure.hasChildren) {
    return [structure];
  } else {
    let childStructs = [structure];

    structures
      .filter((s) => s.parentId === structure.id)
      .forEach((s) => childStructs.push(...childStructures(s, structures)));

    return childStructs;
  }
}

//#endregion
//#region Structure actions
/**
 * Sorts structures by their hierarchy.
 * @param {array} structures All structures imported from a project as list.
 * @param {Structure} parentStructure The topmost parent structure under which all structures will be sorted.
 *                                    Defaults to null to begin at the topmost level.
 * @returns {array} The structures sorted based on their hierarchy.
 */
export function sortStructures(structures, parentStructure = null) {
  if (parentStructure !== null && !(parentStructure instanceof Structure))
    throw TypeError(
      `parentStructure must be of type Structure, recieved ${typeof parentStructure}: ${parentStructure}`
    );

  // Find all children of parentstructure
  let children = structures.filter((s) =>
    parentStructure === null
      ? s.parentId === parentStructure
      : s.parentId === parentStructure.id
  );

  // Sort by sameLevelRank
  children.sort((a, b) => a.sameLevelRank - b.sameLevelRank);

  // Check if they have children of their own and sort recursively.
  let sorted = [];
  children.forEach((child) => {
    if (child.hasChildren) {
      sorted = [...sorted, ...sortStructures(structures, child)];
    } else {
      sorted = [...sorted, child];
    }
  });

  // Combine with parent and return as array
  if (parentStructure !== null) {
    return [parentStructure, ...sorted];
  } else {
    return sorted;
  }
}

/**
 * Move an existing structure in a defined direction.
 * Invalid moves return original array.
 * @param {Structure} structure The structure to move.
 * @param {int} direction Number of steps to move, postive numbers lower the structure, negative numbers raise it.
 * @param {Array} structures The existing structures.
 * @returns {Array} The modified structures.
 */
export function moveStructure(structure, direction, structures) {
  if (!(structure instanceof Structure)) {
    throw TypeError(
      `structure must be of type Structure, recieved ${typeof structure}: ${structure}`
    );
  }
  if (!isInt(direction)) {
    throw TypeError(
      `direction must be of type int, received ${typeof direction}: ${direction}`
    );
  }
  if (!Array.isArray(structures)) {
    throw TypeError(
      `structures must be of type Array, received ${typeof structures}: ${structures}`
    );
  }

  // No move requested, return original
  if (direction === 0) return structures;

  // Sort siblings by rank
  const siblings = structures
    .filter((s) => s.parentId === structure.parentId)
    .sort((a, b) => a.sameLevelRank - b.sameLevelRank);

  // Move not possible, return original structures
  if (!structureCanMove(structure, direction, siblings)) return structures;

  const sameLevelIndex = siblings.findIndex((s) => s.id === structure.id);
  const newSameLevelRank = siblings[sameLevelIndex + direction].sameLevelRank;

  // All items between old and new rank must be updated
  const sibsToModify = siblings.filter((s) => {
    if (direction > 0) {
      return (
        s.sameLevelRank >= structure.sameLevelRank &&
        s.sameLevelRank <= newSameLevelRank
      );
    } else {
      return (
        s.sameLevelRank <= structure.sameLevelRank &&
        s.sameLevelRank >= newSameLevelRank
      );
    }
  });
  // Rotate ranking values of all affected siblings
  const prevRanks = sibsToModify.map((s) => s.sameLevelRank);
  sibsToModify.forEach((s, idx) => {
    s.sameLevelRank =
      prevRanks[(idx + sibsToModify.length + direction) % sibsToModify.length];
  });

  // Structures are already edited by reference
  return sortStructures(structures);
}

/**
 * Insert a new structure into the list of existing structures, update structures as necessary.
 * @param {Structure} structure A structure to insert into existing structures.
 * @param {Array} structures List of all existing structures.
 * @returns {Array} Updated structure list.
 */
export function insertStructure(structure, structures) {
  if (!(structure instanceof Structure))
    throw TypeError(
      `structure must be of type Structure, recieved ${typeof structure}: ${structure}`
    );
  if (!Array.isArray(structures))
    throw TypeError(
      `structures must be of type Array, received ${typeof structures}: ${structures}`
    );

  // Do not allow duplicate ids.
  if (structures.map((s) => s.id).some((id) => id === structure.id)) {
    throw Error(
      `id "${structure.id}" already present in structures. 
      Please use the newStructureId() method to generate a unique id.`
    );
  }

  // Find all same level-children and sort as lowest
  structure.sameLevelRank =
    structures
      .filter((s) => s.parentId === structure.parentId)
      .map((s) => s.id)
      .reduce((a, b) => Math.max(a, b), -1) + 1;

  // Update parent
  if (structure.parentId !== null) {
    const parent = structures.find((s) => s.id === structure.parentId);
    parent.hasChildren = true;
    parent.isUnfolded = true;
  }

  // Add adapted structure to all structures
  structures.push(structure);

  return sortStructures(structures);
}

/**
 * Delete a structure from the list of existing structures, update structures as necessary.
 * @param {Structure} structure The structure to delete from existing structures.
 * @param {Array} structures List of all existing structures.
 * @param {function} deleteAnnotations Optional. Delete rois of structure given to function. Receives (Structure: structureToDelete).
 * @returns {Array} Updated structure list.
 */
export function deleteStructure(
  structure,
  structures,
  deleteAnnotations = () => {}
) {
  if (!(structure instanceof Structure))
    throw TypeError(
      `structure must be of type Structure, recieved ${typeof structure}: ${structure}`
    );
  if (!Array.isArray(structures))
    throw TypeError(
      `structures must be of type Array, received ${typeof structures}: ${structures}`
    );

  // Delete all rois of structure and its children
  // Find all children and delete them
  childStructures(structure, structures).forEach((s) => {
    deleteAnnotations(s);
    structures.splice(
      structures.findIndex((struct) => struct.id === s.id),
      1
    );
  });

  // Find all siblings
  const siblings = structures
    .filter((s) => s.parentId === structure.parentId)
    .sort((a, b) => a.sameLevelRank - b.sameLevelRank);

  // Should parent no longer have children
  if (siblings.length === 0 && structure.parentId !== null) {
    structures.find((s) => s.id === structure.parentId).hasChildren = false;
    return sortStructures(structures);
  }

  // Update sameLevelRank of all siblings
  siblings.forEach((s, idx) => (s.sameLevelRank = idx));

  return sortStructures(structures);
}

/**
 * Duplicates a structure including all its decendents and
 * Inserts the duplicate as lowest sibling in same hierarchical rank.
 * @param {Structure} structure The structure to duplicate.
 * @param {Array} structures Existing structures.
 * @returns {Array} The updated structures.
 */
export function duplicateStructure(structure, structures) {
  if (!(structure instanceof Structure))
    throw TypeError(
      `structure must be of type Structure, recieved ${typeof structure}: ${structure}`
    );
  if (!Array.isArray(structures))
    throw TypeError(
      `structures must be of type Array, received ${typeof structures}: ${structures}`
    );

  // Find all children of structure to duplicate, including the structure
  const childStructs = childStructures(structure, structures);

  // Reserve new Ids
  const firstNewId = newStructureId(structures);
  const newIds = Array(childStructs.length);
  for (let i = 0; i < childStructs.length; i++) {
    newIds[i] = firstNewId + i;
  }

  // Create new structures based on children
  const newStructs = childStructs.map((s) => Structure.copy(s));

  // Adapt parent-Ids
  newStructs.forEach((s, idx) => {
    s.id = newIds[idx];
    s.label = `Copy: ${s.label}`;
    const parentIdx = childStructs.findIndex((cs) => cs.id === s.parentId);

    // If parent is one of the newly added structures, adapt to the new id.
    if (parentIdx >= 0) s.parentId = newIds[parentIdx];
  });

  structures = insertStructure(newStructs.shift(), structures);
  structures.push(...newStructs);
  const tmp = sortStructures(structures);
  return tmp;
}

/**
 * Export structuretree in frontend to .strhsa file.
 * @param {string} username Full username of exporting user.
 * @param {object} project Project the structures referr to.
 * @param {array} structures Structuretree to export.
 * @returns {object} Success status of export.
 */
export function exportStructures(username, project, structures) {
  if (typeof username !== "string") {
    throw TypeError(
      `username must be of type string, received ${typeof username}: ${username}`
    );
  }
  if (!isObject(project)) {
    throw TypeError(
      `project must be of type object, received ${typeof project}: ${project}`
    );
  }
  if (!Array.isArray(structures)) {
    throw TypeError(
      `structures must be an array, received ${typeof structures}: ${structures}`
    );
  }

  try {
    // Export rois with meta info
    const timestamp = convertDateToShortIsoString(Date.now());
    downloadJsonObjectAsFile(
      {
        version: 1.0,
        timestamp: timestamp,
        user: username,
        project: project,
        structures,
      },
      `${timestamp}_${project.name}_StructureTree_(${structures.length}_Structures).strhsa`
    );

    return {
      success: true,
      msg: `Successfully exported ${structures.length} structures.`,
    };
  } catch (err) {
    return {
      success: false,
      msg: `Failed to export ${structures.length} structures.`,
      error: err,
    };
  }
}

/**
 * Import structures from .strhsa file. Replaces all current structures and deletes all current frontend annotations.
 * @param {uint} firstNewId First new id for new structures.
 * @param {function} callback Function to execute with the new structures. Receives (array: newStructures).
 * @param {function} error Optional. Function to execute on error. Receives (string: errorMessage).
 * @returns
 */
export async function importStructures(firstNewId, callback, error = () => {}) {
  if (!isInt(firstNewId) || firstNewId < 0)
    throw TypeError(
      `firstNewId must be an integer >= 0, received ${typeof firstNewId}: ${firstNewId}`
    );
  if (typeof callback !== "function")
    throw TypeError(
      `callback must be a function, received ${typeof callback}: ${callback}`
    );
  if (typeof error !== "function")
    throw TypeError(
      `error must be a function, received ${typeof error}: ${error}`
    );

  // Structure replacement warning
  const continueImport = await window.openConfirmationDialog(
    "Import Structures",
    `All structures and annotations will be deleted and replaced with the imported structures.
    Import anyway?`
  );
  if (!continueImport) return;

  const files = await requestFileInput([".strhsa"]);
  if (files.length === 0) return;
  const file = files[0];

  const fr = new FileReader();
  fr.onload = async (e) => {
    try {
      const data = JSON.parse(e.target.result);
      if (!Array.isArray(data.structures)) {
        window.showErrorSnackbar(`No valid structures found in ${file.name}.`);
        return;
      }

      // Empty structure import warning
      if (data.structures.length == 0) {
        const continueImport = await window.openConfirmationDialog(
          "No structures to import",
          `There are no structures included in uploaded file. 
          An import will remove all existing structures, including their annotations. 
          Import anyway?`
        );
        if (!continueImport) return;
      }

      let newStructures = [];

      // Replace full structure tree
      data.structures.forEach((s) => {
        const newStruct = Structure.fromObject(s);
        newStructures = insertStructure(newStruct, newStructures);
      });

      // Reserve new Ids
      const newIds = Array(data.structures.length);
      for (let i = 0; i < data.structures.length; i++) {
        newIds[i] = firstNewId + i;
      }

      // Update parent-child relationships
      newStructures.forEach((s) => {
        if (!s.parentId) return;
        const parentIndex = newStructures.findIndex(
          (struct) => struct.id === s.parentId
        );
        s.parentId = newIds[parentIndex];
      });

      // Update ids
      newStructures.forEach((s, i) => (s.id = newIds[i]));

      // Set new structures
      callback(sortStructures(newStructures));
    } catch (err) {
      const errmsg = `Failed to import the structures from file ${file.name}:\n${err}`;
      console.debug(errmsg);
      error(errmsg);
    }
  };
  // Only accept the first element
  fr.readAsText(file);
}
//#endregion

import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  DialogContent,
  TextField,
  IconButton,
  Grid,
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  List,
  Tooltip,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";
import Backend from "../../../common/utils/Backend";
import EmptySlideCreation from "./EmptySlideCreation";
import {
  Folder,
  ArrowUpward,
  ArrowDownward,
  Image,
  CheckBox,
  AddToPhotos,
} from "@mui/icons-material";

const styles = () => ({
  root: {},
  freeHistoParameters: {},
  applyButton: {
    width: "100%",
  },
  dialogContent: {
    paddingTop: 0,
    maxWidth: 900,
    height: 562,
  },
  fileList: {
    border: "2px solid rgb(218, 218, 218)",
    height: "100%",
    overflowY: "auto",
    minHeight: "calc(100% - 35px)",
    maxHeight: "500px",
  },
});

// The openslide reader cannot work with non-ascii symbols
const non_utf8_capable_filetypes = ["mrxs", "svs", "ndpi", "svslide"];

class StepFileSelection extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      dirfiles: [],
      currentPath: "",
      lastFileName: "",
      filterText: "",
      sortMenuOpen: false,
      sortBy: "name",
      sortReverse: true,
    };

    this.updateFolderList("");
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setMountedState = (stateObject, callback) => {
    if (this._isMounted) {
      this.setState(stateObject, callback);
    }
  };

  getLastDir = (folderPath) => {
    let pathEnding = "-1";
    let lastSeperator = folderPath.lastIndexOf("/");
    if (lastSeperator < 0) {
      lastSeperator = folderPath.lastIndexOf(String.fromCharCode(92));
    }
    if (lastSeperator > 0) {
      pathEnding = folderPath.substr(lastSeperator + 1, folderPath.length);
    }
    return pathEnding;
  };

  onChangeFilter(e) {
    let filterText = e.target.value;
    let { currentPath } = this.state;
    this.setMountedState({ filterText });
    this.updateFolderList(currentPath, filterText);
  }

  getFilesFromPath = (currentPath, filterText, callback, addAll = false) => {
    let supportedFileFormats = [];

    switch (this.props.formData.projectType) {
      case "ProteomeAnalysis":
        supportedFileFormats = ["txt"];
        break;

      case "ESREvaluation":
      case "ESRTraining":
        supportedFileFormats = ["csv"];
        break;

      case "AudioAnnotator":
        supportedFileFormats = ["mp3", "wav"];
        break;

      default:
        supportedFileFormats = [
          "avi",
          "bmp",
          "czi",
          "dm3",
          "gif",
          "hsasld",
          "jpeg",
          "jpg",
          "lif",
          "mp4",
          "mrxs",
          "ndpi",
          "png",
          "scn",
          "svs",
          "svslide",
          "tif",
          "tiff",
          // The following formats are not supported on linux systems
          "isyntax",
          "nd2",
          "zvi",
        ];
    }
    Object.freeze(supportedFileFormats);

    // load list of available files
    Backend.walkDir(currentPath, true, (e) => {
      let filteredDirFiles = e.filter((entry) => {
        // filter out files with not supported file formats (e.g. .xlsx)
        let pathParts = entry.path.split(".");
        let extension = pathParts[pathParts.length - 1].toLowerCase();
        let filename = pathParts[pathParts.length - 2];
        let path = entry.path.split("/")[entry.path.split("/").length - 1];
        let foldersToExclude = ["DAT", "SCS", "ANS"];
        let lastDir = this.getLastDir(entry.path);
        let sameMrxsFile = e.find((element) => {
          return element.path === entry.path + ".mrxs";
        });
        if (entry.type === "file") {
          if (supportedFileFormats.indexOf(extension) < 0) {
            return false;
          }
          // Filter out placeholder file
          if (filename === "empty_file") {
            return false;
          }
        }

        if (filterText !== "") {
          if (path.toLowerCase().includes(filterText.toLowerCase())) {
            return true;
          } else {
            return false;
          }
        } else {
          if (entry.type === "directory" && !addAll) {
            if (foldersToExclude.includes(lastDir)) return false;
            return typeof sameMrxsFile === "undefined";
          } else if (entry.type === "directory" && addAll) {
            if (foldersToExclude.includes(lastDir)) return false;
            this.getFilesFromPath(entry.path, filterText, callback, addAll);
          } else {
            return true;
          }
        }
      });

      let sortedDirFiles = filteredDirFiles.sort((a, b) => {
        if (a.path > b.path) {
          return 1;
        }
        if (a.path < b.path) {
          return -1;
        }
        return 0;
      });

      callback(sortedDirFiles);
    });
  };

  updateFolderList = (currentPath, filterText = "") => {
    this.getFilesFromPath(currentPath, filterText, (dirfiles) => {
      // write received info into state
      this.setMountedState({
        currentPath: currentPath,
        dirfiles: dirfiles,
      });
    });
  };

  addFile = (event, fileName) => {
    // Ensure that only ascii-conform paths are used.
    if (
      non_utf8_capable_filetypes.includes(
        fileName.split(".").pop().toLowerCase()
      )
    ) {
      let hasMoreThanAscii = [...fileName].some(
        (char) => char.charCodeAt(0) > 127
      );
      if (hasMoreThanAscii) {
        window.showErrorSnackbar(
          "File type does not support characters such as 'ä', 'ö', 'ü', or 'ß' in path."
        );
        window.openWarningDialog(
          "The file " +
            fileName +
            " or its path contain invalid characters such as 'ä', 'ö', 'ü', or 'ß'. \
            Please rename the file or its folder."
        );
        return;
      }
    }

    let { files } = this.props.formData;
    let { lastFileName, dirfiles } = this.state;
    let dirFilePaths = dirfiles.map((file) => file.path);
    if (event.shiftKey && lastFileName !== "") {
      let lastFileIdx = dirFilePaths.indexOf(lastFileName);
      if (lastFileIdx >= 0) {
        let fileIdx = dirFilePaths.indexOf(fileName);
        let firstIdx = Math.min(lastFileIdx, fileIdx);
        let lastIdx = Math.max(lastFileIdx, fileIdx);
        files = files.filter((file) => !dirFilePaths.includes(file));
        for (let i = firstIdx; i <= lastIdx; i++) {
          let fileNameToAdd = dirFilePaths[i];
          files.push(fileNameToAdd);
        }
        this.props.onChangeFiles(files.sort());
      }
    } else {
      if (files.includes(fileName)) {
        files = files.filter((c) => c !== fileName);
      } else {
        files.push(fileName);
      }
      this.setMountedState({ lastFileName: fileName });
      this.props.onChangeFiles(files.sort());
    }
  };

  toggleAllFiles = (currentPath) => {
    let { dirfiles } = this.state;
    let { files } = this.props.formData;
    if (dirfiles.filter((file) => files.includes(file.path)).length > 0) {
      this.removeFolder(currentPath);
    } else {
      this.addFolder(currentPath);
    }
  };

  addFolder = (folderPath) => {
    this.getFilesFromPath(
      folderPath,
      "",
      (dirfiles) => {
        let filePaths = dirfiles.map((file) => file.path);
        let { files } = this.props.formData;
        let filteredFiles = filePaths.filter(
          (file) => !files.some((x) => file === x)
        );
        let filesToAdd = files.concat(filteredFiles);
        this.props.onChangeFiles(filesToAdd);
      },
      true
    );
  };

  removeFolder = () => {
    let { files } = this.props.formData;
    let dirFilePaths = this.state.dirfiles.map((file) => file.path);
    let resultFiles = files.filter((file) => dirFilePaths.indexOf(file) < 0);
    if (files.length !== resultFiles.length) {
      this.props.onChangeFiles(resultFiles.sort());
    }
  };

  isDisabled = (relativePath) => {
    let { project } = this.props;
    if (project && project.previewImageFiles) {
      const projectFiles = [
        ...new Set(project.previewImageFiles.map((file) => file.relativePath)),
      ];
      if (projectFiles.includes(relativePath)) return true;
    }
    return false;
  };

  toggleSortMenu = () => {
    this.setMountedState({ sortMenuOpen: !this.state.sortMenuOpen });
  };

  setSortBy = (value) => {
    let { sortBy, sortReverse } = this.state;

    if (value === sortBy) {
      sortReverse = !sortReverse;
    } else {
      sortReverse = false;
    }

    let dirfiles = [];
    if (value === "name") {
      dirfiles = this.state.dirfiles.sort((a, b) => {
        if (a.path > b.path) {
          return 1;
        }
        if (a.path < b.path) {
          return -1;
        }
        return 0;
      });
    } else {
      dirfiles = this.state.dirfiles.sort((a, b) => {
        if (a.creationTime > b.creationTime) {
          return 1;
        }
        if (a.creationTime < b.creationTime) {
          return -1;
        }
        return 0;
      });
    }
    if (!sortReverse) {
      dirfiles = dirfiles.reverse();
    }

    this.setMountedState({
      dirfiles: dirfiles,
      sortMenuOpen: false,
      sortBy: value,
      sortReverse: sortReverse,
    });
  };

  /**
   * Sets an image to the default error image when there is an error.
   * @param {field} e The field event
   */
  image_error = (e) => {
    e.target.src = "/img/image_error.svg";
  };

  render() {
    const { classes } = this.props;
    const { files } = this.props.formData;
    const {
      dirfiles,
      currentPath,
      filterText,
      sortMenuOpen,
      sortBy,
      sortReverse,
    } = this.state;

    return (
      <DialogContent className={classes.dialogContent}>
        <Grid
          container
          style={{
            display: "grid",
            gridTemplateRows: "1fr auto",
            overflow: "hidden",
            height: "100%",
          }}
        >
          <Grid container style={{ overflow: "hidden" }}>
            <Grid item xs={6} style={{ height: "100%" }}>
              <div style={{ position: "relative" }}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  height="50px"
                >
                  <Box fontSize="16px">Your Microscope Image Files:</Box>
                  <Box>
                    <Tooltip disableInteractive title="Sort By">
                      <IconButton
                        ref={(node) => {
                          this.anchorEl = node;
                        }}
                        aria-controls="sort-menu"
                        aria-haspopup="true"
                        onClick={() => this.toggleSortMenu()}
                        size="large"
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      id="sort-menu"
                      keepMounted
                      anchorEl={this.anchorEl}
                      open={sortMenuOpen}
                      onClose={() => this.toggleSortMenu()}
                    >
                      <MenuItem onClick={() => this.setSortBy("name")}>
                        Sort by name{" "}
                        {sortBy === "name" && (
                          <span>
                            {sortReverse ? <ArrowUpward /> : <ArrowDownward />}
                          </span>
                        )}
                      </MenuItem>
                      <MenuItem onClick={() => this.setSortBy("date")}>
                        Sort by creation date{" "}
                        {sortBy === "date" && (
                          <span>
                            {sortReverse ? <ArrowUpward /> : <ArrowDownward />}
                          </span>
                        )}
                      </MenuItem>
                    </Menu>
                  </Box>
                </Box>
              </div>

              <div style={{ position: "absolute", top: 5, right: 24 }}>
                <SearchIcon
                  style={{
                    display: "inline-block",
                    fill: "lightgray",
                    marginTop: 20,
                  }}
                />
                <TextField
                  autoFocus
                  id="SearchFolderAndFiles"
                  variant="standard"
                  fullWidth
                  style={{
                    width: "200px",
                    marginLeft: 6,
                    marginTop: 0,
                    display: "inline-block",
                  }}
                  key={"filterText"}
                  margin="normal"
                  label={"Search Folders/Files"}
                  value={filterText}
                  onChange={(e) => this.onChangeFilter(e)}
                />
              </div>
              <List dense={true} className={classes.fileList}>
                {currentPath && (
                  <ListItem>
                    <ListItemIcon
                      style={{ marginLeft: "-8px", marginRight: "-10px" }}
                    >
                      <Checkbox
                        name="CheckboxToggleAllFiles"
                        checked={
                          dirfiles.filter((file) => files.includes(file.path))
                            .length > 0
                        }
                        onChange={() => this.toggleAllFiles(currentPath)}
                      />
                    </ListItemIcon>
                    <ListItemIcon>
                      <Tooltip
                        disableInteractive
                        title={`Up to "${
                          currentPath.substring(
                            0,
                            currentPath.lastIndexOf("/")
                          ) || "root"
                        }"`}
                      >
                        <IconButton
                          onClick={() => {
                            this.updateFolderList(
                              currentPath.substring(
                                0,
                                currentPath.lastIndexOf("/")
                              )
                            );
                          }}
                          size="large"
                        >
                          <ArrowUpward />
                        </IconButton>
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText primary={currentPath} />
                  </ListItem>
                )}
                {dirfiles.map((e, id) =>
                  e.type === "file" ? (
                    <ListItemButton
                      key={id}
                      disabled={this.isDisabled(e.path)}
                      onClick={(clickEvent) => this.addFile(clickEvent, e.path)}
                    >
                      <ListItemIcon>
                        <Tooltip
                          disableInteractive
                          title={
                            <div
                              style={{ display: "inline-block" }}
                              key={e.path}
                            >
                              <div style={{ display: "inline-block" }}>
                                {
                                  <img
                                    style={{
                                      display: "block",
                                      background: "black",
                                      margin: "5px",
                                      objectFit: "contain",
                                    }}
                                    width="200"
                                    height="200"
                                    src={Backend.imageThumbnail(
                                      encodeURIComponent(e.path)
                                    )}
                                    onError={this.image_error}
                                    alt="Preview Image"
                                  />
                                }
                                <div
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                >
                                  {e.path}
                                </div>
                              </div>
                            </div>
                          }
                        >
                          {files.includes(e.path) ? (
                            <CheckBox name="CheckboxPath" style={{ color: "#0078D7" }} />
                          ) : (
                            <Image />
                          )}
                        </Tooltip>
                      </ListItemIcon>
                      <Tooltip
                        disableInteractive
                        title={
                          <div>
                            <div style={{ width: 80, display: "inline-block" }}>
                              File Size:{" "}
                            </div>
                            {e.fileSize}
                            <br />
                            <div style={{ width: 80, display: "inline-block" }}>
                              Creation Date:{" "}
                            </div>
                            {e.creationTime}
                          </div>
                        }
                      >
                        <ListItemText
                          primary={e.path.replace(/^.*[\\/]/, "")}
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            color: e.loaded ? "#08c21d" : "#000000",
                          }}
                        />
                      </Tooltip>
                    </ListItemButton>
                  ) : (
                    <ListItemButton
                      key={id}
                      onClick={() => this.updateFolderList(e.path)}
                    >
                      <ListItemIcon style={{ color: "#FFE896" }}>
                        <Folder />
                      </ListItemIcon>
                      <ListItemText
                        primary={e.path.replace(/^.*[\\/]/, "")}
                        style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                      />
                      <ListItemSecondaryAction>
                        <Tooltip disableInteractive title="Add folder">
                          <IconButton
                            onClick={() => this.addFolder(e.path)}
                            size="large"
                          >
                            <AddToPhotos />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItemButton>
                  )
                )}
              </List>
            </Grid>
            <Grid item xs={6} style={{ height: "100%" }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                height="50px"
              >
                {this.props.selectionTarget} Files:
              </Box>
              <List dense={true} className={classes.fileList}>
                {files.map((e, i) => (
                  <Tooltip disableInteractive key={i} title={e}>
                    <ListItemButton
                      disabled={this.isDisabled(e)}
                      onClick={(clickEvent) => this.addFile(clickEvent, e)}
                    >
                      <ListItemIcon style={{ color: "#0078D7" }}>
                        <CheckBox style={{ color: "#0078D7" }} />
                      </ListItemIcon>
                      <ListItemText primary={e} />
                    </ListItemButton>
                  </Tooltip>
                ))}
              </List>
            </Grid>
          </Grid>
          {/* Allow slide-free projects in Hist Classification Module. */}
          {/* Meta-Data will need to be entered manually. */}
          {this.props.formData.projectType === "HistoClassification" && (
            <EmptySlideCreation addFile={this.addFile} />
          )}
        </Grid>
      </DialogContent>
    );
  }
}

StepFileSelection.propTypes = {
  classes: PropTypes.object.isRequired,
  selectionTarget: PropTypes.string.isRequired,
  project: PropTypes.object,
  formData: PropTypes.object,
  onChangeFiles: PropTypes.func,
};

export default withStyles(styles)(StepFileSelection);

import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import ScanMinimap from "./ScanMinimap";
// import ScanDebug from "./ScanDebug";
// import ScanDebug2 from "./ScanDebug2";
import ScanComments from "./ScanComments";
import ScanStream from "./ScanStream";
import Backend from "../../common/utils/Backend";

import { withScanViewerContext } from "../contexts/ScanViewerContext";
import ScanDebugData from "./ScanDebugData";

const styles = () => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
    backgroundColor: "#D3D3D3",
    display: "grid",
    overflow: "hidden",
    gridTemplateRows: "1fr",
  },
  mainStream: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  imgStyle: {
    maxWidth: "100%",
    maxHeight: "100%",
    pointerEvents: "none",
  },
  minimapStream: {
    position: "absolute",
    left: 5,
    bottom: 5,
    border: "3px solid white",
  },
  progressBar: {
    height: 10,
  },
  debugDataContainer: {
    position: "absolute",
    padding: 10,
    margin: 10,
    width: 330,
    left: 0,
    top: 0,
    background: "white",
  },
  debugDataKey: {
    width: 150,
    display: "inline-block",
  },
  debugDataValue: {
    display: "inline-block",
  },
});

const ScanRenderer = ({ classes, scanViewerContext }) => {
  const { slideScanning, debugDataVisible } = scanViewerContext;
  // const [imageHash, setImageHash] = useState(Date.now()); // to reload stream
  const [deleteArea, setDeleteArea] = useState(null);
  const [mouseState, setMouseState] = useState("idle");
  const [debugDataList, setDebugDataList] = useState(null);

  let deleteAreaRef = useRef(null);
  const mainStreamRef = useRef();

  useEffect(() => {
    window.onbeforeunload = () => {
      cleanup();
    };
    window.addEventListener("resize", () => computeRendererSize());
    computeRendererSize();
    document
      .getElementById("scanMainStream")
      .addEventListener("mousewheel", onMouseWheel, true);

    const socket = Backend.getSocket();
    socket.on("debug_data", (data) => {
      //parse data to json
      data = JSON.parse(data);
      if (data.data_list) {
        setDebugDataList(data.data_list);
      }
    });

    return () => {
      cleanup();
    };
  }, []);

  const onMouseWheel = (e) => {
    if (e.deltaY > 0) {
      scanViewerContext.zoomOut();
    } else if (e.deltaY < 0) {
      scanViewerContext.zoomIn();
    }
  };

  const computeRendererSize = () => {
    if (mainStreamRef.current) {
      const w = mainStreamRef.current.clientWidth;
      const h = window.innerHeight - 64;
      scanViewerContext.setScanRendererSize(w, h);
    }
  };

  const setDeleteAreaStart = (e) => {
    const clickPoint = getGridPoint(e);
    deleteAreaRef.current = {
      p1: clickPoint,
      p2: clickPoint,
    };
  };

  const setDeleteAreaEnd = (e) => {
    if (deleteAreaRef.current === null) return;
    deleteAreaRef.current.p2 = getGridPoint(e);
    setDeleteArea(deleteAreaRef.current);
  };

  const onMouseDown = (e) => {
    if (e.button === 0 || e.button === 1) {
      setMouseState("translate");
    } else if (e.button === 2) {
      setMouseState("delete");
      setDeleteAreaStart(e);
    }
  };

  const onMouseUp = (event) => {
    if (mouseState === "delete") {
      setDeleteAreaEnd(event);
      scanViewerContext.deleteScanArea(deleteAreaRef.current);
      deleteAreaRef.current = null;
      setDeleteArea(null);
    }
    setMouseState("idle");
  };

  const onMouseMove = (event) => {
    if (mouseState === "translate") {
      scanViewerContext.moveCenter(event.movementX, event.movementY);
    } else if (mouseState === "delete") {
      setDeleteAreaEnd(event);
    }
    event.preventDefault(); // prevent image from being marked
  };

  const getGridPoint = (e) => {
    const parentRect = mainStreamRef.current.getBoundingClientRect();
    const x = e.clientX - parentRect.x;
    const y = e.clientY - parentRect.y;
    const center = [
      parseInt(parentRect.width / 2, 10),
      parseInt(parentRect.height / 2, 10),
    ];
    return {
      x: x,
      y: y,
      centeredX: x - center[0],
      centeredY: y - center[1],
    };
  };

  const cleanup = () => {
    window.removeEventListener("resize", () => computeRendererSize());
  };

  return (
    <div className={classes.root} ref={mainStreamRef}>
      <div
        id="scanMainStream"
        onContextMenu={(e) => e.preventDefault()}
        className={classes.mainStream}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseUp}
      >
        <ScanStream streamKey="scan_stream_main" deleteArea={deleteArea} />
      </div>
      {slideScanning && <ScanMinimap />}

      {/* <ScanDebug src={`${scanDebugStreamSrc}&${imageHash}`} />
      <ScanDebug2 src={`${scanDebugStreamSrc}&${imageHash}`} /> */}

      {debugDataVisible && debugDataList && (
        <ScanDebugData debugDataList={debugDataList} />
      )}
      <ScanComments />
    </div>
  );
};

ScanRenderer.propTypes = {
  classes: PropTypes.object.isRequired,
  scanViewerContext: PropTypes.object.isRequired,
};

export default withScanViewerContext(withStyles(styles)(ScanRenderer));

import React, { Component } from "react";
import PropTypes from "prop-types";

import Backend from "../common/utils/Backend";

import {
  Select,
  InputLabel,
  FormHelperText,
  FormControl,
  Typography,
  MenuItem,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Tooltip,
  Button,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Folder } from "@mui/icons-material";

import withStyles from "@mui/styles/withStyles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";

import FolderChooser from "../admin/components/FolderChooser";

const styles = (theme) => ({
  root: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(600 + 3 * 2 * 8)]: {
      // [theme.breakpoints.up(500 + theme.spacing(3 * 2))]: {
      // In the mui v5 update, theme.spacing returns "48px" instead of 48.
      // Therefore, hardcoding the default theme.spacing multiplyer of 8.
      width: "100%",
      height: "100%",
      margin: 0,
      overflow: "auto",
    },
  },
  licensStatusContainer: {
    position: "relative",
  },
  licensingInputArea: {
    "& textarea": {
      overflow: "auto !important",
      minHeight: 50,
      maxHeight: "184px",
    },
  },
  textAreaButton: {
    display: "block",
  },
  paper: {
    maxWidth: 600,
    margin: "20px auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
    width: "100%",
  },
});

class LicensingPage extends Component {
  constructor(props) {
    super(props);
    this.selectedFolderPath = "storagePath";
    this.state = {
      licenseStatus: "",
      validationMsg: "",
      hardwareID: "",
      os: "Windows",
      expirationDate: "",
      isSubmitting: false,
      showStoragePathChooser: false,
      folderPath: "",
      showFolderChooser: false,
      validationObject: {
        storagePath: true,
        tempPath: true,
        galleryOutputPath: true,
        javaPath: true,
      },
      formData: {
        storagePath: "",
        tempPath: "",
        galleryOutputPath: "",
        javaPath: "",
        localhost: false,
        licenseKey: "",
      },
    };

    Backend.getLicensingInfo((result) => this.setState(result));
    Backend.readAppSettingsLicensing((result) => {
      const formData = result;
      this.setState({ formData });
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };

  correctPath(path) {
    path = path.replace(/\\/g, "/");
    while (path.includes("//")) {
      path = path.replace("//", "/");
    }
    if (!path.endsWith("/")) {
      path = path + "/";
    }
    return path;
  }

  handleSubmit = () => {
    this.setState({
      isSubmitting: true,
    });

    const { formData } = this.state;

    let storagePath = formData.storagePath;
    let tempPath = formData.tempPath;
    let galleryOutputPath = formData.galleryOutputPath;
    let javaPath = formData.javaPath;

    storagePath = this.correctPath(storagePath);
    tempPath = this.correctPath(tempPath);
    galleryOutputPath = this.correctPath(galleryOutputPath);

    formData["storagePath"] = storagePath;
    formData["tempPath"] = tempPath;
    formData["galleryOutputPath"] = galleryOutputPath;
    formData["javaPath"] = javaPath;

    this.setState({ formData });

    Backend.writeAppSettingsLicensing(this.state.formData, (data) => {
      let allValid = true;
      let dialogString = "";
      let validationObject = this.state.validationObject;
      for (const [key, value] of Object.entries(data)) {
        if (!value) {
          allValid = false;
          validationObject[key] = false;

          if (key === "javaPath") {
            dialogString += "File not found: ";
          } else {
            dialogString += "Folder not found: ";
          }
          dialogString += formData[key] + "\n";
        }
      }
      if (allValid) {
        window.location.reload();
      } else {
        window.openWarningDialog(dialogString);
        this.setState({ isSubmitting: false, validationObject });
      }
    });
  };

  removeErrorStyle = (key) => {
    let validationObject = this.state.validationObject;
    if (validationObject[key] !== "none") {
      validationObject[key] = true;
      this.setState({ validationObject });
    }
  };

  handleCopyLink = () => {
    navigator.clipboard.writeText(this.state.hardwareID);
    window.showSuccessSnackbar("Hardware Id copyied to clipboard!");
  };

  pasteLicenseKeyFromClipboard = () => {
    navigator.clipboard
      .readText()
      .then((text) => {
        let formData = this.state.formData;
        formData.licenseKey = text;
        this.setState({ formData });
        window.showSuccessSnackbar(
          "License Key replaced with clipboard content!"
        );
      })
      .catch((err) => {
        window.openErrorDialog("Failed to read clipboard contents: " + err);
      });
  };

  readLicenseKeyFromFile = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".txt";

    fileInput.onchange = () => {
      const file = fileInput.files[0];
      if (file && file.size <= 1048576) {
        const fileReader = new FileReader();

        fileReader.onload = (event) => {
          const fileContents = event.target.result;
          let formData = this.state.formData;
          formData.licenseKey = fileContents;
          this.setState({ formData });
          window.showSuccessSnackbar("License Key replaced with file content!");
        };

        fileReader.readAsText(file);
      } else if (file && file.size > 1048576) {
        window.showErrorSnackbar(
          "Selected file is too big to contain a License Key!"
        );
      }
    };

    fileInput.click();
  };

  handleAccept = (key, path) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [key]: path,
      },
    }));
  };

  render() {
    const { classes } = this.props;
    const { isSubmitting, error, formData } = this.state;

    return (
      <main className={classes.root}>
        <CssBaseline />
        <Paper className={classes.paper}>
          {this.state.licenseStatus === "INVALID" && (
            <h1>Invalid License Key!</h1>
          )}
          {this.state.licenseStatus === "CRACKED" && (
            <h1>Cracked License Key!</h1>
          )}
          {this.state.licenseStatus === "VALID" && <h1>Valid License Key</h1>}
          <div className={classes.statusBlock}>
            <strong>License State: </strong>
            {this.state.licenseStatus}
            <br />
            {this.state.validationMsg !== "" && (
              <React.Fragment>
                <strong>Validation Message: </strong>
                {this.state.validationMsg}
                <br />
              </React.Fragment>
            )}
            {this.state.expirationDate !== "" && (
              <React.Fragment>
                <strong>Expiration Date: </strong>
                {this.state.expirationDate}
                <br />
              </React.Fragment>
            )}

            <FormControl
              fullWidth
              sx={{ m: 1, margin: "10px 0 0 0" }}
              variant="outlined"
            >
              <InputLabel>Hardware Id</InputLabel>
              <OutlinedInput
                fullWidth
                type="text"
                value={this.state.hardwareID}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="copy link to clipboard"
                      onClick={this.handleCopyLink}
                      edge="end"
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label="Stream link"
              />
            </FormControl>
          </div>
          <br />

          {this.state.licenseStatus === "VALID" && (
            <Button color="primary" variant="contained" href="/login">
              Sign In
            </Button>
          )}
          <ValidatorForm className={classes.form} onSubmit={this.handleSubmit}>
            {error && (
              <Typography
                align="center"
                variant="caption"
                style={{ color: "#ff0000" }}
              >
                {error}
              </Typography>
            )}

            <FolderChooser
              title="Choose Directory"
              open={this.state.showFolderChooser}
              isRelative={false}
              storagePath={this.state.folderPath}
              onClose={(path) => {
                if (path) {
                  this.handleAccept(this.selectedFolderPath, path);
                } 
                this.setState({ showFolderChooser: false, folderPath: "" });
              }}
            />
            <TextValidator
              className={classes.inputField}
              style={{
                border: this.state.validationObject.storagePath
                  ? "none"
                  : "5px solid red",
              }}
              onClick={() => this.removeErrorStyle("storagePath")}
              fullWidth
              margin="normal"
              name="storagePath"
              label="Storage Path"
              type="text"
              validators={["required"]}
              errorMessages={["this field is required"]}
              onChange={this.handleChange}
              value={formData.storagePath}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className={classes.inputButton}
                      onClick={() => {
                        this.selectedFolderPath = "storagePath";
                        this.setState({
                          showFolderChooser: true,
                          folderPath: "",
                        });
                      }}
                    >
                      <Folder />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextValidator
              style={{
                border: this.state.validationObject.tempPath
                  ? "none"
                  : "5px solid red",
              }}
              onClick={() => this.removeErrorStyle("tempPath")}
              fullWidth
              margin="normal"
              name="tempPath"
              label="Data Path"
              type="text"
              validators={["required"]}
              errorMessages={["this field is required"]}
              onChange={this.handleChange}
              value={formData.tempPath}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className={classes.inputButton}
                      onClick={() => {
                        this.selectedFolderPath = "tempPath";
                        this.setState({ showFolderChooser: true });
                      }}
                    >
                      <Folder />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextValidator
              style={{
                border: this.state.validationObject.galleryOutputPath
                  ? "none"
                  : "5px solid red",
              }}
              onClick={() => this.removeErrorStyle("galleryOutputPath")}
              fullWidth
              margin="normal"
              name="galleryOutputPath"
              label="Gallery Output Path"
              type="text"
              validators={["required"]}
              errorMessages={["this field is required"]}
              onChange={this.handleChange}
              value={formData.galleryOutputPath}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className={classes.inputButton}
                      onClick={() => {
                        this.selectedFolderPath = "galleryOutputPath";
                        this.setState({ showFolderChooser: true });
                      }}
                    >
                      <Folder />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextValidator
              style={{
                border: this.state.validationObject.javaPath
                  ? "none"
                  : "5px solid red",
              }}
              onClick={() => this.removeErrorStyle("javaPath")}
              fullWidth
              margin="normal"
              name="javaPath"
              label="Java Path"
              type="text"
              validators={["required"]}
              errorMessages={["this field is required"]}
              onChange={this.handleChange}
              value={formData.javaPath}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className={classes.inputButton}
                      onClick={() => {
                        this.selectedFolderPath = "javaPath";
                        this.setState({ showFolderChooser: true });
                      }}
                    >
                      <Folder />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <FormControl fullWidth margin="normal">
              <InputLabel>Localhost?</InputLabel>
              <Select
                name="SelectLocalhost"
                label="Localhost?"
                value={formData.localhost}
                onChange={this.handleChange}
                inputProps={{
                  name: "localhost",
                  id: "localhost-native-helper",
                }}
              >
                <MenuItem value={false}>False</MenuItem>
                <MenuItem value={true}>True</MenuItem>
              </Select>
              <FormHelperText>Set True to use remotely</FormHelperText>
            </FormControl>
            <div className={classes.licensStatusContainer}>
              <TextValidator
                className={classes.licensingInputArea}
                style={{
                  border:
                    this.state.licenseStatus === "VALID"
                      ? "none"
                      : "5px solid red",
                }}
                fullWidth
                margin="normal"
                name="licenseKey"
                label="License Key"
                type="text"
                validators={["required"]}
                errorMessages={["this field is required"]}
                onChange={this.handleChange}
                value={formData.licenseKey}
                variant="outlined"
                multiline
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <div>
                        <Tooltip title="Paste content from clipboard">
                          <IconButton
                            className={classes.textAreaButton}
                            onClick={this.pasteLicenseKeyFromClipboard}
                          >
                            <ContentPasteIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Import License key from File!">
                          <IconButton
                            className={classes.textAreaButton}
                            onClick={this.readLicenseKeyFromFile}
                          >
                            <FileUploadIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <Button
              className={classes.submit}
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Submit
            </Button>
          </ValidatorForm>
          {this.state.licenseStatus !== "VALID" && this.state.os !== "Windows" && (
            <div>
              <strong>To Update LicenseKey do following steps: </strong>
              <br />
              <ol>
                <li>Get Valid License Key</li>
                <li>Open env.list</li>
                <li>Paste Valid License Key to HSA_LICENSE_KEY</li>
                <li>Shutdown docker container</li>
                <li>
                  Restart Container with <code>docker-compose up</code>
                </li>
              </ol>
            </div>
          )}
        </Paper>
      </main>
    );
  }
}

LicensingPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LicensingPage);

import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import {
  updateLayer,
  findSameLayer,
  getIntersections,
  findRoi,
  createRegionRoi,
} from "../../utils/PolygonUtil";
import Tool from "./Tool";

class CopyTool extends Tool {
  name = "Copy";
  includeBaseROI = false;
  mode = "copy";

  setLayer(obj) {
    this.layer = obj.layer;
    this.roiLayers = obj.roiLayers;
    this.selectedLayer = obj.selectedLayer;
    let layerResults = findSameLayer(obj.structures, obj.selectedLayer);
    this.selectedSameLayer = layerResults[0];
    this.structures = obj.structures;
  }

  setPreviewRect() {}

  /** Handeling of mouse events.
   *
   * @param {MouseEvent} event
   * @param {Object} p x and y of cursor {x:, y: }
   * @param {String} color Color of the currently selected structure (#012DEF)
   * @param {Bool} subtype Is the mouse over a subtype or not?
   * @param {String} name Name of the structure the cursor is above.
   * @param {Bool} positionInRoiLayer (Not used) Is the cursor currently in a ROI Layer?
   * @param {Bool} fullyLoaded (Not used)
   * @param {Int} parentLayer (Not used) The id of the parentlayer of the structure the curson is above.
   */
  mouse(params) {
    let { event, p, color, subtype, name } = params;
    // mouse middle button
    if (event.button === 1) {
      return;
    }
    // right mouse button -> clear
    this.clear = event.button === 2;

    // Click on something
    if (event.type === "mouseup") {
      const historyLength = window.projectHistory.getHistoryLength();
      let historyItem = [];
      let histId = this.structures[this.selectedLayer].id;

      let drawRegions = []; // Coordinates of the new structures
      let foundRoiResults = findRoi(
        p,
        this.roiLayers,
        this.structures,
        this.includeBaseROI
      );
      let foundRegionIndex = foundRoiResults[0];
      let foundRoi = foundRoiResults[1];

      // No Results: do nothing.
      if (foundRegionIndex < 0) return;

      // Same (parent) layer: do nothing.
      if (
        foundRegionIndex === this.selectedLayer &&
        !this.clear &&
        !foundRoi.isSubtype
      ) {
        return;
      }

      // Singular operations:
      if (
        this.mode === "copy" ||
        this.mode === "convert" ||
        this.mode === "overlap"
      ) {
        // Add found ROI to the new ROIs
        if (foundRoi !== null) drawRegions.push(foundRoi);
      }

      // Full-layer operations:
      else if (
        this.mode === "copyAll" ||
        this.mode === "convertAll" ||
        this.mode === "overlapAll"
      ) {
        let allRegions = this.roiLayers[
          foundRegionIndex
        ].layer.regionRois.filter(
          (regionroi) => regionroi.subtypeName === foundRoi.subtypeName // Only look at the same subtype
        );
        // Overlap all:
        if (this.mode === "overlapAll") {
          let intersection = getIntersections(
            this.layer.regionRois.filter((roi) => !roi.isObject), // Overlapping items cannot be objects
            allRegions
          );
          if (
            intersection !== null &&
            intersection.geometry.coordinates.length > 0
          ) {
            drawRegions = intersection.geometry.coordinates.map((roi_region) =>
              createRegionRoi(
                roi_region,
                this.structures[this.selectedLayer].color,
                this.structures[this.selectedLayer].isSubtype,
                false, // Not fully loaded
                this.structures[this.selectedLayer].id,
                false // Overlapping items cannot be objects
              )
            );
          }
          if (!this.clear) {
            for (let roi of this.layer.regionRois) {
              historyItem.push({ add: false, id: histId, roi: roi });
            }
            this.layer.regionRois = [];
            this.roiLayers[this.selectedLayer].tree.clear();
          }
        }
        // Convert or copy all: add all found ROIs to the new ROIs
        else {
          drawRegions = allRegions;
        }
      }

      //For convert, delete the ROIs from structure A before writing them to B
      if (
        !this.clear &&
        (this.mode === "convert" || this.mode === "convertAll")
      ) {
        if (typeof drawRegions !== "undefined") {
          updateLayer(
            this.roiLayers[foundRegionIndex].layer,
            { regions: drawRegions, inverted: false },
            true, // delete old poly
            color,
            subtype,
            name,
            this.roiLayers[foundRegionIndex].tree,
            null,
            null,
            false,
            null,
            this.structures[foundRegionIndex].id,
            null,
            null,
            foundRoi.isObject
          );
        }
      }

      // Write the new regions to the selected layer
      if (typeof drawRegions !== "undefined") {
        updateLayer(
          this.layer,
          { regions: drawRegions, inverted: false },
          this.clear,
          color,
          subtype,
          name,
          this.roiLayers[this.selectedSameLayer].tree,
          -1,
          false,
          this.mode === "overlap",
          null,
          this.structures[this.selectedLayer].id,
          null,
          null,
          foundRoi.isObject
        );
      }

      window.projectHistory.add(historyItem);

      const historyLength2 = window.projectHistory.getHistoryLength();
      window.projectHistory.mergePastItems(historyLength2 - historyLength);
    }
  }

  drawCustomCursor() {}

  exit() {}

  renderConfiguration() {
    return (
      <div>
        <Typography variant="h6">{this.name}:</Typography>
        <ConfigForm
          mode={this.mode}
          includeBaseROI={this.includeBaseROI}
          onChange={(e) => {
            this.includeBaseROI = e;
          }}
          onChangeMode={(e) => {
            this.mode = e;
          }}
        />
      </div>
    );
  }
}

class ConfigForm extends Component {
  state = { mode: "copy" };
  render() {
    let { onChange, onChangeMode } = this.props;
    let { mode, includeBaseROI } = this.state;
    return (
      <div>
        <FormControl component="fieldset" fullWidth>
          <RadioGroup
            value={mode}
            aria-label="mode"
            name="mode"
            onChange={(e) => {
              onChangeMode(e.target.value);
              this.setState({ mode: e.target.value });
            }}
          >
            <FormGroup row>
              <FormControlLabel
                control={<Radio color="primary" value="copy" />}
                label="Copy"
                style={{ width: "150px" }}
              />
              <FormControlLabel
                color="primary"
                control={<Radio color="primary" value="copyAll" />}
                label="Copy all"
              />
            </FormGroup>

            <FormGroup row>
              <FormControlLabel
                color="primary"
                control={<Radio color="primary" value="convert" />}
                label="Convert"
                style={{ width: "150px" }}
              />
              <FormControlLabel
                control={<Radio color="primary" value="convertAll" />}
                label="Convert all"
              />
            </FormGroup>
            <FormGroup row>
              <FormControlLabel
                control={<Radio color="primary" value="overlap" />}
                label="Overlap"
                style={{ width: "150px" }}
              />
              <FormControlLabel
                control={<Radio color="primary" value="overlapAll" />}
                label="Overlap all"
              />
            </FormGroup>
          </RadioGroup>
        </FormControl>
        <FormControl component="fieldset">
          {
            <FormControlLabel
              control={
                <Checkbox
                  name="CheckboxIncludeBaseROI"
                  value={includeBaseROI}
                  color="primary"
                  onChange={(e) => {
                    onChange(e.target.checked);
                    this.setState({ includeBaseROI: e.target.checked });
                  }}
                />
              }
              label="Include Base ROI"
            />
          }
        </FormControl>
      </div>
    );
  }
}

ConfigForm.propTypes = {
  onChange: PropTypes.func,
  onChangeMode: PropTypes.func,
};

export default CopyTool;

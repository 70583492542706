// Copyright: HS Analysis GmbH, 2023
// Author: Valentin Haas

// Framework imports
import React from "react";
import PropTypes from "prop-types";

// Material UI packages

// HSA packages
import AITrainingSettings, {
  trainingDataTypebyModuleName,
} from "../../../common/components/AITrainingSettings";

import {
  CommonlyUsedCategory,
  AdvancedDatasetSettings,
  AdvancedModelSettings,
} from "./SettingCategories";

/**
 * Grouped setting selection options for the model and dataset.
 * Depending on the project type, different relevant options are available.
 * @param {AITrainingSettings} trainingSettings The training settings for the AI model and dataset.
 * @param {function} setTrainingSettings Function to update the training settings.
 * @param {object} ome The OME metadata for an image.
 * @param {string} projectType The type of the project.
 * @returns {JSX.Element} Accordion components allowing to adjust the model and dataset settings.
 */
export default function ModelAndDatasetSettings(props) {
  const { trainingSettings, setTrainingSettings, ome, projectType } = props;
  const [openAccordion, setOpenAccordion] = React.useState("common");
  const trainingDataType = trainingDataTypebyModuleName(projectType);

  return (
    <>
      <CommonlyUsedCategory
        trainingSettings={trainingSettings}
        setTrainingSettings={setTrainingSettings}
        trainingDataType={trainingDataType}
        open={openAccordion === "common"}
        updateOpen={(isOpen) => setOpenAccordion(isOpen ? "common" : null)}
      />
      <AdvancedDatasetSettings
        trainingSettings={trainingSettings}
        setTrainingSettings={setTrainingSettings}
        trainingDataType={trainingDataType}
        ome={ome}
        open={openAccordion === "dataset"}
        updateOpen={(isOpen) => setOpenAccordion(isOpen ? "dataset" : null)}
      />
      <AdvancedModelSettings
        trainingSettings={trainingSettings}
        setTrainingSettings={setTrainingSettings}
        open={openAccordion === "model"}
        updateOpen={(isOpen) => setOpenAccordion(isOpen ? "model" : null)}
      />
    </>
  );
}

ModelAndDatasetSettings.propTypes = {
  trainingSettings: PropTypes.instanceOf(AITrainingSettings).isRequired,
  setTrainingSettings: PropTypes.func.isRequired,
  ome: PropTypes.object,
  projectType: PropTypes.string,
};

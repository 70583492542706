import React, { useRef, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import {
  ListItem,
  TextField,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EyeIconButton from "./EyeIconButton";
import CaseSettingsEditableOptionsMenu from "./CaseSettingsEditableOptionsMenu";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  selectFieldDatatype: {
    width: 300,
    marginRight: 5,
  },
});

const types = {
  text: "Text",
  options: "Options",
  date: "Date",
  textArea: "Text Area",
};

const CaseSettingsListItem = forwardRef((props, forwardedRef) => {
  const caseSettingsEditableOptionsMenuRef = useRef(null);

  const { data, idx, onDeleteRow } = props;
  const classes = useStyles();
  const [label, setLabel] = React.useState(data.label);
  const [dataType, setDataType] = React.useState(data.type);
  const [defaultValue, setDefaultValue] = React.useState(data.defaultValue);
  const [visible, setVisible] = React.useState(data.visible);

  const isDisabled = data.label === "Case Acc#";

  const getCaseData = () => {
    let items = [];
    let returnDefaultValue = defaultValue;
    if (dataType === types.options) {
      const optionsData =
        caseSettingsEditableOptionsMenuRef.current.getOptionsData();
      items = dataType === types.options ? optionsData.items : data.items;
      returnDefaultValue = optionsData.defaultValue;
    }

    return {
      label,
      type: dataType,
      defaultValue: returnDefaultValue,
      visible,
      items,
    };
  };

  useImperativeHandle(forwardedRef, () => ({
    getCaseData,
  }));

  return (
    <ListItem>
      {!isDisabled && (
        <DragIndicatorIcon
          style={{ cursor: "pointer" }}
          className="drag-handle"
        />
      )}
      <TextField
        className={classes.selectFieldDatatype}
        name="Textfield"
        style={{ marginLeft: isDisabled ? 24 : 0 }}
        disabled={isDisabled}
        select
        label="Datatype"
        variant="outlined"
        value={dataType}
        onChange={(e) => setDataType(e.target.value)}
      >
        {Object.keys(types).map((key, idx) => {
          return (
            <MenuItem key={key + idx} value={types[key]}>
              {types[key]}
            </MenuItem>
          );
        })}
      </TextField>
      <TextField
        style={{
          marginRight: 5,
        }}
        name="Textfield"
        disabled={isDisabled}
        fullWidth
        variant="outlined"
        value={label}
        label="Name"
        onChange={(e) => setLabel(e.target.value)}
      />
      {dataType === types.options && (
        <CaseSettingsEditableOptionsMenu
          ref={caseSettingsEditableOptionsMenuRef}
          data={data}
          idx={idx}
        />
      )}
      {dataType === types.date && (
        <TextField
          className={classes.dataTypeFieldPreset}
          name="Textfield"
          fullWidth
          select
          variant="outlined"
          value={defaultValue}
          label="Default Value"
          onChange={(e) => {
            setDefaultValue(e.target.value);
          }}
        >
          <MenuItem value="None">None</MenuItem>
          <MenuItem value="Current Date">Current Date</MenuItem>
        </TextField>
      )}
      {dataType === types.text && (
        <TextField
          className={classes.dataTypeFieldPreset}
          name="Textfield"
          fullWidth
          disabled={isDisabled}
          variant="outlined"
          value={defaultValue}
          label="Default Value"
          onChange={(e) => {
            setDefaultValue(e.target.value);
          }}
        />
      )}
      {dataType === types.textArea && (
        <TextField
          className={classes.dataTypeFieldPreset}
          name="Textfield"
          fullWidth
          multiline
          minRows="1"
          variant="outlined"
          value={defaultValue}
          label="Default Value area"
          onChange={(e) => {
            setDefaultValue(e.target.value);
          }}
        />
      )}
      <Tooltip
        title={visible ? "Hide from default View!" : "Show in default View!"}
      >
        <span>
          <EyeIconButton
            checked={visible}
            onClick={() => setVisible((prevValue) => !prevValue)}
          />
        </span>
      </Tooltip>

      <Tooltip disableInteractive title={"Delete Case Column!"}>
        <span>
          <IconButton
            disabled={isDisabled}
            onClick={() => onDeleteRow(idx)}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>
    </ListItem>
  );
});

CaseSettingsListItem.displayName = "CaseSettingsListItem";

CaseSettingsListItem.propTypes = {
  data: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired,
  onDeleteRow: PropTypes.func.isRequired,
};

export default CaseSettingsListItem;

// Framework imports
import "./css/global.css";
import React, { Component } from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";

// External packages
import withStyles from "@mui/styles/withStyles";
import { Button } from "@mui/material";
import { withSnackbar } from "notistack";

// HSA packages
import { PrivateRoute } from "./common/components";
import { Role } from "./common/utils";
import { TilesProvider } from "./viewer/contexts/TilesContext";
import AboutPage from "./about/AboutPage";
import AdminPage from "./admin/AdminPage";
import AudioViewer from "./audioViewer/AudioViewer";
import AIViewer from "./aiViewer/AIViewer";
import CasesPage from "./cases/CasesPage";
import CustomDialog from "./common/components/CustomDialog";
import FileViewer from "./fileViewer/FileViewer";
import HomePage from "./home/HomePage";
import LicensingPage from "./licensing/LicensingPage";
import LoginPage from "./account/LoginPage";
import NavigationBar from "./common/components/NavigationBar";
import PersistentStorageProvider from "./viewer/contexts/PersistentStorageContext";
import PredictionPage from "./prediction/PredictionPage";
import ProjectHistoryProvider from "./viewer/contexts/ProjectHistoryContext";
import ProjectProvider from "./viewer/contexts/ProjectContext";
import ProteomViewer from "./proteomViewer/ProteomViewer";
import Report from "./home/Report";
import ResultTabProvider from "./viewer/contexts/ResultTabContext";
import ScanProvider from "./scanViewer/contexts/ScanViewerContext";
import ScanViewer from "./scanViewer/ScanViewer";
import SettingsPage from "./settings/SettingsPage";
import SpectraViewer from "./spectraViewer/SpectraViewer";
import SpectraViewerProvider from "./spectraViewer/contexts/SpectraViewerContext";
import UserHistoryPage from "./userhistory/UserHistoryPage";
import Viewer from "./viewer/Viewer";

const styles = (theme) => ({
  root: {
    height: "100vh",
    display: "grid",
    gridTemplateRows: "auto 1fr",
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    height: 50,
  },
  appbarPlaceholder: theme.mixins.toolbar,
});

class App extends Component {
  static displayName = App.name;

  render() {
    const { classes } = this.props;

    /**
     * Adds a green success snackbar at bottom left of window.
     * @param {string} str Message to display.
     */
    window.showSuccessSnackbar = (str) => {
      this.props.enqueueSnackbar(str, {
        variant: "success",
      });
    };

    /**
     * Adds an orange warning snackbar at bottom left of window.
     * @param {string} str Message to display.
     */
    window.showWarningSnackbar = (str) => {
      this.props.enqueueSnackbar(str, {
        variant: "warning",
      });
    };

    /**
     * Adds a red error snackbar at bottom left of window.
     * @param {string} str Message to display.
     */
    window.showErrorSnackbar = (str) => {
      this.props.enqueueSnackbar(str, {
        variant: "error",
      });
    };

    /**
     * Adds a black info snackbar at bottom left of window.
     * @param {string} str Message to display.
     * @param {function} action Action to perform once confirmed by user.
     * @param {string} action_msg Message displayed on the primary button.
     * @param {string} hide_msg Optional. Message displayed on dismiss button. If undefined, button will be hidden.
     */
    window.showActionSnackbar = (str, action, action_msg, hide_msg) => {
      // Close the old snackbar before showing the new one
      this.props.closeSnackbar();

      this.props.enqueueSnackbar(str, {
        action: (sbId) => (
          <>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                action();
                this.props.closeSnackbar(sbId);
              }}
            >
              {action_msg}
            </Button>
            {(hide_msg || hide_msg === 0) && hide_msg && (
              <Button
                sx={{ marginLeft: "3px" }}
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => this.props.closeSnackbar(sbId)}
              >
                {hide_msg}
              </Button>
            )}
          </>
        ),
        persist: true,
      });
    };

    return (
      <div className={classes.root}>
        <div className={classes.appbarPlaceholder} />
        <PrivateRoute
          path="/audio_view/:id"
          component={({ match }) => <AudioViewer projectId={match.params.id} />}
        />

        <PrivateRoute path="/ai_view" component={() => <AIViewer />} />

        <PrivateRoute
          path="/view/:id"
          component={({ match }) => (
            <PersistentStorageProvider
              projectId={match.params.id ? match.params.id : "001"}
            >
              <ProjectProvider>
                <ProjectHistoryProvider historyDepth={20}>
                  <ResultTabProvider>
                    <TilesProvider>
                      <Viewer id={match.params.id ? match.params.id : "001"} />
                    </TilesProvider>
                  </ResultTabProvider>
                </ProjectHistoryProvider>
              </ProjectProvider>
            </PersistentStorageProvider>
          )}
        />

        <PrivateRoute
          path="/file_view/"
          component={() => (
            <PersistentStorageProvider projectId="file_view">
              <TilesProvider>
                <FileViewer />
              </TilesProvider>
            </PersistentStorageProvider>
          )}
        />

        <PrivateRoute
          path="/proteome_view/:id"
          component={({ match }) => (
            <ProteomViewer id={match.params.id ? match.params.id : "001"} />
          )}
        />
        <PrivateRoute
          path="/esr_view/:id"
          component={({ match }) => (
            <SpectraViewerProvider>
              <SpectraViewer id={match.params.id ? match.params.id : "001"} />
            </SpectraViewerProvider>
          )}
        />
        <PrivateRoute
          path="/scan_view"
          component={() => (
            <ScanProvider>
              <ScanViewer />
            </ScanProvider>
          )}
        />
        <PrivateRoute path="/cases" component={() => <CasesPage />} />
        <PrivateRoute path="/prediction" component={() => <PredictionPage />} />
        <PrivateRoute
          path="/report/:id"
          component={({ match }) => <Report id={match.params.id} />}
        />
        <Route path="/login" component={() => <LoginPage />} />
        <Route path="/licensing" component={() => <LicensingPage />} />
        <PrivateRoute exact path="/" component={() => <HomePage />} />
        <PrivateRoute path="/settings" component={() => <SettingsPage />} />
        <PrivateRoute
          path="/userhistory"
          component={() => <UserHistoryPage />}
        />
        <PrivateRoute path="/about" component={() => <AboutPage />} />
        <PrivateRoute
          path="/admin"
          roles={[Role.Admin]}
          component={AdminPage}
        />

        <NavigationBar showDevButtons={false} />
        <CustomDialog />
      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(withStyles(styles)(App));

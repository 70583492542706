import {
  updateLayer,
  findSiblingRoiLayers,
  findSameLayer,
  createRegionRoi,
  pointInside,
} from "../../utils/PolygonUtil";

import {
  Typography,
  FormControl,
  FormLabel,
  Slider,
  Button,
} from "@mui/material";
import {
  getContainedRegionRois,
  getParentIndexLayer,
} from "../../utils/StructuresUtils";
import Tool from "./Tool";
import React from "react";
import RBush from "rbush";
import * as turf from "@turf/turf";
import { buffer } from "turf";
import OverlapConfigForm from "./ConfigForms/OverlapConfigForm";

class RoiResizeTool extends Tool {
  toolName = "ROI Resize Tool";
  noConfig = false;
  removeOverlap = true;
  removeOverlapSame = true;
  tempRemoveOverlap = true;
  threshold = 1;
  tree = new RBush();

  setLayer(obj) {
    this.layer = obj.layer;
    this.roiLayers = obj.roiLayers;
    let layerResults = findSameLayer(obj.structures, obj.selectedLayer);
    this.selectedLayer = layerResults[0];
    this.parentLayer = layerResults[1];
    this.structures = obj.structures;
    this.structure = obj.structures[obj.selectedLayer]; // currently selected structure
    this.regionRois = getContainedRegionRois(
      this.structure,
      this.structures,
      this.roiLayers
    );
    this.parentIndex = getParentIndexLayer(this.structure, this.structures);
    this.spinloader = obj.spinloader;
    this.tree.clear();
    this.tree.load(this.regionRois.map((item) => item.treeItem));

    // set removeOverlap
    // save current settings (in tempRemoveOverlap) if structure gets changed
    if (obj.selectedLayer === 0) {
      if (!this.noConfig) {
        this.noConfig = true;
        this.tempRemoveOverlap = this.removeOverlap;
        this.removeOverlap = false;
        window.forceSidebarUpdate();
      }
    } else {
      if (this.noConfig) {
        this.noConfig = false;
        this.removeOverlap = this.tempRemoveOverlap;
        window.forceSidebarUpdate();
      }
    }
  }

  findSmallestTreeItem(p) {
    let minArea = Number.MAX_SAFE_INTEGER;
    let resultItem;
    if (this.structures[this.selectedLayer].visible) {
      let treeItems = this.tree.search({
        minX: p.x,
        minY: p.y,
        maxX: p.x,
        maxY: p.y,
      });
      for (let treeItem of treeItems) {
        let b = treeItem.roi.bounds;

        if (pointInside(p, treeItem.roi)) {
          let bArea = (b.right - b.left) * (b.bottom - b.top);
          if (bArea < minArea) {
            minArea = bArea;
            resultItem = treeItem;
          }
        }
      }
    }
    return resultItem;
  }

  deleteItem = (item, parentIdx) => {
    let historyItem = [];
    let histId = this.structures[this.selectedLayer].id;
    historyItem.push({ add: false, id: histId, roi: item.roi });
    this.roiLayers[parentIdx].tree.remove(item);
    this.roiLayers[parentIdx].layer.regionRois = this.roiLayers[parentIdx].tree
      .all()
      .map((treeItem) => treeItem.roi);
    window.projectHistory.add(historyItem);
  };

  pointsToUpdateLayer = (newPoints, treeItem, pIndex) => {
    let layerResults = findSameLayer(
      this.structures,
      this.roiLayers.findIndex(
        (element) => element.id === treeItem.roi.structureId
      )
    );
    this.selectedLayer = layerResults[0];
    this.parentLayer = layerResults[1];

    let item = treeItem.roi;
    let regionRoi = createRegionRoi(
      newPoints,
      item.color,
      item.isSubtype,
      item.subtypeName,
      item.fullyLoaded,
      item.structureId,
      false
    );

    let drawRegion = {
      regions: [],
      inverted: false,
    };

    drawRegion.regions.push(regionRoi);

    let overlapRoiLayers = [];
    if (this.removeOverlapSame) {
      overlapRoiLayers.push(this.roiLayers[this.selectedLayer]);
    }
    if (this.removeOverlap) {
      let siblingRoiLayers = findSiblingRoiLayers(
        this.structures,
        this.selectedLayer,
        this.roiLayers
      );
      siblingRoiLayers.map((layer) => overlapRoiLayers.push(layer));
    }

    this.deleteItem(treeItem, pIndex);

    updateLayer(
      this.roiLayers[this.selectedLayer].layer,
      drawRegion,
      false, //clear
      item.color,
      item.isSubtype,
      item.subtypeName,
      this.roiLayers[this.selectedLayer].tree,
      this.positionInRoiLayer,
      item.fullyLoaded,
      false, //overlap
      this.roiLayers[this.parentLayer],
      item.structureId,
      overlapRoiLayers,
      false //clicekd on roi
    );
  };

  getAllChildsOfStructure = (structure, structures) => {
    let affectedStructures = [];
    affectedStructures.push(structure);
    for (let affStruct of affectedStructures) {
      if (affStruct.hasChild) {
        let childs = structures.filter((struct) =>
          struct.parentId === affStruct.id ? struct : false
        );
        for (let child of childs) {
          if (!child.classificationSubtype) {
            affectedStructures.push(child);
          }
        }
      }
    }
    return affectedStructures;
  };

  growPoly = (treeItem, pIndex) => {
    let newPoints = [];
    let turfpoly = turf.polygon(treeItem.roi.regions);
    let bufferedTurfPoly = buffer(turfpoly, this.threshold * 160);
    if (bufferedTurfPoly.geometry.coordinates[0].length > 0) {
      bufferedTurfPoly = turf.simplify(bufferedTurfPoly, {
        tolerance: 0.05,
        highQuality: false,
      });
    }
    newPoints = bufferedTurfPoly.geometry.coordinates;
    if (newPoints[0].length < 5 && turf.area(bufferedTurfPoly) < 1) return;
    this.pointsToUpdateLayer(newPoints, treeItem, pIndex);
  };

  processStructures = (structures) => {
    this.spinloader.show();
    this.historyItem = [];
    this.histId = this.structures[this.selectedLayer].id;
    let roiCount = 0;
    let roisProcessed = 0;
    for (let structure of structures) {
      let regionRois = getContainedRegionRois(
        structure,
        this.structures,
        this.roiLayers
      );
      for (let regionRoi of regionRois) {
        this.historyItem.push({ add: false, id: this.histId, roi: regionRoi });
      }
      roiCount += regionRois.length;
    }
    for (let structure of structures) {
      let pIndex = getParentIndexLayer(structure, this.structures);
      let regionRois = getContainedRegionRois(
        structure,
        this.structures,
        this.roiLayers
      );
      for (let regionRoi of regionRois) {
        setTimeout(() => {
          this.growPoly(regionRoi.treeItem, pIndex);
          roisProcessed += 1;
          if (roisProcessed >= roiCount) {
            this.finishProcessStructures(structures);
          }
        }, 0);
      }
    }
  };

  finishProcessStructures = (structures) => {
    for (let structure of structures) {
      const regionRois = getContainedRegionRois(
        structure,
        this.structures,
        this.roiLayers
      );
      for (let regionRoi of regionRois) {
        this.historyItem.push({ add: true, id: this.histId, roi: regionRoi });
      }
    }
    window.projectHistory.collection = [];
    window.projectHistory.addCollection();
    window.projectHistory.add(this.historyItem);
    this.spinloader.hide();
  };

  growStructures = () => {
    window.projectHistory.startCollection();
    let structures = this.getAllChildsOfStructure(
      this.structure,
      this.structures
    );
    this.processStructures(structures);
  };

  onApply = () => {
    this.growStructures();
  };

  // triggered from Renderer, but usable from tool as well
  onKeyDown(event) {
    // enter key
    if (event.code === "Enter") {
      this.onApply();
    }
  }

  mouse(params) {
    let { color, subtype, name, positionInRoiLayer, fullyLoaded, p, event } =
      params;
    this.color = color;
    this.subtype = subtype;
    this.name = name;
    this.positionInRoiLayer = positionInRoiLayer;
    this.fullyLoaded = fullyLoaded;

    if (event.type === "mousedown" && event.button === 0) {
      let treeItem = this.findSmallestTreeItem(p);
      if (treeItem) {
        window.projectHistory.startCollection();
        this.growPoly(treeItem, this.parentIndex);
        window.projectHistory.addCollection();
      }
    }
  }

  renderConfiguration() {
    return (
      <div style={{ margin: "12px" }}>
        {/* canvas for debugging */}
        <canvas id="outputCanvas"></canvas>
        <Typography variant="h6">{this.toolName}:</Typography>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">
            Grow or shrink an indiviual ROI or all belonging to a structure
          </FormLabel>
          <FormLabel component="legend">
            {"Growth: " + this.threshold + " pixel"}
          </FormLabel>
          <Slider
            min={-4}
            max={4}
            step={0.1}
            value={this.threshold}
            onChange={(e, v) => {
              this.threshold = v;
              window.forceSidebarUpdate();
            }}
            onKeyDown={(e) => this.onKeyDown(e)} //onKeyDown also triggered from Renderer
          />
        </FormControl>
        <OverlapConfigForm
          removeOverlap={this.removeOverlap}
          removeOverlapSame={this.removeOverlapSame}
          onChangeRemoveOverlap={(e) => (this.removeOverlap = e)}
          onChangeRemoveOverlapSame={(e) => (this.removeOverlapSame = e)}
        />
        <Button
          style={{ marginTop: "5px" }}
          fullWidth
          variant="contained"
          color="primary"
          onClick={this.onApply}
        >
          Apply [Enter]
        </Button>
      </div>
    );
  }
}

export default RoiResizeTool;

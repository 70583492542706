// Framework imports
import React from "react";
import PropTypes from "prop-types";
// import { styled } from "@mui/system";

import { AudioViewerContext } from "../AudioViewer";
import withStyles from "@mui/styles/withStyles";

import { Typography, Tooltip, IconButton, ListItemButton } from "@mui/material";

// Icons
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";

// define the component's styling
const styles = () => ({
  previewImageContainer: {
    position: "relative",
  },
  importText: {
    padding: 5,
    fontWeight: "bold",
    lineHeight: "45px",
    display: "inline-block",
  },
  lineThrough1: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    pointerEvents: "none",
    background:
      "linear-gradient(to bottom right, transparent calc(50% - 1px), red, transparent calc(50% + 1px) )",
  },
  lineThrough2: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    pointerEvents: "none",
    background:
      "linear-gradient(to top right, transparent calc(50% - 1px), red, transparent calc(50% + 1px) )",
  },
  sceneItem: {
    position: "relative",
    display: "inline-block",
    width: "fit-content",
    paddingLeft: "5px",
    objectFit: "contain",
    // border: "1px solid #ccc!important",
    cursor: "pointer",
    "& button": {
      padding: 0,
      visibility: "hidden",
      zIndex: 999999,
      width: 0,
      height: 0,
    },
    "&:hover": {
      boxShadow: "1px 1px 2px #333",
      "& button": {
        visibility: "visible",
        zIndex: 999999999999,
      },
    },
  },
  excludeButton: {
    position: "absolute",
    top: 0,
    right: 0,
  },
});

/**
 * Displays all Audio Files of the project.
 */
const AudioFileTree = (props) => {
  const { classes } = props;
  const AudioViewer = React.useContext(AudioViewerContext);
  const { selectedFile } = AudioViewer;
  const { files } = AudioViewer.project;
  const selectedFileIdx =
    files && selectedFile
      ? files.findIndex((file) => file.id === selectedFile.id)
      : 0;

  const onExcludeAudioFileToggle = (e, file) => {
    file.excluded = !file.excluded;
    AudioViewer.updateFiles(files);
    e.preventDefault();
    e.stopPropagation();
  };

  const onSelectFile = (file) => {
    AudioViewer.updateSelectedFile(file);
  };

  const hasNotExcludedScenes = () => {
    const result = files.some((file) => !file.excluded);
    return result;
  };

  const onExcludeFilesToggle = () => {
    const exludedValue = hasNotExcludedScenes();
    AudioViewer.updateFiles(
      files.map((file) => {
        file.excluded = exludedValue;
        return file;
      })
    );
  };

  return (
    <div style={{ height: "100%", overflow: "auto" }}>
      {files && [
        <div
          key="AudioHeadline1"
          style={{
            position: "relative",
            display: "grid",
            gridTemplateColumns: "1fr auto",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontSize: "18px", padding: "8px 20px 0" }}
          >
            {"Files (" + files.length + "):"}
          </Typography>
          <Tooltip
            disableInteractive
            title={
              hasNotExcludedScenes(files)
                ? "Exclude all from analysis"
                : "Include all to analysis"
            }
          >
            <IconButton
              className={classes.excludeButton}
              style={{ color: "#666", marginRight: 16 }}
              size="small"
              onClick={() => {
                const toExclude = hasNotExcludedScenes(files);
                onExcludeFilesToggle(
                  files.filter((file) => file.excludeScene !== toExclude),
                  toExclude
                );
              }}
            >
              {hasNotExcludedScenes(files) ? (
                <RemoveCircleIcon />
              ) : (
                <AddCircleIcon />
              )}
            </IconButton>
          </Tooltip>
        </div>,
        <div key="FileCheckboxesList2">
          {files.map((file, fileIdx) => (
            // <div key={file.fileName}>
            //   <FormControlLabel
            //     label={file.fileName}
            //     control={
            //       <Checkbox
            //         checked={file.visible}
            //         //   indeterminate={checked[0] !== checked[1]}
            //         onChange={(e) => {
            //           file.visible = e.target.checked;
            //           AudioViewer.updateFiles(files);
            //         }}
            //       />
            //     }
            //   />
            // </div>
            <ListItemButton
              selected={fileIdx === selectedFileIdx}
              key={file.fileName}
              // className={classes.sceneItem}
              style={{
                display: "grid",
                gridTemplateColumns: "1fr auto",
                width: "100%",
              }}
            >
              <div
                className={classes.previewImageContainer}
                onClick={() => onSelectFile(file)}
              >
                <div>
                  <div className={classes.importText}>{file.fileName}</div>
                </div>
                <div
                  style={{
                    display: !file.excluded ? "none" : "block",
                  }}
                  className={classes.lineThrough1}
                ></div>
                <div
                  style={{
                    display: !file.excluded ? "none" : "block",
                  }}
                  className={classes.lineThrough2}
                ></div>
              </div>
              <Tooltip
                disableInteractive
                placement="top"
                title={
                  !file.excluded
                    ? "Exclude from analysis"
                    : "Include in analysis"
                }
              >
                <IconButton
                  // className={classes.excludeButton}
                  style={{ color: "#666" }}
                  size="small"
                  onClick={(e) => onExcludeAudioFileToggle(e, file)}
                  //   onClick={() =>
                  //     onExcludeFilesToggle([file.visilbe], !sceneFile.excludeScene)
                  //   }
                >
                  {file.excluded ? <AddCircleIcon /> : <RemoveCircleIcon />}
                </IconButton>
              </Tooltip>
            </ListItemButton>
          ))}
        </div>,
      ]}
    </div>
  );
};

AudioFileTree.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AudioFileTree);
